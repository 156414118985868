import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from '@neptune/material.module';
import { UserProfileDialogComponent } from './user-profile-dialog.component';
import { ChangePasswordDialogModule } from '../change-password-dialog/change-password-dialog.module';
import { UserService } from '@neptune/services/user.service';
import { ResponseDialogModule } from '@neptune/components/response-dialog/response-dialog.module';
import { LoadingModalModule } from '@neptune/components/loading-modal/loading-modal.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { StandardDateModule } from '@neptune/components/standard-list-page/standard-date/standard-date.module';

@NgModule({
  declarations: [UserProfileDialogComponent],
  imports: [
    FuseSharedModule,
    MaterialModule,
    ResponseDialogModule,
    LoadingModalModule,
    ChangePasswordDialogModule,
    ImageCropperModule,
    StandardDateModule
  ],
  exports: [UserProfileDialogComponent],
  providers: [UserService]
})
export class UserProfileDialogModule {}
