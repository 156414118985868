import { of as observableOf, throwError as observableThrowError, Observable, of } from 'rxjs';
import { map, delay, mergeMap, catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { APIConfig } from 'environments/environment';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { StoreService } from './store.service';
import { cloneDeep } from 'lodash';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { CognitoService } from './cognito.service';

// eslint-disable-next-line no-shadow
export enum Endpoint {
  API_KEY,
  ASSET,
  CANVAS,
  CLEANSING_RULE,
  CONNECTOR,
  CONNECTOR_NEW,
  CONNECTOR_INSTANCES,
  CONTACT,
  CONTACT_LOOKUP,
  CONTACT_PROFILE,
  DOMAIN,
  DRAIN,
  DYNAMIC_CONTENT,
  FILE,
  FILE_TRANSFER,
  FORM,
  JOIN,
  SEGMENTATION,
  SEGMENTATION_BUILDER,
  SEGMENTATION_WATERFALL,
  SEGMENTATION_WATERFALL_GRAPHQL,
  SEGMENTATION_WATERFALL_REPORT,
  JOURNEY,
  LANDING_PAGE,
  LINK,
  LIST_DEFINITION,
  MESSAGE,
  METADATA,
  MY_FILES,
  NEWS,
  NOTIFICATIONS_GRAPHQL,
  NXTDRIVE,
  ORG,
  ORG_NEW,
  PIPELINE,
  PROJECT,
  PUBLIC_HUB,
  PUBLIC_ORG,
  PUBLIC_USER,
  PURL,
  QUERY,
  REPEAT_BLOCK,
  RESOURCE,
  SCHEDULER,
  SENDER,
  TABLE,
  UDF,
  USER,
  USER_DELETE,
  WHERE_USED
}

interface StatusAttempt {
  maxWait?: number;
  retryCodes?: number[];
}

interface Error {
  status: number;
}

/**
 * Retry strategy for http observables, retry delays double on each new attempt.
 */
export const retryStrategyLogarithmic =
  ({ maxWait = 10, retryCodes = [204] }: StatusAttempt = {}) =>
  (
    // eslint-disable-next-line @typescript-eslint/ban-types
    error: Observable<Error>
  ) =>
    error.pipe(
      mergeMap((err: Error, i: number) => {
        if (retryCodes.indexOf(err.status) !== -1) {
          let count = 0;
          let totalWait = 1;
          let _delay = 1;
          while (count < i) {
            if (totalWait === maxWait) {
              console.warn(`Max wait time of ${maxWait}s reached, no more retry attempts`);
              const error1 = { error: 'No retry' };
              return observableThrowError(() => error1);
            }
            _delay = _delay * 2;
            totalWait += _delay;
            count++;
            if (totalWait > maxWait) {
              _delay -= totalWait % maxWait;
              totalWait = maxWait;
            }
          }
          console.log(`Attempt ${i + 1}: retrying in ${_delay}s making totalWait: ${totalWait}s`);
          return observableOf(err.status).pipe(delay(_delay * 1000));
        }
        const error2 = { error: `No retry on error status ${err.status}` };
        return observableThrowError(() => error2);
      })
    );

@Injectable()
export abstract class BaseService {
  protected static MAX_WAIT: number = 60;

  private endpoints: Map<Endpoint, string>;

  constructor(
    protected http: HttpClient,
    protected cognitoService: CognitoService,
    protected storeService: StoreService
  ) {
    // To avoid conflicts PLEASE TAKE A MINUTE TO ORDER ALPHABETICALLY this Map
    this.endpoints = new Map([
      [Endpoint.API_KEY, APIConfig.ApiKeyApiUrl],
      [Endpoint.ASSET, APIConfig.AssetsApiUrl],
      [Endpoint.CANVAS, APIConfig.CanvasApiURL],
      [Endpoint.CLEANSING_RULE, APIConfig.CleansingRuleApiUrl],
      [Endpoint.CONNECTOR, APIConfig.ConnectorApiUrl],
      [Endpoint.CONNECTOR_NEW, APIConfig.ConnectorApiUrlNEW],
      [Endpoint.CONTACT, APIConfig.ContactApiUrl],
      [Endpoint.CONNECTOR_INSTANCES, APIConfig.ConnectorApiUrlInstances],
      [Endpoint.CONTACT_LOOKUP, APIConfig.ContactLookupApiUrl],
      [Endpoint.CONTACT_PROFILE, APIConfig.ContactProfileUrl],
      [Endpoint.DOMAIN, APIConfig.DomainsApiUrl],
      [Endpoint.DRAIN, APIConfig.DataDrainURL],
      [Endpoint.DYNAMIC_CONTENT, APIConfig.DynamicContentApiUrl],
      [Endpoint.FILE, APIConfig.FileApiUrl],
      [Endpoint.FILE_TRANSFER, APIConfig.FileTransferApiUrl],
      [Endpoint.FORM, APIConfig.FormApiUrl],
      [Endpoint.JOIN, APIConfig.JoinApiUrl],
      [Endpoint.JOURNEY, APIConfig.JourneyApiUrl],
      [Endpoint.LANDING_PAGE, APIConfig.LandingPageApiUrl],
      [Endpoint.LINK, APIConfig.LinkApiUrl],
      [Endpoint.LIST_DEFINITION, APIConfig.ListDefinitionApiUrl],
      [Endpoint.MESSAGE, APIConfig.MessageApi],
      [Endpoint.METADATA, APIConfig.MetadataApi],
      [Endpoint.MY_FILES, APIConfig.MyFilesApiURL],
      [Endpoint.NEWS, APIConfig.NewsApiUrl],
      [Endpoint.NOTIFICATIONS_GRAPHQL, APIConfig.NotificationsGraphqlApiUrl],
      [Endpoint.ORG, APIConfig.OrgApiURL],
      [Endpoint.NXTDRIVE, APIConfig.NxtdriveApiUrl],
      [Endpoint.PIPELINE, APIConfig.DataPipelineURL],
      [Endpoint.PROJECT, APIConfig.ProjectsApiUrl],
      [Endpoint.PUBLIC_HUB, APIConfig.PublicApiHub],
      [Endpoint.PUBLIC_ORG, APIConfig.PublicOrgApiUrl],
      [Endpoint.PUBLIC_USER, APIConfig.PublicUserApiUrl],
      [Endpoint.PURL, APIConfig.PurlApiUrl],
      [Endpoint.QUERY, APIConfig.QueriesApiUrl],
      [Endpoint.RESOURCE, APIConfig.ResourceAPI],
      [Endpoint.REPEAT_BLOCK, APIConfig.RepeatBlockApiUrl],
      [Endpoint.SCHEDULER, APIConfig.SchedulerApiUrl],
      [Endpoint.SEGMENTATION, APIConfig.SegmentationApiUrl],
      [Endpoint.SEGMENTATION_BUILDER, APIConfig.SegmentationBuilderApiUrl],
      [Endpoint.SEGMENTATION_WATERFALL, APIConfig.SegmentationWaterFallApiUrl],
      [Endpoint.SEGMENTATION_WATERFALL_GRAPHQL, APIConfig.SegmentationWaterFallGraphqlApiUrl],
      [Endpoint.SEGMENTATION_WATERFALL_REPORT, APIConfig.SegmentationWaterFallReportApiUrl],
      [Endpoint.SENDER, APIConfig.SenderAPI],
      [Endpoint.TABLE, APIConfig.TableApiUrl],
      [Endpoint.UDF, APIConfig.UDFApiUrl],
      [Endpoint.USER, APIConfig.UserApiUrl],
      [Endpoint.USER_DELETE, APIConfig.DeleteUserApiUrl],
      [Endpoint.WHERE_USED, APIConfig.WhereUsedApiURL]
    ]);

    this.init();
  }

  protected init() {}

  public getStore<T>(id: string): T {
    return this.storeService.get<T>(id);
  }

  protected getEndpoint(type: Endpoint) {
    return this.endpoints.get(type);
  }

  protected getURL(type: Endpoint, url: string): string {
    return `${this.endpoints.get(type)}/${url}`;
  }

  protected getAuth(type: Endpoint): Observable<HttpHeaders> {
    return this.cognitoService.getSession().pipe(
      map((session: CognitoUserSession) =>
        new HttpHeaders().set('Authorization', `Bearer ${session.getIdToken().getJwtToken()}`)
      ),
      catchError(err => of(new HttpHeaders()))
    );
  }

  protected clearCacheFor(cacheKey) {
    this.storeService.delete(cacheKey);
  }

  protected baseGetWithHeaders<R>(
    endpoint: Endpoint,
    url: string,
    errorOnNull: boolean | null = false,
    // eslint-disable-next-line @typescript-eslint/ban-types
    params?: object
  ): Observable<HttpResponse<R>> {
    return this.getAuth(endpoint).pipe(
      mergeMap(header =>
        this.http
          .get<R>(this.getURL(endpoint, url), {
            headers: header,
            params: this.formatParams(params),
            observe: 'response'
          })
          .pipe(
            map(data => {
              if (errorOnNull && !data) {
                throw new Error('Returned data was null');
              } else {
                return data;
              }
            })
          )
      )
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected baseGet<R>(
    endpoint: Endpoint,
    url: string,
    errorOnNull: boolean | null = false,
    params?: any
  ): Observable<R> {
    return this.baseGetWithHeaders<R>(endpoint, url, errorOnNull, params).pipe(map(response => response.body as R));
  }

  protected baseGetWithCache<R>(
    cacheKey: string,
    fromCache: boolean,
    endpoint: Endpoint,
    url: string,
    errorOnNull: boolean = false,
    // eslint-disable-next-line @typescript-eslint/ban-types
    params?: object | null,
    cacheExpirationTime?: number
  ): Observable<R> {
    if (fromCache && this.storeService.has(cacheKey)) {
      return observableOf(this.storeService.get<R>(cacheKey));
    } else {
      return this.baseGetWithHeaders(endpoint, url, errorOnNull, params as object).pipe(
        map(response => {
          const res: R = <R>cloneDeep(response.body);
          return this.storeService.set<R>(cacheKey, res, cacheExpirationTime);
        })
      );
    }
  }

  protected baseGetRetry<R>(endpoint: Endpoint, url: string, maxWait: number = BaseService.MAX_WAIT): Observable<R> {
    return this.getAuth(endpoint).pipe(
      mergeMap(header =>
        this.http.get<R>(this.getURL(endpoint, url), { headers: header, params: new HttpParams() }).pipe(
          map(value => {
            if (!value) {
              throw new HttpErrorResponse({ error: 'Returned null', status: 204 });
            } else {
              return value;
            }
          }),
          retry({
            delay: retryStrategyLogarithmic({ maxWait, retryCodes: [204, 500] })
          })
        )
      )
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected basePost<T, R>(endpoint: Endpoint, url: string, body: T, extraHeader?: object): Observable<R> {
    return this.getAuth(endpoint).pipe(
      mergeMap(header => {
        if (extraHeader) {
          // eslint-disable-next-line guard-for-in
          for (const key in extraHeader) {
            header = header.set(key, extraHeader[key]);
          }
        }
        return this.http.post<R>(this.getURL(endpoint, url), body, { headers: header });
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected basePut<T, R>(endpoint: Endpoint, url: string, body: T, extraHeader?: object): Observable<R> {
    return this.getAuth(endpoint).pipe(
      mergeMap(header => {
        if (extraHeader) {
          // eslint-disable-next-line guard-for-in
          for (const key in extraHeader) {
            header = header.set(key, extraHeader[key]);
          }
        }
        return this.http.put<R>(this.getURL(endpoint, url), body, { headers: header });
      })
    );
  }

  protected basePutWithHeaders<T, R>(
    endpoint: Endpoint,
    url: string,
    body: T,
    // eslint-disable-next-line @typescript-eslint/ban-types
    extraHeader?: object
  ): Observable<HttpResponse<R>> {
    return this.getAuth(endpoint).pipe(
      mergeMap(header => {
        if (extraHeader) {
          // eslint-disable-next-line guard-for-in
          for (const key in extraHeader) {
            header = header.set(key, extraHeader[key]);
          }
        }
        return this.http.put<R>(this.getURL(endpoint, url), body, { headers: header, observe: 'response' });
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected baseDelete<R>(
    endpoint: Endpoint,
    url: string,
    params?: object | null,
    extraHeader?: object
  ): Observable<R> {
    return this.getAuth(endpoint).pipe(
      mergeMap(header => {
        if (extraHeader) {
          // eslint-disable-next-line guard-for-in
          for (const key in extraHeader) {
            header = header.set(key, extraHeader[key]);
          }
        }
        return this.http.delete<R>(this.getURL(endpoint, url), {
          headers: header,
          params: this.formatParams(params as object)
        });
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  protected timestampToHeader(timestamp: string): object {
    return {
      'If-Unmodified-Since': this.formatTimestamp(timestamp)
    };
  }

  protected formatTimestamp(ts: string): string {
    const date: Date = new Date(ts);
    // return date.toGMTString(); ** is deprecated
    // --> GMT and UTC are different timezones, they are Greenwich Mean Time and Coordinated Universal Time respectively.
    // GMT is a 'solar' timezone, whereas UTC is 'atomic'.
    // For most purposes they are essentially the same thing, however UTC is more 'universal'.
    return date.toUTCString();
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private formatParams(params?: object): HttpParams {
    let httpParams: HttpParams = new HttpParams();
    if (params) {
      // eslint-disable-next-line guard-for-in
      for (const key in params) {
        httpParams = httpParams.set(key, params[key]);
      }
    }
    return httpParams;
  }
}
