import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';

export interface UploadServiceReturnValue {
  httpRequest: Subscription;
  progress: Observable<number>;
}

@Injectable()
export class UploadService {
  constructor(private http: HttpClient) {}

  public upload(url: string, file: File): UploadServiceReturnValue {
    // Create a request including the file
    const req = new HttpRequest('PUT', url, file, {
      reportProgress: true
    });

    // Use a subject to keep track of the status
    const progress = new BehaviorSubject<number>(0);

    // Send the request to the server and subscribe for updates
    const httpRequest = this.http.request(req).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        // Report progress
        progress.next(Math.round((100 * event.loaded) / (event.total as number)));
      } else if (event instanceof HttpResponse) {
        // Complete the Observable, 100 reaches in the UploadProgress Event
        progress.complete();
      }
    });

    return {
      httpRequest,
      progress: progress.asObservable()
    };
  }
}
