import { Pipe, PipeTransform } from '@angular/core';
import { MailToNamePipe } from '../mailToName/mailToName.pipe';

@Pipe({ name: 'insertedMailOrUserToName' })
export class InsertedMailOrUserToName implements PipeTransform {
  transform(value: any) {
    const mailToName = new MailToNamePipe();

    return value.insertedByUser
      ? value.insertedByUser.firstName + ' ' + value.insertedByUser.lastName
      : mailToName.transform(value.insertedBy);
  }
}
