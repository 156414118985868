import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseResetPasswordComponent } from './reset-password.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
  declarations: [FuseResetPasswordComponent],
  imports: [MatButtonModule, MatFormFieldModule, MatInputModule, FuseSharedModule, MatIconModule, MatTooltipModule],
  exports: [FuseResetPasswordComponent]
})
export class ResetPasswordModule {}
