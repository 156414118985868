// NOTE: Please Use alphabetical order when adding new icon!!
export const icons: string[] = [
  'account_user',
  'add',
  'accept_filled',
  'accept_outline',
  'api_key',
  'back_arrow',
  'bucket',
  'checkbox-checked',
  'checkbox-unchecked',
  'checkbox_checked',
  'checkbox_unchecked',
  'clear-table',
  'close_inspector',
  'complete',
  'contact_identifier',
  'contact_search',
  'copy',
  'credentials',
  'csv',
  'clear',
  'dash',
  'dashboard',
  'data_output',
  'database',
  'database_black',
  'dataview',
  'datatype_date',
  'datatype_number',
  'datatype_text',
  'datatype_longtext',
  'date',
  'dictionary',
  'dyn_content',
  'dyn_content_16x16',
  'dyn_content_block',
  'donut_audience',
  'donut_coupon',
  'donut_data_file',
  'donut_date_decision',
  'donut_decision',
  'donut_dmm',
  'donut_email',
  'donut_form',
  'donut_import',
  'donut_landing_page',
  'donut_multiwait',
  'donut_output',
  'donut_print',
  'donut_purl',
  'donut_segment_waterfall',
  'donut_sms',
  'donut_survey',
  'donut_transform',
  'donut_wait_until',
  'donut_wait',
  'drag',
  'edit',
  'edit_black',
  'edit-copy',
  'edit_blue',
  'edit_circled',
  'export',
  'export_black',
  'filter',
  'folder',
  'folder_black',
  'folder-browser',
  'folder_16x16',
  'form',
  'formula',
  'fx',
  'fx_white',
  'hamburger',
  'help',
  'history',
  'home',
  'identifier',
  'identifier_address',
  'identifier_extended',
  'identifier_name',
  'id-verification-badge-active',
  'id-verification-badge-inactive',
  'import',
  'in-progress',
  'infinity',
  'insert_row_above',
  'insert_row_below',
  'jobs',
  'join',
  'joined_joined_table',
  'joined_table',
  'journey',
  'key',
  'key_black',
  'lists',
  'lock',
  'lock_circled',
  'log',
  'log-out',
  'message_print',
  'message_email',
  'metadata',
  'move_to',
  'multiselect',
  'myfiles',
  'news',
  'odata',
  'open_inspector',
  'options',
  'organization',
  'pan',
  'pipeline_in',
  'pipeline_in_black',
  'pipeline_out',
  'pipeline_out_black',
  'print_on_demand',
  'project',
  'project_black',
  'project_16x16',
  'proof_list',
  'purl',
  'query',
  'query_black',
  'radio-btn-checked',
  'radio_btn_checked',
  'radio_btn_unchecked',
  'ranked',
  'read_only',
  'redo',
  'redo_disabled',
  'refresh',
  'reject_filled',
  'reject_outline',
  'reorder',
  'repeat_block',
  'reports',
  'reports_black',
  'reset-password',
  'resource_css_js',
  'resource',
  'roles',
  'schedule',
  'schedule_black',
  'sb_edit',
  'sb_make_child',
  'sb_make_parent',
  'sb_percent',
  'sb_trash',
  'search',
  'search_gray',
  'seed_list',
  'select_all',
  'send-email',
  'sender_profile',
  'segmentation-waterfall',
  'settings',
  'sftp',
  'sigma',
  'size-to-fit',
  'size_to_fit_disabled',
  'sql',
  'stopped',
  'table',
  'table_black',
  'table_cleansing',
  'table_status_error',
  'table_status_error_blue',
  'table_status_inprog',
  'table_status_inprog_blue',
  'table_status_ok',
  'table_status_ok_blue',
  'time',
  'timezone',
  'trash',
  'tree-folder',
  'triangle_down',
  'triangle_right',
  'triangle_up',
  'trigger_all',
  'trigger_after_date',
  'trigger_before_date',
  'trigger_bounce',
  'trigger_click',
  'trigger_condition',
  'trigger_no_resp',
  'trigger_open',
  'trigger_purl',
  'trigger_social_media_share',
  'trigger_unsubscribe',
  'type-date',
  'type_decimal',
  'type-number',
  'type-text',
  'undo',
  'undo-last-import',
  'undo_disabled',
  'user-folder',
  'udf',
  'users',
  'view',
  'wait_for',
  'warning',
  'webpage'
];
