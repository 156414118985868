import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { PageToolbarComponent } from './page-toolbar.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { StandardSearchModule } from '../standard-search/standard-search.module';
import { ButtonGroupModule } from '@neptune/components/button-group/button-group.module';

@NgModule({
  declarations: [PageToolbarComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    StandardSearchModule,
    MatSlideToggleModule,
    FormsModule,
    ButtonGroupModule,
    RouterModule,
    FuseDirectivesModule,
    FlexLayoutModule
  ],
  exports: [PageToolbarComponent]
})
export class PageToolbarModule {}
