import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppCustomPreloader } from './app.custom-preload-strategy';
import { ReportsComponent } from './main/content/reports/reports.component';
import { AuthGuard } from './main/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    title: 'NXTDRIVE',
    redirectTo: 'pages/auth/login'
  },
  {
    path: 'pages/auth',
    title: 'NXTDRIVE',
    data: { breadcrumb: ['Home'] },
    loadChildren: () =>
      import(
        /* webpackChunkName: "authentication" */
        './main/content/pages/authentication/authentication.module'
      ).then(m => m.AuthenticationModule)
  },
  {
    path: 'pages',
    title: 'NXTDRIVE',
    redirectTo: 'pages/auth/login',
    pathMatch: 'full'
  },
  {
    path: 'pages',
    title: 'NXTDRIVE',
    loadChildren: () =>
      import(
        /* webpackChunkName: "pages" */
        './main/content/pages/pages.module'
      ).then(m => m.PagesModule),
    // Since this bundle it's too big we'll preloaded on the background.
    data: { preload: true }
  },
  // NOTE: handling sisense old url. Remove redirect if sisense url get's updated
  { path: 'pages/main/data/canvas-tables', title: 'NXTDRIVE', redirectTo: 'pages/database' },
  {
    path: 'reports',
    title: 'NXTDRIVE',
    component: ReportsComponent,
    data: { breadcrumb: ['Reports'] },
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    title: 'NXTDRIVE',
    redirectTo: 'pages/auth/login'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: AppCustomPreloader,
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
