<mat-card class="key-card">
  <mat-card-header class="h3 key-header">
    <mat-icon svgIcon="key_black" class="pr-4"></mat-icon>
    Key Field
  </mat-card-header>
  <mat-card-content fxLayout="column" fxLayoutAlign="space-around none">
    <mat-form-field>
      <mat-label>Key Fields</mat-label>
      <mat-select [(value)]="keyFields" multiple [disabled]="disableSelect" (selectionChange)="onSelected()">
        <mat-option *ngFor="let field of _fields" class="key-card-option" [value]="field">{{ field.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-error *ngIf="!disableSelect && this.keyFields.length < 1" class="warning-txt">
      A key field must be selected.
    </mat-error>
    <div *ngIf="!disableSelect && this.keyFields.length < 1" class="warning-txt">
      *Key fields must be numeric or text limited to 255 characters.
    </div>
  </mat-card-content>
</mat-card>
