import { from as observableFrom, of as observableOf, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService, Endpoint } from './base.service';

import { FunctionDataService, ExpressionRequestConvert } from '@neptune/models/expression';

@Injectable()
export class ExpressionService extends BaseService {
  public FUNCTIONS_DATA = 'FUNCTIONS_DATA';

  /**
   * For use with jasmine testing, returns a mock of service
   */
  public static mockService(jasmine: any): any {
    return {
      // getConnectors : jasmine.createSpy('getConnectors').and.returnValue(
      //     Observable.of([])
      // ),
    };
  }

  init() {}

  /**
   * Get store list functions.
   */
  getStoreFunctionList(fromCache: boolean = true): Observable<FunctionDataService[]> {
    if (fromCache) {
      const functions: FunctionDataService[] = this.storeService.get(this.FUNCTIONS_DATA);

      if (functions) {
        return observableOf(functions);
      }
    }

    return observableFrom(this.getListFunctionExpression()).pipe(
      map((result: FunctionDataService[]) => {
        const functions: FunctionDataService[] = result.map(data => data);

        this.storeService.set(this.FUNCTIONS_DATA, functions);

        return functions;
      })
    );
  }

  /**
   * List Functions data by service.
   */
  getListFunctionExpression(): Observable<FunctionDataService[]> {
    return super.baseGet<FunctionDataService[]>(Endpoint.PIPELINE, `DataPipelines/functions`);
  }

  postConvertExpression(data: ExpressionRequestConvert): Observable<any> {
    return super.basePost(Endpoint.PIPELINE, `DataPipelines/convert`, data);
  }

  postConvertExpressionToNxtdriveAPI(fileId: string, data: ExpressionRequestConvert): Observable<any> {
    return super.basePost(Endpoint.NXTDRIVE, `my-files/${fileId}/data-preview`, data);
  }

  /**
   * Inserts a new function in the cursor's current position.
   * The text following the cursor becomes the function's arguments.
   */
  insertFunction(editor: any, functionName: string): void {
    const cursorPosition = editor.getCursorPosition();
    const args = editor.session.getLine(cursorPosition.row).substring(cursorPosition.column);

    if (!args) {
      editor.insert(`${functionName}()`);
      return;
    }

    editor.session.replace(editor.find(args), `${functionName}(${args})`);
  }
}
