import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export enum ResponseType {
  ERROR,
  ETL_SUBMIT,
  SAVE_SCHEMA,
  COMMIT_SCHEMA,
  DELETE_TABLE
}

@Component({
  selector: 'response-dialog',
  templateUrl: 'response-dialog.html',
  styleUrls: ['response-dialog.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ResponseDialog implements OnInit {
  constructor(public dialogRef: MatDialogRef<ResponseDialog>, @Inject(MAT_DIALOG_DATA) public data: string) {}
  message: string;

  // eslint-disable-next-line @typescript-eslint/ban-types
  static openResponse(dialog: MatDialog, type: ResponseType, message?: string, closeHandler?: Function) {
    const text = message || ResponseDialog.getResponseMessage(type);
    const dialogRef = dialog.open(ResponseDialog, {
      data: text
    });
    if (closeHandler) {
      dialogRef.beforeClosed().subscribe(() => {
        closeHandler();
      });
    }
  }

  static getResponseMessage(type: ResponseType): string {
    switch (type) {
      case ResponseType.ERROR:
        return 'Error encountered';
      case ResponseType.ETL_SUBMIT:
        return 'Etl Submitted!';
      case ResponseType.SAVE_SCHEMA:
        return 'Table schema saved for later completion.';
      case ResponseType.COMMIT_SCHEMA:
        return 'Destination Table created!';
      case ResponseType.DELETE_TABLE:
        return 'Table deleted.';
      default:
        return `Unknown response type ${type}`;
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.message = this.data;
  }
}
