import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LongTextComponent } from '@neptune/components/long-text/long-text.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@neptune/material.module';

@NgModule({
  declarations: [LongTextComponent],
  imports: [CommonModule, FlexLayoutModule, MaterialModule],
  exports: [LongTextComponent]
})
export class LongTextModule {}
