<div class="info-dialog-wrapper">
  <div fxLayout="row" fxLayoutAlign=" center" class="info-dialog-header">
    <div>
      {{ inputData.header }}
    </div>
  </div>

  <div fxLayout="row" class="info-dialog-content">
    <div *ngIf="inputData.message" fxLayout="column">
      <div fxLayout="row">
        {{ inputData.message }}
      </div>
    </div>
    <div *ngIf="inputData.messages" fxLayout="column">
      <div fxLayout="row" *ngFor="let message of inputData.messages">
        {{ message }}
      </div>
    </div>
  </div>

  <div fxLayout="row" class="info-dialog-footer" fxLayoutAlign="space-between center">
    <div></div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <ng-container *ngIf="inputData.buttonType == buttonType.OK">
        <!-- <button mat-button class="btn-text-only" [mat-dialog-close]="true">Cancel</button>&nbsp;&nbsp; -->
        <button mat-flat-button class="btn-text-only" [mat-dialog-close]="true">Ok</button>
      </ng-container>
      <ng-container *ngIf="inputData.buttonType == buttonType.YesNo">
        <button mat-button class="btn-text-only" mat-dialog-close>No</button>
        &nbsp;&nbsp;
        <button mat-flat-button class="btn-text-only" (click)="onConfirm()">Yes</button>
        &nbsp;&nbsp;
      </ng-container>
    </div>
  </div>
</div>
