import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '../icon/icon.module';
import { DatatypeIconComponent } from './datatype-icon.component';

@NgModule({
  declarations: [DatatypeIconComponent],
  imports: [CommonModule, MatIconModule, IconModule],
  exports: [DatatypeIconComponent]
})
export class DatatypeIconModule {}
