import { Observable, of as observableOf } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DataTypeUtils, MY_FILES_LABEL, SchemaObject, TableDataType } from '@neptune/models/file';
import {
  Connector,
  ConnectorData,
  ConnectorId,
  ConnectorInstance,
  ConnectorInstanceSet,
  ConnectorOption,
  ConnectorParam,
  ConnectorSetModel,
  ConnectorStatus,
  ConnectorType,
  SecretObject,
  Databases,
  SnowflakeSchemas,
  SnowflakeTables,
  SnowflakeTableSchema
} from '@neptune/models/connector';
import { IconMap } from './source-icon-map';
import { BaseService, Endpoint } from './base.service';
import { OrgModel } from '@neptune/models';

/**
 * API respond for connector instance edit or creation
 */
interface InstanceModel {
  UpdatedAt: string;
  SecretName: string;
  InsertedBy: string;
  PublicField: [{ URI: string } | { User: string }];
  InsertedAt: string;
  InstanceId: string;
  UpdatedBy: string;
  OrgConnector: string;
}

@Injectable()
export class ConnectorService extends BaseService {
  // ** Map of sources to their icon data */
  private iconMap: IconMap = new IconMap();

  connectorApiUrl: string;

  /**
   * For use with jasmine testing, returns a mock of service
   */
  public static mockService(jasmine: any): any {
    return {
      getConnectors: jest.fn(() => observableOf([])),
      getConnectorInstanceSets: jest.fn(() => observableOf([]))
    };
  }

  init() {
    this.connectorApiUrl = super.getEndpoint(Endpoint.CONNECTOR) || '';
  }

  /**
   * Convert Connector to ConnectorData
   */
  convertConnector(connector: Connector): ConnectorData {
    const connectorData: ConnectorData = {
      name: connector.id,
      type: this.determineType(connector.id),
      title: connector.title || connector.id,
      id: connector.id,
      icon: this.iconMap.getOrCreateSvgIcon(connector.id),
      connectorOptions: connector.options.map(option => {
        // TEMP :: API is only sending back a string currently, will need an object
        const connectorOption: ConnectorOption = {
          // TODO :: need input type will be driven from Connector
          paramName: option.paramName,
          type: option.type || 'text',
          // TODO :: We may not want this to be the same as the id, if so need more data sent from API
          title: option.title || option.paramName,
          public: option.public || false
        };
        return connectorOption;
      }),
      count: connector.count,
      status: connector.status,
      direction: connector.direction,
      oauth: connector.oauth
    };
    return connectorData;
  }

  /**
   * Convert Connector to ConnectorData
   */
  orgToConnectorData(org: OrgModel): ConnectorData {
    const connectorData: ConnectorData = {
      name: org.OrgID,
      type: ConnectorType.childOrg,
      title: org.OrgID,
      id: org.OrgID,
      icon: this.iconMap.getOrCreateSvgIcon('child_org')
    };
    return connectorData;
  }

  /**
   * Determine connector source type from id
   */
  private determineType(id: string): ConnectorType {
    let type = ConnectorType.connect;
    if (id === ConnectorId.MyFiles) {
      type = ConnectorType.upload;
    }
    if (id === ConnectorId.TABLES || id === ConnectorId.LISTS) {
      type = ConnectorType.localTable;
    }

    // NOTE :: May need to have more types for FTP and other file related connections
    return type;
  }

  /**
   * Get array of available connectors.
   * Only those with status: 'ACTIVE' should be returned.
   */
  getConnectors(direction?: string): Observable<Connector[]> {
    const queryParams = direction ? { direction } : {};
    return super.baseGet<{ connectors: Connector[] }>(Endpoint.CONNECTOR, 'connectors/types', true, queryParams).pipe(
      map((data: { connectors: Connector[] }) => {
        if (!data.connectors) {
          throw new Error('Returned data did not have connectors property');
        }
        data.connectors.map(connector =>
          connector.id === ConnectorId.MyFiles ? (connector.title = MY_FILES_LABEL) : connector.title
        );
        return data.connectors.filter((connector: Connector) => connector.status === ConnectorStatus.ACTIVE);
      })
    );
  }

  /**
   * Get connector info.
   */
  getConnector(connectorId: string): Observable<Connector> {
    return super.baseGet<Connector>(Endpoint.CONNECTOR_NEW, `connectors/${connectorId}`).pipe(
      map((response: Connector) => {
        if (!response) {
          throw new Error('No data returned');
        }
        return response;
      })
    );
  }

  /**
   * Establish connection with connector, cannot retrieve objects until after this connection has been made.
   * NOTE :: Even though you may already know the instance id you still need to make this connection
   */
  connectConnector(param: ConnectorParam): Observable<SecretObject> {
    return super.basePost<ConnectorParam, SecretObject>(Endpoint.CONNECTOR, `connectors`, param).pipe(
      map(secret => {
        // NOTE :: This is ridiculous, API should just return instance_id!
        if (!secret.instance_id && secret.message) {
          const last: string = secret.message.split(' ').pop() || '';
          if (last) {
            secret['instance_id'] = last;
          }
        }
        return secret;
      })
    );
  }

  /**
   * Post credentials
   */
  addConnectorCredentialSet(connectorId: string, param: ConnectorParam): Observable<string> {
    return super
      .basePost<ConnectorParam, InstanceModel>(
        Endpoint.CONNECTOR_INSTANCES,
        `connectors/${connectorId}/instances`,
        param
      )
      .pipe(
        map((result: InstanceModel) => {
          if (result) {
            // NOTE :: for some reason all you get back is the instanceId?
            return this.convertInstanceModel(result, connectorId).instanceId;
          } else {
            throw new Error('No connector instance returned');
          }
        })
      );
  }

  /**
   * Add Credentials and return ConnectorInstance
   */
  addConnectorInstance(connectorId: string, param: ConnectorParam): Observable<ConnectorInstance> {
    return new Observable(observer => {
      this.addConnectorCredentialSet(connectorId, param).subscribe({
        next: (instanceId: string) => {
          this.getConnectorInstance(connectorId, instanceId).subscribe({
            next: (instance: ConnectorInstance) => {
              observer.next(instance);
              observer.complete();
            },
            error: err => observer.error(err)
          });
        },
        error: err => observer.error(err)
      });
    });
  }

  /**
   * Put credentials set
   */
  updateConnectorInstance(
    instanceId: string,
    connectorId: string,
    param: ConnectorParam
  ): Observable<ConnectorInstance> {
    return super
      .basePut<ConnectorParam, InstanceModel>(
        Endpoint.CONNECTOR_INSTANCES,
        `connectors/${connectorId}/instances/${instanceId}`,
        param
      )
      .pipe(
        map((result: InstanceModel) => {
          if (result) {
            return this.convertInstanceModel(result, connectorId);
          } else {
            throw new Error('No connector instance returned');
          }
        })
      );
  }

  /**
   * Delete a connector instance, returns instance id
   */
  deleteConnectorInstance(connectorId: string, instanceId: string): Observable<string> {
    return super
      .baseDelete<InstanceModel>(Endpoint.CONNECTOR_INSTANCES, `connectors/${connectorId}/instances/${instanceId}`)
      .pipe(
        map((result: InstanceModel) => {
          if (result) {
            return this.convertInstanceModel(result, connectorId).instanceId;
          } else {
            throw new Error('No connector instance returned');
          }
        })
      );
  }

  /**
   * Get a credential set
   */
  getConnectorInstance(connectorId: string, instanceId: string): Observable<ConnectorInstance> {
    return super.baseGet<any>(Endpoint.CONNECTOR_INSTANCES, `connectors/${connectorId}/instances/${instanceId}`).pipe(
      map((response: { connectors: ConnectorInstance[] }) => {
        if (response && response.connectors && response.connectors.length > 0) {
          return response.connectors[0];
        } else {
          throw new Error('No connector instance found');
        }
      })
    );
  }

  /**
   * Update connector status
   */
  updateConnector(connectorId: string | undefined, status: string): Observable<any> {
    return super.basePut(Endpoint.CONNECTOR_NEW, `connectors/${connectorId}`, { status });
  }

  /**
   * Get all connector instance sets, only returns sets that contain instances
   */
  getConnectorInstanceSets(): Observable<ConnectorInstanceSet[]> {
    return super.baseGet<any>(Endpoint.CONNECTOR_INSTANCES, `connectors/instances`).pipe(
      map((result: { connectors: { instances: ConnectorInstance[] }[] }) => {
        if (result && result.connectors) {
          return result.connectors
            .filter(x => x.instances && x.instances.length > 0)
            .map(
              y =>
                <ConnectorInstanceSet>{
                  connectorId: y.instances[0].connectorType,
                  instances: y.instances
                }
            );
        }
        return [];
      })
    );
  }

  /**
   * Get to connector instances by id
   */
  getInstanceSet(connectorId: string): Observable<ConnectorInstanceSet> {
    return super.baseGet<ConnectorSetModel>(Endpoint.CONNECTOR_INSTANCES, `connectors/${connectorId}/instances`).pipe(
      map((response: ConnectorSetModel) => {
        if (!response) {
          throw new Error('No data returned');
        }
        return <ConnectorInstanceSet>{
          connectorId,
          instances: response.connectors
        };
      })
    );
  }

  /**
   * Get list of connector tables
   */
  getConnectorObjects(connectId: string, secretName: string): Observable<string[]> {
    const url = `connectors/${connectId}/${secretName}/objects`;
    return super.baseGetRetry<{ Objects: any[] }>(Endpoint.CONNECTOR, url, 20).pipe(
      map((dataResp: { Objects: string[] }) => {
        if (!dataResp || !dataResp.Objects) {
          throw new Error(`Invalid or null object returns from object request for ${connectId} connector`);
        }
        return dataResp.Objects;
      })
    );
  }

  /**
   * Get table schema from a connector source
   */
  getTableSchema(
    connectId: string,
    objectId: string,
    secretName: string,
    maxWait: number = BaseService.MAX_WAIT
  ): Observable<SchemaObject> {
    const url = `connectors/${connectId}/${secretName}/objects/${objectId}/schema`;
    return super.baseGetRetry<SchemaObject>(Endpoint.CONNECTOR, url, maxWait);
  }

  /**
   * Get table preview from connector source
   */
  getTableData(
    connectId: string,
    objectId: string,
    secretName: string,
    maxWait: number = BaseService.MAX_WAIT,
    retry: boolean = true
  ): Observable<[string[]]> {
    const url = `connectors/${connectId}/${secretName}/objects/${objectId}/preview`;
    return retry
      ? super.baseGetRetry<[string[]]>(Endpoint.CONNECTOR, url, maxWait)
      : super.baseGet<[string[]]>(Endpoint.CONNECTOR, url);
  }

  getOauthURL(connectorId: string, state: string): Observable<any> {
    const url = `connectors/${connectorId}/auth_url`;
    return super.baseGet(Endpoint.CONNECTOR_NEW, url, false, { state });
  }

  sendOauthToken(connectorId: string | undefined, param): Observable<any> {
    const url = `connectors/${connectorId}/auth_token`;
    return super.basePost(Endpoint.CONNECTOR_NEW, url, param);
  }

  /**
   * Get Snowflake Databases New Import
   */
  getSnowflakeDatabases(): Observable<Databases> {
    return super.baseGet<Databases>(Endpoint.CONNECTOR_INSTANCES, `tables/snowflake/databases`);
  }

  /**
   * Get Snowflake Schemas New Import
   */
  getSnowflakeSchemas(database): Observable<SnowflakeSchemas> {
    return super.baseGet<SnowflakeSchemas>(
      Endpoint.CONNECTOR_INSTANCES,
      `tables/snowflake/schemas?database=${database}`
    );
  }

  /**
   * Get Snowflake Tables New Import
   */
  getSnowflakeTables(database: string, schema: string): Observable<SnowflakeTables> {
    const queryParams = {
      database,
      schema
    };
    return super.baseGet<SnowflakeTables>(Endpoint.CONNECTOR_INSTANCES, 'tables/snowflake', true, queryParams);
  }

  /**
   * Helper to convert API to front end format
   */
  private convertInstanceModel(model: InstanceModel, connectorType: string): ConnectorInstance {
    return <ConnectorInstance>{
      connectorType,
      instanceId: model.InstanceId,
      lastUpdated: model.UpdatedAt,
      publicField: model.PublicField,
      secretName: model.SecretName,
      orgConnector: model.OrgConnector
    };
  }

  public getPreviewTable(tableId: string, tableType: TableDataType): Observable<any> {
    const type = DataTypeUtils.TableDataTypePluralMap.get(tableType);
    return this.baseGet(Endpoint.CONNECTOR, `connectors/${type}/0/objects/${tableId}/preview`);
  }

  public getSnowflakeTableSchema(
    tableId: string,
    database: string,
    schema: string
  ): Observable<SnowflakeTableSchema[]> {
    return this.baseGet(
      Endpoint.CONNECTOR_INSTANCES,
      `tables/snowflake/${tableId}?database=${database}&schema=${schema}`
    );
  }

  public getPreviewSnowflakeTable(tableId: string, database: string, schema: string): Observable<[string[]]> {
    return this.baseGet(
      Endpoint.CONNECTOR_INSTANCES,
      `tables/snowflake/${tableId}/preview?database=${database}&schema=${schema}`
    );
  }
}
