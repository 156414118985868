import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WysiwygEditorComponent } from '@neptune/components/wysiwyg-editor/wysiwyg-editor.component';
import { IframeWrapperModule } from '@neptune/components/iframe-wrapper/iframe-wrapper.module';
import { LoadingModalModule } from '@neptune/components';

@NgModule({
  declarations: [WysiwygEditorComponent],
  imports: [CommonModule, IframeWrapperModule, LoadingModalModule],
  exports: [WysiwygEditorComponent]
})
export class WysiwygEditorModule {}
