import { Injectable } from '@angular/core';
import { MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition, MatLegacySnackBarRef as MatSnackBarRef, LegacySimpleSnackBar as SimpleSnackBar, MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingModalService {
  constructor(private snackBar: MatSnackBar) {}

  public loadingSubject = new BehaviorSubject(false);

  public startLoading() {
    this.loadingSubject.next(true);
  }

  public stopLoading() {
    this.loadingSubject.next(false);
  }

  public get loading(): boolean {
    return this.loadingSubject.value;
  }

  public openSnackBar(
    message: string,
    action?: string | null,
    className?: string | string[] | null,
    duration?: number,
    verticalPos?: MatSnackBarVerticalPosition
  ): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, action as string, {
      duration: duration ? duration : 4000,
      panelClass: className ? className : ['custom-snackbar', 'custom-snackbar-send'],
      verticalPosition: verticalPos ? verticalPos : 'bottom',
      horizontalPosition: 'center'
    });
  }
}
