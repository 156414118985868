import { Directive, ElementRef, HostListener } from '@angular/core';
import { MessageKeySelectorTargetService } from '@neptune/services/message-key-selector-target.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[canReceiveKeySelectorData]'
})
export class CanReceiveKeySelectorDataDirective {
  constructor(
    private elementHost: ElementRef,
    private messageKeySelectorTargetService: MessageKeySelectorTargetService
  ) {}

  @HostListener('focusin') focusIn(): void {
    this.messageKeySelectorTargetService.setFocus(this.elementHost);
  }

  @HostListener('focusout', ['$event']) focusOut(ev: any): void {
    const isMessageKeySelectorPicker = !!(ev.relatedTarget && ev.relatedTarget.closest('message-key-selector-picker'));
    this.messageKeySelectorTargetService.removeFocus(isMessageKeySelectorPicker);
  }
}
