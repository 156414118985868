<div class="html-text-edit-preview" fxLayout="column" fxLayoutAlign="space-between end">
  <loading-modal></loading-modal>
  <!-- WYSIWYG | HTML | TEXT toggle -->
  <mat-button-toggle-group
    [(ngModel)]="currentToggleSelection"
    class="toggle-group html-text-edit-preview__toggle-group"
    [ngModelOptions]="{ standalone: true }"
    (change)="onToggleChange($event)"
  >
    <mat-button-toggle *ngIf="!disableWysiwyg" [value]="ToggleSelection.WYSIWYG">WYSIWYG</mat-button-toggle>
    <span *ngIf="!disableWysiwyg && (!disableHtml || !disableText)" class="h-divider"></span>
    <mat-button-toggle *ngIf="!disableHtml" [value]="ToggleSelection.HTML">HTML</mat-button-toggle>
    <span *ngIf="!disableHtml && !disableText" class="h-divider"></span>
    <mat-button-toggle *ngIf="!disableText" [value]="ToggleSelection.TEXT">TEXT</mat-button-toggle>
  </mat-button-toggle-group>

  <!-- EDIT MODE -->
  <div
    *ngIf="mode === ViewMode.EDIT"
    fxFlex
    fxFlexAlign="stretch"
    class="html-text-edit-preview__edit"
    [ngSwitch]="currentToggleSelection"
  >
    <!-- WYSIWYG -->
    <wysiwyg-editor
      *ngSwitchCase="ToggleSelection.WYSIWYG"
      [content]="wysiwyg.content"
      (onContentChange)="onWysiwygChange($event)"
      (onWysiwygClicked)="onWysiwygClicked($event)"
    ></wysiwyg-editor>

    <!-- HTML -->
    <mat-form-field
      appearance="fill"
      *ngSwitchCase="ToggleSelection.HTML"
      class="ace-form-field"
      [ngClass]="{ 'mat-form-field-invalid': htmlModel.invalid && htmlModel.errors.maxSize }"
    >
      <mat-label *ngIf="html?.label">{{ html?.label }}</mat-label>
      <ace-editor-wrapper
        (onFocus)="setTextArea($event)"
        (textChanged)="onChange($event)"
        [disabled]="!!disabled"
        [mode]="'html'"
        [options]="aceOptions"
        classes="html-ace-wrapper  {{ html?.classes }}"
        [(ngModel)]="html.content"
        [nepMaxSize]="html?.maxSize"
        #htmlModel="ngModel"
        #htmlAceWrapper
      ></ace-editor-wrapper>
      <ng-container *ngIf="htmlModel.invalid && (htmlModel.dirty || htmlModel.touched)">
        <mat-error *ngIf="htmlModel.invalid && htmlModel.errors.maxSize">
          {{ getMaxSizeMessage(html.maxSize, html.type) }}
        </mat-error>
      </ng-container>
    </mat-form-field>
    <!-- TEXT OF SUBJECT EMAIL-->
    <div *ngIf="isEmailSubjectType; else notEmailSubjectType">
      <mat-form-field
        fxFlex="100"
        appearance="fill"
        *ngSwitchCase="ToggleSelection.TEXT"
        class="html-text-edit-preview__edit__subject"
      >
        <input
          *ngIf="isEmailSubjectType"
          matInput
          canReceiveKeySelectorData
          (change)="onSubjectChange($event)"
          type="text"
          placeholder="Subject"
          [(ngModel)]="text.content"
          #textModel="ngModel"
          maxlength="255"
          class="mat-form-field-input"
          [disabled]="!!disabled"
          (ngModelChange)="onChange($event)"
        />
      </mat-form-field>
    </div>
    <!-- TEXT -->
    <ng-template #notEmailSubjectType>
      <mat-form-field
        appearance="fill"
        *ngSwitchCase="ToggleSelection.TEXT"
        class="ace-form-field"
        [ngClass]="{ 'mat-form-field-invalid': textModel.invalid && textModel.errors.maxSize }"
      >
        <mat-label *ngIf="text?.label">{{ text?.label }}</mat-label>
        <ace-editor-wrapper
          (onFocus)="setTextArea($event)"
          (textChanged)="onChange($event)"
          [mode]="'text'"
          [options]="aceOptions"
          classes="text-ace-wrapper  {{ text?.classes }}"
          [(ngModel)]="text.content"
          [nepMaxSize]="text?.maxSize"
          [disabled]="!!disabled"
          #textModel="ngModel"
          #textAceWrapper
        ></ace-editor-wrapper>
        <ng-container *ngIf="textModel.invalid && (textModel.dirty || textModel.touched)">
          <mat-error *ngIf="textModel.invalid && textModel.errors.maxSize">
            {{ getMaxSizeMessage(text.maxSize, text.type) }}
          </mat-error>
        </ng-container>
      </mat-form-field>
      <button
        mat-flat-button
        [disabled]="!!disabled"
        matTooltip="Browse from your computer"
        color="accent"
        *ngIf="currentToggleSelection != ToggleSelection.WYSIWYG"
        class="html-text-edit-preview__edit__upload"
        (click)="uploader.click()"
      >
        {{ smallUploadButton ? '' : 'Browse from your computer' }}
        <mat-icon [ngClass]="{ 'pl-4': !smallUploadButton }">cloud_upload</mat-icon>
      </button>
      <input accept="text/*" hidden type="file" #uploader (change)="uploadFile($event)" />
    </ng-template>
  </div>

  <!-- PREVIEW MODE -->
  <div
    *ngIf="mode === ViewMode.PREVIEW"
    fxFlex
    fxFlexAlign="stretch"
    fxLayout="column"
    fxLayoutAlign="space-between stretch"
    class="html-text-edit-preview__preview"
  >
    <div class="size-controller">
      <div
        *ngFor="let screenSize of screenSizes; first as isFirst"
        class="size-control"
        (mouseover)="hoveredSize = screenSize"
        (mouseout)="hoveredSize = emptySize"
        (click)="selectedSize = screenSize"
        [ngClass]="{ selected: hoveredSize.size >= screenSize.size, 'first-child': isFirst }"
        [style.width]="screenSize.size + 'px'"
      ></div>
      <div class="size-control-label" [ngClass]="{ selected: hoveredSize.size > 0 }">
        <div *ngIf="hoveredSize.size === 0; then selectedSizeBlock; else hoveredSizeBlock"></div>
        <ng-template #selectedSizeBlock>{{ selectedSize.name }} ({{ selectedSize.size }} px)</ng-template>
        <ng-template #hoveredSizeBlock>{{ hoveredSize.name }} ({{ hoveredSize.size }} px)</ng-template>
      </div>
    </div>
    <div
      class="html-text-edit-preview__preview__component"
      [ngSwitch]="currentToggleSelection"
      [style.width]="selectedSize.size + 'px'"
    >
      <nep-iframe-wrapper
        *ngSwitchCase="ToggleSelection.HTML"
        #iframePreview
        [source]="SOURCE_PREVIEW"
        class="no-click nep-iframe-wrapper"
        (iframeEvent)="iframeEvent($event)"
        (customEvent)="customEventPreview($event)"
        [sourceId]="SOURCE_ID"
      >
        <loading-modal [loading]="true"></loading-modal>
      </nep-iframe-wrapper>
      <div class="text-preview" *ngSwitchCase="ToggleSelection.TEXT">
        {{ text.content }}
      </div>
    </div>
  </div>
</div>
