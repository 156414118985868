import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

export interface ResetPasswordProperties {
  bullet: boolean;
  notNumber: boolean;
  notUppercase: boolean;
  notLowercase: boolean;
  notSpecial: boolean;
  notMinChar: boolean;
  notMaxChar: boolean;
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class FuseResetPasswordComponent implements OnInit {
  @Input() resetPasswordFormErrors: any;
  @Input() resetPasswordForm: UntypedFormGroup;

  public hidepass = true;
  public hideconfirm = true;
  public properties: ResetPasswordProperties;

  constructor() {}

  ngOnInit() {
    this.properties = {
      bullet: true,
      notNumber: true,
      notUppercase: true,
      notLowercase: true,
      notSpecial: true,
      notMinChar: true,
      notMaxChar: true
    };

    this.resetPasswordForm.valueChanges.subscribe(() => {
      this.onResetPasswordFormValuesChanged();
      this.controlPassword();
    });
  }

  get passwordControl() {
    return this.resetPasswordForm.get('password');
  }

  get passwordConfirmControl() {
    return this.resetPasswordForm.get('passwordConfirm');
  }

  onResetPasswordFormValuesChanged() {
    for (const field in this.resetPasswordFormErrors) {
      if (!this.resetPasswordFormErrors.hasOwnProperty(field)) {
        continue;
      }
      // Clear previous errors
      this.resetPasswordFormErrors[field] = {};
      // Get the control
      const control = this.resetPasswordForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.resetPasswordFormErrors[field] = control.errors;
      }
    }
  }

  public controlPassword() {
    const password = this.resetPasswordForm.get('password')?.value;
    this.properties.notNumber = !/\d/.test(password);
    this.properties.notUppercase = !/[A-Z]/.test(password);
    this.properties.notLowercase = !/[a-z]/.test(password);
    this.properties.notSpecial = !/[!@#$%^&]/.test(password);
    this.properties.notMinChar = !(password.length >= 12);
    this.properties.notMaxChar = !(password.length <= 256);
    return null;
  }
}
