// eslint-disable-next-line no-shadow
export enum DialogType {
  Information,
  Warning,
  Question
}

// eslint-disable-next-line no-shadow
export enum DialogButtonType {
  YesNo,
  OK
}

export interface DialogInput {
  type: DialogType;
  buttonType: DialogButtonType;
  header: string;
  message?: string;
  /** If specified will display as list */
  messages?: string[];
}
