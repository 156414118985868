<div class="print-on-demand-preview-dialog">
  <div mat-dialog-title class="dialog-header">
    <div class="dialog-title">
      <span class="h2">{{ data?.title }}</span>
    </div>
  </div>

  <mat-dialog-content>
    <loading-modal></loading-modal>
    <print-on-demand-preview-content *ngIf="message" [message]="message"></print-on-demand-preview-content>
  </mat-dialog-content>

  <mat-dialog-actions fxLayoutAlign="end start" class="dialog-footer" fxLayout="row nowrap">
    <button mat-flat-button color="primary" (click)="onClose()">Close</button>
  </mat-dialog-actions>
</div>
