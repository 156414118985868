export interface Statistic {
  id?: string;
  name?: string;
  key?: string;
  description: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  data?: Object;
  active?: boolean;
  deleted?: true;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  type?: StatisticType;
  subType?: StatisticSubType;
}

// eslint-disable-next-line no-shadow
export enum StatisticType {
  Database = 'DATABASE',
  Notifications = 'NOTIFICATIONS'
}

// eslint-disable-next-line no-shadow
export enum StatisticSubType {
  SnowFlake = 'SNOWFLAKE',
  Message = 'MESSAGE',
  Journey = 'JOURNEY',
  Pipeline = 'PIPELINE'
}
