import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'propertyFilter'
})
export class PropertyFilterPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/ban-types
  transform(items: any[], filter: Object): any[] {
    if (!items || !filter) {
      return items;
    }

    const filterKeys: string[] = Object.keys(filter);
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(item => filterKeys.every(filterKey => item[filterKey] === filter[filterKey]));
  }
}
