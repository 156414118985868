import { Component, Input, OnChanges } from '@angular/core';
import { DateTime } from '@neptune/models/schedule';
import { TimezoneUtils } from '@neptune/models/timezone';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'standard-user-date',
  templateUrl: './standard-user-date.component.html',
  styleUrls: ['./standard-user-date.component.scss']
})
export class StandardUserDateComponent implements OnChanges {
  @Input('date') public date: string | DateTime | Date;
  @Input('user') public user: string;

  public datetime: DateTime;

  ngOnChanges() {
    if (!this.date || !this.user) {
      return;
    }
    this.datetime = TimezoneUtils.utcToDateTime(this.date as string) as DateTime;
    // remove space between time and am/pm
    if (this.datetime.time) {
      this.datetime.time = this.datetime.time.replace(' ', '');
    }
  }
}
