import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from '@neptune/material.module';
import { AggregateFunctionDialog } from './aggregate-function-dialog';
import { SelectFieldDialogComponent } from './select-field-dialog/select-field-dialog.component';
import { DatatypeIconModule } from '@neptune/components/datatype-icon/datatype-icon.module';
import { MessageKeySelectorPickerModule } from 'app/main/content/pages/message/message-key-selector-picker/message-key-selector-picker.module';
import { LongTextModule } from '@neptune/components/long-text/long-text.module';
import { LoadingModalModule } from '@neptune/components/loading-modal/loading-modal.module';

@NgModule({
  declarations: [AggregateFunctionDialog, SelectFieldDialogComponent],
  exports: [AggregateFunctionDialog],
  imports: [
    CommonModule,
    MaterialModule,
    FuseSharedModule,
    DatatypeIconModule,
    MessageKeySelectorPickerModule,
    LongTextModule,
    LoadingModalModule
  ],
  providers: []
})
export class AggregateFunctionDialogModule {}
