import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { EnumToArrayPipe } from 'app/main/utilities/enumToArray.pipe';
import { OrdinalPipe } from './ordinal.pipe';

@NgModule({
  declarations: [KeysPipe, GetByIdPipe, HtmlToPlaintextPipe, FilterPipe, CamelCaseToDashPipe, EnumToArrayPipe, OrdinalPipe],
  imports: [],
  exports: [KeysPipe, GetByIdPipe, HtmlToPlaintextPipe, FilterPipe, CamelCaseToDashPipe, EnumToArrayPipe]
})
export class FusePipesModule {}
