import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CdkTableModule } from '@angular/cdk/table';
import { MaterialModule } from 'app/main/content/material.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { PurlListComponent } from './purl-list.component';
import { PurlService } from '@neptune/services/purl.service';
import { DropdownButtonModule } from '@neptune/components/dropdown-button/dropdown-button.module';
import { DropdownModule } from '@neptune/components/dropdown/dropdown.module';
import { StandardDateModule } from '@neptune/components/standard-list-page/standard-date/standard-date.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [PurlListComponent],
  imports: [
    CommonModule,
    MaterialModule,
    NgxDatatableModule,
    FuseSharedModule,
    DropdownButtonModule,
    DropdownModule,
    StandardDateModule,
    CdkTableModule
  ],
  exports: [PurlListComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [PurlService]
})
export class PurlListModule {}
