import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailChipListInputComponent } from '@neptune/components/email-chip-list-input/email-chip-list-input.component';
import { MaterialModule } from '@neptune/material.module';

@NgModule({
  declarations: [EmailChipListInputComponent],
  imports: [CommonModule, MaterialModule],
  exports: [EmailChipListInputComponent]
})
export class EmailChipListInputModule {}
