import { of as observableOf, from as observableFrom, Observable } from 'rxjs';

import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  OrganizationUser,
  User,
  UserModel,
  UserOrg,
  UserUtils,
  InitiateAuthData,
  RespondAuthData,
  AuthAttributes,
  UserDetails,
  Roles
} from '@neptune/models/user';
import { BaseService, Endpoint } from './base.service';
import { OrgModel, OrgParam } from '@neptune/models';
import { HttpClient } from '@angular/common/http';
import { CognitoService } from './cognito.service';
import { StoreService } from './store.service';
import { AccountService } from './account.service';

@Injectable()
export class UserService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected cognitoService: CognitoService,
    protected storeService: StoreService,
    protected accountService: AccountService
  ) {
    super(http, cognitoService, storeService);
  }

  /**
   * For use with jasmine testing, returns a mock of service
   */
  public static mockService(jasmine: any): any {
    return {
      getUsers: jest.fn(() => observableOf([])),
      getOrganizations: jest.fn(() => observableOf([]))
    };
  }

  /* Get all users
   * @param asMap
   */
  public getUsers(asMap: boolean = true): Observable<User[] | Map<string, User> | any> {
    return super.baseGet<UserModel[]>(Endpoint.USER, `users`).pipe(
      map((models: UserModel[]) => {
        if (asMap) {
          const userDataMap: Map<string, User> = new Map<string, User>();
          for (const model of models) {
            userDataMap.set(model.Username, UserUtils.convertUserModel(model));
          }

          return userDataMap;
        } else {
          return models.map(model => UserUtils.convertUserModel(model));
        }
      }),
      catchError(err => err)
    );
  }

  public getOrganizations(userName: string): Observable<UserOrg[]> {
    return this.accountService
      .getCurrentUserDetails()
      .pipe(
        mergeMap(
          (userDetails: UserDetails) =>
            super.baseGet(
              userDetails.attributes.qp_role === Roles.C3USER ? Endpoint.PUBLIC_USER : Endpoint.USER,
              `users/${userName}/orgs`
            ) as Observable<UserOrg[]>
        )
      );
  }

  public getOrganizationUsers(organizationId: string): Observable<OrganizationUser[]> {
    return super.baseGet(Endpoint.USER, `users/orgs/${organizationId}`);
  }

  /**
   * Delete a user from cognito and Sisense
   */
  public deleteUser(username: string): Observable<null> {
    const url = `${username}`;
    return super.baseDelete(Endpoint.USER_DELETE, url);
  }

  /**
   * Create a user
   */
  public createUser<U extends User>(user: U): Observable<User> {
    return super.basePost(Endpoint.USER, `users`, user);
  }

  /**
   * Update a User
   */
  public updateUser<U extends User>(user: U): Observable<User> {
    user.username = user.email;
    const userJson = JSON.stringify(user);
    return this.accountService
      .getCurrentUserDetails()
      .pipe(
        mergeMap(
          (userDetails: UserDetails) =>
            super.basePut(
              userDetails.attributes.qp_role === Roles.C3USER ? Endpoint.PUBLIC_USER : Endpoint.USER,
              `users/${user.username}`,
              userJson
            ) as Observable<User>
        )
      );
  }

  /**
   * Approve User status
   */
  public approveUser<U extends User>(user: U): Observable<User> {
    const originalUsername = user.username;
    return super.basePut(Endpoint.USER, `users/${originalUsername}/approve`, {});
  }

  /**
   * Approve User status
   */
  public rejectUser<U extends User>(user: U): Observable<User> {
    const originalUsername = user.username;
    return super.basePut(Endpoint.USER, `users/${originalUsername}/reject`, {});
  }

  /**
   * Get user avatar
   */
  public getUserAvatar(username: string): Observable<any> {
    const url = `users/${username}/avatar`;
    return this.accountService
      .getCurrentUserDetails()
      .pipe(
        mergeMap(
          (userDetails: UserDetails) =>
            super.baseGet(
              userDetails.attributes.qp_role === Roles.C3USER ? Endpoint.PUBLIC_USER : Endpoint.USER,
              url
            ) as Observable<User>
        )
      );
  }

  /**
   * Update user avatar
   */
  public updateUserAvatar(imageData: string, imageType: string, username: string): Observable<any> {
    const url = `users/${username}/avatar`;

    return this.accountService.getCurrentUserDetails().pipe(
      mergeMap(
        (userDetails: UserDetails) =>
          observableFrom(
            new Promise((resolve, reject) => {
              const data = {
                avatar: {
                  image: imageData,
                  type: imageType
                }
              };

              super
                .basePost(
                  userDetails.attributes.qp_role === Roles.C3USER ? Endpoint.PUBLIC_USER : Endpoint.USER,
                  url,
                  data
                )
                .subscribe({
                  next: result => {
                    resolve(result);
                  },
                  error: err => reject(err)
                });
            })
          ) as Observable<User>
      )
    );
  }

  public addOrganization(userName: string, model: OrgParam): Observable<UserOrg> {
    return super.basePost(Endpoint.USER, `users/${userName}/orgs/${model.orgId}`, { role: model.role });
  }

  public updateOrganization(userName: string, orgId: string, model: UserOrg): Observable<UserOrg> {
    return super.basePut(Endpoint.USER, `users/${userName}/orgs/${orgId}`, model);
  }

  public deleteOrganization(userName: string, orgId: string): Observable<UserOrg> {
    return super.baseDelete(Endpoint.USER, `users/${userName}/orgs/${orgId}`);
  }

  public adminImpersonationOrg(org: OrgModel): Observable<any> {
    return super.basePut(Endpoint.NXTDRIVE, `users/switchorganization/${org.OrgID}`, {});
  }

  /**
   * Create a MFA user
   */
  public createMfaNewUser<U extends User>(user: U): Observable<User> {
    return super.basePost(Endpoint.NXTDRIVE, `mfa-user`, user);
  }

  /**
   * Send the invite email with the magic link
   */
  public sendInviteWithMagicLink(email: string, given_name: string, family_name: string) {
    return super.basePost(Endpoint.NXTDRIVE, `mfa-user/invite`, { email, given_name, family_name });
  }

  /**
   * Initiate Auth
   */
  public initiateAuth(data: InitiateAuthData): Observable<any> {
    return super.basePost(Endpoint.PUBLIC_HUB, `mfa-user/initiateAuth`, data);
  }

  /**
   * Respond Auth
   */
  public respondAuth(data: RespondAuthData): Observable<any> {
    return super.basePost(Endpoint.PUBLIC_HUB, `mfa-user/respondAuth`, data);
  }

  /**
   * set Auth Attributes
   */
  public setAttributes(data: AuthAttributes): Observable<any> {
    return super.basePost(Endpoint.PUBLIC_HUB, `mfa-user/setAttributes`, data);
  }

  /**
   * Add MFA Organization
   */
  public addMFAOrganization(userName: string, model: OrgParam): Observable<UserOrg> {
    return super.basePost(Endpoint.PUBLIC_HUB, `mfa-user/${userName}/orgs/${model.orgId}`, { role: model.role });
  }

  /**
   * Add MFA Organization
   */
  public updateMFARelations(userName: string): Observable<UserOrg> {
    return super.basePost(Endpoint.PUBLIC_HUB, `mfa-user/updateRelations/${userName}`, {});
  }
}
