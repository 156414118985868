import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';
import { MessageService } from '@neptune/services/message.service';
import { Message } from '@neptune/models/message';
import { LoadingModalComponent } from '../loading-modal/loading-modal.component';

export interface PrintOnDemandPreviewDialogInput {
  title: string;
  messageId: string;
}

@Component({
  selector: 'nep-print-on-demand-preview-dialog',
  templateUrl: './print-on-demand-preview-dialog.component.html',
  styleUrls: ['./print-on-demand-preview-dialog.component.scss']
})
export class PrintOnDemandPreviewDialogComponent implements OnInit {
  @ViewChild(LoadingModalComponent, { static: true }) loadingComponent: LoadingModalComponent;
  public message: Message;
  constructor(
    public dialogRef: MatDialogRef<PrintOnDemandPreviewDialogComponent>,
    public messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: PrintOnDemandPreviewDialogInput
  ) {}

  ngOnInit() {
    this.loadingComponent.startLoading();
    this.messageService.getMessage(this.data.messageId).subscribe({
      next: res => {
        this.message = res;
        this.loadingComponent.stopLoading();
      },
      error: () => this.loadingComponent.showError('There was an error fetching message')
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
