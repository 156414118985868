<!-- Buttons -->
<ng-container *ngFor="let btn of buttonsConfig; index as i" [ngSwitch]="btn.configType">
  <!-- Internal link button -->
  <a
    *ngSwitchCase="ButtonConfigType.INTERNAL_LINK"
    [routerLink]="btn.link"
    routerLinkActive="active"
    qp-roles
    [rolList]="btn.rolList"
    mat-button
    [ngClass]="btn.classes + ' ' + btn.buttonType + btn.disabled ? ' disabled' : ''"
    [matTooltip]="btn.tooltip"
  >
    {{ btn.name }}
  </a>
  <!-- End internal link button -->

  <!-- External link button -->
  <a
    *ngSwitchCase="ButtonConfigType.EXTERNAL_LINK"
    [href]="btn.link"
    target="_blank"
    qp-roles
    [rolList]="btn.rolList"
    [ngClass]="btn.classes + ' ' + btn.buttonType + btn.disabled ? ' disabled' : ''"
    [matTooltip]="btn.tooltip"
  >
    {{ btn.name }}
  </a>
  <!-- End external link button -->

  <!-- General button -->
  <ng-container *ngSwitchCase="ButtonConfigType.MAT_BUTTON">
    <button
      *ngIf="!btn.hide"
      [ngClass]="btn.classes + ' ' + btn.buttonType + ' mat-' + btn.colorType"
      (click)="btn.onClick($event)"
      qp-roles
      [rolList]="btn.rolList"
      [disabled]="btn.disabled"
      [matTooltip]="btn.tooltip"
      mat-button
    >
      <mat-icon *ngIf="btn.icon && !btn.svgIcon">{{ btn.icon }}</mat-icon>
      <mat-icon *ngIf="btn.icon && btn.svgIcon" [svgIcon]="btn.icon"></mat-icon>
      {{ btn.name }}
    </button>
  </ng-container>
  <!-- End general button -->

  <!-- General button with menu -->
  <button
    *ngSwitchCase="ButtonConfigType.MAT_BUTTON_MENU"
    [ngClass]="btn.classes + ' ' + btn.buttonType + ' mat-' + btn.colorType"
    (click)="btn.onClick($event)"
    qp-roles
    [rolList]="btn.rolList"
    [disabled]="btn.disabled"
    [matTooltip]="btn.tooltip"
    mat-button
    [matMenuTriggerFor]="btn.matMenu"
  >
    <mat-icon *ngIf="btn.icon && !btn.svgIcon">{{ btn.icon }}</mat-icon>
    <mat-icon *ngIf="btn.icon && btn.svgIcon" [svgIcon]="btn.icon"></mat-icon>
    {{ btn.name }}
  </button>
  <!-- End general button -->

  <!-- Checkbox -->
  <mat-checkbox
    [ngModel]="btn.checkboxStatus"
    *ngSwitchCase="ButtonConfigType.MAT_CHECKBOX"
    (change)="btn.onClick($event)"
    [disabled]="btn.disabled"
    [matTooltip]="btn.tooltip"
    qp-roles
    [rolList]="btn.rolList"
  >
    {{ btn.name }}
  </mat-checkbox>
  <!-- End checkbox -->

  <!-- Separator -->
  <div *ngSwitchCase="ButtonConfigType.SEPARATOR" qp-roles [rolList]="btn.rolList">
    <span [ngClass]="btn.classes" [matTooltip]="btn.tooltip">|</span>
  </div>
  <!-- End separator -->

  <!-- Expand menu -->
  <div *ngSwitchCase="ButtonConfigType.EXPAND_MENU">
    <button
      qp-roles
      [rolList]="btn.rolList"
      [ngClass]="btn.classes + ' ' + btn.buttonType + ' mat-' + btn.colorType"
      [disabled]="btn.disabled"
      [matTooltip]="btn.tooltip"
      [matMenuTriggerFor]="moreMenu"
      (click)="$event.stopPropagation()"
      mat-button
    >
      <mat-icon *ngIf="btn.icon && !btn.svgIcon">{{ btn.icon }}</mat-icon>
      <mat-icon *ngIf="btn.icon && btn.svgIcon" [svgIcon]="btn.icon"></mat-icon>
      {{ btn.name }}
    </button>

    <mat-menu #moreMenu="matMenu" overlapTrigger="false" direction="down">
      <dropdown-button
        *ngFor="let row of btn.matOptions"
        [label]="row.label"
        (onClick)="row.click()"
        [qpIcon]="row.qpIcon"
      ></dropdown-button>
      <mat-divider></mat-divider>
    </mat-menu>
  </div>

  <!-- End expand menu -->

  <!-- Text -->
  <div *ngSwitchCase="ButtonConfigType.TEXT" qp-roles [rolList]="btn.rolList">
    <span *ngIf="btn.visible" [ngClass]="btn.classes" [matTooltip]="btn.tooltip" (click)="btn.onClick($event)">
      {{ btn.text }}
    </span>
  </div>
  <!-- End text -->

  <!-- Togle group button -->
  <div *ngSwitchCase="ButtonConfigType.MAT_BUTTON_TOOGLE_GROUP" qp-roles [rolList]="btn.rolList">
    <mat-button-toggle-group
      [(ngModel)]="btn.model"
      [ngClass]="btn.classes"
      (change)="btn.onClick(btn.model)"
      [ngModelOptions]="{ standalone: true }"
    >
      <mat-button-toggle *ngFor="let option of btn.matToggleOptions" [value]="option.value">
        {{ option.label }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <!-- End toogle group button -->
</ng-container>
<!-- End buttons -->
