import { EventEmitter, Injectable, OnInit, OnDestroy, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NameId } from '@neptune/models/file';
/**
 * Abstract class for list component used as part of standard list
 * Exmaple of URL:
 *
    @Component({
        templateUrl: './standard-list.component.html'
    }) 
 */

@Injectable()
export abstract class StandardListComponent<T> implements OnInit, OnDestroy {
  /** Array of all of list entities */
  list: T[];
  /** Original Array of list entities */
  initialRowsData: T[];
  /** Array of list entities currently being displayed */
  rowsData: T[];

  objectData: T;
  /** Message to display in case of no list entities */
  message: string;

  /** Roles to display or not the main button (create) */
  rolList: string | string[];

  /** Disabled refresh button variable */
  isRefreshButtonDisabled: boolean = false;

  // GUIDs for previous pages when using pagination
  lastKeyAsGUIDs: (string | undefined)[] = [undefined];

  // GUIDs for previous pages when using pagination
  destinationTable: (string | undefined) = undefined;

  // current page number when using pagination
  currentPage: number = 1;

  // Page Size when using pagination
  pageSizeFilterValue: number;

  // Table list
  fullTableList: NameId[];

  searchText: (string | undefined) = undefined;

  showActions: boolean = true;

  /** Used by selectable lists */
  selectedData: any;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output('onSelectionChange') onSelectionChange: EventEmitter<any> = new EventEmitter();

  public onInitialized: EventEmitter<this> = new EventEmitter();
  public onStartLoading: EventEmitter<any> = new EventEmitter();
  public onStopLoading: EventEmitter<any> = new EventEmitter();
  public onRefresh: EventEmitter<any> = new EventEmitter();
  public onShowWarning: EventEmitter<string> = new EventEmitter();
  public onShowError: EventEmitter<string> = new EventEmitter();
  public onShowSuccess: EventEmitter<string> = new EventEmitter();
  public onRefreshStatusSetted: EventEmitter<boolean> = new EventEmitter();
  public onAfterTablePopulated: EventEmitter<boolean> = new EventEmitter();

  /** Clean up for Observables */
  protected unsubscribeAll = new Subject<void>();

  constructor() {
    this.message = 'Body Default Data';
    this.list = [];
    this.rowsData = [];
    this.rolList = [];
  }

  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngOnInit() {
    this.onInitialized.emit(this);
  }

  ngOnDestroy(): void {
    // clean up all Observable subscribes
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  /**
   * Method responsible for initial population of list with entities
   *
   * @return Observable<null>
   */
  public abstract populate(): Observable<null>;

  /**
   * Method responsible for initial population of table filter
   *
   * @return Observable<null>
   */
  public populateTableFilter(): Observable<null> {
    return new Observable((observer) => {
      observer.next(); observer.complete();
    });
  };

  /**
   * This 3 Fun are called before each filter part is applied.
   * override if needed.
   */
  public beforeApplyingSearch() {}
  public beforeApplyingToggle() {}
  public beforeApplyingDropdown() {}

  public resetPaginationStatus() {
    this.lastKeyAsGUIDs = [undefined];
    this.currentPage = 1;
  }

  /**
   * Add entity to list.
   * Also applies messaging required for UI
   * NOTE :: this has only local effects, does not alter the backend
   *
   * @param data
   */
  protected addEntity(data: T | T[], atIndex: number = -1) {
    if (atIndex < 0) {
      atIndex = this.list.length;
    }
    // apply message values & functional update of existing tasks array
    if (Array.isArray(data)) {
      data.forEach(entity => {
        this.processEntity(entity, <T[]>data);
      });
      this.list.splice(atIndex, 0, ...data);
      // process the entire list
      this.processList(this.list);
    } else {
      this.processEntity(data, this.list);
      this.list.splice(atIndex, 0, data);
      this.list = this.list.slice();
      // process just the new entity
      this.processList(this.list, data);
    }
    this.updateTableView();
  }

  /**
   * Set table filter list.
   *
   * @param tables
   */
  protected setTableFilterList(tables) {
    this.fullTableList = tables.map(table => ({
      name: table.name,
      id: table.Id,
    }));
  }

  /**
   * 
   * Add next page GUID when using pagination
   */
  protected addToLastKeyAsGUIDs(lastKeyAsGUID) {
    this.lastKeyAsGUIDs[this.currentPage] = lastKeyAsGUID;
  }

  /**
   * 
   * Set current page number when using pagination
   */
  protected setCurrentPage(page: number) {
    this.currentPage = page;
  }

  /**
   * Update table view by asigning list to rowsData
   */
  protected updateTableView() {
    this.rowsData = this.list;
  }

  /**
   * Replace matching entity in list.
   * NOTE :: this has only local effects, does not alter the backend
   *
   * @param data
   */
  protected replaceEntity(newEntity: T, oldEntity?: T) {
    // if oldEntity not given then use newEntity to match
    const match = oldEntity || newEntity;
    let found: boolean = false;
    this.list = this.list.map(x => {
      if (this.compareEntity(match, x)) {
        oldEntity = x;
        found = true;
        this.processEntity(newEntity);
        return newEntity;
      }
      return x;
    });
    if (found) {
      this.processList(this.list, newEntity, oldEntity);
    }
    this.updateTableView();
  }

  /**
   * Remove entity from list.
   * NOTE :: this has only local effects, does not alter the backend
   *
   * @param data
   */
  protected removeEntity(entity: T) {
    const length: number = this.list.length;
    this.list = this.list.filter(x => !this.compareEntity(entity, x));
    if (this.list.length !== length) {
      this.processList(this.list, null, entity);
    }
    this.updateTableView();
  }

  /**
   * Clear entity list.
   * NOTE :: this has only local effects, does not alter the backend
   *
   * @param data
   */
  protected clearEntities() {
    this.list = [];
    this.updateTableView();
  }

  /**
   * Configures a list of T entities.
   *
   * @param entity
   */
  protected setEntities(entity: T[]) {
    this.rowsData = entity;
  }

  /**
   * Compare two entities, return true is they are considered the same
   *
   * @param entity
   */
  protected compareEntity(a: T, b: T): boolean {
    return a === b;
  }

  /**
   * Process entity before adding to list & display
   *
   * @param entity
   */
  protected processEntity(entity: T, entities?: T[]) {}

  /**
   * Process entity list before adding to display.
   * If newEntity is defined assumes that is the only entity that as changed
   * If oldEntity is defined assumes that has been removed from the list
   * If newEntity & oldEntity are undefined entire list checks against itself
   *
   * @param entity
   */
  protected processList(entities: T[], newEntity?: T | null, oldEntiy?: T) {}

  protected startLoading() {
    this.onStartLoading.emit();
  }

  protected stopLoading() {
    this.onStopLoading.emit();
  }

  protected refresh() {
    this.onRefresh.emit();
  }

  protected afterTablePopulated() {
    this.onAfterTablePopulated.emit();
  }

  protected setRefreshDisabledStatus(status: boolean) {
    this.isRefreshButtonDisabled = status;
    this.onRefreshStatusSetted.emit(status);
  }

  /**
   * Forces a refresh on list
   */
  protected forceRefreshListView() {
    this.rowsData = this.rowsData.filter(() => true);
    this.list = this.rowsData.filter(() => true);
  }

  /**
   * Forces a refresh on rowsData
   */
  protected forceRefreshDataView() {
    this.rowsData = this.rowsData.filter(() => true);
  }

  protected showWarning(msg: string) {
    this.onShowWarning.emit(msg);
  }

  protected showError(msg: string) {
    this.onShowError.emit(msg);
  }

  protected showSuccess(msg: string) {
    this.onShowSuccess.emit(msg);
  }
}
