/* eslint-disable @typescript-eslint/ban-types */
import { AssetBase } from './asset';
import { QueryFragment, QueryGroupMemberGroup } from './query';

export interface Query extends AssetBase {
  updateDate?: string;
  lastRunTime?: string;
  lastCount?: number;
  queryJSON?: string;
  /** NOTE :: Is this the same as tableName? Would like to unify */
  tableId?: string;
  id?: string;
  name?: string;
  description?: string;
}

/** check_any [query.service.ts] (129)*/
export interface QueryData {
  id?: string;
  name?: string;
  description?: string;
  starObjectId?: string;
  starObject?: string;
  star_object_table_name?: string;
  star_object_name?: string;
  queryJSON?: string;
  orgId?: string;
  projectId?: string;
  folderId?: string;
  projectID?: string;
  folderID?: string;
  folderName?: string;
  information?: object;
  queryOriginalStructure?: object;
  assetVid?: string;
  tableName?: string;
  tableId?: string;
  originalTable?: string;
  updatedQuery?: boolean;
  queryId?: string;
  queryData?: string;
}

// model for single query
export interface QueryModel {
  id?: number | string;
  name: string;
  Name?: string;
  description: string;
  starObject: string;
  queryData: string;
  queryJson?: string;
  count?: number;
  lastExecutedDate?: string;
  lastExecutedCount?: number;
  status?: string;
  addDate?: string;
  modDate?: string;
  modifiedBy?: string;
  orgId?: string;
  org_id?: string;
  folderId?: string;
  projectId?: string;
}

export interface AttributeModel {
  id?: number | string;
  name?: string;
  type?: string;
  hasChildren?: number;
  count?: number;
  filename?: string;
  value1?: string;
  customName?: string;
  dataType?: string;
  attribute_type?: string;
  attribute_parent?: number;
  value?: string;
  valueRange?: string;
  total?: number;
}

// eslint-disable-next-line no-shadow
export enum QueryBlockDataType {
  Block = 'block',
  Expression = 'exp',
  // eslint-disable-next-line no-shadow
  Query = 'query',
  List = 'list',
  Table = 'table'
}

// eslint-disable-next-line no-shadow
export enum QueryBlockLogicType {
  And = 'and',
  Or = 'or'
}

export interface QueryDataUnit {
  id: string | number;
  type: QueryBlockDataType;
  count?: number;
  data: QueryExpression | QueryListUnit;
}

export interface QueryExpression {
  id?: string | null;
  name?: string | null;
  op?: OpArr | string;
  filename?: string;
  value?: string;
  valueRange?: string;
  count?: number;
  type?: string;
}

export interface OpArr {
  value?: string | null;
  type?: string;
}

export interface QueryListUnit {
  id?: string | number;
  name?: string;
  op?: string;
  count?: number;
}

export interface SegmentationQuery {
  id: number | string;
  type: QueryBlockDataType;
  logic?: SegmentLogic;
  count?: number | null;
  op?: string;
  data: Array<SegmentationQuery | QueryDataUnit>;
  total?: number | null;
  tableName?: string;
  tableCount?: number;
  star_object_table_name?: string;
  star_object_name?: string;
}

export interface SegmentLogic {
  andOrOp: QueryBlockLogicType;
  notOp: boolean;
}

// eslint-disable-next-line no-shadow
export enum QueryBlockActionType {
  'Add' = 'add',
  'Delete' = 'delete',
  'Update' = 'update',
  'UpdateOperator' = 'updateop',
  'UpdateBlockLogicOperator' = 'updateBlockOp'
}
export interface QueryBlockActionRequest {
  action: QueryBlockActionType;
  type: QueryBlockDataType;
  fragment?: QueryFragment;
  data?: QueryExpression;
  id?: number | string;
  item?: QueryGroupMemberGroup;
  count?: number;
}

export interface BuilderDialogInput {
  queryId?: string;
  queryName?: string;
  queryDescription?: string;
  tableId: string;
  tableName: string;
}

export interface AttributeAccordion {
  id?: number | string;
  name?: string;
  filename?: string;
  type?: string;
  hasChildren: boolean;
  count?: number;
  children?: Array<AttributeAccordion | ValueAccordion>;
}

export interface ValueAccordion {
  id: number;
  name: string;
  type: string;
  count?: number;
  hasChildren: boolean;
  value1: string;
  value2: string;
}

// eslint-disable-next-line no-shadow
export enum NodeDataType {
  ATTRIBUTE = 'attr',
  VALUE = 'value',
  TABLE = 'table'
}

export type NodeData = IAttributeData | IValueData | ITableData;

export interface IAttributeData {
  attrId: string;
  attrName: string;
  fileName: string;
  count: number;
}

export interface ITableData {
  id: string;
  name: string;
  type: NodeDataType.TABLE;
}

export interface IValueData {
  attrId: string;
  attrName: string;
  fileName: string;
  value: DataValue;
}

export interface ParamsList {
  starObject?: string;
  id?: string | number;
}

export interface DataValue {
  attribute_parent: number;
  count: number;
  customName: string;
  filename: string;
  id: number;
  name: string;
  type: string;
  value1: string;
  value2: string;
}

export interface BlockEvent {
  blockData: any;
  currentTableId: string;
  currentTableName: string;
  event: PointerEvent;
}
