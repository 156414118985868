import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

import { FuseNavigationModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { NavbarVerticalStyle2Component } from 'app/layout/components/navbar/vertical/style-2/style-2.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [NavbarVerticalStyle2Component],
  imports: [MatButtonModule, MatIconModule, FuseSharedModule, FuseNavigationModule, RouterModule],
  exports: [NavbarVerticalStyle2Component]
})
export class NavbarVerticalStyle2Module {}
