import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NeptunePipesModule } from './pipes/pipes.module';
import { NeptuneComponentsModule } from './components/components.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, NeptunePipesModule, NeptuneComponentsModule],
  exports: [CommonModule, NeptunePipesModule, NeptuneComponentsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NeptuneSharedModule {}
