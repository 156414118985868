import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormCanvasElement } from '../../factories/form-canvas-elements';

@Component({
  selector: 'nel-form-preview-content',
  templateUrl: './form-preview-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormPreviewContentComponent implements OnInit {
  @Input() previewElements: FormCanvasElement[];
  @Output() submitted = new EventEmitter<{ fields: [{ [key: string]: AbstractControl }] }>();

  public formGroup: UntypedFormGroup;

  ngOnInit(): void {
    this.toFormGroup(this.previewElements);
  }

  private toFormGroup(elements: FormCanvasElement[]) {
    const group: { [key: string]: AbstractControl } = {};
    elements.forEach(element => {
      group[element.id] = new UntypedFormControl(element.value, element.formGroupValidators);
    });
    this.formGroup = new UntypedFormGroup(group);
  }

  onSubmit() {
    if (!this.formGroup.valid) {
      return;
    }

    this.submitted.emit(this.formGroup.value);
  }

  onReset() {
    this.formGroup.reset();
  }
}
