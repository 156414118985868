import { Injectable } from '@angular/core';
import { ItemNode } from '@neptune/models/projects';
import { Subject } from 'rxjs';
import { ProjectItemFlatNode } from '../shared-components/project-list-database';

@Injectable({ providedIn: 'root' })
export class ProjectsListService {
  newAsset = new Subject<any>();

  public createNewAsset(contentType: string, project: ItemNode) {
    const node = new ProjectItemFlatNode();
    node.item = project;
    node.projectId = project.id;
    this.newAsset.next({ type: contentType, project: node });
  }

  public createNewProject() {
    this.newAsset.next({ type: 'project', project: null });
  }

  public createNewQuery(project?, folder?) {
    this.newAsset.next({ type: 'query', project, folder });
  }
}
