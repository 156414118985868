/**
 * Possible types of Angular Material Buttons that can be used in the PageToolbarComponent
 * See: https://material.angular.io/components/button/api#MatButton
 */
import { Roles } from '@neptune/models/user';
import { MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';

// eslint-disable-next-line no-shadow
export enum MatButtonTypes {
  DEFAULT = 'mat-button',
  RAISED = 'mat-raised-button',
  FLAT = 'mat-flat-button',
  STROKED = 'mat-stroked-button',
  ICON = 'mat-icon-button'
}

// eslint-disable-next-line no-shadow
export enum MatButtonColorTypes {
  DEFAULT = '',
  PRIMARY = 'primary',
  ACCENT = 'accent',
  WARN = 'warn'
}

/**
 * Possible types of action elements in this button group, from a normal button, checkbox, to links.
 * This should grow whenever we need more types of action here.
 */
// eslint-disable-next-line no-shadow
export enum ButtonConfigType {
  EXTERNAL_LINK = 'external-link',
  INTERNAL_LINK = 'internal-link',
  MAT_BUTTON = 'mat_button',
  MAT_BUTTON_MENU = 'mat_button_menu',
  MAT_CHECKBOX = 'mat_checkbox',
  SEPARATOR = 'separator',
  EXPAND_MENU = 'expand',
  TEXT = 'text',
  MAT_BUTTON_TOOGLE_GROUP = 'mat_button_toogle_group'
}

export interface MatOption {
  label: string;
  click: any;
  qpIcon: string;
}

export interface MatToogleOption {
  label: string;
  value: string;
}

/**
 * Configuration object for buttons used in PageToolbarComponent
 */
export interface ToolbarButtonConfiguration {
  name: string;
  buttonType?: MatButtonTypes;
  colorType?: MatButtonColorTypes;
  configType: ButtonConfigType;
  classes?: string;
  icon?: string;
  link?: string;
  checkboxStatus?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClick?: Function;
  rolList?: Roles | Roles[];
  svgIcon?: boolean;
  matMenu?: MatMenu;
  disabled?: boolean;
  tooltip?: string;
  text?: string;
  visible?: boolean;
  hide?: boolean;
  matOptions?: MatOption[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  model?: Object;
  matToggleOptions?: MatToogleOption[];
}

/**
 * Buttons used as tabs in a standard list page
 */
export interface ListPageTabButtons {
  name: string;
  value: string;
}
