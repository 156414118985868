<mat-toolbar class="p-0 mat-elevation-z1">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <!--<div>-->
    <!--<button mat-button class="toggle-button-navbar mat-icon-button" (click)="toggleSidebarUnfold('navbar')">-->
    <!--<mat-icon>menu</mat-icon>-->
    <!--</button>-->
    <!--</div>-->
    <button
      *ngIf="navigationQueueEnabled"
      mat-icon-button
      class="toggle-button-navbar navigate-back"
      (click)="navigateBack()"
      matTooltip="Back"
    >
      <mat-icon>undo</mat-icon>
    </button>
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <div *ngIf="!isC3user">
        <button mat-button class="toggle-button-navbar mat-icon-button" (click)="toggleSidebarOpened('navbar')">
          <mat-icon class="mat-icon-menu" matTooltip="Menu">menu</mat-icon>
        </button>
      </div>

      <div class="toolbar-separator"></div>
      <a class="logo home" pl="8" [routerLink]="'pages/homepage'" *ngIf="!isC3user">
        <img width="128" src="assets/images/logos/nxtdrive-color.svg" />
      </a>
        <img class="logo home" width="128" src="assets/images/logos/nxtdrive-color.svg" *ngIf="isC3user" />
      <standard-search-component
        class="search-bar"
        fxFlex="50"
        [search]="searchConfig.searchValue"
        [placeholder]="searchConfig.searchPlaceholder"
        [auto]="'off'"
        (onSearch)="search($event.search)"
        (onChange)="onSearchChange($event.search)"
        *ngIf="!isC3user"
      ></standard-search-component>

      <div class="wrapper" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center" style="width: 100%" *ngIf="isC3user">
        <div class="organization-name" fxLayout="row" style="width: 100%">
          <span class="sisense-org">{{ organizationName }}</span>
        </div>
      </div>

      <mat-card class="popup-search" *ngIf="showAuto">
        <mat-option *ngIf="loading">
          <mat-spinner [diameter]="20"></mat-spinner>
        </mat-option>
        <mat-option *ngFor="let result of autoResults" [value]="result" class="popup-result">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <span fxLayout="row">
              <mat-icon [svgIcon]="getIconForAsset(result)" class="align-asset-icon"></mat-icon>
              <span (click)="navigateToAsset(result)">{{ result.name }}</span>
            </span>
            <span class="project-folder">
              <div>{{ result.projectName }}</div>
              /
              <div>{{ result.folderName }}</div>
            </span>
          </div>
        </mat-option>
      </mat-card>
    </div>

    <div class="wrapper" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button class="help-button" (click)="openHelpWindow()" *ngIf="!isC3user">
        <mat-icon class="toolbar-icon-help" svgIcon="help" matTooltip="Help"></mat-icon>
      </button>
      <div class="toolbar-separator" *ngIf="!isC3user"></div>

      <div
        *ngIf="impersonationOrgOptions.length === 0 && !isC3user"
        qp-roles
        class="organization-name"
        fxLayout="row"
        fxLayoutAling="space-between center"
      >
        <mat-icon fxFlexAlign="center" svgIcon="organization" class="mr-8"></mat-icon>
        <span>{{ organizationName }}</span>
      </div>

      <button
        *ngIf="impersonationOrgOptions.length > 1 && !isC3user"
        qp-roles
        mat-button
        [matMenuTriggerFor]="orgMenu"
        class="organization-name"
        fxLayout="row"
        fxLayoutAling="space-between center"
        matTooltip="Switch to different Organization"
      >
        <mat-icon fxFlexAlign="center" svgIcon="organization" class="mr-8"></mat-icon>
        <span>{{ organizationName }}</span>
        <mat-icon class="s-24" fxHide.xs>keyboard_arrow_down</mat-icon>
      </button>

      <iframe
        [src]="urlLogout | safe"
        width="100%"
        height="1000"
        frameborder="0"
        style="border: 0; visibility: hidden;"
        allowfullscreen
        aria-hidden="false"
        tabindex="0"
        *ngIf="logoutframe"
      ></iframe>

      <mat-menu #orgMenu="matMenu" [overlapTrigger]="false">
        <div *ngIf="impersonationOrgOptions.length > 1" class="ml-16 mr-16">Switch to:</div>
        <div class="toolbar-component-switch-to-org-list">
          <dropdown-button
            *ngFor="let org of impersonationOrgOptions"
            [label]="org.OrgID"
            qpIcon="organization"
            (onClick)="switchToOrganization(org)"
          ></dropdown-button>
        </div>
      </mat-menu>

      <div class="toolbar-separator" *ngIf="impersonationOrgOptions.length > 1"></div>

      <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-icon *ngIf="!avatarPath" [svgIcon]="'account_user'"></mat-icon>
          <img *ngIf="avatarPath" class="avatar" [src]="avatarPath" />
          <div class="name-wrapper ml-8" fxLayout="column" fxLayoutAlign="space-between center">
            <span *ngIf="!isC3user" class="username mr-12" fxHide fxShow.gt-sm>{{ fullName }}</span>
            <span *ngIf="isC3user" class="mr-12 sisense-org" fxHide fxShow.gt-sm>{{ fullName }}</span>
          </div>
          <mat-icon class="s-24" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
        <dropdown-button label="Edit Profile" (onClick)="editProfile()" qpIcon="edit"></dropdown-button>
        <dropdown-button label="Change Password" (onClick)="changePassword()" qpIcon="key"></dropdown-button>
        <mat-divider></mat-divider>
        <dropdown-button label="Logout" (onClick)="logout()" qpIcon="log-out"></dropdown-button>
      </mat-menu>

      <!--<button mat-button [matMenuTriggerFor]="userMenu" class="user-button">-->
      <!--<div fxLayout="row" fxLayoutAlign="center center">-->
      <!--<img class="avatar mr-0 mr-sm-16" src="assets/images/avatars/Velazquez.jpg" />-->
      <!--<span class="username mr-12" fxHide fxShow.gt-sm>Charlie Adams</span>-->
      <!--<mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>-->
      <!--</div>-->
      <!--</button>-->

      <!--<mat-menu #userMenu="matMenu" [overlapTrigger]="false">-->
      <!--<button mat-menu-item>-->
      <!--<mat-icon>account_circle</mat-icon>-->
      <!--<span>My Profile</span>-->
      <!--</button>-->

      <!--<button mat-menu-item class="">-->
      <!--<mat-icon>mail</mat-icon>-->
      <!--<span>Inbox</span>-->
      <!--</button>-->

      <!--<button mat-menu-item class="">-->
      <!--<mat-icon>exit_to_app</mat-icon>-->
      <!--<span>Logout</span>-->
      <!--</button>-->
      <!--</mat-menu>-->

      <!--<div class="toolbar-separator"></div>-->

      <!--<fuse-search-bar (input)="search($event)"></fuse-search-bar>-->

      <!--<div class="toolbar-separator"></div>-->

      <!--<button mat-button fxHide fxShow.gt-xs class="language-button" [matMenuTriggerFor]="languageMenu">-->
      <!--<div fxLayout="row" fxLayoutAlign="center center">-->
      <!--<img class="flag mr-8" [src]="'assets/icons/flags/' + selectedLanguage.flag + '.png'" />-->
      <!--<span class="iso text-uppercase">{{ selectedLanguage.id }}</span>-->
      <!--</div>-->
      <!--</button>-->

      <!--<mat-menu #languageMenu="matMenu" [overlapTrigger]="false">-->
      <!--<button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">-->
      <!--<span fxLayout="row" fxLayoutAlign="start center">-->
      <!--<img class="flag mr-16" [src]="'assets/icons/flags/' + lang.flag + '.png'" />-->
      <!--<span class="iso">{{ lang.title }}</span>-->
      <!--</span>-->
      <!--</button>-->
      <!--</mat-menu>-->

      <!--<div class="toolbar-separator" fxHide fxShow.gt-xs></div>-->
    </div>
  </div>
</mat-toolbar>
