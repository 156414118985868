import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageKeySelectorPickerComponent } from 'app/main/content/pages/message/message-key-selector-picker/message-key-selector-picker.component';
import { MaterialModule } from '@neptune/material.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { AssetSelectorModule } from '@neptune/components/asset-selector/asset-selector.module';
import { LoadingModalModule } from '@neptune/components';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UDFService } from '@neptune/services/udf.service';

@NgModule({
  declarations: [MessageKeySelectorPickerComponent],
  imports: [MaterialModule, CommonModule, FuseSharedModule, AssetSelectorModule, LoadingModalModule, DragDropModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [MessageKeySelectorPickerComponent],
  providers: [UDFService]
})
export class MessageKeySelectorPickerModule {}
