<div class="asset-selector-dialog">
  <loading-modal></loading-modal>
  <div mat-dialog-title class="dialog-header">
    <div class="dialog-title">
      <span class="h2">{{ title }}</span>
    </div>
  </div>

  <mat-dialog-content>
    <div class="asset-selector-dialog__content">
      <p *ngIf="data?.description">{{ data?.description }}</p>
      <ng-container>
        <div class="asset-selector-dialog__content__container" fxLayout>
          <!-- Folder navigation -->
          <div fxFlex="30" class="asset-selector-dialog__content__container__navigation" fusePerfectScrollbar>
            <mat-tree
              [dataSource]="dataSource"
              [treeControl]="treeControl"
              class="asset-selector-dialog__content__container__navigation__tree"
            >
              <!-- This is the tree node template for leaf nodes -->
              <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                <li
                  class="mat-tree-node leaf-node"
                  (click)="toggleSelect(node)"
                  [class.selected]="isAssetNavSelected(node)"
                >
                  <!-- Assume level 0 is a project -->
                  <mat-icon *ngIf="node.level === 0" svgIcon="project"></mat-icon>
                  <mat-icon *ngIf="node.level !== 0" [svgIcon]="getAssetIcon(node.assetType)"></mat-icon>
                  {{ node.name }}
                </li>
              </mat-tree-node>
              <!-- This is the tree node template for expandable nodes -->
              <mat-nested-tree-node
                *matTreeNodeDef="let node; when: hasChild"
                [disabled]="node.loading || !checkSelectable(node)"
                [class.disabled]="!checkSelectable(node)"
              >
                <li>
                  <div
                    class="mat-tree-node parent-node"
                    (click)="toggleSelect(node)"
                    [class.selected]="isAssetNavSelected(node)"
                  >
                    <button
                      mat-icon-button
                      matTreeNodeToggle
                      [attr.aria-label]="'toggle ' + node.name"
                      (click)="node.assetType === AssetType.PROJECT ? toggleSelect(node) : null"
                    >
                      <mat-icon
                        class="mat-icon-rtl-mirror"
                        [svgIcon]="treeControl.isExpanded(node) ? 'triangle_down' : 'triangle_right'"
                      ></mat-icon>
                    </button>
                    <mat-icon [svgIcon]="getAssetIcon(node.assetType)"></mat-icon>
                    {{ node.name }}
                    <mat-spinner *ngIf="node.loading" [diameter]="20"></mat-spinner>
                  </div>
                  <ul
                    [class.asset-selector-dialog__content__container__navigation__tree__invisible]="
                      !treeControl.isExpanded(node)
                    "
                  >
                    <ng-container matTreeNodeOutlet></ng-container>
                  </ul>
                </li>
              </mat-nested-tree-node>
            </mat-tree>
          </div>
          <!-- Asset listing -->
          <div fxFlex="70" class="asset-selector-dialog__content__container__table">
            <ngx-datatable
              class="material"
              columnMode="force"
              [rows]="rowsData"
              [rowClass]="rowClass"
              [headerHeight]="48"
              [rowHeight]="48"
              [scrollbarH]="false"
              [scrollbarV]="true"
              [selectionType]="data?.multiSelect ? SelectionType.multi : SelectionType.single"
              [selected]="selectedAssets"
              [selectCheck]="checkSelectable"
            >
              <ngx-datatable-column name="Name" [flexGrow]="4" prop="name">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-icon [svgIcon]="getAssetIcon(row.assetType)"></mat-icon>
                    <div class="ml-12">{{ row.name }}</div>
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Count" [flexGrow]="1" prop="count" *ngIf="isQuery">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span>{{ row.lastExecutedCount === -1 ? 'Not Executed' : row.lastExecutedCount }}</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Status" [flexGrow]="1" prop="status">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span>{{ row.status }}</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Table" [flexGrow]="2" prop="tableName">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span>{{ row.tableName }}</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Updated" [flexGrow]="2" prop="updatedAt">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <standard-user-date
                    [date]="row.updatedAt"
                    [user]="row | updatedMailOrUserToName"
                  ></standard-user-date>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </ng-container>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayoutAlign="end start" class="dialog-footer" fxLayout="row nowrap">
    <button mat-button color="primary" (click)="onClose()" class="cancel-btn">Cancel</button>
    <button mat-flat-button color="primary" [disabled]="selectedAssets.length === 0" (click)="onSelect()">
      Select
    </button>
  </mat-dialog-actions>
</div>
