import { Directive, ElementRef, HostListener, Input, SimpleChange } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[showAssetMultipleVersionIcon]'
})
export class ShowAssetMultipleVersionIconDirective {
  @Input('hasMultipleVersions') hasMultipleVersions?: boolean;

  constructor(private elementRef: ElementRef) {}

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {
    this.elementRef.nativeElement.hidden = !this.hasMultipleVersions;
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  @HostListener('change') ngOnChanges(hasMultipleVersions: SimpleChange) {
    this.elementRef.nativeElement.hidden = !hasMultipleVersions.currentValue;
  }
}
