import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Field, DataType } from '@neptune/models/file';

@Component({
  selector: 'app-key-selector',
  templateUrl: './key-selector.component.html',
  styleUrls: ['./key-selector.component.scss']
})
export class KeySelectorComponent implements OnInit {
  @Input() set fields(f: Field[]) {
    if (f === undefined) {
      f = [];
    }
    this._fields = f.filter(field => field.dataType !== DataType.TEXT);
    // eslint-disable-next-line @typescript-eslint/no-shadow
    this.keyFields = this._fields.filter((f: Field) => f.isPrimaryKey);
  }
  @Input() disableSelect: boolean = false;
  @Output() keySelection = new EventEmitter<Field[]>();

  public keyFields: Field[] = [];
  public _fields: Field[];

  ngOnInit(): void {}

  public onSelected() {
    this.keyFields.forEach((f: Field) => (f.isPrimaryKey = true));
    this.keySelection.emit(this.keyFields);
  }
}
