import { JsepService } from '@neptune/services/jsep.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import {
  AggregationDefinition,
  AggregationFilter,
  DataTypeJDBC,
  FieldCalculationType,
  TransformDefinition,
  AggregateLiteralValueDefinition,
  AggregateFunctionValueDefinition,
  AggregateTransformFunctions,
  DataType,
  DataTypeUtils,
  JoinData,
  TableData,
  TableDetails,
  Transformation
} from '@neptune/models/file';
import { SelectedField } from 'app/main/content/pages/message/message-key-selector-picker/message-key-selector-picker.component';
import { SelectFieldDialogComponent } from './select-field-dialog/select-field-dialog.component';
import {
  ExpressionDialog,
  ExpressionDialogInput,
  ExpressionDialogOutput
} from '@neptune/components/expression-editor/expression-dialog';
import { TableService } from '@neptune/services/table.service';
import { NeptuneSetup } from 'environments/environment';
import * as moment from 'moment';
import { LoadingModalComponent } from '@neptune/components/loading-modal/loading-modal.component';
import { firstValueFrom } from 'rxjs';

export interface AggregateFunctionInput {
  currentField: string;
  currentFieldDataType: DataType;
  tableSchema: TableDetails;
  tables: TableData[];
  joinedTables: JoinData[];
  possibleTables: string[];
  transformation: Transformation;
  editable: boolean;
}

@Component({
  selector: 'app-aggregate-function-dialog',
  templateUrl: './aggregate-function-dialog.html',
  styleUrls: ['./aggregate-function-dialog.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AggregateFunctionDialog implements OnInit {
  @ViewChild('filtersTable') filtersTable: MatTable<any>;
  @ViewChild(LoadingModalComponent, { static: true })
  private loadingComponent: LoadingModalComponent;

  possibleTables: string[];
  possibleFunctions: string[];

  public filtersDataSource: AggregationFilter[] = [];
  public displayedColumns: string[];
  public function: AggregateTransformFunctions;
  public selectedField: SelectedField;

  constructor(
    public dialogRef: MatDialogRef<AggregateFunctionDialog>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: AggregateFunctionInput,
    private tableService: TableService,
    private jsepService: JsepService
  ) {}

  ngOnInit() {
    this.getSavedValues();
    this.possibleTables = this.data.tables.filter(t => this.data.possibleTables.indexOf(t.Id) >= 0).map(t => t.name);
    this.possibleFunctions = Object.values(AggregateTransformFunctions);
    this.displayedColumns = ['fieldId', 'operator', 'valueDef'];
  }

  private async getSavedValues() {
    const transformation = this.data.transformation;
    if (transformation) {
      const aggregate = transformation.transformDefinition.aggregate;
      this.function = aggregate?.function as AggregateTransformFunctions;
      if (aggregate?.selectedField) {
        this.selectedField = aggregate.selectedField;
      } else {
        // Getting data from selected aggregate field
        let table = this.data.tables.filter(tabl => tabl.Id === aggregate?.tableId)[0];
        if (!table) {
          this.loadingComponent.startLoading();
          table = await firstValueFrom(this.tableService.getTable(aggregate?.tableId as string));
          this.loadingComponent.stopLoading();
        }
        const field = table.fields.filter(fld => fld.name === aggregate?.fieldId)[0];
        this.selectedField = {
          field,
          tableId: aggregate?.tableId as string,
          tableName: table.name
        };
      }
      this.filtersDataSource = JSON.parse(JSON.stringify(aggregate?.filters));
      this.parseTablesAndDates();
    }
  }

  private async parseTablesAndDates() {
    this.filtersDataSource.forEach(async filter => {
      if (this.getFieldDataType(filter.fieldType as DataTypeJDBC) === 'date') {
        filter.valueDef?.forEach((value: any) => {
          if (value.valueType === 'literal') {
            value.filterValue = moment(value.filterValue, 'YYYY-MM-DD');
          }
        });
      }
      const filterTable = await firstValueFrom(this.tableService.getTable(filter?.tableId as string));
      filter.tableName = filterTable.name;
      filter.fieldType = filterTable.fields.find(el => el.name === filter.fieldId)?.type?.toLowerCase() as DataTypeJDBC;
    });
  }

  public addNewFilter() {
    const newFilter: AggregationFilter = {
      tableId: undefined,
      tableName: undefined,
      fieldId: undefined,
      fieldType: undefined,
      operator: undefined,
      valueDef: [
        {
          valueType: 'literal',
          dataType: undefined,
          filterValue: undefined
        }
      ] as AggregateLiteralValueDefinition[]
    };
    this.filtersDataSource.push(newFilter);
    if (this.filtersDataSource.length > 1) {
      this.filtersTable.renderRows();
    }
  }

  public deleteFilter(filterIndex: number) {
    this.filtersDataSource.splice(filterIndex, 1);
    if (this.filtersDataSource.length > 0) {
      this.filtersTable.renderRows();
    }
  }

  public openFieldToAggregateDialog() {
    return this.dialog
      .open(SelectFieldDialogComponent, {
        data: this.data
      })
      .afterClosed()
      .subscribe((result: SelectedField) => {
        if (result) {
          this.selectedField = result;
        }
      });
  }

  public openSelectFieldDialog(item: AggregationFilter) {
    return this.dialog
      .open(SelectFieldDialogComponent, {
        data: this.data
      })
      .afterClosed()
      .subscribe((result: SelectedField) => {
        if (result) {
          this.resetItemValues(item);
          item.tableId = result.tableId;
          item.tableName = result.tableName;
          item.fieldId = result.field.name;
          item.fieldType = result.field.type;
          (item.valueDef as AggregateLiteralValueDefinition[])[0].dataType = DataTypeUtils.toDataType(
            result.field.type
          );
        }
      });
  }

  public getFieldDataType(fieldType: DataTypeJDBC): string {
    return DataTypeUtils.DataTypeJDBCMap.get(fieldType) as string;
  }

  private resetItemValues(item: AggregationFilter): void {
    Object.assign(item, {
      tableId: undefined,
      tableName: undefined,
      fieldId: undefined,
      fieldType: undefined,
      operator: undefined,
      valueDef: [
        {
          valueType: 'literal',
          dataType: undefined,
          filterValue: undefined
        }
      ]
    });
  }

  public onComparisonOperatorChange(operator: string, item: AggregationFilter): void {
    if (operator === 'bt') {
      item.valueDef = [
        {
          valueType: 'literal',
          dataType: DataTypeUtils.toDataType(item.fieldType as DataTypeJDBC),
          filterValue: undefined
        },
        {
          valueType: 'literal',
          dataType: DataTypeUtils.toDataType(item.fieldType as DataTypeJDBC),
          filterValue: undefined
        }
      ];
    } else {
      item.valueDef = [
        {
          valueType: 'literal',
          dataType: DataTypeUtils.toDataType(item.fieldType as DataTypeJDBC),
          filterValue: undefined
        }
      ];
    }
  }

  public openExpressionDialog(element: AggregationFilter, inputIndex: number): void {
    this.tableService
      .checkTableName(element.tableId as string, true, NeptuneSetup.DefaultCacheExpirationTime)
      .subscribe(res => {
        const data: ExpressionDialogInput = {
          fieldName: element.fieldId as string,
          fullFieldName: `${element.tableName}.${element.fieldId}`,
          expression: this.jsepService.jsep(element.fieldId as string),
          tableName: element.tableName,
          tableId: element.tableId,
          sources: res.fields.map(
            field =>
              <any>{
                name: field.name,
                dataType: DataTypeUtils.toDataType(field.type),
                isPrimaryKey: field.isPrimaryKey
              }
          ),
          hidePreview: true
        };

        if (
          element.valueDef &&
          element.valueDef[inputIndex] &&
          element.valueDef[inputIndex].valueType === 'expression'
        ) {
          data.expressionPrompt = (element.valueDef[inputIndex] as AggregateFunctionValueDefinition).text;
        } else {
          data.expressionPrompt = element.fieldId;
        }

        this.dialog
          .open(ExpressionDialog, {
            data,
            panelClass: 'no-padding-dialog'
          })
          .afterClosed()
          .subscribe((result: ExpressionDialogOutput) => {
            if (result && element.valueDef) {
              element.valueDef[inputIndex] = {
                valueType: 'expression',
                filterValue: { ...result.expression, version: 2 },
                text: result.text
              } as AggregateFunctionValueDefinition;
            }
          });
      });
  }

  public formIsValid(): boolean {
    return this.function && this.selectedField && (this.filtersDataSource.length === 0 || this.validateFilters());
  }

  private validateFilters(): boolean {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < this.filtersDataSource.length; i++) {
      const filter = this.filtersDataSource[i];
      if (
        !(filter.valueDef as AggregateFunctionValueDefinition[])[0].text &&
        !(filter.valueDef as AggregateFunctionValueDefinition[])[0].filterValue
      ) {
        return false;
      } else if (
        filter.operator === 'bt' &&
        !(filter.valueDef as AggregateFunctionValueDefinition[])[1].text &&
        !(filter.valueDef as AggregateFunctionValueDefinition[])[1].filterValue
      ) {
        return false;
      }
    }
    return true;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave() {
    const result = <Transformation>{
      destinationTableId: this.data.tableSchema.id,
      fieldId: this.data.currentField,
      transformDefinition: <TransformDefinition>{
        dataType: this.data.currentFieldDataType,
        type: FieldCalculationType.AGGREGATE,
        aggregate: <AggregationDefinition>{
          function: this.function,
          selectedField: this.selectedField,
          tableId: this.selectedField.tableId,
          fieldId: this.selectedField.field.name,
          filters: this.filtersDataSource
        }
      },
      upsert: true
    };

    result.transformDefinition.aggregate?.filters.forEach(filter => {
      if (this.getFieldDataType(filter.fieldType as DataTypeJDBC) === 'date') {
        filter.valueDef?.forEach(value => {
          if (value.valueType === 'literal') {
            value.filterValue = value.filterValue.format('YYYY-MM-DD');
          }
        });
      }
    });

    this.dialogRef.close(result);
  }
}
