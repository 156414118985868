import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { DialogInput, DialogButtonType } from '@neptune/models/dialog';

@Component({
  selector: 'info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
  // styles:[],
  encapsulation: ViewEncapsulation.None
})
export class InfoDialogComponent implements OnInit {
  buttonType = DialogButtonType;

  constructor(
    private dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: DialogInput
  ) {}

  ngOnInit() {}

  onConfirm() {
    this.dialogRef.close({ answer: true });
  }
}
