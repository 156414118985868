import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StandardUserDateComponent } from './standard-user-date.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [StandardUserDateComponent],
  imports: [CommonModule, FlexLayoutModule],
  exports: [StandardUserDateComponent]
})
export class StandardUserDateModule {}
