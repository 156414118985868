import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

/**
 * Helper function to constrain styles only to a specific className when using ViewEncapsulation.NONE on a dialog.
 *
 * IMPORTANT:
 * - The dialogComponent should have <b>encapsulation: ViewEncapsulation.None</b> on the Component decorator.
 * - This function should be called on the constructor of the dialog component
 *
 * @param dialogComponentName
 * @param dialogContainerClass
 * @param dialogRef
 */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function dialogContainerClassSafeCheck(
  dialogComponentName: string,
  dialogContainerClass: string,
  dialogRef: MatDialogRef<any>
) {
  if (
    !dialogRef._containerInstance._config.panelClass ||
    dialogRef._containerInstance._config.panelClass !== dialogContainerClass
  ) {
    throw new Error(
      `Please add { ..., panelClass: '${dialogContainerClass}', ... } to '${dialogComponentName} dialog configuration object.`
    );
  }
}
