export class WhereUsedModel {
  dependentObject?: string;
  data?: {
    description?: any;
    dependentObject?: any;
    dependentObjectName?: any;
  };
  typeOfObject?: string;
  dependentObjectId?: string;
  typeOfDependentObject?: string;
  objectId?: string;
  orgId?: string;
  object?: string;
}
