import { Injectable } from '@angular/core';
import { BaseService, Endpoint } from '@neptune/services/base.service';
import { Observable, of as observableOf } from 'rxjs';
import { UDF } from '@neptune/models/udf';

@Injectable()
export class UDFService extends BaseService {
  private PATH: string = 'org_udf';

  /**
   * For use with jasmine testing, returns a mock of service
   */
  public static mockService(jasmine: any, uDF: UDF): any {
    return {
      get: jasmine.createSpy('get').and.returnValue(observableOf(uDF)),
      update: jasmine.createSpy('update').and.returnValue(observableOf(uDF)),
      create: jasmine.createSpy('create').and.returnValue(observableOf(uDF)),
      delete: jasmine.createSpy('delete').and.returnValue(observableOf(uDF))
    };
  }

  public get(id: string): Observable<UDF> {
    return super.baseGet<UDF>(Endpoint.UDF, `${this.PATH}/${id}`);
  }

  public getAll(): Observable<UDF[]> {
    return super.baseGet<UDF[]>(Endpoint.UDF, `${this.PATH}`);
  }

  public update(uDF: UDF): Observable<any> {
    return super.basePut<any, any>(Endpoint.UDF, `${this.PATH}/${uDF.id}`, uDF);
  }

  public create(name: string): Observable<UDF> {
    // FILL: Complete with all needed params for this particular
    return super.basePost(Endpoint.UDF, `${this.PATH}`, {
      name
    });
  }

  public delete(uDF: UDF): Observable<any> {
    return super.baseDelete(Endpoint.UDF, `${this.PATH}/${uDF.id}`);
  }
}
