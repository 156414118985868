import { Component, Inject, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { dialogContainerClassSafeCheck } from '@neptune/utilities/dialog-container-safecheck';
import { Resource, ResourceStatus, ResourceDialogData, ResourceDialogState } from '@neptune/models/resource';
import { ConfirmInput, ConfirmDialog } from '../confirm-dialog/confirm-dialog';
import { ResourceDialogContentComponent } from './resource-dialog-content/resource-dialog-content.component';
import { LoadingModalComponent } from '../loading-modal/loading-modal.component';
import { ResourceService } from '@neptune/services/resource.service';
import { AccountService } from '@neptune/services/account.service';
import { UserData } from '@neptune/models';

export interface FileStatus {
  statuses: ResourceStatus[];
  label: string;
  count: number;
  class: string;
}

@Component({
  selector: 'app-resource-dialog',
  templateUrl: './resource-dialog.component.html',
  styleUrls: ['./resource-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResourceDialogComponent implements OnInit {
  @ViewChild('resourceUpload') resourceUpload: ResourceDialogContentComponent;
  @ViewChild(LoadingModalComponent, { static: true }) loadingComponent: LoadingModalComponent;

  /** Default title */
  public title: string = 'Upload New Resources';

  public bucketName: string;
  // FIXME: need BACKEND to provide key to this prop in Org table
  public resourcePath: string = 'tomas-tests-upload-path';

  /** Flag for preloader */
  public loading: boolean = false;

  /** File resources to be uploaded  */
  public files: Resource[] = [];

  public fileStatuses: FileStatus[] = [
    {
      statuses: [ResourceStatus.DONE, ResourceStatus.SUCCESS],
      label: 'upload(s) completed',
      count: 0,
      class: 'status-done'
    },
    {
      statuses: [
        ResourceStatus.UPLOADING,
        ResourceStatus.IN_PROGRESS,
        ResourceStatus.FINISHED_UPLOAD,
        ResourceStatus.DELETE
      ],
      label: 'upload(s) in progress',
      count: 0,
      class: 'status-uploading'
    },
    {
      statuses: [ResourceStatus.ERROR, ResourceStatus.CANCELLED, ResourceStatus.FAILED, ResourceStatus.DELETED],
      label: 'upload(s) failed',
      count: 0,
      class: 'status-error'
    }
  ];

  /** Dialog state */
  State = ResourceDialogState;
  public currentState: ResourceDialogState = ResourceDialogState.UPLOAD;

  /** Display uplaoded count */
  public setFilesStatus() {
    if (!this.files.length) {
      return;
    }

    this.fileStatuses = this.files
      .filter(resource => resource.status !== ResourceStatus.INVALID && resource.status !== ResourceStatus.READY)
      .reduce(
        (acc, resource) => {
          const statusAccumulator = acc.find(a => a.statuses.indexOf(resource.status) !== -1);
          if (statusAccumulator) {
            statusAccumulator.count += 1;
          }
          return acc;
        },
        this.fileStatuses.map(status => ({ ...status, count: 0 }))
      );
  }

  public isCompleted(state: ResourceDialogState): boolean {
    return this.resourceService.isCompleted(this.files, state);
  }

  public isDisabled(state: ResourceDialogState): boolean {
    return this.resourceService.isDisabled(this.files, state);
  }

  public showButton(state: ResourceDialogState) {
    return this.currentState === state;
  }

  public canClose(): boolean {
    return this.resourceService.canClose(this.files);
  }

  /**
   * For simplicity I'm preventing the user from cancelling the upload process if there are resources that haven't been POSTed yet.
   * Obviously a better solution would contemplate waiting for the POST to finish on the background in order to have the resource ID and then call the DELETE for that resource.
   * No more time to make things right.
   */
  public get isCancelDisabled(): boolean {
    return this.resourceService.isCancelDisabled(this.files);
  }

  constructor(
    private dialog: MatDialog,
    private accountService: AccountService,
    public dialogRef: MatDialogRef<ResourceDialogComponent>,
    public resourceService: ResourceService,
    @Inject(MAT_DIALOG_DATA) public data: ResourceDialogData
  ) {
    // Ensure the proper panelClass has been added to the configuration object
    dialogContainerClassSafeCheck('ResourceDialogComponent', 'resource-dialog-container', dialogRef);
  }

  ngOnInit(): void {
    const orgData = this.resourceService.getOrgData();
    this.bucketName = orgData.resources?.OrgBucket?.Name as string;
    // FIXME: need BACKEND to provide key to this prop in Org table
    this.resourcePath = orgData.resources?.OrgBucket?.ResourcePath || 'resource';
    // DynamoDB has a strange way to store null and BE does not switch to actual  null, patch to release quick
    if (
      orgData.resources?.OrgBucket?.RootPath &&
      orgData.resources.OrgBucket.RootPath.length > 0 &&
      orgData.resources.OrgBucket?.RootPath !== '{"NULL":true}'
    ) {
      this.resourcePath = `${orgData.resources?.OrgBucket?.RootPath}/${this.resourcePath}`;
    }
  }

  onFilesChange(files: Resource[]) {
    this.currentState = this.resourceService.setCurrentState(files) as ResourceDialogState;
    this.setFilesStatus();
  }

  onSave() {
    this.accountService
      .getCurrentUser()
      .then((userData: UserData) => {
        // eslint-disable-next-line arrow-body-style
        const newRecords = this.resourceService.mapResourceToAsset(this.files).map(res => {
          return { ...res, updatedBy: userData.attributes?.email, insertedBy: userData.attributes?.email };
        });
        this.dialogRef.close(newRecords);
      })
      .catch((error: Error) => {
        this.dialogRef.close([]);
      });
  }

  onUpload() {
    this.resourceUpload.upload();
  }

  onClose() {
    // Close and early return if theres no pending actions
    if (this.canClose()) {
      this.dialogRef.close();
      return;
    }

    const data: ConfirmInput = {
      title: `Are you sure you want to cancel?`,
      message: 'Upload in progress will be cancelled, and complete uploads will be deleted.',
      okMessage: 'Yes, Cancel',
      cancelMessage: `No, Don't Cancel`
    };

    ConfirmDialog.open(
      this.dialog,
      data,
      confirmed => {
        this.loading = true;
        this.resourceUpload.cancel();
        this.dialogRef.close();
      },
      cancelled => {}
    );
  }

  onCopiedToClipboard() {
    this.loadingComponent.showSuccess('Copied to clipboard!');
  }
}
