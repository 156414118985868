import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PrintOnDemand } from '@neptune/models/message';
import { LoadingModalComponent } from '@neptune/components/loading-modal/loading-modal.component';

@Component({
  selector: 'print-on-demand-preview-content',
  templateUrl: './print-on-demand-preview-content.component.html',
  styleUrls: ['./print-on-demand-preview-content.component.scss']
})
export class PrintOnDemandPreviewContentComponent implements OnInit {
  public _modifiedMessage: PrintOnDemand;

  @Input('message') set initialMessage(m: PrintOnDemand) {
    this._modifiedMessage = m;
  }

  @ViewChild(LoadingModalComponent, { static: true }) loadingComponent: LoadingModalComponent;

  private imageAmount: number = 2;

  ngOnInit() {
    this.loadingComponent.startLoading();
  }

  public imageLoaded() {
    this.imageAmount--;
    if (this.imageAmount === 0) {
      this.loadingComponent.stopLoading();
    }
  }

  public errorLoadingImage() {
    this.loadingComponent.showError('There was an error loading campaign image');
  }
}
