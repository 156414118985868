import { AssetId, JourneyState } from './journey';
import { DynamicContentStatus } from './dynamic-content';
import { MessageStatus } from './message';
import { LandingPageStatus } from './landing-page';
import { SegmentBuilderStatus } from '@neptune/models/segment-builder';
import { ListDefinitionStatus } from '@neptune/models/list-definition';
import { RepeatBlockStatus } from '@neptune/models/repeat-block';
import { ResourceStatus } from './resource';
import { PrintOnDemandStatus } from '@neptune/models/message';
import { TableJoinsData, RowDelimiterType } from '@neptune/models/file';
import { Endpoint } from '../services/base.service';
import { PurlStatus } from './purl';
import { SegmentWFStatus } from './segmentation-waterfall';
import { Folder } from '@neptune/models/projects';
import { FormStatus } from '@neptune/models/form';

// eslint-disable-next-line no-shadow
export enum AssetType {
  DATA_OUTPUT = 'Message-DATA_OUTPUT',
  DYNAMIC_CONTENT_EMAIL_SUBJECT = 'DynamicContent-EMAIL_SUBJECT',
  DYNAMIC_CONTENT_EMAIL_BODY = 'DynamicContent-EMAIL_BODY',
  DYNAMIC_CONTENT_HOSTED_PAGE = 'DynamicContent-HOSTED_PAGE',
  DYNAMIC_CONTENT_PRINT = 'DynamicContent-PRINT',
  DYNAMIC_CONTENT_DATA_OUTPUT = 'DynamicContent-DATA_OUTPUT',
  EMAIL = 'Message-EMAIL',
  FOLDER = 'Folder', // NOTE: treated as AssetType for AssetSelectorDialog
  FORM = 'Form',
  IMPORT = 'Import',
  JOURNEY = 'Journey',
  LANDING_PAGE = 'LandingPage',
  LIST_DEFINITION_EMAIL_PROOF = 'ListDefinition-EMAIL_PROOF',
  LIST_DEFINITION_SEED = 'ListDefinition-SEED',
  PRINT = 'Message-PRINT',
  PRINT_ON_DEMAND = 'Message-PRINT_ON_DEMAND',
  PROJECT = 'Project', // NOTE: treated as AssetType for AssetSelectorDialog
  PURL = 'PURL',
  SEGMENT_BUILDER = 'SegmentBuilderCard',
  QUERY = 'Query',
  REPEAT_BLOCK_EMAIL_BODY = 'RepeatBlock-EMAIL_BODY',
  REPEAT_BLOCK_HOSTED_PAGE = 'RepeatBlock-HOSTED_PAGE',
  RESOURCE = 'RESOURCE',
  RESOURCE_JPEG = 'Resource-JPEG',
  RESOURCE_JPG = 'Resource-JPG',
  RESOURCE_JFIF = 'Resource-JFIF',
  RESOURCE_PNG = 'Resource-PNG',
  RESOURCE_GIF = 'Resource-GIF',
  RESOURCE_JS = 'Resource-JS',
  RESOURCE_CSS = 'Resource-CSS',
  SEGMENTATION = 'Segmentation',
  SEGMENTATION_WATERFALL_MODEL = 'SegmentWaterfallModel',
  TRANSFORM = 'Transform'
}

// eslint-disable-next-line no-shadow
export enum AssetStatus {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  PUBLISHED = 'PUBLISHED'
}

// eslint-disable-next-line no-shadow
export enum QueryParam {
  ASSET_ID = 'asset-id',
  PROJECT_ID = 'pr-id',
  ID = 'id',
  QUERY_NAME = 'query-name',
  FOLDER_NAME = 'folder-name'
}

// NOTE: the only way I found to support different status for each asset is to declare the union on the base interface
export type AllStatus =
  | SegmentBuilderStatus
  | ListDefinitionStatus
  | FormStatus
  | RepeatBlockStatus
  | PrintOnDemandStatus
  | AssetStatus
  | JourneyState
  | DynamicContentStatus
  | MessageStatus
  | LandingPageStatus
  | ResourceStatus
  | PurlStatus
  | SegmentWFStatus;

/**
 * Interface based on swagger Asset definition
 * see: http://neptune-swagger.s3-website-us-east-1.amazonaws.com/?urls.primaryName=Neptune%20Asset%20API%20-%20Dev#/default/get_assets
 */
export interface Asset {
  /** The unique id of the asset reference entity. */
  id?: string;
  /** Name of the asset. */
  name?: string;
  /** The asset type (Query, Journey, Email, Print). */
  assetType?: AssetType;
  /** The project id. */
  projectId?: string;
  /** The folder id. */
  folderId?: string;
  /** The project Name. */
  projectName?: string;
  /** The folder name. */
  folderName?: string;
  /** Description of the asset. */
  description?: string;
  /** The Id of the owner organization. */
  orgId?: string;
  /** The unique id of the actual asset (Query, Message, etc.). */
  assetId?: string;
  /** The record creator. */
  insertedBy?: string;
  /** The record creation date. */
  insertedAt?: string;
  /** The last user who update the record. */
  updatedBy?: string;
  /** The last date in which the record was updated. */
  updatedAt?: string;
  /** Current status of asset, status will be unique to the AssetType. */
  status?: AllStatus;
  /** Current table name of the asset (Specific asset returns table Id, generic asset returns tableName) */
  tableName?: string | null;
  /** Current table id of the asset  */
  tableId?: string;
  /** Current version of the asset  */
  version?: number;
  /** Multiple versions flag  */
  hasMultipleVersions?: boolean;
  /** Only Query Asset SWF */
  isQuery?: boolean;
  isSeedList?: boolean;
  children?: Folder[] | null;
  modelId?: string;
  sourceId?: string;
  sourceName?: string;
  sourceType?: string;
  SK?: string;
  type?: string;
}

/**
 * Base interface for any new asset being created
 */
export interface AssetBase {
  // Properties for adding an asset
  /** Name of the asset. */
  name?: string;
  /** The asset type (Query, Journey, Email, Print). */
  assetType?: AssetType;
  /** The project id, required on PUT */
  projectId?: string;
  /** The folder id, required on PUT */
  folderId?: string;
  /** Table name asset relates to, generally required on PUT */
  tableName?: string;
  /** Id Table asset relates to */
  tableId?: string;
  /** Description of the asset, optional on PUT */
  description?: string;
  /** Joined tables */
  joinedTables?: TableJoinsData;
  /** Table fields related with the content */
  // eslint-disable-next-line @typescript-eslint/ban-types
  fields?: Object;
  // Properties for getting an asset
  /** Id of the asset, defined by backend. */
  id?: string;
  /** The unique id of the asset reference entity, defined by backend */
  /** NOTE :: This is not a good name, but is what is in the APIs currently */
  assetVid?: string;
  /** Current status of asset, unique to the AssetType, required on GET */
  status?: AllStatus;
}

/**
 * Simplified interface of an Asset used for AssetSelectorDialog
 * // FIXME: check this interface with backend
 */
export interface AssetDefinition {
  /** The id of the asset */
  id: string;
  /** The name to display */
  name: string;
  /** The asset type */
  assetType: AssetType;
  /** AssetType.FOLDER could contain children */
  children?: AssetDefinition[];
  /** Just used for mat-tree styling */
  level?: number;
  /** Flag used by projects */
  loading?: boolean;
}

export interface AssetSelectorDialogFilter {
  /** AssetTypes to be used by filter */
  assetTypes?: AssetType[];
  /** Status to be used by filter */
  status?: AllStatus[];
  /** Table name associated to the asset */
  tableName?: string;
}

/** Interface to be passed to the AssetSelectorDialog */
export interface AssetSelectorDialogData {
  // TODO: which ones are required/optional?
  /** Optional description text */
  description?: string;
  /** Project identifier to list assets */
  projectId?: string;
  /** Project name */
  projectName?: string;
  /** Flag to allow multiple selection */
  multiSelect?: boolean;
  /** The object containing the possible asset filters */
  filterProps?: AssetSelectorDialogFilter;
  /** Option to only show the assets that meet the filter criteria, default false.*/
  hideOthers?: boolean;
  /** Will open the specified folder, or the first one if not found */
  folderId?: string;
  /** Table name assets are associated with */
  tableName?: string;
  /** Table id assets are associated with, not used for filtering */
  tableId?: string;
  /** List of currently selected assets */
  selected?: AssetId[];
}

export class AssetUtils {
  private static assetIconMap: Map<AssetType, string> = new Map<AssetType, string>([
    //   TODO :: Need icons
    [AssetType.PRINT_ON_DEMAND, 'print_on_demand'],
    [AssetType.FORM, 'form'],
    [AssetType.REPEAT_BLOCK_EMAIL_BODY, 'repeat_block'],
    [AssetType.REPEAT_BLOCK_HOSTED_PAGE, 'repeat_block'],
    [AssetType.SEGMENT_BUILDER, 'segment_builder'],
    [AssetType.LIST_DEFINITION_EMAIL_PROOF, 'proof_list'],
    [AssetType.LIST_DEFINITION_SEED, 'seed_list'],
    [AssetType.EMAIL, 'message_email'],
    [AssetType.IMPORT, 'import'],
    [AssetType.JOURNEY, 'journey'],
    [AssetType.PRINT, 'message_print'],
    [AssetType.DATA_OUTPUT, 'data_output'],
    [AssetType.QUERY, 'query'],
    [AssetType.DYNAMIC_CONTENT_EMAIL_BODY, 'dyn_content'],
    [AssetType.DYNAMIC_CONTENT_EMAIL_SUBJECT, 'dyn_content'],
    [AssetType.DYNAMIC_CONTENT_HOSTED_PAGE, 'dyn_content'],
    [AssetType.DYNAMIC_CONTENT_PRINT, 'dyn_content'],
    [AssetType.DYNAMIC_CONTENT_DATA_OUTPUT, 'dyn_content'],
    [AssetType.PROJECT, 'project'],
    [AssetType.FOLDER, 'folder'],
    [AssetType.LANDING_PAGE, 'webpage'],
    [AssetType.PURL, 'purl'],
    [AssetType.SEGMENTATION_WATERFALL_MODEL, 'segmentation-waterfall'],
    [AssetType.SEGMENT_BUILDER, 'resource'],
    [AssetType.RESOURCE, 'resource'],
    [AssetType.RESOURCE_JPEG, 'resource'],
    [AssetType.RESOURCE_JPG, 'resource'],
    [AssetType.RESOURCE_JFIF, 'resource'],
    [AssetType.RESOURCE_PNG, 'resource'],
    [AssetType.RESOURCE_GIF, 'resource'],
    [AssetType.RESOURCE_JS, 'resource'],
    [AssetType.RESOURCE_CSS, 'resource']
  ]);

  // Mapping used for elastic
  private static assetElasticMap: Map<AssetType, string> = new Map<AssetType, string>([
    [AssetType.PRINT_ON_DEMAND, 'Message'],
    [AssetType.REPEAT_BLOCK_EMAIL_BODY, 'RB'],
    [AssetType.REPEAT_BLOCK_HOSTED_PAGE, 'RB'],
    [AssetType.LIST_DEFINITION_EMAIL_PROOF, 'LD'],
    [AssetType.LIST_DEFINITION_SEED, 'LD'],
    [AssetType.EMAIL, 'Message'],
    [AssetType.JOURNEY, 'Journey'],
    [AssetType.PRINT, 'Message'],
    [AssetType.DATA_OUTPUT, 'Message'],
    [AssetType.QUERY, 'Query'],
    [AssetType.DYNAMIC_CONTENT_EMAIL_BODY, 'DC'],
    [AssetType.DYNAMIC_CONTENT_EMAIL_SUBJECT, 'DC'],
    [AssetType.DYNAMIC_CONTENT_HOSTED_PAGE, 'DC'],
    [AssetType.DYNAMIC_CONTENT_PRINT, 'DC'],
    [AssetType.DYNAMIC_CONTENT_DATA_OUTPUT, 'DC'],
    [AssetType.PROJECT, 'Project'],
    [AssetType.FOLDER, 'Folder'],
    [AssetType.LANDING_PAGE, 'LP'],
    [AssetType.PURL, 'tbd'],
    [AssetType.RESOURCE, 'Resource'],
    [AssetType.RESOURCE_JPEG, 'Resource'],
    [AssetType.RESOURCE_JPG, 'Resource'],
    [AssetType.RESOURCE_JFIF, 'Resource'],
    [AssetType.RESOURCE_PNG, 'Resource'],
    [AssetType.RESOURCE_JS, 'Resource'],
    [AssetType.RESOURCE_GIF, 'Resource'],
    [AssetType.RESOURCE_CSS, 'Resource']
  ]);

  private static assetEndpointMap: Map<AssetType, [Endpoint, string]> = new Map<AssetType, [Endpoint, string]>([
    [AssetType.PRINT_ON_DEMAND, [Endpoint.MESSAGE, 'messages']],
    [AssetType.REPEAT_BLOCK_EMAIL_BODY, [Endpoint.REPEAT_BLOCK, 'repeat_blocks']],
    [AssetType.REPEAT_BLOCK_HOSTED_PAGE, [Endpoint.REPEAT_BLOCK, 'repeat_blocks']],
    [AssetType.LIST_DEFINITION_EMAIL_PROOF, [Endpoint.LIST_DEFINITION, 'list_definitions']],
    [AssetType.LIST_DEFINITION_SEED, [Endpoint.LIST_DEFINITION, 'list_definitions']],
    [AssetType.EMAIL, [Endpoint.MESSAGE, 'messages']],
    [AssetType.JOURNEY, [Endpoint.JOURNEY, 'journeys']],
    [AssetType.PRINT, [Endpoint.MESSAGE, 'messages']],
    [AssetType.DATA_OUTPUT, [Endpoint.MESSAGE, 'messages']],
    [AssetType.QUERY, [Endpoint.QUERY, 'queries']],
    [AssetType.DYNAMIC_CONTENT_EMAIL_BODY, [Endpoint.DYNAMIC_CONTENT, 'dynamic_contents']],
    [AssetType.DYNAMIC_CONTENT_EMAIL_SUBJECT, [Endpoint.DYNAMIC_CONTENT, 'dynamic_contents']],
    [AssetType.DYNAMIC_CONTENT_HOSTED_PAGE, [Endpoint.DYNAMIC_CONTENT, 'dynamic_contents']],
    [AssetType.DYNAMIC_CONTENT_PRINT, [Endpoint.DYNAMIC_CONTENT, 'dynamic_contents']],
    [AssetType.DYNAMIC_CONTENT_DATA_OUTPUT, [Endpoint.DYNAMIC_CONTENT, 'dynamic_contents']],
    [AssetType.PROJECT, [Endpoint.PROJECT, 'projects']],
    [AssetType.FOLDER, [Endpoint.PROJECT, 'projects']],
    [AssetType.FORM, [Endpoint.FORM, 'forms']],
    [AssetType.LANDING_PAGE, [Endpoint.LANDING_PAGE, 'landing_pages']],
    [AssetType.RESOURCE, [Endpoint.RESOURCE, 'resources']],
    [AssetType.RESOURCE_JPEG, [Endpoint.RESOURCE, 'resources']],
    [AssetType.RESOURCE_JPG, [Endpoint.RESOURCE, 'resources']],
    [AssetType.RESOURCE_JFIF, [Endpoint.RESOURCE, 'resources']],
    [AssetType.RESOURCE_PNG, [Endpoint.RESOURCE, 'resources']],
    [AssetType.RESOURCE_GIF, [Endpoint.RESOURCE, 'resources']],
    [AssetType.RESOURCE_JS, [Endpoint.RESOURCE, 'resources']],
    [AssetType.RESOURCE_CSS, [Endpoint.RESOURCE, 'resources']],
    [AssetType.SEGMENTATION_WATERFALL_MODEL, [Endpoint.SEGMENTATION_WATERFALL, 'seg_wf_models']],
    [AssetType.SEGMENT_BUILDER, [Endpoint.SEGMENTATION_BUILDER, 'seg_wf_models']]
  ]);

  /**
   * Map Asset type to use visible strings
   */
  private static assetLabelMap: Map<AssetType, string> = new Map<AssetType, string>([
    [AssetType.PRINT_ON_DEMAND, 'Print On Demand'],
    [AssetType.FORM, 'Form'],
    [AssetType.REPEAT_BLOCK_EMAIL_BODY, 'Repeat Block Body'],
    [AssetType.REPEAT_BLOCK_HOSTED_PAGE, 'Repeat Block LP'],
    [AssetType.SEGMENT_BUILDER, 'Segment Builder'],
    [AssetType.LIST_DEFINITION_EMAIL_PROOF, 'Email Proof List'],
    [AssetType.LIST_DEFINITION_SEED, 'Seed List'],
    [AssetType.EMAIL, 'Email'],
    [AssetType.FOLDER, 'Folder'],
    [AssetType.JOURNEY, 'Journey'],
    [AssetType.PRINT, 'Print'],
    [AssetType.DATA_OUTPUT, 'Data Output'],
    [AssetType.PROJECT, 'Project'],
    [AssetType.QUERY, 'Query'],
    [AssetType.DYNAMIC_CONTENT_HOSTED_PAGE, 'Content Block LP'],
    [AssetType.DYNAMIC_CONTENT_EMAIL_SUBJECT, 'Content Block Sub'],
    [AssetType.DYNAMIC_CONTENT_EMAIL_BODY, 'Content Block Body'],
    [AssetType.DYNAMIC_CONTENT_PRINT, 'Content Block Print'],
    [AssetType.DYNAMIC_CONTENT_DATA_OUTPUT, 'Content Block Data Output'],
    [AssetType.LANDING_PAGE, 'Landing Page'],
    [AssetType.PURL, 'Purl'],
    [AssetType.SEGMENTATION_WATERFALL_MODEL, 'Segment Waterfall'],
    [AssetType.SEGMENT_BUILDER, 'Segment Builder'],
    [AssetType.RESOURCE, 'Resource'],
    [AssetType.RESOURCE_JPEG, 'JPEG'],
    [AssetType.RESOURCE_JPG, 'JPG'],
    [AssetType.RESOURCE_JFIF, 'JFIF'],
    [AssetType.RESOURCE_PNG, 'PNG'],
    [AssetType.RESOURCE_JS, 'JS'],
    [AssetType.RESOURCE_GIF, 'GIF'],
    [AssetType.RESOURCE_CSS, 'CSS'],
    [AssetType.IMPORT, 'Import']
  ]);

  /**
   * Map Asset type to URL
   */
  private static assetURLMap: Map<AssetType, string> = new Map<AssetType, string>([
    [AssetType.PRINT_ON_DEMAND, '/pages/asset/print-on-demand-message'],
    [AssetType.FORM, '/pages/asset/form'],
    [AssetType.REPEAT_BLOCK_EMAIL_BODY, '/pages/asset/repeat-block'],
    [AssetType.REPEAT_BLOCK_HOSTED_PAGE, '/pages/asset/repeat-block'],
    [AssetType.LIST_DEFINITION_EMAIL_PROOF, '/pages/asset/list-definition'],
    [AssetType.LIST_DEFINITION_SEED, '/pages/asset/list-definition'],
    [AssetType.EMAIL, '/pages/asset/email-message'],
    [AssetType.JOURNEY, '/pages/asset/journey'],
    [AssetType.PRINT, '/pages/asset/print-message'],
    [AssetType.DATA_OUTPUT, '/pages/asset/data-output'],
    [AssetType.DYNAMIC_CONTENT_EMAIL_BODY, '/pages/asset/dynamic-content'],
    [AssetType.DYNAMIC_CONTENT_EMAIL_SUBJECT, '/pages/asset/dynamic-content'],
    [AssetType.DYNAMIC_CONTENT_HOSTED_PAGE, '/pages/asset/dynamic-content'],
    [AssetType.DYNAMIC_CONTENT_PRINT, '/pages/asset/dynamic-content'],
    [AssetType.DYNAMIC_CONTENT_DATA_OUTPUT, '/pages/asset/dynamic-content'],
    [AssetType.LANDING_PAGE, '/pages/asset/landing-page'],
    [AssetType.QUERY, '/pages/asset/query-page'],
    [AssetType.SEGMENTATION_WATERFALL_MODEL, '/pages/asset/segmentation-page'],
    [AssetType.SEGMENT_BUILDER, '/pages/asset/segment-builder']
  ]);

  /**
   * Map Asset type to QueryParams
   */
  private static sharedQueryParams = [QueryParam.ID, QueryParam.ASSET_ID, QueryParam.PROJECT_ID];
  private static assetQueryParamMap: Map<AssetType, QueryParam[]> = new Map<AssetType, QueryParam[]>([
    [AssetType.PRINT_ON_DEMAND, [...AssetUtils.sharedQueryParams]],
    [AssetType.FORM, [...AssetUtils.sharedQueryParams]],
    [AssetType.REPEAT_BLOCK_EMAIL_BODY, [...AssetUtils.sharedQueryParams]],
    [AssetType.REPEAT_BLOCK_HOSTED_PAGE, [...AssetUtils.sharedQueryParams]],
    [AssetType.SEGMENT_BUILDER, [...AssetUtils.sharedQueryParams]],
    [AssetType.LIST_DEFINITION_EMAIL_PROOF, [...AssetUtils.sharedQueryParams]],
    [AssetType.LIST_DEFINITION_SEED, [...AssetUtils.sharedQueryParams]],
    [AssetType.EMAIL, [...AssetUtils.sharedQueryParams]],
    [AssetType.JOURNEY, [...AssetUtils.sharedQueryParams]],
    [AssetType.PRINT, [...AssetUtils.sharedQueryParams]],
    [AssetType.DATA_OUTPUT, [...AssetUtils.sharedQueryParams]],
    [AssetType.DYNAMIC_CONTENT_EMAIL_BODY, [...AssetUtils.sharedQueryParams]],
    [AssetType.DYNAMIC_CONTENT_EMAIL_SUBJECT, [...AssetUtils.sharedQueryParams]],
    [AssetType.DYNAMIC_CONTENT_HOSTED_PAGE, [...AssetUtils.sharedQueryParams]],
    [AssetType.DYNAMIC_CONTENT_PRINT, [...AssetUtils.sharedQueryParams]],
    [AssetType.DYNAMIC_CONTENT_DATA_OUTPUT, [...AssetUtils.sharedQueryParams]],
    [AssetType.LANDING_PAGE, [...AssetUtils.sharedQueryParams]],
    [AssetType.QUERY, [...AssetUtils.sharedQueryParams, QueryParam.FOLDER_NAME, QueryParam.QUERY_NAME]],
    [AssetType.SEGMENTATION_WATERFALL_MODEL, [...AssetUtils.sharedQueryParams]],
    [AssetType.SEGMENT_BUILDER, [...AssetUtils.sharedQueryParams]]
  ]);

  /**
   * Map QueryParam to Asset property
   * // TODO: find out if we could map Asset[prop] directly
   */
  private static queryParamAssetMap: Map<QueryParam, string> = new Map<QueryParam, string>([
    [QueryParam.ID, 'assetId'],
    [QueryParam.ASSET_ID, 'id'],
    [QueryParam.PROJECT_ID, 'projectId'],
    [QueryParam.QUERY_NAME, 'name'],
    [QueryParam.FOLDER_NAME, 'folderName']
  ]);

  /**
   * Map RowDelimiter Description
   *
   */
  private static rowDelimiterDescription: Map<RowDelimiterType, string> = new Map<RowDelimiterType, string>([
    [RowDelimiterType.CRLF, 'Carriage Return / Line Feed'],
    [RowDelimiterType.LF, 'Line Feed']
  ]);

  /**
   * Some Assets are considered to be global.
   * At the moment of writing, the criteria is:
   * Asset's projectId and folderId are null or 0000-0000-0000-0000
   *
   */
  private static globalAssetTypes: AssetType[] = [AssetType.PURL, AssetType.IMPORT];

  public static getRowDelimiterDescription(type: RowDelimiterType): string {
    return AssetUtils.rowDelimiterDescription.get(type) as string;
  }

  public static getAssetIcon(type: AssetType): string {
    return AssetUtils.assetIconMap.get(type) as string;
  }

  public static getElasticModel(type: AssetType): string {
    return AssetUtils.assetElasticMap.get(type) as string;
  }

  public static getAssetEndpoint(type: AssetType): [Endpoint, string] {
    return AssetUtils.assetEndpointMap.get(type) as [Endpoint, string];
  }

  public static getAssetLabel(type: AssetType | null): string {
    return AssetUtils.assetLabelMap.get(type as AssetType) as string;
  }

  public static getAssetURL(type: AssetType): string {
    return AssetUtils.assetURLMap.get(type) as string;
  }

  public static getAssetParams(type: AssetType): QueryParam[] {
    return AssetUtils.assetQueryParamMap.get(type) as QueryParam[];
  }

  public static getQueryParamAssetProp(param: QueryParam): string {
    return AssetUtils.queryParamAssetMap.get(param) as string;
  }

  public static isGlobalAsset(type: AssetType | null): boolean {
    return type !== null && AssetUtils.globalAssetTypes.indexOf(type) !== -1;
  }
}
