// eslint-disable-next-line no-shadow
export enum DomainType {
  SENDING = 'SENDING',
  HOSTED_PAGES = 'HOSTED_PAGES',
  ALLOWED_ORGANIZATION = 'ALLOWED_ORGANIZATION',
  PURL = 'PURL',
  RESOURCES = 'RESOURCES'
}

export class Domain {
  id?: string;

  name?: string;
  type?: DomainType;

  url?: string;
  details?: any;

  active?: boolean;
  deleted?: boolean;
  insertedBy?: string;
  insertedAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  orgId?: string;
}

export class DomainUtils {
  /**
   * Map Domain type to use visible strings
   */
  private static domainLabelMap: Map<DomainType, string> = new Map<DomainType, string>([
    [DomainType.SENDING, 'Sending'],
    [DomainType.HOSTED_PAGES, 'Hosted Pages'],
    [DomainType.ALLOWED_ORGANIZATION, 'Allowed Organization'],
    [DomainType.PURL, 'PURL'],
    [DomainType.RESOURCES, 'Resource']
  ]);

  public static getDomainTypeLabel(type: DomainType): string {
    return DomainUtils.domainLabelMap.get(type) as string;
  }
}
