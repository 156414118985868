import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationItem } from '@fuse/types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { AccountService } from '@neptune/services/account.service';
import { CognitoService } from '@neptune/services/cognito.service';

@Component({
  selector: 'fuse-nav-vertical-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavVerticalGroupComponent implements OnInit, OnDestroy {
  // CUSTOM @NEPTUNE VARIABLES
  private roles: any = null;

  @HostBinding('class')
  classes = 'nav-group nav-item';

  @Input()
  item: FuseNavigationItem;

  // Private
  private _unsubscribeAll: Subject<void>;

  /**
   * Constructor
   */

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {FuseNavigationService} _fuseNavigationService
   */
  constructor(
    private accountService: AccountService,
    private cognitoService: CognitoService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseNavigationService: FuseNavigationService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.initializeRoles();
    this.cognitoService.onRefreshSession.subscribe(this.initializeRoles.bind(this));
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Neptune Custom Methods
  // -----------------------------------------------------------------------------------------------------

  private initializeRoles() {
    this.accountService.getUserAttributes().then(
      (data: any) => {
        this.roles = data.attributes;
        this._changeDetectorRef.markForCheck();
      },
      () => {}
    );
  }

  public showNavigationItem(item): any {
    const currentRol: any = this.roles && this.roles.find((m: any) => m.Name === 'custom:qp_role');
    const systemManager: any = this.roles && this.roles.find((m: any) => m.Name === 'custom:systemManagement');

    return (
      (item.roles && item.roles.includes(currentRol && currentRol.Value && currentRol.Value.toLowerCase())) ||
      (systemManager && systemManager.Value === 'true') ||
      (item.roles && item.roles.includes('all'))
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to navigation item
    merge(
      this._fuseNavigationService.onNavigationItemAdded,
      this._fuseNavigationService.onNavigationItemUpdated,
      this._fuseNavigationService.onNavigationItemRemoved
    )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
