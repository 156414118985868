<div class="dropdown-container">
  <mat-form-field [appearance]="appearance" [ngClass]="classes" [class.empty]="!selected">
    <mat-label>{{ placeholder }}</mat-label>
    <mat-select
      [(value)]="selected"
      (selectionChange)="onSelectionChange()"
      (openedChange)="onOpenChange($event)"
      [disabled]="disabled"
    >
      <mat-select-trigger>
        <app-datatype-icon *ngIf="selected?.dataType" [type]="selected.dataType"></app-datatype-icon>
        <mat-icon *ngIf="selected?.icon && !selected?.svgIcon" matPrefix>{{ selected?.icon }}</mat-icon>
        <mat-icon *ngIf="selected?.icon && selected?.svgIcon" matPrefix [svgIcon]="selected?.icon"></mat-icon>
        <span class="value-name">
          {{ selected?.label }}
        </span>
      </mat-select-trigger>
      <ng-container *ngFor="let option of options">
        <mat-option *ngIf="!option.divider" [value]="option" [disabled]="option.disabled">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <mat-icon *ngIf="option.icon && !option.svgIcon" matPrefix>{{ option.icon }}</mat-icon>
            <mat-icon *ngIf="option.icon && option.svgIcon" matPrefix [svgIcon]="option.icon"></mat-icon>
            <app-datatype-icon *ngIf="option.dataType" [type]="option.dataType"></app-datatype-icon>
            <span>{{ option.label }}</span>
          </div>
        </mat-option>
        <mat-divider *ngIf="option.divider"></mat-divider>
      </ng-container>
    </mat-select>
  </mat-form-field>
</div>
