import { Component, ViewEncapsulation } from '@angular/core';
import { NavigationQueueService } from '@neptune/services/navigation-queue.service';

@Component({
  selector: 'content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentComponent {
  /**
   * Constructor
   */
  constructor(private navigationQueueService: NavigationQueueService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Neptune Custom Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Event handler for when router-outlet gets active
   *
   * @param component the new component page view
   */
  public onActivate(component: any) {
    this.navigationQueueService.onActivateComponent(component);
  }

  /**
   * Event handler for when router-outlet gets deactivated
   *
   * @param component the old - to be replaced - component page view
   */
  public onDeactivate(component: any) {
    this.navigationQueueService.onDeactivateComponent(component);
  }
}
