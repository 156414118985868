<div class="filterable-action-list" [ngClass]="data?.classes!" fxLayout="column" fxLayoutAlign="space-around stretch">
  <div class="filterable-action-list__header">
    <h4 fxLayout>
      <mat-icon class="header-icon" *ngIf="data?.icon" [svgIcon]="data.icon"></mat-icon>
      <span>{{ data?.header }}</span>
      <ng-container *ngIf="data!.menu && data!.menu.length > 0">
        <mat-menu #listMenu="matMenu">
          <ng-container *ngFor="let option of data?.menu">
            <dropdown-button
              qp-roles
              [rolList]="option.roles"
              *ngIf="!option.divider"
              (onClick)="option.onClick(data, option)"
              [label]="option.label"
              [qpIcon]="option.icon"
              [isDisabled]="option.disabled"
            ></dropdown-button>
            <mat-divider *ngIf="option.divider" class="menu-divider"></mat-divider>
          </ng-container>
        </mat-menu>
        <button mat-icon-button [matMenuTriggerFor]="listMenu" class="list-menu">
          <mat-icon>more_vert</mat-icon>
        </button>
      </ng-container>
      <button
        *ngIf="onListClose"
        mat-icon-button
        (click)="onListClose()"
        class="close-btn"
        [class.hasMenu]="data?.menu && data?.menu.length > 0"
      >
        <mat-icon svgIcon="close_inspector"></mat-icon>
      </button>
      <button
        *ngIf="onListGoBack"
        [class.hasMenu]="data?.menu && data?.menu.length > 0"
        mat-icon-button
        aria-label="Close attributes"
        (click)="onListGoBack()"
        class="go-back-btn"
      >
        <mat-icon svgIcon="close_inspector"></mat-icon>
      </button>
    </h4>
  </div>
  <div *ngIf="info" class="filterable-action-list__info">
    <ng-content select=".filterable-header" *ngTemplateOutlet="info?.template"></ng-content>
  </div>
  <div class="filterable-action-list__filter" fxLayout *ngIf="data?.list?.length">
    <!-- TODO: see notebooks notes, should be better to have search + other filters (sorts/props) -->
    <mat-icon>search</mat-icon>
    <input
      matInput
      [placeholder]="searchPlaceholder"
      [formControl]="listFilter"
      fxFlex="1 1 100%"
      autocomplete="false"
    />
    <button
      *ngIf="listFilter.value"
      mat-button
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="listFilter.reset('')"
      fxFlex="1 1 36px"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="filterable-action-list__list" #container>
    <mat-action-list dense *ngIf="!data?.loading">
      <cdk-virtual-scroll-viewport
        class="virtual-scroll"
        [itemSize]="itemSize"
        [minBufferPx]="minBuffer"
        [maxBufferPx]="maxBuffer"
      >
        <button
          #elementRef
          mat-list-item
          *cdkVirtualFor="let item of filteredList"
          fxLayout
          (click)="onMouseClick(item)"
          [class.--selected]="isRowSelected(item.id)"
          [ngClass]="item.class"
        >
          <ng-container *ngIf="item.isContactTable">
            <mat-icon svgIcon="contact_identifier" class="contact-table-icon"></mat-icon>
          </ng-container>
          <ng-container *ngIf="item.tableStatusIcon">
            <mat-icon [svgIcon]="item.tableStatusIcon" class="table-status-icon"></mat-icon>
          </ng-container>
          <ng-container *ngIf="item.dataTypeIcon">
            <app-datatype-icon [type]="item.dataType" class="datatype-icon"></app-datatype-icon>
          </ng-container>

          <div *ngIf="!item.html" class="name">
            <long-text [value]="item.name"></long-text>
          </div>
          <div *ngIf="item.html" [innerHTML]="sanitize(item.html)" class="html"></div>
          <span *ngIf="item.count" class="count">{{ item.count }}</span>
          <mat-icon
            *ngIf="item.isPrimaryKey"
            svgIcon="key_black"
            class="is-primary-key-icon"
            [class.hasJoin]="item.joined"
            [class.isReadOnly]="item.readOnly || item.readonly"
          ></mat-icon>
          <mat-icon
            *ngIf="item.joined"
            svgIcon="join"
            class="join-icon"
            [class.hasPrimaryKey]="item.isPrimaryKey"
            [class.isReadOnly]="item.readOnly || item.readonly"
          ></mat-icon>
          <mat-icon
            *ngIf="(item.readOnly || item.readonly) && item.dataTypeIcon"
            svgIcon="read_only"
            class="read-only-icon"
          ></mat-icon>

          <ng-container *ngIf="item.menu && item.menu.length > 0">
            <mat-menu #itemMenu="matMenu">
              <ng-container *ngFor="let option of item.menu">
                <dropdown-button
                  qp-roles
                  [rolList]="option.roles"
                  *ngIf="!option.divider"
                  (onClick)="option.onClick(item, option)"
                  [label]="option.label"
                  [qpIcon]="option.icon"
                  [isDisabled]="option.disabled"
                ></dropdown-button>
                <mat-divider *ngIf="option.divider" class="menu-divider"></mat-divider>
              </ng-container>
            </mat-menu>
            <button
              mat-icon-button
              (click)="openMenu(item, $event)"
              [matMenuTriggerFor]="itemMenu"
              class="menu-btn"
              [class.hasCount]="item.count"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
          </ng-container>
        </button>
      </cdk-virtual-scroll-viewport>
    </mat-action-list>
  </div>
  <ng-content select=".filterable-footer"></ng-content>
</div>
