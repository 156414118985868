import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-side-drawers-base',
  templateUrl: './side-drawers-base.component.html',
  styleUrls: ['./side-drawers-base.component.scss']
})
export class SideDrawersBaseComponent implements OnInit {
  /** Template inside the MatTab view that contains an `<ng-content>`. */
  @ViewChild(TemplateRef, { static: true }) template: TemplateRef<any>;

  ngOnInit() {}
}
