import { BorderStyle, Worksheet } from 'exceljs';

export default abstract class ExceljsUtils {
  public static setCellsBorder(ws: Worksheet, cells: string[], borderStyle: BorderStyle) {
    cells.forEach(cell => {
      ws.getCell(cell).border = {
        top: { style: borderStyle },
        left: { style: borderStyle },
        bottom: { style: borderStyle },
        right: { style: borderStyle }
      };
    });
  }

  public static setFontStyle(ws: Worksheet, cells: string[], fontStyle: any) {
    cells.forEach(cell => {
      const wsCell = ws.getCell(cell);
      wsCell.font = {
        ...wsCell.font,
        ...fontStyle
      };
    });
  }

  public static autosizeColumnCells(columns: any) {
    let dataMax: number[];
    let max: number;
    columns.forEach((column: any) => {
      dataMax = [];
      column.eachCell({ includeEmpty: false }, (cell: any) => {
        dataMax.push(cell.value?.toString().length + 2 || 0);
      });
      max = Math.max(...dataMax);
      column.width = Math.max(10, max);
    });
  }
}
