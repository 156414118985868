/* eslint-disable no-invalid-this */
import { Component, OnInit, Inject, EventEmitter, ViewChild } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';
import { AccountService } from '@neptune/services/account.service';
import { User } from '@neptune/models/user';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { LoadingModalComponent } from '@neptune/components/loading-modal/loading-modal.component';
import { SharedValidators } from 'app/shared/validators/shared-validators';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.html',
  styleUrls: ['./change-password-dialog.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ChangePasswordDialog implements OnInit {
  @ViewChild(LoadingModalComponent, { static: true }) loadingComponent: LoadingModalComponent;

  public title = 'Change Password';
  public loading: boolean = false;
  public onUserPasswordChanged: EventEmitter<any> = new EventEmitter();
  public resetPasswordForm: UntypedFormGroup;
  public resetPasswordFormErrors: any;
  public loginEmail: string;
  public hidecurrentpass = true;
  public userEmail: string;
  public code: string;
  public errorCode: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private dialogRef: MatDialogRef<ChangePasswordDialog>,
    @Inject(MAT_DIALOG_DATA) private user: User
  ) {
    this.resetPasswordFormErrors = {
      currentPassword: {},
      password: {},
      passwordConfirm: {}
    };
  }

  onClose(confirm: boolean = false) {
    this.dialogRef.close(confirm);
  }

  ngOnInit() {
    this.resetPasswordForm = this.formBuilder.group(
      {
        currentPassword: ['', [Validators.required]],
        password: ['', [Validators.required, SharedValidators.passwordValidator]],
        passwordConfirm: ['', [Validators.required]]
      },
      {
        validator: SharedValidators.match('password', 'passwordConfirm', 'password-mismatch')
      }
    );

    this.resetPasswordForm.valueChanges.subscribe(() => {
      this.onResetPasswordFormValuesChanged();
    });
  }

  onResetPasswordFormValuesChanged() {
    for (const field in this.resetPasswordFormErrors) {
      if (!this.resetPasswordFormErrors.hasOwnProperty(field)) {
        continue;
      }
      // Clear previous errors
      this.resetPasswordFormErrors[field] = {};
      // Get the control
      const control = this.resetPasswordForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.resetPasswordFormErrors[field] = control.errors;
      }
    }
  }

  onSave() {
    this.loading = true;
    if (this.passControl?.value) {
      this.accountService.changePassword(this.currentPassControl?.value, this.passControl?.value).subscribe({
        next: () => {
          this.onUserPasswordChanged.emit(this.user);
          this.loadingComponent.showSuccess('User password successfully saved');
          this.dialogRef.close(this.user);
        },
        error: error => {
          const message = error.error ? error.error.message : error.message;
          this.loadingComponent.showError(message);
        },
        complete: () => (this.loading = false)
      });
    }
  }

  get passControl() {
    return this.resetPasswordForm.get('password');
  }

  get passConfirmControl() {
    return this.resetPasswordForm.get('passwordConfirm');
  }

  get currentPassControl() {
    return this.resetPasswordForm.get('currentPassword');
  }
}
