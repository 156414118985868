import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { AbstractControl, ControlValueAccessor, NgControl, ValidationErrors } from '@angular/forms';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';
import { CustomAceEditor } from '@neptune/components/expression-editor/custom-mode/qp-mode';
import {
  Field,
  FunctionDataService,
  FunctionTypeGroup,
  JsepExpression,
  NameId,
  TableCreationStore
} from '@neptune/models';
import { LoadingModalComponent } from '@neptune/components/loading-modal/loading-modal.component';
import { ExpressionService } from '@neptune/services/expression.service';
import { AceEditorComponent } from '@derekbaker/ngx-ace-editor-wrapper';
import { JsepService } from '@neptune/services/jsep.service';

// Notes from how to implement this :
// https://itnext.io/creating-a-custom-form-field-control-compatible-with-reactive-forms-and-angular-material-cf195905b451
// https://v9.material.angular.io/guide/creating-a-custom-form-field-control
@Component({
  selector: 'expression-editor-input',
  templateUrl: './expression-editor-input.component.html',
  styleUrls: ['./expression-editor-input.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: ExpressionEditorInputComponent
    }
  ],
  // eslint-disable-next-line
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id',
    '[attr.aria-describedby]': 'describedBy'
  }
})
export class ExpressionEditorInputComponent
  implements OnInit, DoCheck, OnDestroy, AfterViewInit, ControlValueAccessor, MatFormFieldControl<string> {
  static nextId = 0;

  // Start ExpressionEditorComponent Specific properties
  @ViewChild('editor', { static: true }) editor: AceEditorComponent;
  @ViewChild(LoadingModalComponent, { static: true })
  private loadingComponent: LoadingModalComponent;

  @Input('tableName') tableName: string;
  @Input('fields') fields: Field[];
  @Input('editable') editable?: boolean;

  public customAce: CustomAceEditor;
  private functionList: FunctionDataService[] = [];
  public functionGroup: FunctionTypeGroup[] = [];
  public functionGroupSelected: FunctionTypeGroup;
  public functionSelected: FunctionDataService;
  public isSelectedFunction = false;
  public selectedIdx: number;
  public selectedFunc: number;
  public tableFieldSelected: NameId;
  public indexGroupTab: number;
  public tableFieldIndexSelected: number;
  public isSelectedField = false;
  // End ExpressionEditorComponent Specific properties

  @HostBinding() id = `expression-editor-input-${ExpressionEditorInputComponent.nextId++}`;

  @ViewChild('container', { read: ElementRef }) container: ElementRef;

  /**
   * Gets called when the editor's value has changed
   *
   * @type {EventEmitter<string>} The editor's new value
   */
  @Output() textChanged = new EventEmitter<string>();

  _value: string;

  _changeFn: (_: any) => void;
  _touchedFn: any;

  // MatFormFieldControl Implementation

  stateChanges = new Subject<void>();
  focused: boolean;
  controlType = 'expression-editor-input';
  errorState = false;
  public _placeholder: string;
  public _required = false;
  public _disabled = false;

  @HostBinding('attr.aria-describedby') describedBy = '';
  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis);
    this.stateChanges.next();
  }

  constructor(
    public _elementRef: ElementRef,
    public _focusMonitor: FocusMonitor,
    private expressionService: ExpressionService,
    private jsepService: JsepService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef.nativeElement, true).subscribe(origin => {
      if (this.focused && !origin && this._touchedFn) {
        this._touchedFn();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
      this.ngControl.control?.setValidators(this.expressionValidator.bind(this));
    }
  }

  // Start ExpressionEditorComponent Specific functions

  ngOnInit() {
    this.editable = this.editable ?? true;
    this.loadingComponent.startLoading();
    // store for table creation data
    this.expressionService.getStore(TableCreationStore.ID);

    this.tableFieldSelected = this.fields[0]; // select first column

    this.tableFieldIndexSelected = 0;

    this.selectedIdx = 0;

    this.indexGroupTab = 0;

    // get expression functions from store or API if not yet loaded
    this.expressionService.getStoreFunctionList().subscribe({
      next: result => {
        this.functionList = result;
        this.buildGroupFunction();
        this.customAce = new CustomAceEditor(
          this.editor,
          this.fields.map(f => f.name),
          this.functionList
        );
        this.customAce.configureEditor();
        this.loadingComponent.stopLoading();
      },
      error: err => {
        console.warn('Expression error:', err);
        this.loadingComponent.showError('There was an error Loading Dialog.');
      }
    });
  }

  ngAfterViewInit() {
    this.editor.getEditor().gotoLine(0, 0);
  }

  buildGroupFunction(): void {
    const groups: { [property: string]: FunctionDataService[] } = {};

    this.functionGroup = [];

    this.functionList.forEach((funct: FunctionDataService) => {
      const groupName = funct.category;
      if (!groups[groupName]) {
        groups[groupName] = [];
      }

      groups[groupName].push(funct);
    });

    // eslint-disable-next-line guard-for-in
    for (const groupService in groups) {
      const groupObj: FunctionTypeGroup = {
        name: groupService,
        functions: groups[groupService]
      };

      this.functionGroup.push(groupObj);
    }

    // select the first group
    this.onSelectedFuncGroup(0);
    // select the first function in the group
    this.onSelectedFunction(0);
  }

  /**
   * this function a selected typeFunctionGroup.
   *
   * @param group
   * @type {FunctionTypeGroup}
   * @returns void
   */
  onSelectedFuncGroup(index: number, group?: FunctionTypeGroup): void {
    this.functionGroupSelected = group || this.functionGroup[index];
    this.selectedIdx = index;
    this.onSelectedFunction(0);
  }

  /**
   * Handler for specific function selection
   *
   * @param index Index of desired function in currently selected function group
   * @param func
   */
  onSelectedFunction(index: number, func?: FunctionDataService): void {
    if (func) {
      this.functionSelected = func;
    } else if (this.functionGroupSelected.functions) {
      this.functionSelected = this.functionGroupSelected.functions[index];
    }
    this.isSelectedFunction = true;
    this.selectedFunc = index;
  }

  insertExpressionEditor(): void {
    switch (this.indexGroupTab) {
      case 0: // FUNCTIONS
        if (this.functionSelected) {
          this.expressionService.insertFunction(this.editor.getEditor(), this.functionSelected.name);
        }
        break;
      case 1: // FIELDS
        if (this.tableFieldSelected) {
          this.editor.getEditor().insert(this.tableFieldSelected.name); // insert column
        }
        break;
      default:
        // code...
        break;
    }
  }

  clickTab(event: number) {
    this.indexGroupTab = event;
  }

  onSelectedFieldTable(field: Field, index: number) {
    this.tableFieldSelected = field;
    this.tableFieldIndexSelected = index;
    this.isSelectedField = true;
  }

  public getCurrentExpression(): JsepExpression {
    const numRows: number = this.customAce.getCurrentSession().getLength();
    let expression = '';
    for (let i = 0; i < numRows; i++) {
      expression += ' ' + this.customAce.getCurrentSession().getLine(i);
    }
    return this.jsepService.jsep(expression);
  }

  // Expression Checking Start
  private expressionValidator(control: AbstractControl): ValidationErrors | null {
    if (this.customAce) {
      try {
        this.getCurrentExpression();
      } catch (e: any) {
        const errorMessageApply: string = e.message || 'Error parsing tokens';
        return { expression: errorMessageApply };
      }
    }

    return null;
  }

  // Expression Checking END

  // End ExpressionEditorComponent Specific functions

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef.nativeElement);
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = (this.ngControl.invalid && (this.ngControl.touched || this.ngControl.dirty)) as boolean;
      if (this.customAce) {
        this.customAce.setError(this.errorState);
      }
      this.stateChanges.next();
    }
  }

  onContainerClick(event: MouseEvent) {}

  get empty() {
    const commentText = this._value ? this._value.trim() : '';
    return commentText ? false : true;
  }

  // ControlValueAccessor implementation

  registerOnChange(fn: (_: any) => void): void {
    this._changeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this._touchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  // Event handling

  onChange() {
    if (this._changeFn) {
      this._changeFn(this._value);
    }

    this.textChanged.emit(this._value);
  }
  // Property accessors

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
    this.onChange();
    this.stateChanges.next();
  }
}
