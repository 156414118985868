<div class="user-profile-dialog">
  <loading-modal></loading-modal>
  <div mat-dialog-title class="dialog-header">
    <div class="dialog-title">
      <span class="h2">Edit Profile</span>
    </div>
  </div>

  <form [formGroup]="userProfileForm" (ngSubmit)="onSave()">
    <mat-dialog-content class="description-container" fxLayout="column" fxLayoutAlign="space-between stretch">
      <div fxLayout="row" fxLayoutAlign="space-around start">
        <div fxLayout="column" fxLayoutAlign="space-between center" fxFlex="34">
          <div class="avatar-container">
            <div class="avatar-image" fxLayout="row" fxLayoutAlign="center center">
              <image-cropper
                *ngIf="showImageCropper"
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [aspectRatio]="4 / 4"
                [resizeToWidth]="128"
                [cropperMinWidth]="176"
                [cropperMinHeight]="176"
                [onlyScaleDown]="true"
                [roundCropper]="true"
                format="png"
                outputType="base64"
                (imageCropped)="onImageCropped($event)"
                (imageLoaded)="onImageLoaded()"
                (loadImageFailed)="onLoadImageFailed()"
                [style.display]="showCropper ? null : 'none'"
              ></image-cropper>
              <mat-icon
                class="avatar default"
                *ngIf="!avatarPath && !showImageCropper"
                [svgIcon]="'account_user'"
              ></mat-icon>
              <img *ngIf="avatarPath && !showImageCropper" class="avatar" [src]="avatarPath" />
            </div>
            <div class="change-methods-text" fxLayout="row" fxLayoutAlign="center center">
              <span *ngIf="!showImageCropper" class="change-image-text" (click)="avatarInput.click()">
                Change Image
              </span>
              <span *ngIf="showImageCropper" class="save-image-text" (click)="onSaveAvatar($event)">Save Image</span>
              <input #avatarInput accept="image/*" class="avatar-input" type="file" (change)="onAvatarUpload($event)" />
            </div>
          </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="64">
          <div fxLayout="row" fxLayoutAlign="space-between stretch">
            <div fxLayout="column" fxLayoutAlign="space-between start">
              <mat-form-field fxFlex="48" appearance="fill">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="firstNameFormControl" />
              </mat-form-field>
              <mat-error
                *ngIf="
                  userProfileForm.get('firstNameFormControl')?.touched &&
                  !userProfileForm.get('firstNameFormControl')?.valid &&
                  userProfileForm.get('firstNameFormControl')?.hasError('required')
                "
              >
                First Name is required
              </mat-error>
            </div>

            <div fxLayout="column" fxLayoutAlign="space-between start">
              <mat-form-field fxFlex="48" appearance="fill">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="lastNameFormControl" />
              </mat-form-field>
              <mat-error
                *ngIf="
                  userProfileForm.get('lastNameFormControl')?.touched &&
                  !userProfileForm.get('lastNameFormControl')?.valid &&
                  userProfileForm.get('lastNameFormControl')?.hasError('required')
                "
              >
                Last Name is required
              </mat-error>
            </div>
          </div>

          <mat-form-field fxFlex="100" appearance="fill">
            <mat-label>Email Address</mat-label>
            <input matInput type="email" formControlName="emailFormControl" />
          </mat-form-field>
          <mat-error
            *ngIf="
              userProfileForm.get('emailFormControl')?.touched &&
              !userProfileForm.get('emailFormControl')?.valid &&
              userProfileForm.get('emailFormControl')?.hasError('required')
            "
          >
            Email Address is required
          </mat-error>
          <mat-error
            *ngIf="
              userProfileForm.get('emailFormControl')!.touched &&
              !userProfileForm.get('emailFormControl')!.hasError('required') &&
              userProfileForm.get('emailFormControl')!.hasError('emailWithFullDomain')
            "
          >
            {{ userProfileForm.get('emailFormControl')!.errors.emailWithFullDomain.message }} Email Address
          </mat-error>

          <mat-form-field fxFlex="100">
            <mat-label class="pl-16">Role</mat-label>
            <input matInput formControlName="roleFormControl" class="pl-12" />
          </mat-form-field>
          <mat-error
            *ngIf="
              userProfileForm.get('roleFormControl')?.touched &&
              !userProfileForm.get('roleFormControl')?.valid &&
              userProfileForm.get('roleFormControl')?.hasError('required')
            "
          >
            Role is required
          </mat-error>

          <mat-form-field appearance="fill" class="user-profile-dialog__timezone-select__parent">
            <mat-label>
              {{ userProfileForm.controls.timezoneFormControl.value ? 'Timezone' : 'Select your timezone' }}
            </mat-label>
            <mat-icon matPrefix svgIcon="timezone" class="form-icon-front"></mat-icon>
            <mat-select
              panelClass="user-profile-dialog__timezone-select"
              placeholder="Select your timezone"
              formControlName="timezoneFormControl"
            >
              <mat-option *ngFor="let tz of timezones" [value]="tz.name">
                (GMT {{ tz.formattedOffset }}) - {{ tz.formattedName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            *ngIf="
              userProfileForm.get('timezoneFormControl')?.touched &&
              !userProfileForm.get('timezoneFormControl')?.valid &&
              userProfileForm.get('timezoneFormControl')?.hasError('required')
            "
          >
            Timezone is required
          </mat-error>
        </div>
      </div>
      <div class="user-last-logged-in" fxFlexOffset="2" fxLayout="row">
        <span fxFlex="48">Last logged in:</span>
        <standard-date [date]="lastLoginDate"></standard-date>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between start" class="dialog-footer">
      <button type="button" mat-stroked-button color="primary" (click)="onChangePassword()">Change Password</button>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <button type="button" mat-button color="primary" (click)="onClose()" class="cancel-btn">Cancel</button>
        <button type="submit" mat-flat-button color="primary" [disabled]="userProfileForm.invalid">Save</button>
      </div>
    </mat-dialog-actions>
  </form>
</div>
