import { Component, ViewChild, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';
import { ExpressionService } from '@neptune/services/expression.service';
import { Field } from '@neptune/models/file';
import { ProfileSource } from '@neptune/models/pipeline';
import { Expression, ExpressionRequestConvert, JsepExpression } from '@neptune/models/expression';
import { LocalStorageService } from '@neptune/services/local-storage.service';
import { LoadingModalComponent } from '@neptune/components/loading-modal/loading-modal.component';
import { ConnectorId } from '@neptune/models/connector';
import { ConnectorService } from '@neptune/services/connector.service';
import { ExpressionEditorInputComponent } from '@neptune/components/expression-editor-input/expression-editor-input.component';
import { cloneDeep } from 'lodash';

// this resolves modules within ace-build due to webpack
require('ace-builds/webpack-resolver');

export interface ExpressionDialogInput {
  // table name
  tableName?: string;
  // table id
  tableId?: string;
  // full name
  fullFieldName: string;
  // name
  fieldName: string;
  // column
  fieldColumn?: string;
  // expression
  expression?: Expression | JsepExpression;
  // list of available sources for use in the expressions
  sources: Field[];
  profileSource?: ProfileSource;
  // whether to retrieve last expression test from local storage, and store to local
  excludeLocal?: boolean;
  // string to populate prompt
  expressionPrompt?: string;
  // hide preview
  hidePreview?: boolean;
  // is a new table creation process
  isNewTableSchema?: boolean;
  // source fields values
  sampleData?: { [key: string]: any }[];
  editable?: boolean;
}

export interface ExpressionDialogOutput {
  expression: Expression | JsepExpression;
  text?: string;
}

export interface PreviewElement {
  fieldName: string;
  before: string;
  after: string;
}

const MAX_PREVIEW_WAIT: number = 40;

@Component({
  selector: 'expression-dialog',
  templateUrl: 'expression-dialog.html',
  styleUrls: ['./expression-dialog.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ExpressionDialog implements OnInit {
  // @ViewChild('editor', { static: true }) editor: AceEditorComponent;
  @ViewChild('expressionEditorInput', { static: true }) expressionEditorInput: ExpressionEditorInputComponent;
  @ViewChild('loadingComponent', { static: true }) loadingComponent: LoadingModalComponent;

  // ACE Editor
  prompt: string;

  /* Available sources */
  sources: Field[] = [];
  fieldName = '';
  fullFieldName = '';

  public isErrorApply = false;
  public errorMessageApply = '';

  // datatable
  public displayedColumns: string[] = ['fieldName', 'before', 'after'];
  public previewItems: PreviewElement[];
  public previewEnabled: boolean = false;

  public tableName: string;

  public hidePreview: boolean;

  public expressionApplied: boolean;

  private profileSource: ProfileSource;

  constructor(
    public dialogRef: MatDialogRef<ExpressionDialog>,
    private expressionService: ExpressionService,
    private connectorService: ConnectorService,
    private localStorageService: LocalStorageService,
    @Inject(MAT_DIALOG_DATA) public data: ExpressionDialogInput
  ) {}

  ngOnInit() {
    // table name
    this.tableName = this.data.tableName || '';

    // name of field
    this.fieldName = this.data.fieldName;

    // full name field
    this.fullFieldName = this.data.fullFieldName;

    this.sources = cloneDeep(this.data.sources);

    this.hidePreview = !!this.data.hidePreview;

    this.profileSource = this.getProfileSource(this.data, this.sources);

    // Init Column data in ace editor after view init.
    if (this.data.expressionPrompt) {
      // apply expression prompt if supplied
      this.prompt = this.data.expressionPrompt;
    } else if (!this.data.excludeLocal && this.localStorageService.getPromptFor(this.fullFieldName)) {
      // apply stored expression
      this.prompt = this.localStorageService.getPromptFor(this.fullFieldName);
    } else {
      this.prompt = this.fieldName;
    }
  }

  /**
   * Close dialog, if save is true pass back updated profile
   * param save
   */
  onClose(save: boolean = false): void {
    if (save) {
      const currentExpression: JsepExpression = { ...this.expressionEditorInput.getCurrentExpression(), version: 2 };
      if (!this.hidePreview) {
        this.requestPreview(currentExpression);
      }
      // save storage prompt
      if (!this.data.excludeLocal) {
        this.localStorageService.setPromptFor(this.data.fullFieldName, this.prompt);
      }
      // return expression
      const output: ExpressionDialogOutput = {
        expression: currentExpression,
        text: this.prompt
      };
      this.dialogRef.close(output);
    } else {
      this.dialogRef.close();
    }
  }

  /**
   * Apply current expression to limited set of fields and see output
   */
  onApplyTransform(): void {
    if (!this.hidePreview) {
      this.requestPreview({ ...this.expressionEditorInput.getCurrentExpression(), version: 2 });
    }
  }

  /**
   * Extract profile source from input data.
   * If source is not provided then make sure preview table has been created
   * param data
   */
  private getProfileSource(data: ExpressionDialogInput, sources: Field[]) {
    // check for profile source
    const source: ProfileSource | undefined = data.profileSource;
    if (source && source.connectorId && source.parameters && source.parameters.instanceId && source.object) {
      this.previewEnabled = true;
      return cloneDeep(source);
    } else {
      if (!this.hidePreview) {
        // make call to create table preview which is required for testing expression
        this.connectorService
          .getTableData(
            ConnectorId.TABLES,
            this.data.tableId || this.data.tableName || '',
            '0',
            MAX_PREVIEW_WAIT,
            false
          )
          .subscribe({
            next: () => {
              // preview ready
              this.previewEnabled = true;
            },
            error: err => {
              this.isErrorApply = true;
              const response = 'Failed to retrieve table preview';
              this.errorMessageApply = response;
              console.error(`${response}`, err);
              this.loadingComponent.showError(this.errorMessageApply);
            }
          });
      }
      // when source is not provided, assume reference an existing table
      return <ProfileSource>{
        connectorId: ConnectorId.TABLES,
        fields: cloneDeep(sources),
        object: this.data.tableId,
        parameters: {
          instanceId: `0`
        }
      };
    }
  }

  private requestExpressionSource(currentProfile: ProfileSource): ProfileSource {
    const responseProfile = cloneDeep(currentProfile);
    if (this.profileSource.connectorId === ConnectorId.TABLES || this.profileSource.connectorId === ConnectorId.LISTS) {
      responseProfile.parameters.instanceId = '0';
    }
    return responseProfile;
  }

  private requestPreview(expression: JsepExpression) {
    if (!this.profileSource) {
      throw new Error('Profile source unavailable');
    }

    const requestExpression: ExpressionRequestConvert = {
      name: this.data.fieldName,
      expression: { ...expression, text: this.prompt },
      source: this.requestExpressionSource(this.profileSource),
      sampleData: this.data.sampleData
    };

    let expressionIsAField = false;

    if (requestExpression.sampleData) {
      const expressionString = (requestExpression.expression as JsepExpression)?.name?.trim().toLocaleLowerCase();
      requestExpression.source.fields.forEach(field => {
        if (field.name.trim().toLowerCase() === expressionString) {
          expressionIsAField = true;
        }
      });
      if (expressionIsAField) {
        this.isErrorApply = false;
        const items: PreviewElement[] = requestExpression.sampleData.map((array: any) => {
          const previewElement: PreviewElement = {
            fieldName: '',
            before: '',
            after: ''
          };

          previewElement.fieldName = (requestExpression.expression as JsepExpression).name || '';
          previewElement.before = array[previewElement.fieldName];
          previewElement.after = array[previewElement.fieldName];

          return previewElement;
        });

        this.previewItems = items;
        this.expressionApplied = true;
      }
    }

    // call service
    if (!expressionIsAField) {
      this.loadingComponent.startLoading();
      if (this.data.profileSource?.parameters.fileId) {
        this.expressionService
          .postConvertExpressionToNxtdriveAPI(this.data.profileSource?.parameters.fileId, requestExpression)
          .subscribe({
            next: response => {
              this.handleSuccessfulPreviewResponse(response);
            },
            error: error => this.handleFailedPreviewResponse(error)
          });
      } else {
        this.expressionService.postConvertExpression(requestExpression).subscribe({
          next: response => {
            this.handleSuccessfulPreviewResponse(response);
          },
          error: error => this.handleFailedPreviewResponse(error)
        });
      }
    }
  }

  private handleSuccessfulPreviewResponse(response) {
    if (response && typeof response === 'string') {
      this.isErrorApply = true;
      this.errorMessageApply = response;
      this.loadingComponent.showError(this.errorMessageApply);
      return;
    }

    this.isErrorApply = false;

    const items: PreviewElement[] = response.map((array: any) => {
      const previewElement: PreviewElement = {
        fieldName: '',
        before: '',
        after: ''
      };

      previewElement.fieldName = response[0][0];
      previewElement.before = array[1];
      previewElement.after = array[0];

      return previewElement;
    });

    items.shift();
    this.previewItems = items;

    this.expressionApplied = true;

    this.loadingComponent.stopLoading();
  }

  private handleFailedPreviewResponse(error) {
    this.isErrorApply = true;
    this.errorMessageApply = error.error.message;
    this.loadingComponent.showError(`Issue testing expression: ${this.errorMessageApply}`);
  }

  public onExpressionChanged(text: string) {
    this.expressionApplied = false;
    this.previewEnabled = true;
  }
}
