import { DataType } from './file';
import { ProfileSource } from './pipeline';
import jsep from 'jsep';

// eslint-disable-next-line no-shadow
export enum MergeRule {
  /** Replace destination value with incoming value */
  ALWAYS_REPLACE = 'ALWAYS_REPLACE',
  /** Replace with incoming value only if destination is null */
  REPLACE_IF_NULL = 'REPLACE_IF_NULL',
  /** Set destination to null if there is a matching record on incoming data */
  RESET = 'RESET',
  /** Only replace destination if the incoming value is not null */
  NEVER_REPLACE_WITH_NULL = 'NEVER_REPLACE_WITH_NULL'
}

// eslint-disable-next-line no-shadow
export enum ExpressionType {
  FUNCTION = 'function',
  SOURCE = 'source',
  LITERAL = 'literal'
}

// eslint-disable-next-line no-shadow
export enum TaskCategory {
  TEMPLATE = 'TEMPLATE',
  RUN = 'RUN'
}

export interface FunctionTypeGroup {
  name: string;
  functions?: FunctionDataService[];
}

export interface FunctionDataService {
  name: string;
  category: string;
  description: DescriptionFunction;
}

export interface DescriptionFunction {
  summary: string;
  method: string;
  params: string[];
  return: string;
}

/**
 * Names of available methods
 * // TODO :: Not complete
 */
// eslint-disable-next-line no-shadow
export enum FunctionType {
  // logic
  if = 'if',
  ifnull = 'ifnull',
  isnull = 'isnull',
  isnan = 'isnan',
  nullif = 'nullif',
  // string
  ucase = 'ucase',
  lcase = 'lcase',
  concat = 'concat',
  sentencecase = 'sentencecase',
  rtrim = 'rtrim',
  ltrim = 'ltrim',
  rpad = 'rpad',
  lpad = 'lpad',
  space = 'space',
  instr = 'instr',
  substring = 'substring',
  to_string = 'to_string',
  trim = 'trim',
  repeat = 'repeat',
  replace = 'replace',
  reverse = 'reverse',
  right = 'right',
  left = 'left',
  length = 'length',
  // numeric
  round = 'round',
  sign = 'sign',
  to_bigint = 'to_bigint',
  ceiling = 'ceiling',
  floor = 'floor',
  add = 'add',
  subtract = 'subtract',
  multiply = 'multiply',
  divide = 'divide',
  // time/data
  timestamp = 'timestamp',
  to_date = 'to_date',
  to_date_formatted = 'to_date_formatted',
  year = 'year',
  dateformat = 'dateformat',
  date_sub_day = 'date_sub_day',
  day = 'day',
  month = 'month',
  dayofweek = 'dayofweek',
  second = 'second',
  minute = 'minute',
  hour = 'hour',
  last_day = 'last_day',
  months_between = 'months_between',
  quarter = 'quarter',
  now = 'now',
  add_date = 'add_date',
  diff_date = 'diff_date'
}

export interface JsepExpression extends jsep.Expression {
  version: number;
  text?: string;
  name?: string;
  raw?: string;
}

export interface ExpressionField {
  name: string;
  mergeRule: MergeRule;
  sourceValue: Expression | JsepExpression;
}

export type Expression = ExpressionLiteral | ExpressionSource | ExpressionFunction | ExpressionVersion;

export interface ExpressionRequestConvert {
  expression: Expression | JsepExpression;
  name: string;
  source: ProfileSource;
  sampleData?: {[key: string]: any}[];
}

export interface ExpressionLiteral {
  type: ExpressionType.LITERAL;
  dataType: DataType;
  value: string; // TODO :: Will this always be a string or can we use DataType?
}

export interface ExpressionVersion {
  type: ExpressionType.LITERAL;
  dataType: DataType;
  value: string; // TODO :: Will this always be a string or can we use DataType?
  version?: number;
}

export interface ExpressionSource {
  type: ExpressionType.SOURCE;
  dataType: DataType;
  value: string;
  sourceName?: string;
}

export interface ExpressionFunction {
  type: ExpressionType.FUNCTION;
  dataType: DataType;
  function: FunctionType;
  values: Expression[];
}

export interface FunctionSpec {
  params: DataType[]; // TODO :: how to determine finite # of params or infinite?
  returnType: DataType | null;
}

export class ExpressionUtil {
  /**
   * Stub for list of available methods
   */
  private static funcMap: Map<FunctionType, FunctionSpec> = new Map<FunctionType, FunctionSpec>([
    // LOGIC Functions
    [
      FunctionType.if,
      {
        params: [
          DataType.BOOLEAN, // must resolve to a boolean
          DataType.ANY,
          DataType.ANY
        ],
        returnType: DataType.BOOLEAN
      }
    ],
    [
      FunctionType.ifnull,
      {
        params: [DataType.ANY, DataType.ANY],
        returnType: DataType.ANY
      }
    ],
    [
      FunctionType.isnull,
      {
        params: [DataType.ANY],
        returnType: DataType.BOOLEAN
      }
    ],
    [
      FunctionType.isnan,
      {
        params: [DataType.ANY],
        returnType: DataType.BOOLEAN
      }
    ],
    [
      FunctionType.nullif,
      {
        params: [DataType.ANY, DataType.ANY],
        returnType: DataType.ANY // | DataType.NULL
      }
    ],
    // STRING Functions
    [
      FunctionType.concat,
      {
        // TODO :: manage how to take takes ...args of string type
        params: [DataType.STRING],
        returnType: DataType.STRING
      }
    ],
    [
      FunctionType.rtrim,
      {
        params: [DataType.STRING],
        returnType: DataType.STRING
      }
    ],
    [
      FunctionType.ltrim,
      {
        params: [DataType.STRING],
        returnType: DataType.STRING
      }
    ],
    [
      FunctionType.rpad,
      {
        params: [DataType.STRING, DataType.NUMERIC, DataType.NUMERIC],
        returnType: DataType.STRING
      }
    ],
    [
      FunctionType.lpad,
      {
        params: [DataType.STRING, DataType.NUMERIC, DataType.NUMERIC],
        returnType: DataType.STRING
      }
    ],
    [
      FunctionType.space,
      {
        params: [DataType.NUMERIC],
        returnType: DataType.STRING
      }
    ],
    [
      FunctionType.instr,
      {
        params: [DataType.STRING, DataType.STRING],
        returnType: DataType.NUMERIC
      }
    ],
    [
      FunctionType.substring,
      {
        params: [DataType.STRING, DataType.NUMERIC, DataType.NUMERIC],
        returnType: DataType.STRING
      }
    ],
    [
      FunctionType.to_string,
      {
        params: [DataType.ANY],
        returnType: DataType.STRING
      }
    ],
    [
      FunctionType.trim,
      {
        params: [DataType.STRING],
        returnType: DataType.STRING
      }
    ],
    [
      FunctionType.ucase,
      {
        params: [DataType.STRING],
        returnType: DataType.STRING
      }
    ],
    [
      FunctionType.lcase,
      {
        params: [DataType.STRING],
        returnType: DataType.STRING
      }
    ],
    [
      FunctionType.sentencecase,
      {
        params: [DataType.STRING],
        returnType: DataType.STRING
      }
    ],
    [
      FunctionType.repeat,
      {
        params: [DataType.STRING, DataType.NUMERIC],
        returnType: DataType.STRING
      }
    ],
    [
      FunctionType.replace,
      {
        params: [DataType.STRING, DataType.STRING, DataType.STRING],
        returnType: DataType.STRING
      }
    ],
    [
      FunctionType.reverse,
      {
        params: [DataType.STRING],
        returnType: DataType.STRING
      }
    ],
    [
      FunctionType.right,
      {
        params: [DataType.STRING, DataType.NUMERIC],
        returnType: DataType.STRING
      }
    ],
    [
      FunctionType.left,
      {
        params: [DataType.STRING, DataType.NUMERIC],
        returnType: DataType.STRING
      }
    ],
    [
      FunctionType.length,
      {
        params: [DataType.STRING],
        returnType: DataType.NUMERIC
      }
    ],
    // NUMERIC Functions
    [
      FunctionType.round,
      {
        params: [DataType.NUMERIC, DataType.NUMERIC],
        returnType: DataType.NUMERIC
      }
    ],
    [
      FunctionType.sign,
      {
        params: [DataType.NUMERIC],
        returnType: DataType.NUMERIC
      }
    ],
    [
      FunctionType.to_bigint,
      {
        params: [DataType.NUMERIC],
        returnType: DataType.NUMERIC
      }
    ],
    [
      FunctionType.ceiling,
      {
        params: [DataType.NUMERIC],
        returnType: DataType.NUMERIC
      }
    ],
    [
      FunctionType.floor,
      {
        params: [DataType.NUMERIC],
        returnType: DataType.NUMERIC
      }
    ],
    [
      FunctionType.add,
      {
        params: [DataType.NUMERIC, DataType.NUMERIC],
        returnType: DataType.NUMERIC
      }
    ],
    [
      FunctionType.subtract,
      {
        params: [DataType.NUMERIC, DataType.NUMERIC],
        returnType: DataType.NUMERIC
      }
    ],
    [
      FunctionType.multiply,
      {
        params: [DataType.NUMERIC, DataType.NUMERIC],
        returnType: DataType.NUMERIC
      }
    ],
    [
      FunctionType.divide,
      {
        params: [DataType.NUMERIC, DataType.NUMERIC],
        returnType: DataType.NUMERIC
      }
    ],
    // TiME/DATE Functions
    [
      FunctionType.timestamp,
      {
        params: [DataType.ANY],
        returnType: DataType.TIMESTAMP
      }
    ],
    [
      FunctionType.to_date,
      {
        params: [DataType.ANY],
        returnType: DataType.DATE
      }
    ],
    [
      FunctionType.to_date_formatted,
      {
        params: [
          DataType.STRING,
          DataType.STRING // date format
        ],
        returnType: DataType.DATE
      }
    ],
    [
      FunctionType.year,
      {
        params: [DataType.DATE],
        returnType: DataType.DATE
      }
    ],
    [
      FunctionType.dateformat,
      {
        params: [
          DataType.DATE,
          DataType.STRING // date format
        ],
        returnType: DataType.STRING
      }
    ],
    [
      FunctionType.date_sub_day,
      {
        params: [
          DataType.DATE,
          DataType.NUMERIC // date format
        ],
        returnType: DataType.DATE
      }
    ],
    [
      FunctionType.day,
      {
        params: [
          DataType.DATE // | DataType.TIMESTAMP
        ],
        returnType: DataType.DATE
      }
    ],
    [
      FunctionType.month,
      {
        params: [
          DataType.DATE // | DataType.TIMESTAMP
        ],
        returnType: DataType.NUMERIC
      }
    ],
    [
      FunctionType.dayofweek,
      {
        params: [
          DataType.DATE // | DataType.TIMESTAMP
        ],
        returnType: DataType.DATE
      }
    ],
    [
      FunctionType.second,
      {
        params: [
          DataType.TIMESTAMP // | DataType.STRING
        ],
        returnType: DataType.NUMERIC
      }
    ],
    [
      FunctionType.minute,
      {
        params: [
          DataType.TIMESTAMP // | DataType.STRING
        ],
        returnType: DataType.NUMERIC
      }
    ],
    [
      FunctionType.hour,
      {
        params: [
          DataType.TIMESTAMP // | DataType.STRING
        ],
        returnType: DataType.NUMERIC
      }
    ],
    [
      FunctionType.last_day,
      {
        params: [
          DataType.DATE // | DataType.STRING
        ],
        returnType: DataType.NUMERIC
      }
    ],
    [
      FunctionType.months_between,
      {
        params: [DataType.TIMESTAMP, DataType.TIMESTAMP],
        returnType: DataType.NUMERIC
      }
    ],
    [
      FunctionType.quarter,
      {
        params: [DataType.DATE],
        returnType: DataType.NUMERIC // 1-4
      }
    ],
    [
      FunctionType.now,
      {
        params: [],
        returnType: DataType.DATETIME
      }
    ],
    [
      FunctionType.add_date,
      {
        params: [DataType.STRING, DataType.DATETIME, DataType.NUMERIC],
        returnType: DataType.DATETIME
      }
    ],
    [
      FunctionType.diff_date,
      {
        params: [DataType.STRING, DataType.DATETIME, DataType.DATETIME],
        returnType: DataType.INTEGER
      }
    ]
  ]);

  /**
   * Stub for list of available methods
   */
  public static getMethod(type: FunctionType): FunctionSpec {
    return ExpressionUtil.funcMap.get(type) as FunctionSpec;
  }
}

// /////// METHODS /////////
