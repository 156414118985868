import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from './dropdown/dropdown.module';
import { SvgCanvasModule } from './svg-canvas/svg-canvas.module';
import { LoadingModalModule } from './loading-modal/loading-modal.module';
import { StandardDeleteDialogModule } from './standard-delete-dialog/standard-delete-dialog.module';
import { IframeWrapperModule } from './iframe-wrapper/iframe-wrapper.module';
import { ResponseDialogModule } from './response-dialog/response-dialog.module';
import { InlineEditModule } from './inline-edit/inline-edit.module';
import { ConfirmDialogModule } from './confirm-dialog/confirm-dialog.module';
import { StandardDateModule } from './standard-list-page/standard-date/standard-date.module';
import { StandardUserDateModule } from './standard-list-page/standard-user-date/standard-user-date.module';
import { KeySelectorModule } from './key-selector/key-selector.module';
import { AssetSelectorDialogModule } from './asset-selector-dialog/asset-selector-dialog.module';
import { FilterableActionListModule } from './filterable-action-list/filterable-action-list.module';
import { EmailChipListInputModule } from './email-chip-list-input/email-chip-list-input.module';
import { UserProfileDialogModule } from './user-profile-dialog/user-profile-dialog.module';
import { SideDrawersModule } from './side-drawers-toggler/side-drawers-toggler.module';
import { StatusIconModule } from './status-icon/status-icon.module';
import { StandardSearchModule } from './standard-search/standard-search.module';
import { FieldDialogComponentModule } from './field-dialog/field-dialog.module';
import { DropdownButtonModule } from './dropdown-button/dropdown-button.module';
import { PageToolbarModule } from './page-toolbar/page-toolbar.module';
import { DatatypeIconModule } from './datatype-icon/datatype-icon.module';
import { ChangePasswordDialogModule } from './change-password-dialog/change-password-dialog.module';
import { RelationshipsModule } from './relationships/relationships.module';
import { HtmlTextEditPreviewModule } from './html-text-edit-preview/html-text-edit-preview.module';
import { AssetSelectorModule } from './asset-selector/asset-selector.module';
import { ButtonGroupModule } from './button-group/button-group.module';
import { MaterialModule } from '@neptune/material.module';
import { ToggleButtonModule } from './toggle-button/toggle-button.module';
import { ResourceDialogModule } from './resource-dialog/resource-dialog.module';
import { PrintOnDemandPreviewModule } from './print-on-demand-preview-content/print-on-demand-preview-content.module';
import { PrintOnDemandPreviewDialogModule } from './print-on-demand-preview-dialog/print-on-demand-preview-dialog.module';
import { FolderSelectorDialogModule } from './folder-selector-dialog/folder-selector-dialog.module';
import { LongTextModule } from '@neptune/components/long-text/long-text.module';
import { FormPreviewContentModule } from './form-preview-content/form-preview-content.module';

@NgModule({
  imports: [CommonModule, MaterialModule],
  exports: [
    DropdownModule,
    SvgCanvasModule,
    LoadingModalModule,
    StandardDeleteDialogModule,
    IframeWrapperModule,
    ResponseDialogModule,
    InlineEditModule,
    ConfirmDialogModule,
    StandardDateModule,
    LongTextModule,
    StandardUserDateModule,
    KeySelectorModule,
    AssetSelectorDialogModule,
    FilterableActionListModule,
    EmailChipListInputModule,
    UserProfileDialogModule,
    SideDrawersModule,
    StatusIconModule,
    StandardSearchModule,
    FieldDialogComponentModule,
    DropdownButtonModule,
    PageToolbarModule,
    DatatypeIconModule,
    ChangePasswordDialogModule,
    RelationshipsModule,
    HtmlTextEditPreviewModule,
    AssetSelectorModule,
    ButtonGroupModule,
    ToggleButtonModule,
    ResourceDialogModule,
    PrintOnDemandPreviewModule,
    PrintOnDemandPreviewDialogModule,
    FolderSelectorDialogModule,
    FormPreviewContentModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: []
})
export class NeptuneComponentsModule {}
