<div
  fxLayout="column"
  fxLayoutAlign="start stretch"
  id="message-key-selector-picker"
  class="h-100-p {{ IGNORE_INLINE_EDIT_SAVE_CLICK }} message-key-selector-picker"
>
  <loading-modal></loading-modal>
  <div
    *ngIf="currentTable && currentTableRow && _contactFields && allTables.length"
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxFlex="grow"
    class="select-value-content-container"
  >
    <mat-accordion class="h-100-p message-menu-accordion">
      <mat-expansion-panel
        [expanded]="i === selectedOption"
        hideToggle
        fxLayout="column"
        *ngFor="let step of expansionOptions; let i = index"
        (afterExpand)="selectOption(i)"
      >
        <mat-expansion-panel-header class="message-menu-accordion-header">
          <mat-panel-title>
            <mat-icon [svgIcon]="iconForOption(i)" class="mr-8"></mat-icon>
            {{ step }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div
          *ngIf="!hideInputTableFields || allTablesIdMap[allTables[i]] !== contactTable"
          class="message-menu-accordion-items"
        >
          <mat-button-toggle-group
            class="w-100-p"
            [(ngModel)]="currentTableRow"
            (change)="onTableRowChange()"
            [vertical]="true"
            *ngIf="i < allTables.length"
            cdkDropList="useDragAndDrop"
            id="message-key-selector-key-{{ i }}"
            [cdkDropListConnectedTo]="i === 0 ? cdkDropListConnectedToOnBaseTable : cdkDropListConnectedToOnJoinedTable"
            [cdkDropListData]="_contactFields[allTablesIdMap[allTables[i]]].schema"
            cdkDropListSortingDisabled
            [cdkDropListEnterPredicate]="noReturnPredicate"
            (cdkDropListExited)="onSourceListExited($event, i)"
            (cdkDropListEntered)="onSourceListEntered($event, i)"
          >
            <mat-button-toggle
              *ngFor="let field of _contactFields[allTablesIdMap[allTables[i]]].schema"
              [value]="field.name"
              cdkDrag
              [cdkDragDisabled]="!useDragAndDrop"
              [cdkDragData]="field"
            >
            <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
              {{ field.name }}
            </mat-button-toggle>
          </mat-button-toggle-group>
          <asset-selector
            *ngIf="step === ExpansionOptions.DYNAMIC_CONTENT"
            svgIcon="dyn_content"
            [projectId]="projectId"
            [disabled]="disabled"
            [filters]="getAssetFilter(dynamicContentTypes, [DynamicContentStatus.DONE])"
            (onAssetSelected)="onDynamicContentSelected($event)"
          ></asset-selector>
          <asset-selector
            *ngIf="step === ExpansionOptions.FORM"
            svgIcon="form"
            [projectId]="projectId"
            [disabled]="disabled"
            [filters]="getAssetFilter([AssetType.FORM], [LandingPageStatus.PUBLISHED])"
            (onAssetSelected)="onFormPageSelected($event)"
          ></asset-selector>
          <asset-selector
            *ngIf="step === ExpansionOptions.LANDING_PAGE"
            svgIcon="webpage"
            [projectId]="projectId"
            [disabled]="disabled"
            [filters]="getAssetFilter([AssetType.LANDING_PAGE], [LandingPageStatus.PUBLISHED])"
            (onAssetSelected)="onLandingPageSelected($event)"
          ></asset-selector>
          <asset-selector
            *ngIf="step === ExpansionOptions.REPEAT_BLOCK"
            svgIcon="repeat_block"
            [projectId]="projectId"
            [disabled]="disabled"
            [filters]="getAssetFilter(repeatBlockTypes, [RepeatBlockStatus.APPROVED])"
            (onAssetSelected)="onRepeatBlockSelected($event)"
          ></asset-selector>
          <asset-selector
            *ngIf="step === ExpansionOptions.RESOURCE"
            svgIcon="resource"
            [projectId]="projectId"
            [disabled]="disabled"
            [filters]="getAssetFilter(resourceAssetTypes, [ResourceStatus.DONE])"
            (onAssetSelected)="onResourceSelected($event)"
          ></asset-selector>
          <mat-button-toggle-group
            class="w-100-p"
            [(ngModel)]="currentUDFOption"
            (change)="onUDFGroupChange()"
            [vertical]="true"
            *ngIf="step === ExpansionOptions.UDF"
          >
            <mat-button-toggle *ngFor="let option of udfOptions" [value]="option.id">
              {{ option.udfName }}
            </mat-button-toggle>
          </mat-button-toggle-group>
          <mat-button-toggle-group
            class="w-100-p"
            [(ngModel)]="currentSystemOption"
            (change)="onSystemGroupChange()"
            [vertical]="true"
            *ngIf="step === ExpansionOptions.SYSTEM"
          >
            <mat-button-toggle *ngFor="let option of _systemOptions" [value]="option">
              {{ option }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div fxFlex class="select-value-content-container" *ngIf="!currentTable || !currentTableRow"></div>
  <div fxFlex="48px" class="select-value-footer-container" *ngIf="showInsertButton">
    <div *ngIf="expansionOptions[selectedOption] === ExpansionOptions.RESOURCE; then thenBlock; else elseBlock"></div>
    <ng-template #thenBlock>
      <button type="button" class="mat-button" mat-accent [disabled]="!enableButton" (click)="insertValue()">
        Insert Resource Link
      </button>
    </ng-template>
    <ng-template #elseBlock>
      <button
        type="button"
        class="mat-button"
        mat-accent
        [disabled]="!enableButton"
        (click)="insertValue()"
        [matTooltip]="currentTableRowValue(true)"
      >
        Insert {{ currentTableRowValue(true) }}
      </button>
    </ng-template>
  </div>
</div>
