import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ItemNode } from '@neptune/models/projects';

export class ProjectItemNode {
  children?: ProjectItemNode[];
  item?: ItemNode | any;
  projectId?: string;
  project_id?: string;
  folderId?: string;
  parentId?: string;
  hasFolders?: boolean;
  expandable?: boolean;
  level?: number;
  selectedItem?: ProjectItemNode;
  node?: ProjectItemNode;
  id?: string;

  get visualName() {
    return (this.item && typeof this.item === 'object' && this.item.name) || this.item;
  }

  get uniqueId(): string {
    return `${this.projectId}-${this.folderId}-${this.parentId}-${this.visualName}`;
  }
}

export class ProjectItemFlatNode {
  item?: ItemNode | any;
  level?: number;
  expandable?: boolean;
  projectId?: string;
  project_id?: string;
  folderId?: string;
  parentId?: string;
  hasFolders?: boolean;
  node?: ProjectItemNode;

  get visualName() {
    return (this.item && this.item.name) || this.item;
  }

  get uniqueId(): string {
    return `${this.projectId}-${this.folderId}-${this.parentId}-${this.visualName}`;
  }
}

export interface DialogObj {
  selected: ProjectItemNode;
  source: ProjectItemFlatNode;
}

@Injectable()
export class ProjectListDatabase {
  dataChange = new BehaviorSubject<ProjectItemNode[]>([]);

  public get data(): ProjectItemNode[] {
    return this.dataChange.value;
  }

  constructor() {
    this.initialize();
  }

  initialize() {
    const data = this.buildFileTree([], 0);

    // Notify the change.
    this.dataChange.next(data);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  buildFileTree(obj: object, level: number): ProjectItemNode[] {
    return Object.keys(obj).reduce<ProjectItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new ProjectItemNode();
      node.item = Object.keys(value).includes('name') ? value.name : key;

      if (node.item === 'parentId') {
        return accumulator;
      }

      if (level === 0) {
        if (value[0]) {
          node.hasFolders = value[0].hasFolders;
        } else {
          node.hasFolders = Object.keys(value).length > 0;
        }
      } else {
        node.hasFolders = true;
      }

      if (value != null) {
        if (typeof value === 'object' && !value.projectId) {
          node.children = this.buildFileTree(value, level + 1);
          node.parentId = value.parentId;

          let projectId: string = value.projectId;
          if (!projectId) {
            const dataString: string = JSON.stringify(value);
            const regex: RegExp = new RegExp(`(\"projectId\":\".{0,36})`);

            const projectIdString: string[] = dataString.match(regex) as string[];
            if (projectIdString) {
              const splittedData: string[] = projectIdString.find(Boolean)?.split(':') as string[];
              projectId = splittedData[splittedData.length - 1];
              projectId = projectId.replace(/"/g, '').replace(/}/g, '').replace(/"]/g, '').replace(/]/g, '');
            }
          }

          node.projectId = projectId;
        } else {
          node.item = value;
          node.projectId = value.projectId;
          node.folderId = value.folderId || -1;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  insertItem(parent: ProjectItemNode, name: string): ProjectItemNode {
    if (!parent.children) {
      parent.children = [];
    }

    const newItem = { item: name, visualName: name, uniqueId: '' } as ProjectItemNode;
    parent.children.push(newItem);

    this.dataChange.next(this.data);

    return newItem;
  }

  insertItemAbove(node: ProjectItemNode, name: string): ProjectItemNode {
    const parentNode = this.getParentFromNodes(node);
    const newItem = { item: name, visualName: name, uniqueId: '' } as ProjectItemNode;

    if (parentNode != null) {
      parentNode.children?.splice(parentNode.children.indexOf(node), 0, newItem);
    } else {
      this.data.splice(this.data.indexOf(node), 0, newItem);
    }

    this.dataChange.next(this.data);

    return newItem;
  }

  insertItemBelow(node: ProjectItemNode, name: string): ProjectItemNode {
    const parentNode = this.getParentFromNodes(node);
    const newItem = { item: name, visualName: name, uniqueId: '' } as ProjectItemNode;

    if (parentNode != null) {
      parentNode.children?.splice(parentNode.children.indexOf(node) + 1, 0, newItem);
    } else {
      this.data.splice(this.data.indexOf(node) + 1, 0, newItem);
    }

    this.dataChange.next(this.data);

    return newItem;
  }

  copyPasteItemAbove(from: ProjectItemNode, to: ProjectItemNode): ProjectItemNode {
    const newItem = this.insertItemAbove(to, from.item as string);
    if (from.children) {
      from.children.forEach(child => {
        this.copyPasteItem(child, newItem);
      });
    }
    return newItem;
  }

  getParentFromNodes(node: ProjectItemNode): ProjectItemNode | null {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < this.data.length; ++i) {
      const currentRoot = this.data[i];
      const parent = this.getParent(currentRoot, node);
      if (parent != null) {
        return parent;
      }
    }
    return null;
  }

  getParent(currentRoot: ProjectItemNode, node: ProjectItemNode): ProjectItemNode | null {
    if (currentRoot.children && currentRoot.children.length > 0) {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < currentRoot.children.length; ++i) {
        const child = currentRoot.children[i];
        if (child === node) {
          return currentRoot;
        } else if (child.children && child.children.length > 0) {
          const parent = this.getParent(child, node);
          if (parent != null) {
            return parent;
          }
        }
      }
    }
    return null;
  }

  getNodeById(currentRoot: ProjectItemNode, id: string): ProjectItemNode | null {
    if (currentRoot.children && currentRoot.children.length > 0) {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < currentRoot.children.length; ++i) {
        const child = currentRoot.children[i];
        if (child.folderId === id || child.parentId === id) {
          return child;
        } else if (child.children && child.children.length > 0) {
          const node = this.getNodeById(child, id);
          if (node != null) {
            return node;
          }
        }
      }
    }
    return null;
  }

  updateItem(node: ProjectItemNode, name: string, final: boolean = false) {
    final ? ((node.item as ItemNode) = { name, projectId: node.projectId }) : ((node.item as string) = name);
    if (final && node.folderId) {
      (node.item as ItemNode).folderId = node.folderId;
    }
    this.dataChange.next(this.data);
  }

  deleteItem(node: ProjectItemNode) {
    this.deleteNode(this.data, node);
    this.dataChange.next(this.data);
  }

  copyPasteItem(from: ProjectItemNode, to: ProjectItemNode): ProjectItemNode {
    const newItem = this.insertItem(to, from.item as string);
    if (from.children) {
      from.children.forEach(child => {
        this.copyPasteItem(child, newItem);
      });
    }
    return newItem;
  }

  copyPasteItemBelow(from: ProjectItemNode, to: ProjectItemNode): ProjectItemNode {
    const newItem = this.insertItemBelow(to, from.item as string);
    if (from.children) {
      from.children.forEach(child => {
        this.copyPasteItem(child, newItem);
      });
    }
    return newItem;
  }

  deleteNode(nodes: ProjectItemNode[], nodeToDelete: ProjectItemNode) {
    const index = nodes.indexOf(nodeToDelete, 0);
    if (index > -1) {
      nodes.splice(index, 1);
    } else {
      nodes.forEach(node => {
        if (node.children && node.children.length > 0) {
          this.deleteNode(node.children, nodeToDelete);
        }
      });
    }
  }
}
