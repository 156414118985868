import { Injectable } from '@angular/core';
import { BaseService, Endpoint } from './base.service';
import { of as observableOf, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CognitoService } from './cognito.service';
import { StoreService } from './store.service';
import { HttpLink } from 'apollo-angular/http';
import { ApolloClient, OperationVariables, InMemoryCache } from '@apollo/client/core';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import gql from 'graphql-tag';
import { EventsByUser } from '@neptune/models/notifications';

export const NotificationsGraphql = {
  queries: {},
  mutations: {},
  subscriptions: {
    subscribeToEventsByUser: `
    subscription subscribeToEventsByUser {
      subscribeToEventsByUser(owner: $username) {
          orgId_service
          eventId
          eventDatetime
          eventType
          detail
          owner
          group
          viewed
          ttlEpoch
          data
      }
  }
  `
  }
};

@Injectable()
export class NotificationsService extends BaseService {
  // eslint-disable-next-line @typescript-eslint/ban-types
  public apolloClient: ApolloClient<object>;
  // @ts-ignore
  public appsyncClient: AWSAppSyncClient<NormalizedCacheObject>;
  public querySubscription: OperationVariables;

  constructor(
    public httpClient: HttpClient,
    public cognitoService: CognitoService,
    public storeService: StoreService,
    public httpLink: HttpLink
  ) {
    super(httpClient, cognitoService, storeService);
  }

  /**
   * For use with jasmine testing, returns a mock of service
   */
  public static mockService(jasmine: unknown): unknown {
    return {
      getList: jest.fn(() => observableOf([])),
      getById: jest.fn(() => observableOf([])),
      create: jest.fn(() => observableOf([])),
      update: jest.fn(() => observableOf([])),
      delete: jest.fn(() => observableOf([]))
    };
  }

  public subscribeToEventsByUser(username: string): Observable<EventsByUser> {
    const owner: string = `"${username}"`;
    return new Observable(observer => {
      this.configureClient().subscribe({
        next: () => {
          this.querySubscription = this.appsyncClient
            .subscribe({
              query: gql`
                ${NotificationsGraphql.subscriptions.subscribeToEventsByUser.replace('$username', owner)}
              `
            })
            .subscribe({
              next: ev => observer.next(ev),
              error: err => observer.error(err)
            });
        },
        error: err => observer.error(err)
      });
    });
  }

  protected getAuthToken(): Observable<HttpHeaders> {
    return this.getAuth(Endpoint.NOTIFICATIONS_GRAPHQL);
  }

  private getEndpointURL(type: Endpoint, url: string): string {
    return this.getURL(type, url);
  }

  public closeWebSocket() {
    this.querySubscription.unsubscribe();
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private configureClient(): Observable<object> {
    return new Observable(observer => {
      this.getAuthToken().subscribe({
        next: (headers: HttpHeaders) => {
          const link = this.httpLink.create({
            uri: this.getEndpointURL(Endpoint.NOTIFICATIONS_GRAPHQL, 'graphql'),
            headers
          });
          this.appsyncClient = new AWSAppSyncClient({
            disableOffline: true,
            url: this.getEndpointURL(Endpoint.NOTIFICATIONS_GRAPHQL, 'graphql'),
            region: 'us-east-1',
            auth: {
              type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
              jwtToken: headers.get('Authorization') as string
            }
          });

          // We're not storing in cache the same results for duplicated queries.
          const cache = new InMemoryCache({
            resultCaching: false,
            addTypename: false
          });

          this.apolloClient = new ApolloClient({
            link,
            cache
          });
          observer.next();
          observer.complete();
        },
        error: (err: Error) => observer.error(err)
      });
    });
  }
}
