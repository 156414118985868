import { Injectable } from '@angular/core';
import { BaseService, Endpoint } from './base.service';
import { Observable } from 'rxjs';
import { AssetType, Purl, PurlData } from '@neptune/models';
import { map } from 'rxjs/operators';

@Injectable()
export class PurlService extends BaseService {
  public PURL: string = 'purls';

  /**
   * Get Purl
   */
  getPurls(): Observable<Purl[]> {
    return this.baseGet<Purl[]>(Endpoint.PURL, this.PURL, false).pipe(
      // NOTE: PURL api is not handling purls as assets, but we do handle them in the ui as assets.
      // This is due to Global Assets work in progress / definitions
      map((purls: Purl[]) =>
        purls.map((purl: Purl) => ({
          ...purl,
          assetType: AssetType.PURL
        }))
      )
    );
  }

  getPurl(id: string): Observable<Purl[]> {
    return this.baseGet(Endpoint.PURL, `${this.PURL}/${id}`, false);
  }

  addPurl(data: Purl): Observable<Purl> {
    const body: PurlData = this.convertPurlToData(data);
    return super.basePost(Endpoint.PURL, this.PURL, body);
  }

  editPurl(data: Purl): Observable<any> {
    const url = `${this.PURL}/${data.id}`;
    const body: PurlData = this.convertPurlToData(data);
    return super.basePut(Endpoint.PURL, url, body);
  }

  deletePurl(data: Purl): Observable<any> {
    const url = `${this.PURL}/${encodeURIComponent(data.id)}`;
    return super.baseDelete<null>(Endpoint.PURL, url);
  }

  private convertPurlToData(purl: Purl): PurlData {
    return {
      expression: purl.expression,
      assetId: purl.assetId,
      name: purl.name,
      collisionDetection: purl.collisionDetection,
      projectId: purl.projectId || '',
      domainId: purl.domainId,
      baseTableName: purl.baseTableName,
      targetLandingPage: purl.targetLandingPage,
      status: purl.status
    };
  }
}
