import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { WhereUsedModel } from '@neptune/models/where-used';

export interface StandardDeleteDialogInput {
  dependents: WhereUsedModel[];
  title?: string;
  showDependants?: boolean;
  message?: string;
}

@Component({
  selector: 'standard-delete-dialog',
  templateUrl: 'standard-delete-dialog.html',
  styleUrls: ['standard-delete-dialog.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class StandardDeleteDialog implements OnInit, AfterViewInit {
  pipelines: WhereUsedModel[] = [];
  assets: WhereUsedModel[] = [];

  constructor(
    public dialogRef: MatDialogRef<StandardDeleteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: StandardDeleteDialogInput
  ) {
    this.data.showDependants = this.data.showDependants !== undefined ? this.data.showDependants : true;
    this.data.dependents.map(item => {
      if (!item.data) {
        item.data = {
          dependentObject: item.dependentObject,
          dependentObjectName: item.typeOfObject
        };
      }
    });
    this.pipelines = this.data.dependents.filter(dependant => dependant.dependentObject?.startsWith('DataPipeline'));
    this.assets = this.data.dependents.filter(dependant => !dependant.dependentObject?.startsWith('DataPipeline'));
  }

  onClose(): void {
    this.dialogRef.close((this.data && this.data.dependents) || []);
  }

  ngAfterViewInit() {
    // ngx-table not recalculating width properly, the only way unfortunately is forcing reflow
    // In case you want to reuse this strategy check if this issue has been fixed here.
    // https://github.com/swimlane/ngx-datatable/issues/193
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  }

  ngOnInit() {}
}
