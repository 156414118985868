import { Component, Input } from '@angular/core';
import { DateTime } from '@neptune/models/schedule';
import { TimezoneUtils } from '@neptune/models/timezone';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'standard-date',
  templateUrl: './standard-date.component.html',
  styleUrls: ['./standard-date.component.scss']
})
export class StandardDateComponent {
  @Input() set date(date: string | DateTime | Date) {
    this.datetime = date ? TimezoneUtils.convertToDateTime(date) : undefined;
  }

  public datetime: DateTime | undefined;
}
