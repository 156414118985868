import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'inline-edit',
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.scss']
})
export class InlineEditComponent {
  public static IGNORE_INLINE_EDIT_SAVE_CLICK: string = 'ignore-inline-edit-outside-click';
  @ViewChild('editInput') inputElement: ElementRef;
  @ViewChild('focusElement') focusElement: ElementRef;
  @ViewChild('focusElementLongText') focusElementLongText: ElementRef;

  @Input() disabled: boolean;
  @Input() iconPrefix: boolean = true;
  @Input() suffix: string;
  @Input() useLongText: boolean = false;
  @Input() pattern: string | RegExp;
  @Input('placeholder')
  set placeholder(ph: string) {
    this._placeholder = ph;
  }

  get placeholder(): string {
    return this._placeholder;
  }

  @Input('value')
  set value(x: string) {
    this.editValue = this._value = x;
  }

  get value(): string {
    return this._value;
  }

  @Output('valueChange')
  valueChange: EventEmitter<string> = new EventEmitter<string>();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output('onCancelEdit')
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onCancelEdit: EventEmitter<any> = new EventEmitter<any>();

  public tabIndex = 0;
  private _placeholder = '...';
  private _value = '';

  public editMode: boolean = false;
  public editValue: string;

  private isFocusInsideComponent = false;
  private isComponentClicked = false;

  enableEditMode() {
    // Added setTimout to fix error that occurred after jumping from inline-edit to inline-edit
    // Expression has changed after it was checked. Previous value: 'ng-untouched: true'. Current value: 'ng-untouched: false'.
    // Tried to fix this by correctly add changeDetectorRef.detectChanges() but it did not work.
    // Seems like this is an issue that depends on the order of events called from Chrome.
    setTimeout(() => {
      this.tabIndex = -1;
      this.editMode = true;
    });
  }

  enableEditModeFromFocus() {
    if (this.useLongText) {
      this.focusElementLongText.nativeElement.click();
    } else {
      this.focusElement.nativeElement.click();
    }
  }

  onCancel() {
    if (this.editMode) {
      this.onCancelEdit.emit();
      this.editValue = this.value;
      this.editMode = false;
      this.restoreIndex();
    }
  }

  onSave() {
    if (this.editMode) {
      this.value = this.editValue;
      this.valueChange.emit(this.value);
      this.editMode = false;
      this.restoreIndex();
    }
  }

  onChange() {
    this.value = this.editValue;
    this.valueChange.emit(this.value);
  }

  public insertAtCursor(textToInsert: string) {
    const input = this.inputElement.nativeElement;

    // save selection start and end position
    const start = input.selectionStart;
    const end = input.selectionEnd;

    // update the value with our text inserted
    this.editValue = this.editValue.slice(0, start) + textToInsert + this.editValue.slice(end);
    input.value = this.editValue;

    // update cursor to be at the end of insertion
    input.selectionStart = input.selectionEnd = start + textToInsert.length;
  }

  private restoreIndex() {
    // This is a small hack to be able to jump between inline edit and inline edit
    // if we do not have this you won't be able to jump back for prev inline-edit
    setTimeout(() => (this.tabIndex = 0));
  }

  @HostListener('click')
  clickInside() {
    this.isFocusInsideComponent = true;
    this.isComponentClicked = true;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (
      !this.isFocusInsideComponent &&
      this.isComponentClicked &&
      !event.target.closest(`.${InlineEditComponent.IGNORE_INLINE_EDIT_SAVE_CLICK}`)
    ) {
      // do the heavy process
      this.onSave();
      this.isComponentClicked = false;
    }
    this.isFocusInsideComponent = false;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.editMode) {
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          this.onSave();
          break;
        case 'Escape':
          this.onCancel();
          break;
      }
    }
  }
}
