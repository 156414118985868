// FIXME: https://quickpivot.atlassian.net/browse/NEP-2346

import { RunState } from './task';

/** Posible action types */
// eslint-disable-next-line no-shadow
export enum TableActionTaskTypes {
  ATTRIBUTE_REBUILD = 'Attribute Rebuild',
  DELETE = 'Delete',
  INDEX = 'Index',
  INGEST = 'Ingest',
  REPORT = 'Report',
  ROLLBACK = 'Rollback',
  SEARCH = 'Search',
  IMPORT = 'IMPORT'
}

/** Posible status of actions */
// eslint-disable-next-line no-shadow

export enum TableActionStatus {
  SUCCESS = 'Success',
  SUCCESS_WITH_ERRORS = 'Success_with_errors',
  FAILURE = 'Failure',
  PROGRESS = 'In Progress',
  PENDING = 'Pending',
  SKIPPED = 'Skipped',
  TIMEOUT = 'Timeout'
}

export interface TableActionsRuninfo {
  id: string;
  runInfo: {
    currentStep: string;
    ended: string;
    started: string;
    startedBy: string;
    stepErrors?: RuninfoStepErrorsType;
    stepStart: string;
    stepStatistics?: RuninfoStepStatisticsType;
  };
}
export interface RuninfoStepStatisticsType {
  insertedRows: number;
  totalRows: number;
  updatedRows: number;
  dedupedRows?: number;
  files?: RuninfoStepStatisticsFilesType[];
}

export interface RuninfoStepErrorsType {
  dataTypeMismatch: number;
  stringExceededLength: number;
  nonnullableCount?: number;
  typeMismatchCount?: number;
  columnMismatchCount?: number;
  incompatibleSchemaCount?: number;
  strLengthExceedCount?: number;
}

export interface RuninfoStepStatisticsFilesType {
  filename: string;
  totalRows: number;
  stepErrors: RuninfoStepErrorsType;
}

/** Table Action, current and pending */
export interface TableAction {
  /** Time of Request.  */
  requestTime: string;
  /** Request’s Task Type.  */
  requestTaskType: TableActionTaskTypes;
  /** Task type id.  */
  taskTypeId: string;
  /** Y/N.  */
  inProgress: boolean;
  /** The combination of orgid and tableid.  */
  orgId_tableId: string;
  /** Action Request id.  */
  requestId: string;
  /** Table Id.  */
  tableId: string;
  /** Start time of the requested table action.  */
  startTime: string;
  /** Organization id.  */
  orgId: string;
  /** The combination of orgid and tableid.  */
  requestId_requestTaskType: string;
  /** Message of the step.  */
  message?: string;
}

export interface TableActionsRow {
  /** Action Request id.  */
  requestId: string;
  /** Request’s Task Type.  */
  requestTaskType: TableActionTaskTypes;
  /** Time of Request.  */
  requestTime: string;
  /** Start time of the requested table action.  */
  startTime: string;
  /** End time of the requested table action.  */
  endTime: string;
  /** Message of the step.  */
  message?: string;
  /** Status of the action.  */
  status?: TableActionStatus;
  expanded?: boolean;
  /** Datails of the task ran */
  runInfo?: TableActionsRuninfo;
  /** List of steps */
  children: TableActionsRow[];
  /** Flag of step */
  isChild: boolean;
  /** Error log */
  error?: string | null;
  /** Extra information  */
  extraInfo?: TableActionsRowExtraInfo[];
  icon?: RunState | TableActionStatus;
}

export interface TableActionsRowExtraInfo {
  infoType: string;
  message: string;
}

export interface TableActionsRowLogError {
  /** Action Request id.  */
  requestId: string;
  /** Url of process log.  */
  log: string;
}

export interface TableCount {
  tableCount: number;
}

export const TRUNCATE = 'TRUNCATE';
export const CLEAR_ALL_RECORDS = 'CLEAR ALL RECORDS';
