import { Component, Input } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';

@Component({
  selector: 'email-chip-list-input',
  templateUrl: './email-chip-list-input.component.html',
  styleUrls: ['./email-chip-list-input.component.scss']
})
export class EmailChipListInputComponent {
  @Input('emails') emails: string[];
  @Input('disabled') disabled: boolean;

  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public emailWarning: string = '';

  public removeEmail(email: string) {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  public addEmail(event: MatChipInputEvent) {
    this.clearError();
    const input = event.input;
    const value = event.value.trim();

    // Add element
    if (value) {
      if (this.emails.indexOf(value) < 0) {
        if (this.validateEmail(value)) {
          this.emails.push(value);
          input.value = '';
        } else {
          this.setError('Invalid Email');
        }
      } else {
        this.setError('Duplicated Email');
      }
    }
  }

  private setError(error: string) {
    this.emailWarning = error;
  }

  private clearError() {
    this.emailWarning = '';
  }

  private validateEmail(email) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      String(email).toLowerCase()
    );
  }
}
