/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  Asset,
  AssetType,
  SchemaField,
  AssetSelectorDialogFilter,
  DynamicContent,
  DynamicContentStatus,
  AllStatus,
  ResourceStatus,
  SchemaObject,
  TablesJoins
} from '@neptune/models';
import { InlineEditComponent } from '@neptune/components/inline-edit/inline-edit.component';
import { AssetId } from '@neptune/models/journey';
import { ActivatedRoute } from '@angular/router';
import { LoadingModalComponent } from '@neptune/components/loading-modal/loading-modal.component';
import { DynamicContentService } from '@neptune/services/dynamic-content.service';
import { ResourceService } from '@neptune/services/resource.service';
import { TableService } from '@neptune/services/table.service';
import { LandingPageStatus } from '@neptune/models/landing-page';
import { Resource } from '@neptune/models/resource';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { NeptuneSetup } from 'environments/environment';
import { RepeatBlockStatus } from '@neptune/models/repeat-block';
import { CdkDragEnter, CdkDragExit } from '@angular/cdk/drag-drop';
import { UDFService } from '@neptune/services/udf.service';
import { UDF } from '@neptune/models/udf';
import { FormStatus } from '@neptune/models/form';

// eslint-disable-next-line no-shadow
enum ExpansionOptions {
  DYNAMIC_CONTENT = 'Content Block',
  FORM = 'Form',
  LANDING_PAGE = 'Landing Page',
  REPEAT_BLOCK = 'Repeat Block',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  UDF = 'UDF',
  SYSTEM = 'System',
  RESOURCE = 'Resource'
}

// eslint-disable-next-line no-shadow
export enum DynamicContentPrefix {
  DYNAMIC_CONTENT = 'DC',
  FORM = 'FORM',
  LANDING_PAGE = 'LP',
  REPEAT_BLOCK = 'RB',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  UDF = 'UDF',
  SYSTEM = 'SYSTEM',
  RESOURCE = 'RESOURCE'
}

// eslint-disable-next-line no-shadow
export enum SystemTokens {
  UNSUBSCRIBE = 'Unsubscribe',
  WEBPAGE = 'WebpageView'
}

export interface SelectedField {
  tableId: string;
  tableName: string;
  field: SchemaField;
}

@Component({
  selector: 'message-key-selector-picker',
  templateUrl: './message-key-selector-picker.component.html',
  styleUrls: ['./message-key-selector-picker.component.scss']
})
export class MessageKeySelectorPickerComponent implements OnInit {
  @ViewChild(LoadingModalComponent, { static: true })
  private loadingComponent: LoadingModalComponent;

  @Input() showDynamicContent: boolean;
  @Input() showForm: boolean;
  @Input() showLandingPage: boolean;
  @Input() showRepeatBlock: boolean;
  @Input() showSystem: boolean;
  @Input() showResource: boolean;
  @Input() showUDF: boolean;
  @Input() enableButton: boolean;
  @Input() disabled: boolean;
  @Input() hideInputTableFields: boolean;
  @Input() dynamicContentTypes: AssetType[];
  @Input() repeatBlockTypes: AssetType[];
  @Input() set inputTable(tableId: string | undefined) {
    if (tableId && !this.contactTable) {
      this.contactTable = tableId;
      this.loadingComponent.startLoading();
      this.loadContactFields = Object.assign({}, this.loadContactFields);
      this.loadContactFieldsFor(tableId).subscribe({
        next: () => {
          this.loadUDFs().subscribe(() => {
            this.mapContactFields();
            this.onTableOptionSelected.emit(this.currentTable);
            this.onSingleTableKeyOptionSelected.emit(this.currentTableRow);
          });
        },
        error: err => this.loadingComponent.showError(`Error loading contact fields for ${tableId}`)
      });
    }
  }

  @Input() useMultiTable: boolean = true;
  @Input() showDeepJoin: boolean = true;

  @Input() useDragAndDrop: boolean = false;
  @Input() showInsertButton: boolean = true;
  @Input() cdkDropListConnectedToOnBaseTable: string[] = [];
  @Input() cdkDropListConnectedToOnJoinedTable: string[] = [];
  @Input() showTableName: boolean = false;

  @Output() onValueSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() onDynamicContentSelectedEmitter: EventEmitter<DynamicContent> = new EventEmitter();
  @Output() onResourceSelectedEmitter: EventEmitter<Resource> = new EventEmitter();
  @Output() onTableOptionSelected: EventEmitter<string> = new EventEmitter();
  @Output() onSingleTableKeyOptionSelected: EventEmitter<string> = new EventEmitter();
  @Output() onSelectedField: EventEmitter<SelectedField> = new EventEmitter();
  @Output() onReceiveContactFields: EventEmitter<Map<string, { schema: SchemaField[]; svgIcon: string }>> =
    new EventEmitter();
  @Output() onReceiveTableJoins: EventEmitter<TablesJoins> = new EventEmitter();

  public ExpansionOptions = ExpansionOptions;
  public IGNORE_INLINE_EDIT_SAVE_CLICK: string = InlineEditComponent.IGNORE_INLINE_EDIT_SAVE_CLICK;
  public AssetType = AssetType;
  public LandingPageStatus = LandingPageStatus;
  public FormPageStatus = FormStatus;
  public RepeatBlockStatus = RepeatBlockStatus;
  public ResourceStatus = ResourceStatus;
  public DynamicContentStatus = DynamicContentStatus;
  public currentTableRow: string;
  public currentTable: string | undefined;
  public allTables: string[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  public allTablesIdMap: object = {};
  public _contactFields: Map<string, { schema: SchemaField[]; svgIcon: string }>;
  public loadContactFields: Map<string, { schema: SchemaField[]; svgIcon: string }>;
  public currentTablesFromJoin: string[];
  public resourceAssetTypes: AssetType[] = [
    AssetType.RESOURCE,
    AssetType.RESOURCE_CSS,
    AssetType.RESOURCE_JFIF,
    AssetType.RESOURCE_JPEG,
    AssetType.RESOURCE_JPG,
    AssetType.RESOURCE_GIF,
    AssetType.RESOURCE_JS,
    AssetType.RESOURCE_PNG
  ];

  public expansionOptions = [] as string[];
  public selectedOption: number = 0;

  public projectId: string | null;

  private dynamicContentId: AssetId | null;
  private dynamicContentAsset: DynamicContent | null;
  private repeatBlockId: AssetId;
  private formId: AssetId;
  private landingPageId: AssetId;

  private resource: Resource | null;

  // For right now this is hardcoded, there should be an api at some point.
  public _systemOptions: string[] = [SystemTokens.UNSUBSCRIBE, SystemTokens.WEBPAGE];
  // eslint-disable-next-line no-invalid-this
  public currentSystemOption: string = this._systemOptions[0];

  public udfOptions: UDF[] = [];
  public currentUDFOption: string | undefined = undefined;

  // changes in key selector from standard asset
  public tableJoins: TablesJoins;
  public parentTable: Map<string, string> = new Map();
  public contactTable: string;

  constructor(
    private route: ActivatedRoute,
    private dynamicContentService: DynamicContentService,
    private resourceService: ResourceService,
    private tableService: TableService,
    private udfService: UDFService
  ) {}

  ngOnInit(): void {
    this.projectId = this.route.snapshot.queryParamMap.get('pr-id');
  }

  private initializeExpansionOptions() {
    if (this.showDynamicContent) {
      this.expansionOptions.push(ExpansionOptions.DYNAMIC_CONTENT);
    }
    if (this.showForm) {
      this.expansionOptions.push(ExpansionOptions.FORM);
    }
    if (this.showLandingPage) {
      this.expansionOptions.push(ExpansionOptions.LANDING_PAGE);
    }
    if (this.showRepeatBlock) {
      this.expansionOptions.push(ExpansionOptions.REPEAT_BLOCK);
    }
    if (this.showUDF) {
      this.expansionOptions.push(ExpansionOptions.UDF);
    }
    if (this.showSystem) {
      this.expansionOptions.push(ExpansionOptions.SYSTEM);
    }
    if (this.showResource) {
      this.expansionOptions.push(ExpansionOptions.RESOURCE);
    }
  }

  /**
   * Get filter params for asset selector based on asset type
   */
  public getAssetFilter(types: AssetType[], status?: AllStatus[]): AssetSelectorDialogFilter {
    const res: AssetSelectorDialogFilter = {
      assetTypes: types
    };
    if (this.expansionOptions[this.selectedOption] !== ExpansionOptions.RESOURCE) {
      res.tableName = this.currentTable;
    }
    if (status) {
      res.status = status;
    }
    return res;
  }

  public currentTableRowValue(ui: boolean): string | undefined {
    if (this.currentTablesFromJoin && this.selectedOption < this.currentTablesFromJoin.length) {
      return this.currentTablesFromJoin[this.selectedOption] && this.currentTableRow
        ? this.showTableName && ui
          ? `{{${this.currentTablesFromJoin[this.selectedOption]}.${this.currentTableRow}}}`
          : `{{${this.allTablesIdMap[this.currentTablesFromJoin[this.selectedOption]]}.${this.currentTableRow}}}`
        : '{{}}';
    } else if (this.expansionOptions[this.selectedOption] === ExpansionOptions.DYNAMIC_CONTENT) {
      return this.dynamicContentId && this.dynamicContentId.id
        ? `{[${DynamicContentPrefix.DYNAMIC_CONTENT}.${this.idWithoutVersion(this.dynamicContentId.id)}.${
            this.dynamicContentId.name
          }]}`
        : '{[]}';
    } else if (this.expansionOptions[this.selectedOption] === ExpansionOptions.FORM) {
      return this.formId && this.formId.id
        ? `{[${DynamicContentPrefix.FORM}.${this.idWithoutVersion(this.formId.id)}.${this.formId.name}]}`
        : '{[]}';
    } else if (this.expansionOptions[this.selectedOption] === ExpansionOptions.LANDING_PAGE) {
      return this.landingPageId && this.landingPageId.id
        ? `{[${DynamicContentPrefix.LANDING_PAGE}.${this.idWithoutVersion(this.landingPageId.id)}.${
            this.landingPageId.name
          }]}`
        : '{[]}';
    } else if (this.expansionOptions[this.selectedOption] === ExpansionOptions.REPEAT_BLOCK) {
      return this.repeatBlockId && this.repeatBlockId.id
        ? `{[${DynamicContentPrefix.REPEAT_BLOCK}.${this.idWithoutVersion(this.repeatBlockId.id)}.${
            this.repeatBlockId.name
          }]}`
        : '{[]}';
    } else if (this.expansionOptions[this.selectedOption] === ExpansionOptions.SYSTEM) {
      return this.currentSystemOption ? `{[${DynamicContentPrefix.SYSTEM}.${this.currentSystemOption}]}` : '{[]}';
    } else if (this.expansionOptions[this.selectedOption] === ExpansionOptions.UDF) {
      const udf = this.udfOptions.find(_udf => _udf.id === this.currentUDFOption);
      return this.currentUDFOption ? `{[${DynamicContentPrefix.UDF}.${udf?.udfName}]}` : '{[]}';
    } else if (this.expansionOptions[this.selectedOption] === ExpansionOptions.RESOURCE) {
      return this.resource ? `${this.resource.publishedUrl}` : '';
    }
  }

  public insertValue() {
    if (this.enableButton) {
      this.onValueSelected.emit(this.currentTableRowValue(true));
    }
  }

  public onTableRowChange() {
    this.onDynamicContentSelectedEmitter.emit();
    this.onSingleTableKeyOptionSelected.emit(this.currentTableRow);
    this.tableService
      .checkTableName(this.allTablesIdMap[this.currentTable as string], true, NeptuneSetup.DefaultCacheExpirationTime)
      .subscribe(res => {
        const selectedField = {
          tableId: res.Id,
          tableName: res.name,
          field: res.fields.filter(f => f.name === this.currentTableRow)[0]
        };
        this.onSelectedField.emit(selectedField as any);
      });
  }

  public onSystemGroupChange() {
    this.onDynamicContentSelectedEmitter.emit();
  }

  public onUDFGroupChange() {
    this.onDynamicContentSelectedEmitter.emit();
  }

  public onDynamicContentSelected(asset: Asset) {
    if (asset) {
      this.loadingComponent.startLoading();
      this.dynamicContentService.getSingleDynamicContent(asset.assetId as string).subscribe((dc: DynamicContent) => {
        this.dynamicContentAsset = dc;
        this.onDynamicContentSelectedEmitter.emit(dc);
        this.loadingComponent.stopLoading();
        this.dynamicContentId = this.convertToAssetId(asset);
      });
    } else {
      this.onDynamicContentSelectedEmitter.emit();
      this.dynamicContentId = null;
      this.dynamicContentAsset = null;
    }
  }

  public onRepeatBlockSelected(asset: Asset) {
    this.repeatBlockId = this.convertToAssetId(asset) as AssetId;
  }

  public onLandingPageSelected(asset: Asset) {
    this.landingPageId = this.convertToAssetId(asset) as AssetId;
  }

  public onFormPageSelected(asset: Asset) {
    this.formId = this.convertToAssetId(asset) as AssetId;
  }

  public onResourceSelected(asset: Asset) {
    if (asset) {
      this.loadingComponent.startLoading();
      this.resourceService.getResource(asset.assetId as string).subscribe((resource: Resource) => {
        this.onResourceSelectedEmitter.emit(resource);
        this.loadingComponent.stopLoading();
        this.resource = resource;
      });
    } else {
      this.onResourceSelectedEmitter.emit();
      this.resource = null;
    }
  }

  public selectOption(i: number, broadcast: boolean = true) {
    this.selectedOption = i;
    if (this.selectedOption < this.currentTablesFromJoin.length) {
      this.currentTableRow = this._contactFields[this.allTablesIdMap[this.currentTablesFromJoin[i]]].schema[0].name;
      this.currentTable = this.currentTablesFromJoin[i];
      if (broadcast && this.showDeepJoin) {
        this.loadingComponent.startLoading();
        this.loadContactFieldsFor(this.allTablesIdMap[this.currentTablesFromJoin[i]]).subscribe(() => {
          this.onReceiveContactFields.emit(this.loadContactFields);
          this.onReceiveTableJoins.emit(this.tableJoins);
          this.onTableOptionSelected.emit(this.currentTable);
          this.onSingleTableKeyOptionSelected.emit(this.currentTableRow);
          this.mapContactFields();
          this.loadingComponent.stopLoading();
        });
      }
      if (!this.hideInputTableFields || this.allTablesIdMap[this.allTables[i]] !== this.contactTable) {
        this.tableService
          .checkTableName(this.allTablesIdMap[this.currentTable], true, NeptuneSetup.DefaultCacheExpirationTime)
          .subscribe(res => {
            const selectedField = {
              tableId: res.Id,
              tableName: res.name,
              field: res.fields.filter(f => f.name === this.currentTableRow)[0]
            };
            this.onSelectedField.emit(selectedField as any);
          });
      } else {
        this.onSelectedField.emit(undefined);
      }
    } else {
      this.onSingleTableKeyOptionSelected.emit();
    }
    if (this.expansionOptions[this.selectedOption] === ExpansionOptions.DYNAMIC_CONTENT) {
      this.onDynamicContentSelectedEmitter.emit(this.dynamicContentAsset as DynamicContent);
    } else {
      this.onDynamicContentSelectedEmitter.emit();
    }
  }

  public iconForOption(optionIdx: number): string {
    if (this.currentTablesFromJoin && optionIdx < this.currentTablesFromJoin.length) {
      return this._contactFields[this.allTablesIdMap[this.currentTablesFromJoin[optionIdx]]].svgIcon;
    } else if (this.expansionOptions[optionIdx] === ExpansionOptions.DYNAMIC_CONTENT) {
      return 'dyn_content';
    } else if (this.expansionOptions[optionIdx] === ExpansionOptions.FORM) {
      return 'form';
    } else if (this.expansionOptions[optionIdx] === ExpansionOptions.LANDING_PAGE) {
      return 'webpage';
    } else if (this.expansionOptions[optionIdx] === ExpansionOptions.REPEAT_BLOCK) {
      return 'repeat_block';
    } else if (this.expansionOptions[optionIdx] === ExpansionOptions.SYSTEM) {
      return 'settings';
    } else if (this.expansionOptions[optionIdx] === ExpansionOptions.UDF) {
      return 'udf';
    } else if (this.expansionOptions[optionIdx] === ExpansionOptions.RESOURCE) {
      return 'resource';
    } else {
      return '';
    }
  }

  private getTableJoins(table: string): Observable<TablesJoins> {
    return this.tableService.getTableJoins(table, true, true, NeptuneSetup.DefaultCacheExpirationTime);
  }

  private getTableFields(table: string, svgIcon: string): Observable<{ schema: SchemaObject; svgIcon: string }> {
    return this.tableService
      .checkTableName(table, true, NeptuneSetup.DefaultCacheExpirationTime)
      .pipe(map((schema: SchemaObject) => ({ schema, svgIcon })));
  }

  private loadUDFs(): Observable<UDF[]> {
    if (this.showUDF) {
      return this.udfService
        .getAll()
        .pipe(
          catchError(error => {
            console.log('UDF error:', error);
            this.showUDF = false;
            this.loadingComponent.showWarning('There was an error loading UDFs');
            this.loadingComponent.startLoading();
            return of(null);
          })
        )
        .pipe(
          // @ts-ignore
          tap((udfs: UDF[]) => {
            this.udfOptions = udfs;
          })
        );
    } else {
      return of(null) as unknown as Observable<UDF[]>;
    }
  }

  private mapContactFields() {
    if (
      this.currentTablesFromJoin.length > 0 &&
      JSON.stringify(this.allTables) !== JSON.stringify(this.currentTablesFromJoin)
    ) {
      this._contactFields = this.loadContactFields;
      this.allTables = this.currentTablesFromJoin;
      this.expansionOptions = this.currentTablesFromJoin.map(tName => `${tName} Table`);
      this.initializeExpansionOptions();
      if (this.currentTablesFromJoin.indexOf(this.currentTable as string) < 0) {
        this.currentTable = undefined;
        this.selectedOption = 0;
      }
      if (!this.currentTable) {
        this.currentTable = this.currentTablesFromJoin[this.selectedOption];
      }
      this.selectedOption = this.currentTablesFromJoin.indexOf(this.currentTable);

      this.selectOption(this.selectedOption, false);
      this.onReceiveContactFields.emit(this.loadContactFields);
      this.onReceiveTableJoins.emit(this.tableJoins);
      this.loadingComponent.stopLoading();
    }
  }

  protected loadContactFieldsFor(baseTableId: string): Observable<{ schema: SchemaObject; svgIcon: string }[]> {
    return this.getTableJoins(baseTableId).pipe(
      mergeMap((joins: TablesJoins) => {
        const allTablesFromJoins: string[] = joins.message === 'string' ? [] : Object.keys(joins.message[0]);
        if (!this.tableJoins) {
          this.tableJoins = joins;
        } else if (joins.message !== 'string') {
          allTablesFromJoins.forEach(t => (this.tableJoins.message[0][t] = joins.message[0][t]));
        }
        const obs: Observable<{ schema: SchemaObject; svgIcon: string }>[] = allTablesFromJoins.map((table: string) => {
          this.parentTable[table] = baseTableId;
          return this.getTableFields(table, baseTableId !== this.contactTable ? 'joined_joined_table' : 'joined_table');
        });
        obs.unshift(this.getTableFields(baseTableId, baseTableId !== this.contactTable ? 'joined_table' : 'table'));
        if (baseTableId !== this.contactTable) {
          obs.unshift(this.getTableFields(this.parentTable[baseTableId], 'table'));
        }
        return forkJoin(obs).pipe(
          tap((res: { schema: SchemaObject; svgIcon: string }[]) => {
            if (res.filter(obj => obj.schema && obj.schema.fields.length > 0).length === 0) {
              throw new Error('There was an error loading Contact Table');
            } else {
              if (this.loadContactFields && Object.keys(this.loadContactFields).length === 0) {
                this.loadContactFields = new Map<string, { schema: SchemaField[]; svgIcon: string }>();
              } else {
                this.loadContactFields = Object.assign({}, this.loadContactFields);
              }
              this.currentTablesFromJoin = [];
              if (res[0].schema && res[0].schema.fields.length > 0) {
                this.loadContactFields[res[0].schema.Id as string] = {
                  schema: res[0].schema.fields.map(f => ({ ...f, tableId: res[0].schema.Id })),
                  svgIcon: res[0].svgIcon
                };
                this.currentTablesFromJoin = [res[0].schema.name];
              }
              if (this.useMultiTable) {
                for (let i = 1; i < res.length; i++) {
                  if (res[i].schema && res[i].schema.fields.length > 0) {
                    this.loadContactFields[res[i].schema.Id as string] = {
                      schema: res[i].schema.fields.map(f => ({ ...f, tableId: res[i].schema.Id })),
                      svgIcon: res[i].svgIcon
                    };
                  }
                }
                this.currentTablesFromJoin = res.map(r => r.schema.name);
              }

              this.allTablesIdMap = res.reduce((acc, it) => {
                if (it.schema) {
                  acc[it.schema.name] = it.schema.Id;
                }
                return acc;
              }, this.allTablesIdMap);
            }
          })
        );
      })
    );
  }

  private convertToAssetId(asset: Asset): AssetId | null {
    return asset
      ? {
          id: asset.assetId,
          name: asset.name,
          assetType: asset.assetType
        }
      : null;
  }

  private idWithoutVersion(id: string): string {
    return id.substr(0, 36);
  }

  /** Drag and Drop Option */
  noReturnPredicate() {
    return false;
  }

  onSourceListExited(event: CdkDragExit<string[]>, index: number) {
    // this.dropList(index).splice(event.container.getItemIndex(event.item) + 1, 0, { ...event.item.data, temp: true });
  }

  onSourceListEntered(event: CdkDragEnter<string[]>, index: number) {
    // remove(this.dropList(index), { temp: true });
  }
}
