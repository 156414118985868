import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IframeWrapperComponent } from '@neptune/components/iframe-wrapper/iframe-wrapper.component';
import { NeptunePipesModule } from '@neptune/pipes/pipes.module';

@NgModule({
  declarations: [IframeWrapperComponent],
  imports: [CommonModule, NeptunePipesModule],
  exports: [IframeWrapperComponent]
})
export class IframeWrapperModule {}
