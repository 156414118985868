export interface VersionI {
  dirty: boolean;
  raw: string;
  hash: string;
  distance?: any;
  tag?: any;
  semver?: any;
  suffix: string;
  semverString?: string | null;
  version: string;
}
