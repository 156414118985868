import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService, Endpoint } from './base.service';
import { DynamicContent, DynamicContentBlock } from '@neptune/models';

@Injectable()
export class DynamicContentService extends BaseService {
  public readonly DEFAULT_BLOCK_ID = 'XXXX-XXXX-XXXX-XXXX';

  /**
   * For use with jasmine testing, returns a mock of service
   */
  public static mockService(jasmine: object, dynamicContent: DynamicContent, dynamicContentBlock: DynamicContentBlock) {
    return {
      getDynamicContent: jest.fn(() => observableOf(dynamicContent)),
      getSingleDynamicContent: jest.fn(() => observableOf(dynamicContent)),
      getAllDynamicContent: jest.fn(() => observableOf([])),
      postDynamicContent: jest.fn(() => observableOf(dynamicContent)),
      putDynamicContent: jest.fn(() => observableOf(dynamicContent)),
      getDynamicContentBlock: jest.fn(() => observableOf(dynamicContentBlock)),
      getAllContentBlocks: jest.fn(() => observableOf([])),
      getOneContentBlock: jest.fn(() => observableOf(dynamicContentBlock)),
      postDynamicContentBlock: jest.fn(() => observableOf(dynamicContentBlock)),
      putDynamicContentBlock: jest.fn(() => observableOf(dynamicContentBlock)),
      deleteDynamicContentBlock: jest.fn(() => observableOf(true))
    };
  }

  // Content CRUD
  getDynamicContent(id?: string): Observable<DynamicContent[] | DynamicContent> {
    if (id) {
      return this.getSingleDynamicContent(id);
    } else {
      return this.getAllDynamicContent();
    }
  }

  getSingleDynamicContent(id: string): Observable<DynamicContent> {
    return super.baseGet(Endpoint.DYNAMIC_CONTENT, `dynamic_contents/${id}`);
  }

  getAllDynamicContent(): Observable<DynamicContent[]> {
    return super.baseGet(Endpoint.DYNAMIC_CONTENT, 'dynamic_contents');
  }

  postDynamicContent(content: DynamicContent): Observable<DynamicContent> {
    return super.basePost(Endpoint.DYNAMIC_CONTENT, 'dynamic_contents', content);
  }

  putDynamicContent(id: string, content: DynamicContent): Observable<DynamicContent> {
    return super.basePut(Endpoint.DYNAMIC_CONTENT, `dynamic_contents/${id}`, content);
  }

  // Blocks CRUD
  getDynamicContentBlock(contentId: string, id?: string): Observable<DynamicContentBlock | DynamicContentBlock[]> {
    if (id) {
      return this.getOneContentBlock(contentId, id);
    } else {
      return this.getAllContentBlocks(contentId);
    }
  }
  getAllContentBlocks(contentId: string): Observable<DynamicContentBlock[]> {
    return super
      .baseGet<DynamicContentBlock[]>(Endpoint.DYNAMIC_CONTENT, `dynamic_contents/${contentId}/dynamic_content_blocks`)
      .pipe(
        map((blocks: DynamicContentBlock[]) => {
          const sortedBlocks = blocks.sort((a, b) => (a.evaluationOrder > b.evaluationOrder ? 1 : -1));
          const defaultBlockIndex = sortedBlocks.findIndex(block => block.queryId === this.DEFAULT_BLOCK_ID);
          if (defaultBlockIndex !== -1) {
            const defaultBlock = sortedBlocks.splice(defaultBlockIndex, 1)[0];
            defaultBlock.default = true;
            sortedBlocks.push(defaultBlock);
          }
          return sortedBlocks;
        })
      );
  }
  getOneContentBlock(contentId: string, id: string): Observable<DynamicContentBlock> {
    return super
      .baseGet<DynamicContentBlock>(
        Endpoint.DYNAMIC_CONTENT,
        `dynamic_contents/${contentId}/dynamic_content_blocks/${id}`
      )
      .pipe(
        map((block: DynamicContentBlock) => {
          if (block.queryId === this.DEFAULT_BLOCK_ID) {
            block.default = true;
          }
          return block;
        })
      );
  }
  postDynamicContentBlock(contentId: string, block: DynamicContentBlock): Observable<DynamicContentBlock> {
    const _block = this._cleanBlock(block);
    _block.dynamicContentId = contentId;
    return super.basePost(Endpoint.DYNAMIC_CONTENT, `dynamic_contents/${contentId}/dynamic_content_blocks`, _block);
  }

  cloneContentBlock(id, name, folderId): Observable<DynamicContentBlock> {
    return super.basePost(Endpoint.DYNAMIC_CONTENT, `dynamic_contents/${id}/clone`, {
      name,
      folderId
    });
  }

  putDynamicContentBlock(contentId: string, id: string, block: DynamicContentBlock): Observable<DynamicContentBlock> {
    const _block = this._cleanBlock(block);
    _block.dynamicContentId = contentId;
    return super.basePut(
      Endpoint.DYNAMIC_CONTENT,
      `dynamic_contents/${contentId}/dynamic_content_blocks/${id}`,
      _block
    );
  }

  deleteDynamicContentBlock(contentId: string, id: string) {
    return super.baseDelete(Endpoint.DYNAMIC_CONTENT, `dynamic_contents/${contentId}/dynamic_content_blocks/${id}`);
  }

  /**
   * Remove props that are used solely on the front end
   *
   */
  private _cleanBlock(block: DynamicContentBlock): DynamicContentBlock {
    const _block = { ...block };
    delete _block.default;
    delete _block.query;
    delete _block.uuid;
    return _block;
  }
}
