import { Injectable } from '@angular/core';
import { TableService } from '@neptune/services/table.service';
import { Breadcrumb } from '../breadcrumbs.model';
import { HttpClient } from '@angular/common/http';
import { StoreService } from '@neptune/services/store.service';
import { CognitoService } from '@neptune/services/cognito.service';

@Injectable({
  providedIn: 'root'
})
export class TableResolverService extends TableService {
  constructor(
    protected http: HttpClient,
    protected cognitoService: CognitoService,
    protected storeService: StoreService
  ) {
    super(http, cognitoService, storeService);
  }

  public getTableName(tableId: string, breadcrumbs: Breadcrumb[], text: string) {
    this.getTable(tableId).subscribe(t => {
      const breadcrumb = {
        label: text + ' TABLE ' + t.name,
        url: ''
      };
      breadcrumbs.push(breadcrumb);
    });
  }
}
