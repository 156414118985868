import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DataType } from '@neptune/models/file';
import { IconObject } from '@neptune/models/icon-object';

@Component({
  selector: 'app-datatype-icon',
  template: '<nep-icon [icon]="icon" [iconName]="iconName"></nep-icon>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatatypeIconComponent {
  private static iconMap: Map<DataType, IconObject> = new Map<DataType, IconObject>([
    [DataType.STRING, { name: 'datatype_text', custom: true }],
    [DataType.TEXT, { name: 'datatype_longtext', custom: true }],
    [DataType.INTEGER, { name: 'datatype_number', custom: true }],
    [DataType.NUMERIC, { name: 'type_decimal', custom: true }],
    [DataType.DATETIME, { name: 'calendar-clock' }],
    [DataType.DATE, { name: 'datatype_date', custom: true }],
    [DataType.TIMESTAMP, { name: 'timetable' }],
    [DataType.BOOLEAN, { name: 'check' }],
    [DataType.ANY, { name: 'regex' }],
    [DataType.NULL, { name: 'block-helper' }],
    [DataType.UNKNOWN, { name: 'alert ' }]
  ]);

  public icon: IconObject;
  public iconName: string;
  @Input() set type(value: DataType) {
    this.icon = DatatypeIconComponent.iconMap.get(value) as IconObject;
    this.iconName = `mdi-${this.icon.name}`;
  }
}
