import { AssetBase } from '@neptune/models/asset';
import { AssetId } from '@neptune/models/journey';

export interface RepeatBlock extends AssetBase {
  status?: RepeatBlockStatus;
  type?: RepeatBlockTypes;
  active?: boolean;
  deleted?: boolean;
  approvedAt?: string;
  approvedBy?: string;
  content?: RepeatBlockContent;
  repeat?: RepeatBlockRepeatData;
  assetId?: string;
}

export interface RepeatBlockContent {
  startBlockHtml: string;
  startBlockText: string;
  repeatBlockHtml: string;
  repeatBlockText: string;
  endBlockHtml: string;
  endBlockText: string;
}

export interface RepeatBlockRepeatData {
  tableName: string;
  collectionName?: string;
  fields?: string[];
  numberOfRows?: number;
  queryId?: string;
  queryAssetId?: AssetId;
  sortFields?: SortFields[];
}

export interface SortFields {
  fieldName: string;
  sortDirection: 'asc' | 'desc';
}

// eslint-disable-next-line no-shadow
export enum RepeatBlockStatus {
  DRAFT = 'DRAFT',
  APPROVED = 'APPROVED',
  DELETED = 'DELETED'
}

// eslint-disable-next-line no-shadow
export enum RepeatBlockTypes {
  EMAIL_BODY = 'EMAIL_BODY',
  HOSTED_PAGE = 'HOSTED_PAGE'
}

export class RepeatBlockUtils {
  static LabelMap: Map<RepeatBlockTypes, string> = new Map<RepeatBlockTypes, string>([
    [RepeatBlockTypes.EMAIL_BODY, 'Email Body'],
    [RepeatBlockTypes.HOSTED_PAGE, 'Hosted Page']
  ]);

  static IconMap: Map<RepeatBlockTypes, string> = new Map<RepeatBlockTypes, string>([
    [RepeatBlockTypes.EMAIL_BODY, 'message_email'],
    [RepeatBlockTypes.HOSTED_PAGE, 'webpage']
  ]);

  /**
   * Currently we're supporting EMAIL BODY for HTML,
   * could be enabled for more types, in that case add the desired types here*/
  static disableHtml(type: RepeatBlockTypes): boolean {
    return false;
  }

  static disableText(type: RepeatBlockTypes): boolean {
    return [RepeatBlockTypes.HOSTED_PAGE].indexOf(type) > -1;
  }
}
