<div *ngIf="asButton" class="asset-selector w-100-p" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
  <mat-icon class="lead-text-short" fxFlexAlign="center" matPrefix [svgIcon]="svgIcon"></mat-icon>
  <button mat-flat-button color="primary" (click)="selectAsset()" [disabled]="!!disabled">
    <span class="mat-button-wrapper">{{ buttonLabel }}</span>
  </button>
</div>
<div
  *ngIf="!asButton"
  class="asset-selector w-100-p"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="5px"
>
  <mat-icon class="lead-text-short" fxFlexAlign="center" matPrefix [svgIcon]="svgIcon"></mat-icon>
  <mat-form-field
    appearance="fill"
    (click)="selectAsset()"
    class="asset-field w-100-p"
    [ngClass]="{ 'cursor-pointer ': !disabled }"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <input
      *ngIf="!assetFormControl"
      class="full-width"
      [ngClass]="{ 'cursor-pointer ': !disabled }"
      type="text"
      matInput
      readonly="true"
      [disabled]="!!disabled"
      [value]="assetId?.name"
    />
    <input
      *ngIf="assetFormControl"
      class="full-width display-none"
      [ngClass]="{ 'cursor-pointer ': !disabled }"
      type="text"
      matInput
      readonly="true"
      [value]="assetId.name"
      [disabled]="!!disabled"
      ngDefaultControl
      [errorStateMatcher]="errorMatcher"
      [formControl]="assetFormControl"
    />
    <!--For some reason since A8/A9 migration value is not overriding what Input shows-->
    <!--Instead is showing the value from formControl that's an object-->
    <div *ngIf="assetFormControl">{{ assetId.name }}</div>
    <mat-icon *ngIf="!disabled" fxFlexAlign="center" matSuffix svgIcon="folder-browser"></mat-icon>
  </mat-form-field>
</div>
