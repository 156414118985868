import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ShortenPipe } from '@neptune/pipes/shorten/shorten.pipe';
import { DataTypeToStringPipe } from './data-type-to-string/data-type-to-string.pipe';
import { GetNodeNamePipe } from './getNodeName/get-node-name.pipe';
import { InsertedMailOrUserToName } from './mailOrUserToName/insertedMailOrUserToName.pipe';
import { UpdatedMailOrUserToName } from './mailOrUserToName/updatedMailOrUserToName.pipe';
import { MailToNamePipe } from './mailToName/mailToName.pipe';
import { ParseSizePipe } from './parseSize/parseSize';
import { SafePipe } from './safe/safe.pipe';
import { ToCapitalCasePipe } from '@fuse/pipes/toCapitalCase.pipe';
import { PropertyFilterPipe } from '@fuse/pipes/propertyFilter.pipe';
import { NumberToArrayPipe } from './number-to-array/number-to-array.pipe';
import { FirstLetterToCapitalCasePipe } from '@fuse/pipes/firstLetterToCap‎italCase.pipe';

@NgModule({
  declarations: [
    ShortenPipe,
    DataTypeToStringPipe,
    GetNodeNamePipe,
    InsertedMailOrUserToName,
    UpdatedMailOrUserToName,
    MailToNamePipe,
    ParseSizePipe,
    SafePipe,
    ToCapitalCasePipe,
    PropertyFilterPipe,
    NumberToArrayPipe,
    FirstLetterToCapitalCasePipe
  ],
  imports: [CommonModule],
  exports: [
    ShortenPipe,
    DataTypeToStringPipe,
    GetNodeNamePipe,
    InsertedMailOrUserToName,
    UpdatedMailOrUserToName,
    MailToNamePipe,
    ParseSizePipe,
    SafePipe,
    ToCapitalCasePipe,
    PropertyFilterPipe,
    NumberToArrayPipe,
    FirstLetterToCapitalCasePipe
  ]
})
export class NeptunePipesModule {}
