import {
  Component,
  ChangeDetectorRef,
  Inject,
  OnInit,
  AfterViewInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PurlListComponent } from '../purl-list/purl-list.component';
import { LoadingModalComponent } from '@neptune/components/loading-modal/loading-modal.component';
import { Purl, AssetSelectorDialogData } from '@neptune/models';
import { StandardListDialog } from '../standard-list-dialog/standard-list-dialog';

@Component({
  selector: 'app-purl-list-dialog',
  templateUrl: '../standard-list-dialog/standard-list-dialog.html',
  styleUrls: ['../standard-list-dialog/standard-list-dialog.scss']
})
export class PurlListDialogComponent
  extends StandardListDialog<
    Purl,
    // @ts-ignore
    PurlListComponent
  >
  implements OnInit, AfterViewInit {
  // Angular 8/9 Note: needs to be defined in the component not in the parent, otherwise it will be undefined.
  @ViewChild('loadingComponent', { read: LoadingModalComponent, static: false })
  loadingComponent: LoadingModalComponent;
  @ViewChild('containerBodytable', { read: ViewContainerRef, static: false })
  container: ViewContainerRef;

  public isSelectable = true;

  constructor(
    protected dialogRef: MatDialogRef<PurlListDialogComponent>,
    public dialog: MatDialog,
    protected changeDetector: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) protected data: AssetSelectorDialogData
  ) {
    super(dialogRef, dialog, changeDetector);
  }

  ngOnInit() {
    const title = 'Select PURL';
    super.init(title, null, 'SELECT', false);
  }

  ngAfterViewInit(): void {
    this.onInitViewContainers();
    setTimeout(() => {
      this.tableInstance.showActions = false;
    });
  }

  /**
   * @inheritdoc
   */
  protected initTableInstance() {
    super.initTableInstance();
    this.forceResize();
  }

  protected afterTablePopulated() {
    // filter list by filterProps
    this.tableInstance.list = this.tableInstance.list.filter(
      purl =>
        this.data.filterProps?.status?.includes(purl.status) && purl.baseTableName === this.data.filterProps.tableName
    );
    // call filter to refresh rows
    this.onFilter();
  }

  searchFilter(data: Purl, searchValue: string) {
    return !searchValue || data.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
  }

  /**
   * Work around for ngx-datatbale resize issue if data laoding is instantaneous
   */
  private forceResize() {
    this.dialogRef.afterOpened().subscribe(() => {
      // if load finishes before dialog finishes opening force a resize
      if (this.loadingComponent && !this.loadingComponent.loading) {
        window.dispatchEvent(new Event('resize'));
      }
    });
  }

  /**
   * @inheritdoc
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  onNameTableBodyDescription(): object {
    return PurlListComponent;
  }

  /**
   * @inheritdoc
   */
  onNewEntityFormDialog() {}
}
