import { Injectable } from '@angular/core';
import { JsepExpression } from '@neptune/models';
import jsep from 'jsep';

@Injectable({
  providedIn: 'root'
})
export class JsepService {
  private _jsep = jsep;

  constructor() {
    this._jsep.addBinaryOp('OR', 1);
    this._jsep.addBinaryOp('AND', 2);
    this._jsep.addBinaryOp('=', 6);
  }

  public jsep(expression: string | jsep.Expression): JsepExpression {
    return { ...this._jsep(expression), version: 2 };
  }
}
