import { DateTime } from './schedule';
import { CognitoUserAttribute, CognitoUserSession } from 'amazon-cognito-identity-js';
import { TimezoneUtils } from '@neptune/models/timezone';

// ///////////// USER ///////////////
export class User {
  /** Username */
  public username: string;
  public Attributes?: Attributes;
  /** User Status */
  public UserStatus: string;
  /** UserLastModifiedDate */
  public UserLastModifiedDate?: DateTime;
  /** UserCreateDate */
  public UserCreateDate?: DateTime;
  /** Enabled */
  public Enabled: boolean;
  /* Attributes */
  public Sub: string;
  public Email_Verified: boolean;
  public given_name: string;
  public family_name: string;
  public email: string;
  public avatar: string;
  public timezone: string;
  public org: string;
  public role?: Roles;
  public approval_status?: ApprovalStatus;
  public systemManagement: boolean;
  public originOrgId: string;
  public qp_prev_userId?: string;
  public password?: string;
}

export interface UserData {
  username: string;
  attributes: UserAttributes;
  org: UserOrg;
  systemManagement: boolean;
}

export interface UserDetails {
  username: string;
  attributes: UserAttributes;
  orgData: any;
  session: CognitoUserSession;
}

export interface UserAttributes {
  current_sign_in_at?: string;
  email?: string;
  email_verified?: string;
  family_name?: string;
  given_name?: string;
  last_sign_in_at?: string;
  name?: string;
  qp_orgId?: string;
  qp_role?: string;
  qp_timezone?: string;
  timezone?: string;
  sub?: string;
  systemManagement?: string;
  'custom:qp_approval_status'?: ApprovalStatus;
  username?: string;
  org?: string;
  role?: string;
  avatar_path?: string;
  avatar?: string;
  qp_prev_userId?: string;
}

// eslint-disable-next-line no-shadow
export enum ApprovalStatus {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  PENDING = 'PENDING'
}

// eslint-disable-next-line no-shadow
export enum UserStatus {
  FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
  CONFIRMED = 'CONFIRMED',
  UNCONFIRMED = 'UNCONFIRMED',
  EXTERNAL_PROVIDER = 'EXTERNAL_PROVIDER'
}

export class Attributes extends User {
  Sub: string;
  email_verified: boolean;
  given_name: string;
  family_name: string;
  email: string;
  qp_orgId: string;
  current_sign_in_at: string;
  last_sign_in_at: string;
  timezone: string;
  role: Roles;
  'custom:qp_approval_status'?: ApprovalStatus;
}

export interface UserOrg {
  orgId: string;
  userId?: string;
  role?: Roles;
  isBaseOrg?: string;
  insertedAt?: string;
  insertedBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  orgName?: string;
}

export interface CognitoUserAttributeType {
  attributes: CognitoUserAttribute[];
  session: CognitoUserSession;
}

/**
 * Model of User as defined by API
 */
export interface UserModel {
  /** Username */
  Username: string;
  Attributes: Attributes;
  /** User Status */
  UserStatus: string;
  /** UserLastModifiedDate */
  UserLastModifiedDate: string;
  /** UserCreateDate */
  UserCreateDate: string;
  /** Enabled */
  Enabled: boolean;
}

// eslint-disable-next-line no-shadow
export enum Roles {
  NONE = '',
  ADMIN = 'Admin',
  USER = 'User',
  SYSTEM = 'System',
  C3USER = 'C3user',
  SMSAPIUSER = 'SmsApiUser',
  UPLOADAPIUSER = 'UploadApiUser'
}

// eslint-disable-next-line no-shadow
export enum AllRoles {
  ALL = 'all'
}

export interface OrganizationUserAttribute {
  Name: string;
  Value: string;
}

export interface OrganizationChecked {
  checked?: boolean;
}

export interface OrganizationUser {
  Attributes: OrganizationUserAttribute[];
  Enabled: boolean;
  Name: string;
  Role: Roles;
  Timezone: string;
  UserCreateDate: string;
  UserLastModifiedDate: string;
  UserStatus: UserStatus;
  Username: string;
}

export class UserUtils {
  // ////////////// CONVERSION ////////////////

  /**
   * Converter for User model
   */
  public static convertUserModel(model: UserModel): User {
    const attributes = this.getAttributes(model);

    return <User>{
      username: model.Username,
      Email_Verified: this.searchAttribute(attributes, 'email_verified'),
      given_name: this.searchAttribute(attributes, 'given_name'),
      family_name: this.searchAttribute(attributes, 'family_name'),
      timezone: this.searchAttribute(attributes, 'custom:qp_timezone'),
      avatar: this.searchAttribute(attributes, 'avatar'),
      email: this.searchAttribute(attributes, 'email'),
      org: this.searchAttribute(attributes, 'qp_orgId'),
      role: this.searchAttribute(attributes, 'qp_role'),
      Sub: this.searchAttribute(attributes, 'sub'),
      systemManagement: this.searchAttribute(attributes, 'custom:systemManagement') === 'true',
      approval_status: this.searchAttribute(attributes, 'custom:qp_approval_status') || ApprovalStatus.APPROVED,
      Enabled: model.Enabled,
      UserStatus: model.UserStatus,
      originOrgId: this.searchAttribute(attributes, 'custom:qp_original_orgId'),
      UserCreateDate: TimezoneUtils.utcToDateTime(model.UserCreateDate),
      UserLastModifiedDate: TimezoneUtils.utcToDateTime(model.UserLastModifiedDate),
      qp_prev_userId: this.searchAttribute(attributes, 'custom:qp_prev_userId')
    };
  }

  public static searchAttribute(arr, s) {
    let i;
    let key;
    for (i = arr.length; i--; ) {
      for (key in arr[i]) {
        if (arr[i].hasOwnProperty(key) && arr[i][key].indexOf(s) > -1) {
          return arr[i].Value;
        }
      }
    }
  }

  private static getAttributes(model: UserModel): any {
    return model.Attributes || Object.entries(model).map(([Name, Value]) => new Object({ Name, Value }));
  }
}

// eslint-disable-next-line no-shadow
export enum USER_MESSAGES {
  NEW_USER_SUCCES_MESSAGE = 'User successfully created.',
  UPDATE_USER_SUCCES_MESSAGE = 'User successfully updated. ',
  EXIST_ACCOUNT_MESSAGE = 'An account with this email address already exists in',
  NEW_ACCOUNT_MESSAGE = 'An account with this email address has been added to',
  UNEXPECTED_ERROR = 'Unexpected error has occurred',
  ALREADY_BELONGS_ERROR = 'The User already belongs to another NXTDRIVE Organization. Please contact our NXTDRIVE support team'
}

export const USER_MESSAGE_DURATION = 8000;

export interface InitiateAuthData {
  password?: string;
  email: string;
  given_name?: string;
  family_name?: string;
  phone_number?: string;
  token?: string;
  session?: any;
}

export interface RespondAuthData {
  email: string;
  challengeName: string;
  session: '';
  responseValue: string | undefined;
}

export interface AuthAttributes {
  email: string;
  attributes: AuthAttribute[];
}

export interface AuthAttribute {
  name: string;
  value: string;
}
