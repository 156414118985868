import { Injectable, inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { AccountService } from '@neptune/services/account.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Roles } from '@neptune/models/user';

@Injectable({
  providedIn: 'root'
})
class AuthGuardFn {
  constructor(private router: Router, private accountService: AccountService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.accountService.isLoggedIn().pipe(
      map(result => {
        const orgData = this.accountService.getOrgData();

        if (result) {
          this.accountService.getCurrentUser().then(userData => {
            if (userData.attributes.qp_role === Roles.C3USER) {
              this.router.navigate(['/pages/sisense']);
            }
          });

          if (!orgData || !orgData.orgId) {
            this.router.navigate(['/pages/auth/orgSelect']);
          } else {
            this.accountService.startTimer();
            return true;
          }
        }
        this.router.navigate(['/login']);
        return false;
      }),
      catchError(val => {
        this.router.navigate(['/login']);
        return of(false);
      })
    );
  }
}

export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  inject(AuthGuardFn).canActivate(route, state);
