import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@neptune/material.module';
import { DatatypeIconModule } from '@neptune/components/datatype-icon/datatype-icon.module';
import { DropdownComponent } from './dropdown.component';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
  declarations: [DropdownComponent],
  imports: [CommonModule, FuseSharedModule, MaterialModule, DatatypeIconModule],
  exports: [DropdownComponent]
})
export class DropdownModule {}
