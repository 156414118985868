import { Component, OnInit, Inject, EventEmitter, ViewChild } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog
} from '@angular/material/legacy-dialog';
import { UserService } from '@neptune/services/user.service';
import { User } from '@neptune/models/user';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoadingModalComponent } from '@neptune/components/loading-modal/loading-modal.component';
import { ChangePasswordDialog } from '../change-password-dialog/change-password-dialog';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { Timezone, TimezoneUtils } from '@neptune/models/timezone';
import { SharedValidators } from 'app/shared/validators/shared-validators';

@Component({
  selector: 'user-profile-dialog',
  templateUrl: './user-profile-dialog.component.html',
  styleUrls: ['./user-profile-dialog.component.scss']
})
export class UserProfileDialogComponent implements OnInit {
  @ViewChild(LoadingModalComponent, { static: true }) loadingComponent: LoadingModalComponent;
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  public imageChangedEvent: any = '';
  public showCropper = false;
  public showImageCropper = false;

  public avatarPath = '';

  public lastLoginDate: string;

  public userProfileForm: UntypedFormGroup;

  public onUserDataChanged: EventEmitter<any> = new EventEmitter();

  public timezones: Timezone[] = TimezoneUtils.timezones();

  constructor(
    private userService: UserService,
    private dialogRef: MatDialogRef<UserProfileDialogComponent>,
    private matDialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public user: User
  ) {}

  ngOnInit() {
    this.userProfileForm = this.formBuilder.group({
      emailFormControl: [this.user.email || '', [Validators.required, SharedValidators.emailWithFullDomain]],
      firstNameFormControl: [this.user.given_name || '', Validators.required],
      lastNameFormControl: [this.user.family_name || '', Validators.required],
      roleFormControl: [{ value: this.user.role || '', disabled: true }, Validators.required],
      timezoneFormControl: [this.user.timezone || TimezoneUtils.defaultTimezone, Validators.required]
    });

    if (this.user['avatar']) {
      this.avatarPath = this.user['avatar'];
    }

    if (this.user['last_sign_in_at']) {
      this.lastLoginDate = this.user['last_sign_in_at'];
    }
  }

  onClose(confirm: boolean = false) {
    this.dialogRef.close(confirm);
  }

  onSave() {
    if (this.userProfileForm.invalid) {
      return false;
    }

    try {
      this.loadingComponent.startLoading();
      this.user.given_name = this.userProfileForm.controls.firstNameFormControl.value;
      this.user.family_name = this.userProfileForm.controls.lastNameFormControl.value;
      this.user.email = this.userProfileForm.controls.emailFormControl.value;
      const timezoneModified = this.user.timezone !== this.userProfileForm.controls.timezoneFormControl.value;
      this.user.timezone = this.userProfileForm.controls.timezoneFormControl.value;
      this.userService.updateUser(this.user).subscribe({
        next: user => {
          this.onUserDataChanged.emit(this.user);
          this.loadingComponent.showSuccess('User profile successfully saved');
          if (timezoneModified) {
            // refreshing full page so that all views get updated and use new timezone.
            window.location.reload();
          }
          this.dialogRef.close(user);
        },
        error: () => this.handleError.bind(this)
      });
    } catch (err) {
      this.handleError(err);
    }
  }

  private handleError(err) {
    const message = err.error && err.error.message ? err.error.message : err.message;
    this.loadingComponent.showError(message);
  }

  onChangePassword() {
    this.matDialog.open(ChangePasswordDialog, {
      data: this.user
    });
  }

  onImageCropped(event: ImageCroppedEvent) {
    this.avatarPath = event.base64 as string;
  }

  onLoadImageFailed() {
    this.handleError({ message: 'Load Image Failed' });
  }

  onImageLoaded() {
    this.showCropper = true;
  }

  onAvatarUpload(event) {
    this.showImageCropper = true;
    this.imageChangedEvent = event;
  }

  onSaveAvatar(imageResult) {
    try {
      this.loadingComponent.startLoading();
      this.userService.updateUserAvatar(this.avatarPath, 'png', this.user.email).subscribe({
        next: imageData => {
          this.user['avatar'] = imageData['image'];

          if (this.user['avatar'] && this.user['avatar'].length > 0) {
            this.avatarPath = this.user['avatar'];
          }

          this.loadingComponent.stopLoading();
          this.showImageCropper = false;
          this.onUserDataChanged.emit(this.user);
        },
        error: err => {
          this.handleError(err);
          this.showImageCropper = false;
        }
      });
    } catch (err) {
      this.handleError(err);
    }
  }
}
