import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTreeModule } from '@angular/material/tree';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { FuseSharedModule } from '@fuse/shared.module';

import { MaterialModule } from '@neptune/material.module';
import { StandardUserDateModule } from '@neptune/components/standard-list-page/standard-user-date/standard-user-date.module';
import { StandardSearchModule } from '@neptune/components/standard-search/standard-search.module';
import { PurlListDialogComponent } from './purl-list-dialog.component';
import { LoadingModalModule } from '../loading-modal/loading-modal.module';
import { NeptunePipesModule } from '@neptune/pipes/pipes.module';
import { PurlListModule } from '../purl-list/purl-list.module';

@NgModule({
  declarations: [PurlListDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FuseSharedModule,
    MatTreeModule,
    LoadingModalModule,
    NgxDatatableModule,
    StandardUserDateModule,
    NeptunePipesModule,
    PurlListModule,
    StandardSearchModule
  ],
  exports: [PurlListDialogComponent],
  providers: []
})
export class PurlListDialogModule {}
