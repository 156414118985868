<ace-editor
  #container
  [(text)]="value"
  [mode]="mode"
  #aceEditor
  [theme]="theme"
  [options]="options"
  [readOnly]="readOnly"
  [autoUpdateContent]="autoUpdateContent"
  [durationBeforeCallback]="durationBeforeCallback"
  class="ace-editor-wrapper {{ classes }}"
  (textChange)="onTextChange($event)"
  (textChanged)="onTextChanged($event)"
></ace-editor>
