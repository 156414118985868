<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
  <!-- Left side -->
  <div fxLayoutAlign="start center" fxFlex="50" fxLayout="row" fxLayoutGap="30px">
    <div>
      <h4 class="standard-over-title" *ngIf="topTitle">{{ topTitle }}</h4>
      <h1 *ngIf="!titleIcon" class="standard-title">{{ title }}</h1>
      <button
        class="standard-title h1"
        (click)="onTitleIconClicked()"
        *ngIf="titleIcon"
        mat-button
        matSuffix
        mat-icon-button
      >
        {{ title }}
        <mat-icon matSuffix [svgIcon]="titleIcon"></mat-icon>
      </button>
    </div>
    <mat-form-field fxFlex="30" appearance="fill" *ngIf="showDropdownFilter" class="dropdown-filter">
      <mat-icon *ngIf="dropdown.dropdownFilterIcon" [svgIcon]="dropdown.dropdownFilterIcon"></mat-icon>

      <mat-select
        [placeholder]="dropdown.dropdownFilterPlaceholder"
        (selectionChange)="onDropdownFilterSelected($event.value)"
        [(ngModel)]="dropdownFilterValue"
      >
        <mat-option>All</mat-option>
        <mat-option *ngFor="let elem of dropdown.dropdownFilterElements" [value]="title === 'Imports' ? elem.id : elem.name">
          {{ elem.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex="30" appearance="fill" *ngIf="showPageSizeFilter" class="page-size-filter">
      <mat-label>{{ pageSize.label }}</mat-label>
      <mat-select
        (selectionChange)="onPageSizeFilterSelected($event.value)"
        [(ngModel)]="pageSizeFilterValue"
      >
        <mat-option *ngFor="let value of pageSize.values" [value]="value">
          {{ value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-slide-toggle
      *ngIf="showToggle"
      labelPosition="after"
      [(ngModel)]="toggleFilterStatus"
      (change)="onToggleFilterChange()"
    >
      {{ toggleFilterDescription }}
    </mat-slide-toggle>
  </div>
  <!--  End Left side -->

  <!-- Right side -->
  <div fxFlex="50" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2px">
    <!-- Search -->
    <standard-search-component
      #standardSearch
      *ngIf="showSearch"
      fxFlex="50"
      [search]="searchConfig.searchValue"
      [placeholder]="searchConfig.searchPlaceholder"
      (onSearch)="onSearch($event.search)"
    ></standard-search-component>
    <!-- End search -->

    <button-group [buttonsConfig]="buttons" fxLayoutGap="8px"></button-group>
  </div>
  <!-- End Right side -->
</div>
