import {
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { dialogContainerClassSafeCheck } from '@neptune/utilities/dialog-container-safecheck';
import { AssetSelectorService } from '../asset-selector-dialog/asset-selector.service';
import { FolderSelectorDialogData } from 'app/main/content/pages/data/dataload/table-export/directory-select/directory-select.component';
import { cloneDeep } from 'lodash';

/** Flat node with expandable and level information */
export interface FolderNode {
  expandable: boolean;
  name: string;
  level: number;
  parent?: FolderNode;
  id?: number;
  children?: FolderNode[];
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'folder-selector-dialog',
  templateUrl: './folder-selector-dialog.component.html',
  styleUrls: ['./folder-selector-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class FolderSelectorDialogComponent implements OnInit {
  public title: string = 'Select Folder';
  public selectedFolder: FolderNode;
  public loading: boolean = true;
  public disabledSelect: boolean = false;

  public treeControl = new FlatTreeControl<FolderNode>(
    node => node.level,
    node => node.expandable
  );

  public treeFlattener: MatTreeFlattener<FolderNode, object>;
  public dataSource: MatTreeFlatDataSource<FolderNode, object>;

  // eslint-disable-next-line arrow-body-style
  private _transformer = (node: FolderNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level,
      parent: node.parent,
      id: node.id,
      children: node.children
    };
    // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
  };

  public hasChild = (_: number, node: FolderNode) => node.expandable;

  constructor(
    public dialogRef: MatDialogRef<FolderSelectorDialogComponent>,
    public assetService: AssetSelectorService,
    protected changeDetector: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: FolderSelectorDialogData
  ) {
    // Ensure the proper panelClass has been added to the configuration object
    dialogContainerClassSafeCheck('FolderSelectorDialog', 'folder-selector-dialog-container', dialogRef);
  }

  ngOnInit() {
    // @ts-ignore
    this.treeFlattener = new MatTreeFlattener(
      this._transformer,
      node => node.level,
      node => node.expandable,
      node => node.children
    ) as MatTreeFlattener<FolderNode, object>;
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl as FlatTreeControl<object, object>,
      this.treeFlattener
    );

    this.loading = false;
    this.title = this.data.title;
    this.dataSource.data = this.data.folders;
    const selectedNode = this.data.selected
      ? this.data.selected
      : this.data.folderSelectedName
      ? this.findNodeByFolderName(
          this.treeControl.dataNodes[0],
          this.data.folderSelectedName.slice(0, -1).split('/').pop() as string
        )
      : this.treeControl.dataNodes[0];
    this.toggleSelect(selectedNode as FolderNode);
    this.treeControl.expandAll();
  }

  public onClose(data?: FolderNode) {
    this.dialogRef.close(data);
  }

  public onSelect() {
    this.onClose(this.selectedFolder);
  }

  public isFolderSelected(node: FolderNode): boolean {
    return this.selectedFolder && this.selectedFolder.name === node.name;
  }

  public toggleSelect(node: FolderNode) {
    this.selectedFolder = node;
  }

  public findNodeByFolderName(tree: FolderNode, folderName: string): FolderNode | void {
    if (tree.name === folderName) {
      return tree;
    }
    if (tree.children) {
      for (const child of tree.children) {
        const res = this.findNodeByFolderName(child, folderName);
        if (res) {
          return res;
        }
      }
    }
  }

  recursiveNodeEliminator(tree: Array<FolderNode>, filterText: string): boolean {
    for (let index = tree.length - 1; index >= 0; index--) {
      const node = tree[index];
      if (node.children && node.children.length) {
        if (this.recursiveNodeEliminator(node.children, filterText)) {
          this.filterNodeTree(tree, node, index, filterText);
        }
      } else {
        // Its a leaf node. No more branches.
        this.filterNodeTree(tree, node, index, filterText);
      }
    }
    return tree.length === 0;
  }

  filterChanged(filterText: string) {
    const clonedTreeLocal = cloneDeep(this.data.folders);
    this.recursiveNodeEliminator(clonedTreeLocal, filterText);
    this.dataSource.data = clonedTreeLocal;
    this.disabledSelect = this.dataSource.data.length === 0;
    this.treeControl.expandAll();
    this.changeDetector.detectChanges();
  }

  filterNodeTree(tree: FolderNode[], node: FolderNode, index: number, filterText: string) {
    if (!node.name.toLocaleLowerCase().startsWith(filterText.toLocaleLowerCase())) {
      tree.splice(index, 1);
    }
  }
}
