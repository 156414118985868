import { NgModule } from '@angular/core';
import { StandardSearchComponent } from './standard-search.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
  declarations: [StandardSearchComponent],
  imports: [FuseSharedModule, MatButtonModule, MatIconModule, MatFormFieldModule, MatInputModule],
  exports: [StandardSearchComponent]
})
export class StandardSearchModule {}
