import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Breadcrumb } from './breadcrumbs.model';
import { QueryResolverService } from './resolvers/query-resolver.service';
import { TableResolverService } from './resolvers/table-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  // Subject emitting the breadcrumb hierarchy
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

  // Observable exposing the breadcrumb hierarchy
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  constructor(
    private router: Router,
    private queryResolverService: QueryResolverService,
    private tableResolverService: TableResolverService
  ) {
    this.router.events
      .pipe(
        // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(event => {
        // Construct the breadcrumb hierarchy
        const root = this.router.routerState.snapshot.root;
        const breadcrumbs: Breadcrumb[] = [];
        this.addBreadcrumb(root, [], breadcrumbs);
        // Emit the new hierarchy
        this._breadcrumbs$.next(breadcrumbs);
      });
  }

  private addBreadcrumb(route: ActivatedRouteSnapshot | null, parentUrl: string[], breadcrumbs: Breadcrumb[]) {
    if (route) {
      // Construct the route URL
      const routeUrl = parentUrl.concat(route.url.map(url => url.path));
      // Add an element for the current route part
      if (route.data.breadcrumb) {
        const labelArray: string[] = this.getLabel(route.data);
        if (labelArray.length > 1) {
          // Double Label Array
          if (routeUrl[2] === 'projects') {
            this.doubleLabelFolder(labelArray, routeUrl, breadcrumbs);
          }
          if (routeUrl[2] === 'add-files') {
            this.doubleLabelFile(labelArray, routeUrl, breadcrumbs);
          }
          if (routeUrl[2] === 'analysis') {
            this.getAnalysis(routeUrl, breadcrumbs);
          }
          if (routeUrl[1] === 'lookup') {
            this.getLookup(route, routeUrl, breadcrumbs);
          }
          if (routeUrl[2] === 'data-view') {
            this.getDataView(route, routeUrl, breadcrumbs);
          }
          if (routeUrl[1] === 'import') {
            this.getImport(route, routeUrl, breadcrumbs);
          }
        } else {
          // Single Label Array
          if (Object.entries(route.queryParams).length !== 0) {
            const label = this.getLabel(route.data);

            if (label && label[0] === 'Query') {
              const queryParams = route.queryParams;
              this.queryResolverService.queryManager(queryParams, breadcrumbs);
            }
            if (label && label[0] === 'Exports') {
              this.getExportTable(route, routeUrl, breadcrumbs);
            }
            if (label && label[0] === 'Imports') {
              this.getExportTable(route, routeUrl, breadcrumbs);
            }
            if (label && label[0] === 'SchemaDefinitionTable') {
              this.getSchemaDefinitionTable(route, routeUrl, breadcrumbs);
            }
            if (label && label[0] === 'Contact Profile Lookup') {
              this.getContactLookup(route, routeUrl, breadcrumbs);
            }
            if (label && label[0] === 'Search Results') {
              this.getSearchResult(route, routeUrl, breadcrumbs);
            }
          } else {
            this.singleLabel(route, routeUrl, breadcrumbs);
          }
        }
      }
      // Add another element for the next route part
      this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
    }
  }

  private getLabel(data: Data) {
    // The breadcrumb can be defined as a static string or as a function to construct the breadcrumb element out of the route data
    return typeof data.breadcrumb === 'function' ? data.breadcrumb(data) : data.breadcrumb;
  }

  private singleLabel(route: ActivatedRouteSnapshot, routeUrl: string[], breadcrumbs: Breadcrumb[]) {
    const breadcrumb = {
      label: this.getLabel(route.data),
      url: '/' + routeUrl.join('/')
    };
    breadcrumbs.push(breadcrumb);
  }

  private doubleLabelFolder(labelArray: string[], routeUrl: string[], breadcrumbs: Breadcrumb[]) {
    const poppedUrl = routeUrl.pop();
    // Project Breadcrumb
    const breadcrumb = {
      label: labelArray[0],
      url: '/' + routeUrl.join('/')
    };
    breadcrumbs.push(breadcrumb);
    this.queryResolverService.getFolder(routeUrl[3], poppedUrl as string, breadcrumbs, 3);
  }

  private doubleLabelFile(labelArray: string[], routeUrl: string[], breadcrumbs: Breadcrumb[]) {
    const breadcrumb1 = {
      label: labelArray[0],
      url: 'pages/user/my-files'
    };
    breadcrumbs.push(breadcrumb1);

    const breadcrumb2 = {
      label: labelArray[1],
      url: '/' + routeUrl.join('/')
    };
    breadcrumbs.push(breadcrumb2);
  }

  private getExportTable(route: ActivatedRouteSnapshot, routeUrl: string[], breadcrumbs: Breadcrumb[]) {
    const breadcrumb = {
      label: this.getLabel(route.data),
      url: '/' + routeUrl.join('/')
    };
    breadcrumbs.push(breadcrumb);
    const breadcrumb1 = {
      label: 'Database',
      url: '/pages/database'
    };
    breadcrumbs.push(breadcrumb1);
    const queryParams = route.queryParams;
    const breadcrumb2 = {
      label: 'TABLE ' + queryParams.table,
      url: ''
    };
    breadcrumbs.push(breadcrumb2);
  }

  private getSchemaDefinitionTable(route: ActivatedRouteSnapshot, routeUrl: string[], breadcrumbs: Breadcrumb[]) {
    const breadcrumb = {
      label: 'Database',
      url: '/pages/database'
    };
    breadcrumbs.push(breadcrumb);
    const queryParams = route.queryParams;
    const tableId = routeUrl[routeUrl.length - 1];
    const text = 'Schema Definition ' + queryParams.action.toUpperCase();
    this.tableResolverService.getTableName(tableId, breadcrumbs, text);
  }

  private getContactLookup(route: ActivatedRouteSnapshot, routeUrl: string[], breadcrumbs: Breadcrumb[]) {
    const breadcrumb = {
      label: this.getLabel(route.data),
      url: '/' + routeUrl.join('/')
    };
    breadcrumbs.push(breadcrumb);
    const breadcrumb1 = {
      label: 'Database',
      url: '/pages/database'
    };
    breadcrumbs.push(breadcrumb1);
    const tableId = route.queryParams.tableId;
    const text = 'Record Lookup';
    this.tableResolverService.getTableName(tableId, breadcrumbs, text);
  }

  private getAnalysis(routeUrl: string[], breadcrumbs: Breadcrumb[]) {
    const breadcrumb = {
      label: 'Database',
      url: '/pages/database'
    };
    breadcrumbs.push(breadcrumb);
    const tableId = routeUrl[3];
    const text = 'Analysis';
    this.tableResolverService.getTableName(tableId, breadcrumbs, text);
  }

  private getLookup(route: ActivatedRouteSnapshot, routeUrl: string[], breadcrumbs: Breadcrumb[]) {
    const breadcrumb = {
      label: this.getLabel(route.data)[0],
      url: '/pages/lookup'
    };
    breadcrumbs.push(breadcrumb);
    const breadcrumb1 = {
      label: this.getLabel(route.data)[1],
      url: ''
    };
    breadcrumbs.push(breadcrumb1);
  }

  private getDataView(route: ActivatedRouteSnapshot, routeUrl: string[], breadcrumbs: Breadcrumb[]) {
    const breadcrumb = {
      label: 'Database',
      url: '/pages/database'
    };
    breadcrumbs.push(breadcrumb);
    const queryParams = route.queryParams;
    const breadcrumb1 = {
      label: 'Data View ' + queryParams.action.toUpperCase(),
      url: ''
    };
    breadcrumbs.push(breadcrumb1);
  }

  private getImport(route: ActivatedRouteSnapshot, routeUrl: string[], breadcrumbs: Breadcrumb[]) {
    const breadcrumb = {
      label: 'Imports',
      url: '/pages/import'
    };
    breadcrumbs.push(breadcrumb);
    const breadcrumb1 = {
      label: routeUrl[2].charAt(0).toUpperCase() + routeUrl[2].slice(1),
      url: '/pages/import/new'
    };
    breadcrumbs.push(breadcrumb1);
  }

  private getSearchResult(route: ActivatedRouteSnapshot, routeUrl: string[], breadcrumbs: Breadcrumb[]) {
    const breadcrumb = {
      label: 'Search Results',
      url: ''
    };
    breadcrumbs.push(breadcrumb);
  }
}
