import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'long-text',
  templateUrl: './long-text.component.html',
  styleUrls: ['./long-text.component.scss']
})
export class LongTextComponent {
  @ViewChild('longTextWrapper', { static: true }) private longTextWrapper: ElementRef;

  public overflow: boolean = false;
  public _value: string;

  @Input('value') set value(value: string) {
    this._value = value;
    this.changeDetectorRef.detectChanges();
    this._calculateOverflow();

    // Sometimes value is set before rendering. (if it's inside ngx-datatable)
    setTimeout(this._calculateOverflow.bind(this), 100);
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  private _calculateOverflow() {
    this.overflow =
      this.longTextWrapper.nativeElement.offsetWidth < this.longTextWrapper.nativeElement.firstElementChild.scrollWidth;
  }
}
