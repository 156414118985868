/* eslint-disable @typescript-eslint/ban-types */
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CanvasPositionData,
  CanvasPositionResponse,
  JoinData,
  NameId,
  SchemaObject,
  TableData,
  TableDataSortOrderType,
  TableDataType,
  TableField,
  TableFieldMetrics,
  TableFieldTrend,
  TableIndexStatus,
  TableRowData,
  TablesJoins,
  Transformation
} from '@neptune/models';
import { TableActionsRowLogError, TableActionsRow, TableActionsRuninfo, TableCount } from '@neptune/models/table';
import { RequestQueueUtil } from '@neptune/utilities';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { BaseService, Endpoint } from './base.service';

export interface TablesObject {
  Tables: TableData[];
}

export interface JoinsObject {
  message: JoinData[];
}

interface GetTablesParams {
  type?: TableDataType;
  childOrgId?: string;
  light?: boolean;
  sortBy?: string;
  sortOrder?: TableDataSortOrderType;
  orgId?: string;
  fast?: boolean;
}

interface GetCheckTableNameParams {
  ts: number;
  childOrgId?: string;
}

interface getTableActionsHistory {
  sortBy: string;
  orderBy: string;
  lastKey?: string;
}

@Injectable()
export class TableService extends BaseService {
  private queue: RequestQueueUtil = new RequestQueueUtil();
  public canvasTimestamp: string;

  /**
   * For use with jasmine testing, returns a mock of service
   */
  public static mockService(): object {
    return {
      getTableList: jest.fn(() => of({ Tables: [] })),
      getTablePreview: jest.fn(() => of([])),
      getTableJoins: jest.fn(() => of([])),
      getTableNameIds: jest.fn(() => of([])),
      getTable: jest.fn(() => of([])),
      getExistingJoinList: jest.fn(() => of([])),
      addJoins: jest.fn(() => of([])),
      removeJoins: jest.fn(() => of([])),
      updateJoins: jest.fn(() => of([])),
      updateCanvasPosition: jest.fn(() => of([])),
      getCanvasPosition: jest.fn(() => of([])),
      checkTableName: jest.fn(() => of([])),
      createTable: jest.fn(() => of([])),
      modifyTable: jest.fn(() => of([])),
      deleteTable: jest.fn(() => of([])),
      undoLastImport: jest.fn(() => of([])),
      clearAllRecords: jest.fn(() => of([])),
      saveTransform: jest.fn(() => of([])),
      getTableActions: jest.fn(() => of([])),
      getTableActionsHistory: jest.fn(() => of([]))
    };
  }

  /**
   * Retrieve stored data related to tablle creation
   */

  getTablePreview(tableName): Observable<TablesObject> {
    return super.baseGet<TablesObject>(Endpoint.TABLE, `${tableName}/preview`);
  }

  renameTable(tableId: string, newTableObject: TableData, timestamp): Subject<any> {
    return this.updateTable(tableId, newTableObject, timestamp);
  }

  updateTable(tableId: string, newTableObject: TableData, timestamp?): Subject<any> {
    if (!timestamp) {
      timestamp = new Date().getTime();
    }
    const req = super
      .basePut<TableData, any>(Endpoint.TABLE, `tables/${tableId}`, newTableObject, super.timestampToHeader(timestamp))
      .pipe(
        tap(() => {
          super.clearCacheFor(`TABLE_JOINS_${tableId.toUpperCase()}`);
          super.clearCacheFor(`TABLE_NAME_${tableId.toUpperCase()}`);
        })
      );

    return this.queue.addRequestToQueue(req, true);
  }

  getFirstPartyTablePreview(tableName, connectorid?, childOrgId?: string | null): Observable<[string[]]> {
    if (connectorid) {
      return super.baseGet<[string[]]>(
        Endpoint.CONNECTOR,
        `connectors/${connectorid}/0/objects/${tableName}/preview`,
        false,
        childOrgId ? { childOrgId } : undefined
      );
    } else {
      return super.baseGet<[string[]]>(Endpoint.TABLE, `${tableName}/preview`);
    }
  }

  getTableList(
    type?: TableDataType | null,
    fromCache: null | boolean = false,
    childOrgId?: string | null,
    light?: boolean | null,
    sortBy?: string | null,
    sortOrder?: TableDataSortOrderType,
    orgId?: string | null,
    fast?: boolean | null
  ): Observable<TablesObject> {
    let params: GetTablesParams | undefined = type ? { type } : undefined;
    params = childOrgId ? { ...params, childOrgId } : params;
    params = light ? { ...params, light } : params;
    params = sortBy ? { ...params, sortBy } : params;
    params = sortOrder ? { ...params, sortOrder } : params;
    params = fast ? { ...params, fast } : params;
    params = orgId ? { ...params, orgId } : params;

    return super
      .baseGetWithCache<TablesObject>('TABLE_STORE', fromCache as boolean, Endpoint.TABLE, `tables`, false, params)
      .pipe(
        map(tables => {
          tables.Tables = tables.Tables.filter(table => table.name);
          return tables;
        }),
        map((result: TablesObject) => {
          result.Tables = result.Tables.sort((a, b) =>
            a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1
          );
          return result;
        })
      );
  }

  /**
   * Get Tables as a list of NameId objects
   */
  getTableNameIds(
    fromCache: boolean = false,
    filterStatus: TableIndexStatus = TableIndexStatus.COMPLETED
  ): Observable<NameId[]> {
    return this.getTableList(null, fromCache).pipe(
      map(tables => {
        tables.Tables = tables.Tables.filter(table => {
          if (table.name) {
            if (filterStatus) {
              return table.TableIndexStatus === filterStatus;
            }
            return true;
          }
          return false;
        });
        return tables.Tables.map(x => ({ id: x.Id, name: x.name }));
      })
    );
  }

  getTable(tableId: string): Observable<TableData> {
    const url = `tables/${tableId}`;
    return super.baseGet<TableData>(Endpoint.TABLE, url, false, {
      ts: Date.now()
    });
  }

  getTableWithTransforms(tableId: string): Observable<TableData> {
    const url = `tables/${tableId}`;
    return super.baseGet<TableData>(Endpoint.NXTDRIVE, url, false, {
      getTransforms: true
    });
  }

  getTableRowsStatistics(
    tableId: string,
    offset: number = 1,
    limit: number = 5,
    sort_field: string = '',
    order: string = '',
    include_count: boolean = true
  ): Observable<TableRowData> {
    const url = `tables/statistics/${tableId}/rows`;
    return super.baseGet<TableRowData>(Endpoint.TABLE, url, false, {
      offset,
      limit,
      sort_field,
      order,
      include_count
    });
  }

  getTableFieldsStatistics(tableId): Observable<TableField[]> {
    const url = `tables/statistics/${tableId}/fields`;
    return super.baseGet<TableField[]>(Endpoint.TABLE, url);
  }

  getTableFieldsUniqueCountStatistics(tableId): Observable<{ field: number }> {
    const url = `tables/statistics/${tableId}/fields/unique_count`;
    return super.baseGet<{ field: number }>(Endpoint.TABLE, url);
  }

  getTableFieldMetrics(tableId: string, fieldId: string): Observable<TableFieldMetrics> {
    const url = `tables/statistics/${tableId}/fields/${fieldId}/metrics`;
    return super.baseGet<TableFieldMetrics>(Endpoint.TABLE, url);
  }

  getTableFieldTrends(tableId: string, fieldId: string): Observable<TableFieldTrend[]> {
    const url = `tables/statistics/${tableId}/fields/${fieldId}/trends`;
    return super.baseGet<TableFieldTrend[]>(Endpoint.TABLE, url);
  }

  getTotalUniverse(tableId): Observable<TableCount> {
    return super.baseGet<TableCount>(Endpoint.TABLE, `tables/${tableId}/count`);
  }

  // Verify if a table name is available for creating a new table or renaming it
  verifyTableNameAvailability(tableName: string): Observable<string> {
    return super.baseGet(Endpoint.NXTDRIVE, `tables/${tableName}/validate`);
  }

  getTableJoins(
    tableId: string,
    includeJoins: boolean = true,
    fromCache: boolean = false,
    cacheExpirationTime?: number,
    all?: boolean
  ): Observable<TablesJoins> {
    const params: { [key: string]: unknown } = !includeJoins ? {} : { joining_keys: true };
    if (all) {
      params.all = all;
    }
    return super
      .baseGetWithCache(
        `TABLE_JOINS_${tableId.toUpperCase()}`,
        fromCache,
        Endpoint.JOIN,
        `joins/${tableId}`,
        false,
        params,
        cacheExpirationTime
      )
      .pipe(
        concatMap((joins: any) => {
          if (typeof joins.message === 'string') {
            return of(joins);
          }

          const obs = [] as Observable<any>[];
          joins.message.map(tableJoinsData => {
            Object.keys(tableJoinsData).map(tableName => {
              tableJoinsData[tableName].map(oneTableJoinData => {
                if (!tableJoinsData[tableName][0].baseTable) {
                  obs.push(
                    this.getTableByName(oneTableJoinData.fromTableName).pipe(
                      tap(table => (tableJoinsData[tableName][0].baseTable = table.Id))
                    )
                  );
                }
              });
            });
          });

          if (obs.length === 0) {
            return of(joins);
          }

          return forkJoin(obs).pipe(map(() => joins));
        })
      );
  }

  getExistingJoinList(fromCache: boolean = false): Observable<JoinsObject> {
    return super.baseGetWithCache<JoinsObject>('JOINS_GET', fromCache, Endpoint.JOIN, `joins`);
  }

  addJoins(join: JoinData, timestamp: string): Subject<object> {
    const req = super.basePost<JoinData, any>(Endpoint.JOIN, `joins`, join, super.timestampToHeader(timestamp));
    return this.queue.addRequestToQueue(req, true);
  }

  removeJoins(join: JoinData, timestamp: string): Subject<object> {
    const req = super.baseDelete<any>(
      Endpoint.JOIN,
      `joins/${join.fromTable}/${join.toTable}`,
      {},
      super.timestampToHeader(timestamp)
    );
    return this.queue.addRequestToQueue(req, true);
  }

  updateJoins(join: JoinData, timestamp: string): Subject<object> {
    const req = super.basePut<JoinData, any>(
      Endpoint.JOIN,
      `joins/${join.fromTable}/${join.toTable}`,
      join,
      super.timestampToHeader(timestamp)
    );
    return this.queue.addRequestToQueue(req, true);
  }

  updateCanvasPosition(tablePositions: CanvasPositionData, timestamp): Subject<HttpResponse<CanvasPositionData>> {
    const req = super
      .basePutWithHeaders<CanvasPositionData, any>(
        Endpoint.CANVAS,
        'canvas-positions',
        tablePositions,
        super.timestampToHeader(timestamp)
      )
      .pipe(
        map(res => {
          this.canvasTimestamp = res.headers.get('last-modified') as string;
          return res.body.PositionInfo;
        })
      );
    return this.queue.addRequestToQueue(req, true);
  }

  getCanvasPosition(): Observable<CanvasPositionData> {
    // Added TS to the request since I need the latest version for the header response.
    return super
      .baseGetWithHeaders<CanvasPositionResponse>(Endpoint.CANVAS, 'canvas-positions', false, {
        ts: Date.now()
      })
      .pipe(
        map((res: HttpResponse<CanvasPositionResponse>) => {
          this.canvasTimestamp = res.headers.get('last-modified') as string;
          return (res.body as CanvasPositionResponse).PositionInfo;
        })
      );
  }

  getTableByName(
    tableName: string,
    fromCache: boolean = false,
    cacheExpirationTime?: number
  ): Observable<SchemaObject> {
    return super.baseGetWithCache<SchemaObject>(
      `TABLE_NAME_${tableName.toUpperCase()}`,
      fromCache,
      Endpoint.TABLE,
      `tables/name/${tableName}`,
      false,
      null,
      cacheExpirationTime
    );
  }

  checkTableName(
    tableName: string,
    fromCache: boolean = false,
    cacheExpirationTime?: number | null,
    childOrgId?: string | null
  ): Observable<SchemaObject> {
    let params: GetCheckTableNameParams = { ts: Date.now() };
    params = childOrgId ? { ...params, childOrgId } : params;
    return super.baseGetWithCache<SchemaObject>(
      `TABLE_NAME_${tableName.toUpperCase()}`,
      fromCache,
      Endpoint.TABLE,
      `tables/${tableName}`,
      false,
      params,
      cacheExpirationTime as number
    );
  }

  createTable(schema: SchemaObject): Observable<SchemaObject> {
    return super.basePost<SchemaObject, SchemaObject>(Endpoint.TABLE, `tables`, schema);
  }

  modifyTable(schema: SchemaObject, tableName: string, timestamp: string): Subject<object> {
    const req = super.basePut<SchemaObject, any>(
      Endpoint.TABLE,
      `tables/${tableName}`,
      schema,
      super.timestampToHeader(timestamp)
    );
    return this.queue.addRequestToQueue(req, true);
  }

  deleteTable(tableName: string, tableTS: string): Subject<object> {
    const params: object = {
      tsCanvas: super.formatTimestamp(this.canvasTimestamp)
    };
    const req = super.baseDelete<any>(Endpoint.TABLE, `tables/${tableName}`, params, super.timestampToHeader(tableTS));
    return this.queue.addRequestToQueue(req, true);
  }

  undoLastImport(tableName: string): Observable<object> {
    return super.basePost(Endpoint.TABLE, `tables/${tableName}/undo_last_import`, {});
  }

  clearAllRecords(tableName: string): Observable<object> {
    return super.basePost(Endpoint.TABLE, `tables/${tableName}/clear_all_records`, {});
  }

  addToReporting(tableName: string): Observable<object> {
    return this.basePost(Endpoint.TABLE, `tables/${tableName}/send_to_reporting`, {});
  }

  saveTransform(tableId: string, transformations: Transformation[]): Observable<{ transforms: Transformation[] }> {
    const url = `tables/${tableId}/transforms`;
    return super.basePost(Endpoint.NXTDRIVE, url, { transformUpdates: transformations });
  }

  getTableActionsHistory(
    tableId: string,
    sortBy: string,
    orderBy: string,
    lastKey?: string
  ): Observable<{ group: TableActionsRow[]; lastKeyAsGUID: string; errors: TableActionsRowLogError[] }> {
    let params: getTableActionsHistory = { sortBy, orderBy };
    params = lastKey ? { ...params, lastKey } : params;
    return super.baseGet(Endpoint.NXTDRIVE, `table-actions-history/display/${tableId}`, false, params);
  }

  getDataPipelineForTableAction(id: string, runId: string): Observable<TableActionsRuninfo> {
    return super.baseGet(Endpoint.PIPELINE, `DataPipelines/${id}/runs/${runId}`);
  }
}
