import { ConnectorId } from './connector';

// eslint-disable-next-line no-shadow
export enum UploadStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  DELETED = 'DELETED',
  FAILED = 'FAILED',
  ERROR = 'ERROR'
}

export class MyFile {
  fileId: string | null;
  fileName: string;
  name?: string;
  size?: number;
  stats?: {
    recordsCount: number;
    size: number;
    lastProcessedDate: string;
  };
  insertedAt?: string;
  updatedAt: string | null;
  uploadedAt?: string;
  source?: UploadType;
  status?: UploadStatus;
  field?: string;
  expiredAt?: number;
  userId?: string;
}

export class MyFileUpload {
  fileName: string;
  signedUrl?: string;
  fileId?: string;
  status?: UploadStatus;
}

export interface MyFilesGetFilesResponse {
  directories: any[];
  files: MyFile[];
  continuationToken?: string;
}

export interface MyFilesGetCrawlerStatusResponse {
  status: CrawlerStatus;
  crawlerLogs: CrawlerLog[];
}

export interface MyFilesGetFilePreviewRowResponse {
  [key: string]: string;
}

export interface GetFilesParams {
  maxKeys: number;
  continuationToken: string;
  prefix: string;
}

export interface ConnectorFiles {
  directories: Directory[];
  files: MyFile[];
  continuationToken: string;
}

export interface Directory {
  id: string;
  name: string;
  instanceId: string;
}

export interface FileInstance {
  id: string;
  name: string;
  instance_id: string;
  type: string;
  source: UploadType;
  updated_at: string;
  size: number;
}

export interface CrawlerLog {
  orgId: string;
  id: string;
  instanceIdPathLimit: string;
  status: CrawlerStatus;
  statusDetails: string;
  deleted: boolean;
  insertedBy: string;
  updatedBy: string;
  insertedAt: string;
  updatedAt: string;
}

// eslint-disable-next-line no-shadow
export enum UploadType {
  LOCAL_UPLOAD = 'LOCAL_UPLOAD',
  FTP_UPLOAD = 'FTP_UPLOAD',
  SFTP_UPLOAD = 'SFTP_UPLOAD',
  S3_UPLOAD = 'S3_TRANSFER'
}

// eslint-disable-next-line no-shadow
export enum CrawlerStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  ERROR = 'ERROR'
}

export class MyFilesUtils {
  static uploadTypeMap: Map<UploadType, ConnectorId> = new Map([
    [UploadType.FTP_UPLOAD, ConnectorId.FTP],
    [UploadType.SFTP_UPLOAD, ConnectorId.SFTP],
    [UploadType.S3_UPLOAD, ConnectorId.S3]
  ]);

  static connectorTypeMap: Map<ConnectorId, UploadType> = new Map([
    [ConnectorId.FTP, UploadType.FTP_UPLOAD],
    [ConnectorId.S3, UploadType.S3_UPLOAD]
  ]);
}
