import { FuseNavigation } from '@fuse/types';
import { DynamicContent, MY_FILES_LABEL } from '@neptune/models';

export interface SetNavigateStateType {
  inputValueModel?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  currentSegmentation?: object;
  currentAsset?: DynamicContent;
  currentProject?: Record<string, unknown>;
  editedFolders?: Record<string, unknown>;
  rowsData?: Record<string, unknown>;
  selected?: Record<string, unknown>;
  foldersData?: Record<string, unknown>;
  isGlobal?: Record<string, unknown>;
  back?: Record<string, unknown>;
  totalDataStructure?: Record<string, unknown>;
  globalFolders?: Record<string, unknown>;
  indexGroupTab?: Record<string, unknown>;
  selectedItem?: Record<string, unknown>;
  flatNodeMap?: Record<string, unknown>;
  nestedNodeMap?: Record<string, unknown>;
  selectedParent?: Record<string, unknown>;
  newItemName?: Record<string, unknown>;
  treeControl?: Record<string, unknown>;
  treeFlattener?: Record<string, unknown>;
  dataSource?: Record<string, unknown>;
  checklistSelection?: Record<string, unknown>;
  dragNode?: Record<string, unknown>;
  dragNodeExpandOverWaitTimeMs?: Record<string, unknown>;
  dragNodeExpandOverNode?: Record<string, unknown>;
  dragNodeExpandOverTime?: Record<string, unknown>;
  dragNodeExpandOverArea?: Record<string, unknown>;
  projects?: Record<string, unknown>;
  folders?: Record<string, unknown>;
}

export const devNavigation = {
  id: 'dev',
  title: 'Dev',
  translate: 'Dev',
  type: 'group',
  roles: ['system'],
  children: [
    {
      id: 'components-section',
      title: 'Components Section',
      translate: 'Components Section',
      type: 'item',
      icon: '',
      svgIcon: 'help',
      url: 'pages/reference/components',
      roles: ['system']
    },
    {
      id: 'asset-edit-page-test',
      title: 'Asset Edit Page Test',
      translate: 'Asset Edit Page Test',
      type: 'item',
      icon: '',
      svgIcon: 'message_print',
      url: 'pages/main/asset/print-message',
      roles: ['system']
    }
    // {
    //   id: 'content-builder-js',
    //   title: 'Content Builder Js',
    //   translate: 'Content Builder Js',
    //   type: 'item',
    //   icon: '',
    //   svgIcon: 'send-email',
    //   url: 'pages/template',
    //   roles: ['system']
    // }
  ]
};

export const navigation: FuseNavigation[] = [
  {
    id: 'Main',
    title: 'Main',
    translate: 'Main',
    type: 'group',
    roles: ['all'],
    children: [
      {
        id: 'Home',
        title: 'Home',
        translate: 'Home',
        type: 'item',
        svgIcon: 'home',
        url: 'pages/homepage',
        roles: ['all']
      }
    ]
  },
  {
    id: 'Segmentation',
    title: 'Segmentation',
    translate: 'Segmentation',
    type: 'group',
    roles: ['all'],
    children: [
      {
        id: 'projects-list',
        title: 'Projects',
        translate: 'Projects',
        type: 'item',
        icon: '',
        svgIcon: 'folder-browser',
        url: 'pages/user/projects',
        roles: ['all']
      },
      //   {
      //     id: 'query-page',
      //     title: 'Query Page',
      //     type: 'item',
      //     url: 'pages/asset/query-page'
      //   },
      //   {
      //     id: 'segmentation-query',
      //     title: 'Segmentation Query',
      //     type: 'item',
      //     url: 'pages/segmentation/query'
      //   },
      {
        id: 'my-files',
        title: MY_FILES_LABEL,
        translate: MY_FILES_LABEL,
        type: 'item',
        icon: '',
        svgIcon: 'copy',
        url: 'pages/user/my-files',
        roles: ['all']
      }
    ]
  },
  {
    id: 'Data',
    title: 'Data',
    translate: 'Data',
    type: 'group',
    roles: ['all'],
    children: [
      {
        id: 'canvastable',
        title: 'Database',
        translate: 'Database',
        type: 'item',
        icon: '',
        svgIcon: 'database',
        url: 'pages/database',
        roles: ['all']
      },
      //   {
      //     id: 'lists',
      //     title: 'Lists',
      //     translate: 'Lists',
      //     type: 'item',
      //     icon: '',
      //     svgIcon: 'lists',
      //     url: 'pages/list-table',
      //     roles: ['all']
      //   },
      {
        id: 'import-scheduler',
        title: 'Imports',
        translate: 'Imports',
        type: 'item',
        icon: '',
        svgIcon: 'import',
        url: 'pages/import',
        roles: ['all']
      },
      {
        id: 'pipeline-export',
        title: 'Exports',
        translate: 'Exports',
        type: 'item',
        icon: '',
        svgIcon: 'export',
        url: 'pages/export',
        roles: ['all']
      }
      //   {
      //     id: 'reports',
      //     title: 'Reports & Charts',
      //     translate: 'Reports & Charts',
      //     type: 'item',
      //     icon: '',
      //     svgIcon: 'reports',
      //     url: 'reports',
      //     roles: ['all']
      //   }
    ]
  },
  {
    id: 'profile',
    title: 'Profile',
    translate: 'Profile',
    type: 'group',
    roles: ['all'],
    children: [
      {
        id: 'contacts',
        title: 'Contact Profile Lookup',
        translate: 'Contact Profile Lookup',
        type: 'item',
        icon: '',
        svgIcon: 'contact_search',
        url: 'pages/lookup',
        roles: ['all']
      },
      {
        id: 'sisense-dashboard',
        title: 'Sisense',
        translate: 'Sisense',
        type: 'item',
        icon: '',
        svgIcon: 'help',
        url: 'pages/sisense',
        roles: ['all']
      }
    ]
  },
  {
    id: 'admin',
    title: 'Admin',
    translate: 'Admin',
    type: 'group',
    roles: ['admin'],
    children: [
      {
        id: 'users-list',
        title: 'Users',
        translate: 'Users',
        type: 'item',
        icon: '',
        svgIcon: 'users',
        url: 'pages/user/list',
        roles: ['admin']
      },
      {
        id: 'organization-list',
        title: 'Organizations',
        translate: 'Organizations',
        type: 'item',
        icon: '',
        svgIcon: 'organization',
        url: 'pages/user/organizations',
        roles: ['admin']
      },
      {
        id: 'connectors-list',
        title: 'Credentials',
        translate: 'Credentials',
        type: 'item',
        icon: '',
        svgIcon: 'lock',
        url: 'pages/user/connectors',
        roles: ['admin']
      }
      //   {
      //     id: 'metadata-list',
      //     title: 'Metadata',
      //     translate: 'Metadata',
      //     type: 'item',
      //     icon: '',
      //     svgIcon: 'metadata',
      //     url: 'pages/metadata',
      //     roles: ['admin']
      //   },
      //   {
      //     id: 'purl-list',
      //     title: 'Purl',
      //     translate: 'Purl',
      //     type: 'item',
      //     icon: '',
      //     svgIcon: 'purl',
      //     url: 'pages/user/purl',
      //     roles: ['admin']
      //   },
      //   {
      //     id: 'sender-list',
      //     title: 'Sender',
      //     translate: 'Sender',
      //     type: 'item',
      //     icon: '',
      //     svgIcon: 'sender_profile',
      //     url: 'pages/sender',
      //     roles: ['admin']
      //   },
      //   {
      //     id: 'domain-list',
      //     title: 'Domain',
      //     translate: 'Domain',
      //     type: 'item',
      //     icon: '',
      //     svgIcon: 'webpage',
      //     url: 'pages/domain',
      //     roles: ['admin']
      //   }
      //   {
      //     id: 'api-key',
      //     title: 'API Keys',
      //     translate: 'API Keys',
      //     type: 'item',
      //     icon: '',
      //     svgIcon: 'api_key',
      //     url: 'pages/api-key',
      //     roles: ['admin']
      //   }
    ]
  }
  //   {
  //     id: 'sys',
  //     title: 'System',
  //     translate: 'System',
  //     type: 'group',
  //     roles: ['system'],
  //     children: [
  //       {
  //         id: 'news-announcements',
  //         title: 'News & Announcements',
  //         translate: 'News & Announcements',
  //         type: 'item',
  //         icon: '',
  //         svgIcon: 'news',
  //         url: 'pages/news-announcements',
  //         roles: ['system']
  //       }
  //     ]
  //   }
];
