import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToArray'
})
export class NumberToArrayPipe implements PipeTransform {
  transform(arrayLength: number, initialValue: number = 0): unknown {
    return Array(arrayLength)
      .fill(initialValue)
      .map((val, i) => val + i);
  }
}
