import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export class SharedValidators {
  static emailUsernameValidator(control: AbstractControl): ValidationErrors | null {
    const email_address = <string>control.value;
    const username = email_address.split('@')[0];
    const elems = email_address.split('@').length;
    if (elems === 1 || elems > 2) {
      return { emailUsernameValidator: { message: 'Invalid' } };
    } else {
      return !/^[a-zA-Z0-9](?!.*[!#$%&'*+\/=?^_`{|}~.-][!#$%&'*+\/=?^_`{|}~.-])[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]{1,63}$/.test(
        username
      )
        ? { emailUsernameValidator: { message: 'Invalid' } }
        : null;
    }
  }

  static emailWithFullDomain(control: AbstractControl): ValidationErrors | null {
    const email_address = <string>control.value;
    const emailRegex = /^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
    return emailRegex.test(email_address) ? null : { emailWithFullDomain: { message: 'Invalid' } };
  }

  static passwordValidator(control: AbstractControl): ValidationErrors | null {
    const password = <string>control.value;
    return !/\d/.test(password)
      ? { passwordValidator: { message: 'Must contain at least: One number' } }
      : !/[A-Z]/.test(password)
      ? { passwordValidator: { message: 'Must contain at least: One upper case character' } }
      : !/[a-z]/.test(password)
      ? { passwordValidator: { message: 'Must contain at least: One lower case character' } }
      : !/[!@#$%^&]/.test(password)
      ? { passwordValidator: { message: 'Must contain at least: One special character' } }
      : !(password.length >= 8)
      ? { passwordValidator: { message: 'Must contain at least: 12 characters' } }
      : !(password.length <= 256)
      ? { passwordValidator: { message: 'Must contain at most: 256 characters' } }
      : null;
  }

  static match(firstControlName, secondControlName, customError = 'mismatch'): ValidationErrors | null {
    return (fg: UntypedFormGroup) =>
      fg.get(firstControlName)?.value === fg.get(secondControlName)?.value ? null : { [customError]: true };
  }

  static LessThanToday(control: AbstractControl): ValidationErrors | null {
    const date: Date = new Date();
    const today = new Date(date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear());
    const currentValue = control.value._i
      ? new Date(control.value._i.month + 1 + '/' + control.value._i.date + '/' + control.value._i.year)
      : '';
    return currentValue && currentValue < today ? { LessThanToday: true } : null;
  }
}
