import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RelationshipsComponent } from './relationships.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from '@neptune/material.module';
import { DropdownButtonModule } from '../dropdown-button/dropdown-button.module';
import { DropdownModule } from '../dropdown/dropdown.module';

@NgModule({
  declarations: [RelationshipsComponent],
  imports: [CommonModule, FuseSharedModule, MaterialModule, DropdownButtonModule, DropdownModule],
  exports: [RelationshipsComponent]
})
export class RelationshipsModule {}
