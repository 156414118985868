import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Injector } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { ConfirmDialogModule } from '@neptune/components/confirm-dialog/confirm-dialog.module';
import { LoadingModalGlobalModule } from '@neptune/components/loading-modal-global/loading-modal-global.module';
import { NeptuneModule } from '@neptune/neptune.module';
import { CognitoService } from '@neptune/services/cognito.service';
import { LocalStorageService } from '@neptune/services/local-storage.service';
import { NavigationQueueService } from '@neptune/services/navigation-queue.service';
import { SegmentationWaterFallService } from '@neptune/services/segmentation-waterfall.service';
import { NotificationsService } from '@neptune/services/notifications.service';
import { StoreService } from '@neptune/services/store.service';
import { TranslateModule } from '@ngx-translate/core';
import { ApolloModule } from 'apollo-angular';
import { AppComponent } from 'app/app.component';
import { AppCustomPreloader } from 'app/app.custom-preload-strategy';
import { AppRoutingModule } from 'app/app.routes';
import { fuseConfig } from 'app/fuse-config/index';
import { LayoutModule } from 'app/layout/layout.module';
import { ReportsModule } from 'app/main/content/reports/reports.module';
import 'hammerjs';
import { ProjectsListService } from './main/content/pages/user/my-projects/project-list/projects-list.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    TranslateModule.forRoot(),
    CommonModule,
    FormsModule,
    ReportsModule,
    ConfirmDialogModule,
    ApolloModule,

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,

    // Neptune
    AppRoutingModule,
    NeptuneModule,
    LoadingModalGlobalModule
  ],
  providers: [
    StoreService,
    LocalStorageService,
    CognitoService,
    NavigationQueueService,
    AppCustomPreloader,
    SegmentationWaterFallService,
    NotificationsService,
    ProjectsListService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  static injector: Injector;
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer, injector: Injector) {
    AppModule.injector = injector;
    matIconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/material-design/mdi.svg')
    );
  }
}
