import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MaterialModule } from '@neptune/material.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { StandardDeleteDialog } from './standard-delete-dialog';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  declarations: [StandardDeleteDialog],
  imports: [MaterialModule, FuseSharedModule, NgxDatatableModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: []
})
export class StandardDeleteDialogModule {}
