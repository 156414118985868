<div class class="standard-delete-dialog">
  <div mat-dialog-title class="dialog-header">
    <div class="dialog-title">
      <span class="h2">{{ data.title || 'Dependencies detected' }}</span>
    </div>
  </div>

  <mat-dialog-content>
    <div class="description-container">
      <div class="description-text">
        <p>
          {{ data.message || "This object can't be deleted yet because other objects use it" }}
        </p>
      </div>
      <h2 *ngIf="data.showDependants && pipelines.length">Pipelines</h2>
      <div *ngIf="data.showDependants && pipelines.length">
        <ngx-datatable
          class="material"
          [rows]="pipelines"
          [columnMode]="'force'"
          [headerHeight]="'48'"
          [footerHeight]="56"
          [rowHeight]="'auto'"
          [scrollbarH]="true"
          [limit]="10"
          [count]="pipelines.length"
        >
          <ngx-datatable-column name="Object" prop="data.dependentObject"></ngx-datatable-column>
          <ngx-datatable-column name="Name" prop="data.dependentObjectName"></ngx-datatable-column>
          <ngx-datatable-column name="Type" prop="typeOfDependentObject"></ngx-datatable-column>
        </ngx-datatable>
      </div>
      <h2 *ngIf="data.showDependants && assets.length">Assets</h2>
      <div *ngIf="data.showDependants && assets.length">
        <ngx-datatable
          class="material"
          [rows]="assets"
          [columnMode]="'force'"
          [headerHeight]="'48'"
          [footerHeight]="56"
          [rowHeight]="'auto'"
          [scrollbarH]="true"
          [limit]="10"
          [count]="assets.length"
        >
          <ngx-datatable-column name="Object" prop="data.dependentObject"></ngx-datatable-column>
          <ngx-datatable-column name="Name" prop="data.dependentObjectName"></ngx-datatable-column>
          <ngx-datatable-column name="Type" prop="typeOfDependentObject"></ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayoutAlign="end start" class="dialog-footer" fxLayout="row nowrap">
    <button mat-flat-button color="primary" (click)="onClose()">Close</button>
  </mat-dialog-actions>
</div>
