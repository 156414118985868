import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DataType, DataTypeUtils, Field, FieldCalculationType, TableDefinition } from '@neptune/models/file';
import { REQUIRED_VALIDATION_LABEL, validFieldName } from 'app/shared/validators';

export interface FieldDialogComponentInput {
  isNewTable: boolean;
  isNewField: boolean;
  field?: Field;
  fields?: Field[];
  enableDelete?: boolean;
  tableName?: string;
  tableSchema?: TableDefinition;
  isContactTable?: boolean;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-field-dialog',
  templateUrl: './field-dialog.html',
  styleUrls: ['./field-dialog.scss']
})
export class FieldDialogComponent implements OnInit {
  // value bound to inout
  saveForm: UntypedFormGroup;
  nameError: string = '';
  field: Field;
  enableDelete: boolean = false;

  public get enableSave() {
    return this.saveForm && this.saveForm.valid;
  }

  private _isEdit: boolean;
  private originalName: string;
  public get isEdit(): boolean {
    return this._isEdit;
  }
  public get isNewTable(): boolean {
    return this.data.isNewTable;
  }

  private NAME_REQUIRED: string = 'Name is required';

  // expose data type map
  public typeMap = DataTypeUtils.DataTypeMap;
  public FieldCalculationType: FieldCalculationType;

  public getErrorMessage() {
    const control = this.saveForm.get('fieldName');
    return control?.hasError('required')
      ? REQUIRED_VALIDATION_LABEL
      : control?.hasError('startsWith')
      ? control.errors?.startsWith
      : control?.hasError('prefix')
      ? control.errors?.prefix
      : control?.errors?.allowed;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<FieldDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FieldDialogComponentInput
  ) {}

  ngOnInit() {
    if (!this.data.field || this.data.field.dataType === DataType.UNKNOWN) {
      this._isEdit = false;
      this.field = {
        name: '',
        dataType: DataType.STRING,
        calculationType: FieldCalculationType.NONE,
        format: ''
      };
    } else {
      this._isEdit = true;
      this.field = Object.assign({}, this.data.field);
      this.originalName = this.field.name;
      this.enableDelete = this.data.enableDelete === true; // Could be undefined, true or false (that's why ===)
    }

    const fieldNameControl = this.formBuilder.control(this.field.name, [
      Validators.required,
      validFieldName as ValidatorFn
    ]);
    const dataTypeControl = this.formBuilder.control(this.field.dataType, [Validators.required]);

    this.saveForm = this.formBuilder.group({
      fieldName: fieldNameControl,
      dataType: dataTypeControl
    });
  }

  onTypeChange(dataType: DataType) {
    this.field.dataType = dataType;
  }

  onSave() {
    // check name validity
    this.field.name = this.saveForm.get('fieldName')?.value;
    if (!this.field.name) {
      this.nameError = this.NAME_REQUIRED;
      return;
    }
    const repeatedName: boolean = (this.data.fields &&
      this.data.fields.filter(f => f.name === this.field.name).length > 0) as boolean;
    // eslint-disable-next-line eqeqeq
    if ((this.data.isNewField || this.field.name != this.originalName) && repeatedName) {
      this.nameError = `Choose a unique name`;
      this.saveForm.controls.fieldName.setErrors({ incorrect: true });
      return;
    }

    // Prevent closing the dialog if the form is invalid
    if (this.saveForm.invalid) {
      return;
    }

    this.dialogRef.close(this.field);
  }

  onDelete() {
    this.dialogRef.close({
      deleted: true
    });
  }

  /**
   * Handler for close buttons
   *
   * @param confirm boolean that gets passed to dialog close method
   */
  onClose(): void {
    this.dialogRef.close();
  }
}
