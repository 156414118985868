import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'toggle-button',
  templateUrl: './toggle-button.component.html',
  styles: [],
  encapsulation: ViewEncapsulation.None
})
export class ToggleButtonComponent implements OnInit {
  @Input()
  label: string;

  @Input()
  toggleOn: string;
  @Input()
  onClass: string;
  @Input()
  offClass: string;

  @Output() toggled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() elementToggled: EventEmitter<any> = new EventEmitter<any>();

  andOr: string = 'and';
  notOp: boolean = false;

  ngOnInit() {}

  onClick(event) {
    const element =
      event.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement
        .children[1];

    this.elementToggled.emit(element);

    // eslint-disable-next-line eqeqeq
    this.toggleOn = this.toggleOn == 'true' ? 'false' : 'true';
    // eslint-disable-next-line eqeqeq
    this.toggled.emit(this.toggleOn == 'true' ? true : false);
  }

  // onToggled(): void {
  //   this.toggled.emit(this.toggleOn=='true'?true:false);
  // }
}
