import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[nepMaxSize]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxSizeValidatorDirective, multi: true }]
})
export class MaxSizeValidatorDirective implements Validator {
  @Input('nepMaxSize') maxSize: string;

  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.maxSize ? maxSizeValidator(parseInt(this.maxSize, 10))(control) : null;
  }
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function maxSizeValidator(maxSize: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const exceeds = new Blob([control.value]).size > maxSize;
    return exceeds ? { maxSize: { value: control.value } } : null;
  };
}
