import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

@NgModule({
  providers: []
})
export class NeptuneModule {
  constructor(@Optional() @SkipSelf() parentModule: NeptuneModule) {
    if (parentModule) {
      throw new Error('NeptuneModule is already loaded. Import it in the AppModule only!');
    }
  }

  static forRoot(config): ModuleWithProviders<NeptuneModule> {
    return {
      ngModule: NeptuneModule,
      providers: []
    };
  }
}
