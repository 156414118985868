import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { ReportsComponent } from './reports.component';

@NgModule({
  declarations: [ReportsComponent],
  imports: [FuseSharedModule],
  exports: [ReportsComponent],
  providers: []
})
export class ReportsModule {}
