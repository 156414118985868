import { AssetType } from './asset';

// eslint-disable-next-line no-shadow
export enum MetaLevel {
  GLOBAL = 'global',
  PROJECT = 'project',
  ASSET = 'asset'
}

// eslint-disable-next-line no-shadow
export enum MetaOrder {
  GLOBAL,
  PROJECT,
  ASSET
}

export interface MetaRequestModel {
  projectId?: string;
  /** If requesting an object must provide the containing project id */
  assetId?: string;
}

export interface MetaRequest extends MetaRequestModel {
  /** current level*/
  level?: MetaLevel;
  /** type of asset, if applicable*/
  assetType?: AssetType;
}

export class MetaModel {
  /** uuid assiegned by backend */
  id?: string;
  level: MetaLevel;
  name: string;
  value: string;
  /** id of associated project, project level of below */
  projectId?: string;
  /** id of associated object, object level only*/
  assetId?: string;
  /** type of associated object, object level only*/
  assetType?: AssetType;
}

export class Metadata extends MetaModel {
  atLevel?: boolean;
  levelOrder?: MetaOrder;
  inactive?: boolean;
  atop?: Metadata | null;
  class?: string;
}

export class MetadataUtils {
  public static getLevelOrder(level: MetaLevel): MetaOrder {
    switch (level) {
      case MetaLevel.GLOBAL:
        return MetaOrder.GLOBAL;
      case MetaLevel.PROJECT:
        return MetaOrder.PROJECT;
      case MetaLevel.ASSET:
        return MetaOrder.ASSET;
    }
  }
}
