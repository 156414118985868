/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable space-before-function-paren */
import { EditSession, define as aceDefine, Editor } from 'ace-builds/src-noconflict/ace';
import { FunctionDataService } from '@neptune/models/expression';
import { AceEditorComponent } from '@derekbaker/ngx-ace-editor-wrapper';

let source: string[] | null = null;
let functions: FunctionDataService[] | null = null;

const focusColor: string = '#005aff3b';
const blurColor: string = '#00000040';
const errorColor: string = '#dd0000';

export class CustomAceEditor {
  // When defining highlight rules and modes for ACE if we use only one keyword
  // the correct syntax will not be updated, we use this static number to create
  // each time this class is used a unique highlight and mode name.
  static nextAceModeIndex: number = 0;

  public _editor: AceEditorComponent;
  public _session: EditSession;
  public _focused: boolean = false;

  constructor(editor: AceEditorComponent, sources: string[], funcs: FunctionDataService[]) {
    CustomAceEditor.nextAceModeIndex += 1;
    createQPMode();

    this._editor = editor;

    functions = funcs;
    source = sources;

    const singleEditor: Editor = this._editor.getEditor();
    singleEditor.on('focus', e => {
      this._focused = true;
      this.setBorderColor(false);
    });

    singleEditor.on('blur', e => {
      this._focused = false;
      this.setBorderColor(false);
    });

    singleEditor.container.style.background = 'none';
  }

  private setBorderColor(error: boolean) {
    const singleEditor: Editor = this._editor.getEditor();
    const containerScroller: HTMLElement = singleEditor.container;
    if (containerScroller) {
      if (!error) {
        containerScroller.style.border = this._focused ? `2px solid ${focusColor}` : `1px solid ${blurColor}`;
        containerScroller.style.border = 'none';
      } else {
        containerScroller.style.borderBottom = `2px solid ${errorColor}`;
      }
    }
  }

  public getCurrentSession(): EditSession {
    return this._session || this._editor.getEditor().getSession();
  }

  public getCurrentText(): string {
    return this._editor._text;
  }

  public setFocus(): void {
    if (this._editor) {
      const editor: Editor = this._editor.getEditor();

      editor.focus();
      const session: EditSession = editor.getSession();

      const count: number = session.getLength();

      editor.gotoLine(count, session.getLine(count - 1).length);
    }
  }

  public setError(hasError: boolean) {
    this.setBorderColor(hasError);
  }

  public configureEditor(): void {
    this._editor.setMode(`qp-${CustomAceEditor.nextAceModeIndex}`);
    this._editor.setTheme('tomorrow');

    const edit: Editor = this._editor.getEditor();

    const session: EditSession = edit.getSession();

    session.setMode({
      path: `ace/mode/qp-${CustomAceEditor.nextAceModeIndex}`,
      v: Date.now()
    });

    edit.setSession(session);

    this._session = session;

    edit.setOptions({
      highlightActiveLine: false,
      cursorStyle: 'wide',
      selectionStyle: 'text',
      fontSize: 16,
      showFoldWidgets: false,
      showLineNumbers: false,
      showGutter: false,
      displayIndentGuides: false
    });

    edit.commands.addCommand({
      name: 'showOtherCompletions',
      bindKey: 'Enter',
      exec: e => {
        console.log('Enter pressed, calling applying expression');
      }
    });
  }
}

/**
 * Create and register custom mode for use by code editor
 */
export function createQPMode() {
  aceDefine(
    'ace/mode/matching_brace_outdent',
    ['require', 'exports', 'module', 'ace/range'],

    // eslint-disable-next-line space-before-function-paren
    function (require, exports, module) {
      'use strict';

      const Range = require('../range').Range;

      const MatchingBraceOutdent = function () {};

      (function () {
        this.checkOutdent = function (line, input) {
          if (!/^\s+$/.test(line)) {
            return false;
          }

          return /^\s*\}/.test(input);
        };

        this.autoOutdent = function (doc, row) {
          const line = doc.getLine(row);
          const match = line.match(/^(\s*\})/);

          if (!match) {
            return 0;
          }

          const column = match[1].length;
          const openBracePos = doc.findMatchingBracket({
            row,
            column
          });

          if (!openBracePos || openBracePos.row === row) {
            return 0;
          }

          const indent = this.$getIndent(doc.getLine(openBracePos.row));
          doc.replace(new Range(row, 0, row, column - 1), indent);
        };

        this.$getIndent = function (line) {
          return line.match(/^\s*/)[0];
        };
      }.call(MatchingBraceOutdent.prototype));

      exports.MatchingBraceOutdent = MatchingBraceOutdent;
    }
  );

  aceDefine(
    `ace/mode/qp-${CustomAceEditor.nextAceModeIndex}`,
    ['require', 'exports', 'ace/lib/oop', 'ace/mode/text'],
    function (acequire, exports) {
      'use strict';

      const oop = acequire('ace/lib/oop');
      const TextMode = acequire('ace/mode/text').Mode;
      const QPHighlightRules = acequire(`ace/mode/qp_highlight_rules-${CustomAceEditor.nextAceModeIndex}`)
        .QPHighlightRules;
      const MatchingBraceOutdent = acequire('ace/mode/matching_brace_outdent').MatchingBraceOutdent;

      const Mode = function () {
        console.log('QP Mode');
        this.HighlightRules = QPHighlightRules;
        this.$outdent = new MatchingBraceOutdent();
      };
      oop.inherits(Mode, TextMode); // ACE's way of doing inheritance

      (function () {
        // configure comment start/end characters
        this.lineCommentStart = '//';
        this.blockComment = { start: '/*', end: '*/' };

        // special logic for indent/outdent.
        // By default ace keeps indentation of previous line
        this.getNextLineIndent = function (state, line, tab) {
          const indent = this.$getIndent(line);
          return indent;
        };

        this.checkOutdent = function (state, line, input) {
          return this.$outdent.checkOutdent(line, input);
        };

        this.autoOutdent = function (state, doc, row) {
          this.$outdent.autoOutdent(doc, row);
        };
      }.call(Mode.prototype));

      exports.Mode = Mode;
    }
  );

  aceDefine(
    `ace/mode/qp_highlight_rules-${CustomAceEditor.nextAceModeIndex}`,
    ['require', 'exports', 'ace/lib/oop', 'ace/mode/text_highlight_rules'],
    function (acequire, exports, module) {
      'use strict';

      const oop = acequire('ace/lib/oop');
      const TextHighlightRules = acequire('ace/mode/text_highlight_rules').TextHighlightRules;

      const QPHighlightRules = function () {
        let methodStr = '';

        for (let j = 0; functions && j < functions.length; j++) {
          if (j > 0) {
            methodStr += '|';
          }
          methodStr += functions[j].name;
        }

        let sourceStr = '';
        for (let i = 0; source && i < source.length; i++) {
          if (i > 0) {
            sourceStr += '|';
          }
          sourceStr += source[i];
        }

        const buildinConstants = 'true|false';

        const keywordMapper = (this.$keywords = this.createKeywordMapper(
          {
            'support.function': methodStr,
            'support.constant': sourceStr,
            'constant.language': buildinConstants
          },
          'identifier'
        ));

        // regexp must not have capturing parentheses. Use (?:) instead.
        // regexps are ordered -> the first match is used

        this.$rules = {
          start: [
            {
              token: 'string.regexp',
              regex: '[/](?:(?:\\[(?:\\\\]|[^\\]])+\\])|(?:\\\\/|[^\\]/]))*[/]\\w*\\s*(?=[).,;]|$)'
            },
            {
              token: 'string', // single line
              regex: '["](?:(?:\\\\.)|(?:[^"\\\\]))*?["]'
            },
            {
              token: 'string', // single line
              // eslint-disable-next-line @typescript-eslint/quotes
              regex: "['](?:(?:\\\\.)|(?:[^'\\\\]))*?[']"
            },
            {
              token: 'constant.numeric', // hex
              regex: '0[xX][0-9a-fA-F]+\\b'
            },
            {
              token: 'constant.numeric', // float
              regex: '[+-]?\\d+(?:(?:\\.\\d*)?(?:[eE][+-]?\\d+)?)?\\b'
            },
            {
              token: 'constant.language.boolean',
              regex: '(?:true|false)\\b'
            },
            {
              token: keywordMapper,
              regex: '[a-zA-Z_$][a-zA-Z0-9_$]*\\b'
            },
            {
              token: 'keyword.operator',
              regex:
                // eslint-disable-next-line max-len
                '!|\\$|%|&|\\*|\\-\\-|\\-|\\+\\+|\\+|~|===|==|=|!=|!==|<=|>=|<<=|>>=|>>>=|<>|<|>|!|&&|\\|\\||\\?\\:|\\*=|%=|\\+=|\\-=|&=|\\^=|\\b(?:in|instanceof|new|delete|typeof|void)'
            },
            {
              token: 'punctuation.operator',
              regex: '\\?|\\:|\\,|\\;|\\.'
            },
            {
              token: 'paren.lparen',
              regex: '[[({<]'
            },
            {
              token: 'paren.rparen',
              regex: '[\\])}>]'
            },
            {
              token: 'text',
              regex: '\\s+'
            }
          ]
        };
      };

      oop.inherits(QPHighlightRules, TextHighlightRules);

      exports.QPHighlightRules = QPHighlightRules;
    }
  );
}
