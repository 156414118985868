import { Injectable } from '@angular/core';
import { timer } from 'rxjs';

export class StoreObject {
  public static ID: string;
}

@Injectable()
export class StoreService {
  private get instance(): Map<string, any> {
    if (!this._instance) {
      this._instance = new Map<string, any>();
    }
    return this._instance;
  }

  /**
   * Stores state that is persistent across table creation process
   */
  private _instance: Map<string, any>;

  /**
   * For use with jasmine testing, returns a mock of service
   */
  public static mockService(jasmine?: any): any {
    return {
      get: jest.fn(() => ({}))
    };
  }

  get<T>(id: string): T {
    return this.instance.get(id);
  }

  set<T>(id: string, data: T, cacheExpirationTime?: number): T {
    this.instance.set(id, data);
    if (cacheExpirationTime) {
      timer(cacheExpirationTime).subscribe(() => this.delete(id));
    }
    return data;
  }

  delete(id: string) {
    this.instance.delete(id);
  }

  has(id: string): boolean {
    return this.instance.has(id);
  }
}
