import { Component, ChangeDetectorRef, ViewChild } from '@angular/core';
import { StandardListComponent } from '@neptune/components';
import { PipelineProfile } from '@neptune/models/pipeline';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { includes, some } from 'lodash';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Roles } from '@neptune/models/user';
import { PipelineService } from '@neptune/services/pipeline.service';
import { Asset, AssetSelectorDialogData, AssetType } from '@neptune/models';

export interface ImportAsset extends Asset {
  id: string;
  /** NOTE :: assetId will be same as id, since Import is not being managed as an asset */
  assetId: string;
  name: string;
  source: string;
  sourceTable: string;
  assetType: AssetType.IMPORT;
  selected?: boolean;
}

@Component({
  selector: 'app-import-asset-list',
  templateUrl: './import-asset-list.component.html',
  styleUrls: ['./import-asset-list.component.scss']
})
export class ImportAssetListComponent extends StandardListComponent<ImportAsset> {
  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

  public selectedData: ImportAsset[];
  public data: AssetSelectorDialogData;
  public selectionType: string;

  constructor(
    protected matDialogService: MatDialog,
    public changeDetector: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    public pipelineService: PipelineService
  ) {
    super();
    this.rolList = [Roles.ADMIN, Roles.SYSTEM];
  }

  public populate(): Observable<null> {
    this.startLoading();
    return this.pipelineService
      .getProfilesByTable(this.data.tableId as string)
      .pipe(takeUntil(this.unsubscribeAll))
      .pipe(
        map((result: PipelineProfile[]) => {
          const checkSelect = !!this.data?.selected;
          const imports = result.map(
            profile =>
              <ImportAsset>{
                id: profile.id,
                assetId: profile.id,
                name: profile.name,
                source: profile.source.connectorId,
                sourceTable: profile.source.object,
                selected: checkSelect && !!some(this.data.selected, { id: profile.id }),
                assetType: AssetType.IMPORT
              }
          );
          this.selectedData = imports.filter(i => i.selected);
          this.addEntity(imports);
          this.stopLoading();
          return null;
        })
      );
  }

  public onChecked(data: ImportAsset, checked: boolean) {
    if (checked) {
      this.selectedData.push(data);
    } else {
      this.selectedData = this.selectedData.filter(d => d.id !== data.id);
    }
    this.onSelectionChange.emit(this.selectedData);
  }

  /**
   * @inheritdoc
   */
  protected updateTableView() {
    this.rowsData = this.list.filter((p: ImportAsset) => p);
    this.changeDetector.markForCheck();
  }

  /**
   * @inheritdoc
   */
  searchFilter(data: ImportAsset, searchValue: string): boolean {
    if (searchValue) {
      const search = searchValue.toLowerCase();
      return includes(data.name.toLowerCase(), search);
    } else {
      return true;
    }
  }
}
