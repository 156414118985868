import { Component, OnInit } from '@angular/core';
import { QPConfig } from 'environments/environment';
import { AccountService } from '@neptune/services/account.service';
import { UserDetails } from '@neptune/models/user';

@Component({
  selector: 'qp-reports',
  templateUrl: './reports.component.html'
})
export class ReportsComponent implements OnInit {
  private sisenseServerUrl: string;
  private userToken: string;

  constructor(private accountService: AccountService) {
    this.sisenseServerUrl = QPConfig.SisenseAppUrl;
  }

  ngOnInit() {
    this.accountService.getCurrentUserDetails().subscribe((result: UserDetails) => {
      const session = result.session;
      this.userToken = session.getIdToken().getJwtToken();

      this.sisenseServerUrl += '?q=' + this.userToken;
      window.location.href = this.sisenseServerUrl;
    });
    // TODO: We should try to use NavigationQueueService system to navigate back and forth in the app.
  }
}
