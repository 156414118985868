import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconObject } from '@neptune/models/icon-object';

@Component({
  selector: 'nep-icon',
  template: `
    <i *ngIf="!icon?.custom" class="mdi mdi-24px" [ngClass]="iconName"></i>
    <mat-icon *ngIf="icon?.custom" [svgIcon]="icon.name"></mat-icon>
  `,
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent {
  @Input() icon: IconObject;
  @Input() iconName: string;
}
