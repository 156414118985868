import { firstValueFrom } from 'rxjs';
/* eslint-disable no-invalid-this */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialog } from '@neptune/components/confirm-dialog/confirm-dialog';
import {
  AccountServiceMock,
  OrgData,
  OrgParam,
  RegistrationModel,
  SUCCESSFULLY_CODE,
  SessionData
} from '@neptune/models/account';
import {
  CognitoUserAttributeType,
  InitiateAuthData,
  Roles,
  UserAttributes,
  UserData,
  UserDetails
} from '@neptune/models/user';
import { CognitoUser, CognitoUserAttribute, CognitoUserSession } from 'amazon-cognito-identity-js';
import { NeptuneSetup } from 'environments/environment';
import { interval, Observable, of, Subscription } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { BaseService, Endpoint } from './base.service';
import { CognitoService } from './cognito.service';
import { LocalStorageService } from './local-storage.service';
import { StoreService } from './store.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseService {
  private ONE_MINUTE = 60 * 1000;

  private SESSION_REFRESH_CONFIG = {
    CHECK_INTERVAL: this.ONE_MINUTE,
    MIN_EXPIRATION_GAP_FOR_REFRESH: 10 * this.ONE_MINUTE,
    IDLE_TIMEOUT: NeptuneSetup.AutoIdleSignOutTime * this.ONE_MINUTE,
    COUNTDOWN_TIMEOUT: 2 * 60
  };

  private authSubscription: Subscription;
  private forgotPasswordSubscription: Subscription;
  private initiateAuthData: InitiateAuthData;

  constructor(
    protected http: HttpClient,
    protected storeService: StoreService,
    private dialog: MatDialog,
    protected localStorageService: LocalStorageService,
    public cognitoService: CognitoService
  ) {
    super(http, cognitoService, storeService);

    this.raiseTimerFlag = this.raiseTimerFlag.bind(this);
    this.checkTimer = this.checkTimer.bind(this);
  }

  nextSessionExpiration: number | null;
  idleBox: boolean = false;
  active: boolean = true;
  timeout: number = 0;
  intervalSubscription: Subscription;
  // #endregion

  /**
   * For use with jasmine testing, returns a mock of service
   */

  public static mockService(): AccountServiceMock {
    return {
      getOrgId: jest.fn(() => Promise.resolve('test_org_id')),
      getCurrentUser: jest.fn(() =>
        Promise.resolve(<UserData>{
          username: 'test',
          attributes: [{}],
          systemManagement: false,
          org: {
            orgId: 'quickpivot.com',
            orgName: 'quickpivot.com'
          }
        })
      ),
      getUserAttributes: jest.fn(() =>
        Promise.resolve({
          attributes: [],
          session: <CognitoUserSession>{}
        })
      ),
      getIsUserSystemManagement: jest.fn(() => Promise.resolve('test_org_id')),
      getAccount: jest.fn(() => Promise.resolve({})),
      getAccountAuthToken: jest.fn(() => Promise.resolve('test')),
      getCurrentUserDetails: jest.fn(() =>
        of({
          user: <CognitoUser>{},
          attributes: {},
          session: {},
          orgData: 'test_org'
        })
      )
    };
  }

  // #region Main Idle Detection
  public startTimer() {
    if (!this.intervalSubscription) {
      this.nextSessionExpiration = this.getSessionData()?.exp || null;
      window.addEventListener('mousemove', this.raiseTimerFlag, true);
      window.addEventListener('keydown', this.raiseTimerFlag, true);
      this.intervalSubscription = interval(this.SESSION_REFRESH_CONFIG.CHECK_INTERVAL).subscribe(
        this.checkTimer.bind(this)
      );
    }
  }

  private checkTimer() {
    if (this.nextSessionExpiration && !this.idleBox) {
      this.timeout += this.SESSION_REFRESH_CONFIG.CHECK_INTERVAL;
      if (
        new Date(this.nextSessionExpiration * 1000).getTime() - Date.now() <
        this.SESSION_REFRESH_CONFIG.MIN_EXPIRATION_GAP_FOR_REFRESH
      ) {
        this.cognitoService.refreshSession().subscribe((session: CognitoUserSession) => {
          this.saveCurrentSession(this.cognitoService.username, session);
          this.nextSessionExpiration = session.getAccessToken().getExpiration();
        });
      }
      if (this.timeout > this.SESSION_REFRESH_CONFIG.IDLE_TIMEOUT) {
        this.onTimerExpire();
      }
    }
  }

  private raiseTimerFlag() {
    if (!this.idleBox) {
      this.timeout = 0;
    }
  }

  private onTimerExpire() {
    if (!this.idleBox) {
      this.idleBox = true;
      ConfirmDialog.open(
        this.dialog,
        {
          title: 'Auto Logout',
          message:
            'You have been inactive and will be logged out automatically momentarily. Click confirm if you still wish to stay logged in.',
          okMessage: 'Continue Working',
          cancelMessage: 'Log Out',
          countDown: this.SESSION_REFRESH_CONFIG.COUNTDOWN_TIMEOUT
        },
        () => {
          this.idleBox = false;
          this.raiseTimerFlag();
        },
        () => {
          this.idleBox = false;
          this.logout();
          location.reload();
        }
      );
    }
  }

  // #endregion

  // #region Main Authentication Functions
  public login(email: string, password: string, rememberMe: string, events) {
    const username = this.toUsername(email);
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }

    this.authSubscription = this.cognitoService.onAuthSuccess.subscribe((session: CognitoUserSession) => {
      this.saveCurrentSession(this.cognitoService.username, session);
    });
    this.authSubscription.add(this.cognitoService.onAuthSuccess.subscribe(events.onSuccess));
    this.authSubscription.add(this.cognitoService.onAuthFailure.subscribe(events.onFailure));
    this.authSubscription.add(this.cognitoService.onNewPasswordRequired.subscribe(events.newPasswordRequired));
    this.authSubscription.add(this.cognitoService.onMfaRequired.subscribe(events.mfaRequired));

    this.cognitoService.authenticateUser(username, password, rememberMe);
  }

  logout() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }

    if (this.cognitoService.username) {
      this.localStorageService.clearAccount(this.cognitoService.username);
      this.localStorageService.clearSession(this.cognitoService.username);
    }

    this.nextSessionExpiration = null;
    window.removeEventListener('mousemove', this.raiseTimerFlag);
    window.removeEventListener('keydown', this.raiseTimerFlag);
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
    this.dialog.closeAll();

    this.cognitoService.signOut();
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  register(model: RegistrationModel): Observable<object> {
    // eslint-disable-next-line @typescript-eslint/ban-types
    return super.basePost<RegistrationModel, object>(Endpoint.USER, `users/signup`, model);
  }

  /**
   * API to check if email is valid
   */
  validEmail(email: string): Observable<boolean | null> {
    return super.baseGet<boolean | null>(Endpoint.USER, `users/validateEmailDomain/${email}`);
  }

  // #endregion

  // #region State Functions
  isLoggedIn(): Observable<boolean> {
    return this.cognitoService.getSession().pipe(map(s => s.isValid()));
  }

  getCurrentUser(): Promise<UserData> {
    return firstValueFrom(this.getCurrentUserObs());
  }

  getCurrentUserObs(): Observable<UserData> {
    return this.getCurrentUserDetails().pipe(
      map(
        user =>
          <UserData>{
            username: user.username,
            attributes: user.attributes,
            org: user.orgData
          }
      )
    );
  }

  // Pendo app
  initializePendo() {
    this.getCurrentUser()
      .then(
        userData => {
          if ((window as any).pendo) {
            const env = environment.isPredev ? 'predev' : environment.isDev ? 'dev' : environment.isQa ? 'qa' : 'prod';
            (window as any).pendo.initialize({
              visitor: {
                id: userData.username,
                currentOrg: userData.attributes.qp_orgId,
                email: userData.attributes.email
              },
              account: {
                id: env
              }
            });
            (window as any).pendo.track(env);
          }
        },
        error => {}
      )
      .catch(error => {});
  }

  getUserAttributes(): Promise<CognitoUserAttributeType> {
    return firstValueFrom(this.cognitoService.getUserAttributes());
  }

  getIsUserAdmin(): Observable<boolean> {
    return this.cognitoService.getUserAttributes().pipe(
      map(({ attributes }) => {
        const systemManager = attributes && attributes.find(m => m.getName() === 'custom:systemManagement');
        if (systemManager && systemManager.getValue() === 'true') {
          return true;
        }
        const currentRol = attributes && attributes.find(m => m.getName() === 'custom:qp_role');
        if (currentRol && currentRol.getValue()) {
          const role: string = currentRol.getValue().toLowerCase();
          return role.includes(Roles.ADMIN.toLowerCase()) || role.includes(Roles.SYSTEM.toLowerCase());
        }
        return false;
      })
    );
  }

  getIsUserSystemManagement(): Promise<boolean> {
    return firstValueFrom(
      this.cognitoService
        .getUserAttributes()
        .pipe(
          map(({ attributes }) =>
            attributes.some(
              (elem: CognitoUserAttribute) =>
                elem.getName() === 'custom:systemManagement' && elem.getValue().toLowerCase() === 'true'
            )
          )
        )
    );
  }

  getCurrentUserDetails(): Observable<UserDetails> {
    return this.cognitoService.getUserAttributes().pipe(
      map((res: { attributes: CognitoUserAttribute[]; session: CognitoUserSession }) => {
        const userAttrList: UserAttributes = {};
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < res.attributes.length; i++) {
          let key: string = res.attributes[i].getName();
          if (key.indexOf('custom:') >= 0) {
            key = key.slice(7, key.length);
          }
          userAttrList[key] = res.attributes[i].getValue();
        }

        // Backwards compatibility, originally it was not defined instead of having 'false' value.
        if (!userAttrList['systemManagement']) {
          userAttrList['systemManagement'] = 'false';
        }
        return <UserDetails>{
          username: this.cognitoService.username,
          attributes: userAttrList,
          session: res.session,
          orgData: this.getOrgData()
        };
      })
    );
  }

  postSignIn(): Observable<{ defaultOrg: string }> {
    return this.getCurrentUserDetails().pipe(
      mergeMap((userDetails: UserDetails) =>
        super
          .baseGet<any>(
            userDetails.attributes.qp_role === Roles.C3USER ? Endpoint.PUBLIC_HUB : Endpoint.NXTDRIVE,
            'mfa-user/postSignin'
          )
          .pipe(map((data: { defaultOrg: string }) => ({ userDetails, data })))
      ),
      mergeMap(({ userDetails, data }) => {
        if (!userDetails.attributes.qp_orgId || !userDetails.orgData) {
          return this.cognitoService.refreshSession().pipe(
            tap((session: CognitoUserSession) => {
              this.setOrgData(data, userDetails.username);
            }),
            map(() => data)
          );
        } else {
          return of(data);
        }
      }),
      map((data: { defaultOrg: string }) => data)
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  refreshSession(): Observable<object> {
    this.localStorageService.clearAccount(this.cognitoService.username as string);
    return this.cognitoService.refreshSession().pipe(mergeMap((data: CognitoUserSession) => this.postSignIn()));
  }

  setOrgData(data: OrgParam, username: string | null) {
    if (data['defaultOrg']) {
      const orgData: OrgData = {
        orgId: data['defaultOrg'],
        orgName: data['defaultOrg'],
        resources: data.Resources
      };
      this.localStorageService.setAccount(username as string, orgData);
    }
  }
  // #endregion

  public getOrgData(): OrgData {
    return this.localStorageService.getAccount(this.cognitoService.username as string);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  changePassword(oldPassword: string, newPassword: string): Observable<object> {
    return this.cognitoService.changePassword(oldPassword, newPassword);
  }

  createOrg(orgParam): Observable<boolean> {
    return super
      .basePost(Endpoint.ORG, 'organizations', {
        OrgID: orgParam.orgId,
        Name: orgParam.name
      })
      .pipe(
        map(data => {
          const orgData = {
            orgId: orgParam.orgId,
            orgName: orgParam.name
          };
          this.localStorageService.setAccount(this.cognitoService.username as string, orgData);
          return true;
        })
      );
  }

  forgotPassword(email: string, events) {
    const username: string = this.toUsername(email);

    if (this.forgotPasswordSubscription) {
      this.forgotPasswordSubscription.unsubscribe();
    }

    this.forgotPasswordSubscription = this.cognitoService.onForgotPasswordSuccess.subscribe(events.onSuccess);
    this.forgotPasswordSubscription.add(this.cognitoService.onForgotPasswordFailure.subscribe(events.onFailure));
    this.forgotPasswordSubscription.add(
      this.cognitoService.onInputVerificationCode.subscribe(events.inputVerificationCode)
    );

    this.cognitoService.forgetPassword(username);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  confirmPassword(email: string, verificationCode: string, newPassword: string): Observable<object> {
    const username = this.toUsername(email);
    return this.cognitoService.confirmPassword(username, verificationCode, newPassword);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  completeNewPassword(userAttributes, requiredAttributes, password: string): Observable<object> {
    return this.cognitoService.completeNewPasswordChallenge(userAttributes, requiredAttributes, password);
  }

  private getSessionData(): SessionData {
    return this.localStorageService.getSession(this.cognitoService.username as string);
  }

  private toUsername(email: string): string {
    return email.toLowerCase();
  }

  private saveCurrentSession(username, session: CognitoUserSession) {
    const sessionData = {
      idTokenKey: session.getIdToken().getJwtToken(),
      accessTokenKey: session.getAccessToken().getJwtToken(),
      refreshTokenKey: session.getRefreshToken().getToken(),
      clockDrift: session['clockDrift'],
      exp: session.getAccessToken().getExpiration()
    };
    this.localStorageService.setSession(username, sessionData);
  }
  // #endregion

  /**
   * Set Initiate Auth User Data
   */
  setInitiateAuthUserData(data: InitiateAuthData) {
    data = { ...data, email: this.toUsername(data.email) };
    this.initiateAuthData = data;
  }

  /**
   * Get Initiate Auth User Data
   */
  getInitiateAuthUserData(): InitiateAuthData {
    return this.initiateAuthData;
  }

  /**
   * Validate registration verification code
   */
  validateVerificationCode(username: string, verificationCode: string): Observable<string> {
    return new Observable<string>(observer => {
      this.cognitoService.validateVerificationCode(username, verificationCode).subscribe({
        next: () => {
          observer.next(SUCCESSFULLY_CODE);
          observer.complete();
        },
        error: error => observer.error(error)
      });
    });
  }

  /**
   * Validate mfa verification user code
   */
  verifyMfaCode(username: string, verificationCode: string): Observable<string> {
    return new Observable<string>(observer => {
      this.cognitoService.verifyMfaCode(username, verificationCode).subscribe({
        next: () => {
          observer.next(SUCCESSFULLY_CODE);
          observer.complete();
        },
        error: error => observer.error(error)
      });
    });
  }

  /**
   * Resend MFA Verification Code on resendCode called
   */
  public resendVerificationCode(email: string, password: string | undefined, rememberMe: string) {
    const username = this.toUsername(email);
    this.cognitoService.authenticateUser(username, password, rememberMe);
  }

  /**
   * Send mfa verification code
   */
  public sendMFACode(username: string, code: string, rememberMe: string): Observable<any> {
    return new Observable<string>(observer => {
      this.cognitoService.sendMFACode(username, code, rememberMe).subscribe({
        next: session => {
          this.saveCurrentSession(this.cognitoService.username, session);
          observer.next('SUCCESS');
          observer.complete();
        },
        error: error => observer.error(error)
      });
    });
  }
}
