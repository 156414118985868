<div class="resource-dialog-content">
  <!-- TABLE -->
  <ngx-datatable
    #filesTable
    class="material"
    [rows]="resources"
    columnMode="force"
    [headerHeight]="48"
    [footerHeight]="0"
    rowHeight="auto"
    [sorts]="[{ prop: 'startDate', dir: 'desc' }]"
    [messages]="ngxMessages"
  >
    <!-- ICON / THUMBNAIL -->
    <ngx-datatable-column name="" prop="path" [maxWidth]="100">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <img *ngIf="isImage(row.type)" [src]="sanitizeResourceUrl(row.thumb)" class="thumbnail-image" />
        <mat-icon *ngIf="!isImage(row.type)" [svgIcon]="resourceIcon(row.type)" class="css-js-icon"></mat-icon>
      </ng-template>
    </ngx-datatable-column>

    <!-- FILENAME -->
    <ngx-datatable-column name="Filename" prop="fileName" [maxWidth]="400">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <inline-edit
          [disabled]="row.status !== ResourceStatus.READY && row.status !== ResourceStatus.CANCELLED"
          [(value)]="row.name"
          [suffix]="row.extension"
        ></inline-edit>
      </ng-template>
    </ngx-datatable-column>

    <!-- SIZE -->
    <ngx-datatable-column name="Size" prop="size" [maxWidth]="100">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div>{{ row.size | parseSize }}</div>
      </ng-template>
    </ngx-datatable-column>

    <!-- UPLOAD PROGRESS / URI -->
    <ngx-datatable-column
      name="Upload progress | URI"
      prop="progress"
      cellClass="datatable-cell-progress"
      [maxWidth]="400"
    >
      <ng-template let-row="row" ngx-datatable-cell-template let-rowIndex="rowIndex">
        <!-- INDETERMINATE PROGRESS BAR -->
        <ng-container *ngIf="showIndeterminateProgressBar(row.status)">
          <mat-progress-bar class="file-progress-bar" color="primary" mode="indeterminate"></mat-progress-bar>
          <button
            *ngIf="row.status === ResourceStatus.FINISHED_UPLOAD"
            mat-icon-button
            class="cancel"
            (click)="onCancel(row)"
          >
            <mat-icon svgIcon="clear"></mat-icon>
          </button>
        </ng-container>

        <!-- DETERMINATE PROGRESS BAR -->
        <ng-container *ngIf="showDeterminateProgressBar(row.status)">
          <mat-progress-bar
            class="file-progress-bar"
            color="primary"
            mode="determinate"
            [value]="row.progress"
          ></mat-progress-bar>
          <button
            *ngIf="row.status === ResourceStatus.UPLOADING"
            mat-icon-button
            class="cancel"
            (click)="onCancel(row)"
          >
            <mat-icon svgIcon="clear"></mat-icon>
          </button>
        </ng-container>

        <!-- ICON AND LABELS -->
        <ng-container *ngIf="row.status !== ResourceStatus.UPLOADING">
          <mat-icon *ngIf="statusIcon(row.status)" [svgIcon]="statusIcon(row.status)"></mat-icon>
          <span
            *ngIf="statusLabel(row.status) && row.status !== ResourceStatus.DONE"
            class="status-label {{ row.status | lowercase }}"
          >
            {{ statusLabel(row.status) }}
          </span>
          <ng-container *ngIf="row.status === ResourceStatus.DONE">
            <div class="uri-field">
              <span>{{ row.publishedUrl }}</span>
              <button
                mat-icon-button
                class="copy"
                [cdkCopyToClipboard]="row.publishedUrl"
                (cdkCopyToClipboardCopied)="onCopyToClipboard($event)"
              >
                <mat-icon svgIcon="copy"></mat-icon>
              </button>
            </div>
          </ng-container>

          <!-- REMOVE BUTTON -->
          <button
            *ngIf="showRemoveButton(row.status)"
            mat-icon-button
            class="remove status-{{ row.status | lowercase }}"
            (click)="onRemove(row, rowIndex)"
          >
            <mat-icon svgIcon="clear"></mat-icon>
          </button>

          <!-- DELETE BUTTON -->
          <button
            *ngIf="row.status === ResourceStatus.DONE"
            mat-icon-button
            class="delete"
            (click)="onDeleteClick(row)"
          >
            <mat-icon svgIcon="trash"></mat-icon>
          </button>

          <!-- TRY AGAIN BUTTON -->
          <button *ngIf="showTryAgainButton(row.status)" mat-button class="try-again" (click)="onRetry(row, rowIndex)">
            TRY AGAIN
          </button>
        </ng-container>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>

  <!-- DROPZONE -->
  <ngx-file-drop
    *ngIf="showDrop"
    dropZoneClassName="full-size full-center"
    (onFileDrop)="onDropSuccessFile($event)"
    [multiple]="true"
    [accept]="acceptFiles"
  >
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      <div fxLayout="column" fxLayoutAlign="space-around center">
        <p>
          Drag file(s) here, or
          <span class="accent" (click)="fileInput.click()">
            browse your computer.
          </span>
        </p>
        <input hidden type="file" (change)="onFileBrowsed($event)" #fileInput multiple [accept]="acceptFiles" />
      </div>
    </ng-template>
  </ngx-file-drop>
</div>
