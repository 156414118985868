<div class="reset-password" [formGroup]="resetPasswordForm">
  <div>
    <mat-form-field>
      <input
        matInput
        [type]="hidepass ? 'password' : 'text'"
        placeholder="Create Password"
        formControlName="password"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="hidepass = !hidepass"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hidepass"
      >
        <mat-icon class="password-eye">{{ hidepass ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <mat-error *ngIf="passwordControl?.hasError('required')">Password is required</mat-error>
      <mat-error
        *ngIf="
          passwordControl?.touched &&
          !passwordControl?.hasError('required') &&
          passwordControl?.hasError('passwordValidator')
        "
      >
        <span class="">Your new password should meet the requirements as shown below.</span>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="pt-8">
      <input
        matInput
        [type]="hideconfirm ? 'password' : 'text'"
        placeholder="Confirm Password"
        formControlName="passwordConfirm"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="hideconfirm = !hideconfirm"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hideconfirm"
      >
        <mat-icon class="password-eye">{{ hideconfirm ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <mat-error *ngIf="passwordConfirmControl?.touched && passwordConfirmControl?.hasError('required')">
        Password confirmation is required
      </mat-error>
    </mat-form-field>

    <mat-error
      *ngIf="
        resetPasswordForm.errors &&
        !passwordConfirmControl?.hasError('required') &&
        resetPasswordForm.errors['password-mismatch']
      "
    >
      Passwords must match.
    </mat-error>

    <div class="detail-text password-contain text-left mt-16">
      <strong>Password must contain at least:</strong>
      <div>
        <span
          *ngIf="passwordControl?.touched && (properties.notMinChar || properties.notMaxChar)"
          class="material-icons cancel-circle"
        >
          cancel
        </span>
        <span
          *ngIf="passwordControl?.touched && !properties.notMinChar && !properties.notMaxChar"
          class="material-icons check-circle"
        >
          check_circle
        </span>
        <span *ngIf="!passwordControl?.touched && properties.bullet" class="material-icons bullet">
          noise_control_off
        </span>
        12 to 256 characters
      </div>
      <div>
        <span *ngIf="passwordControl?.touched && properties.notNumber" class="material-icons cancel-circle">
          cancel
        </span>
        <span *ngIf="passwordControl?.touched && !properties.notNumber" class="material-icons check-circle">
          check_circle
        </span>
        <span *ngIf="!passwordControl?.touched && properties.bullet" class="material-icons bullet">
          noise_control_off
        </span>
        One number
      </div>
      <div>
        <span *ngIf="passwordControl?.touched && properties.notSpecial" class="material-icons cancel-circle">
          cancel
        </span>
        <span *ngIf="passwordControl?.touched && !properties.notSpecial" class="material-icons check-circle">
          check_circle
        </span>
        <span *ngIf="!passwordControl?.touched && properties.bullet" class="material-icons bullet">
          noise_control_off
        </span>
        One special character
      </div>
      <div>
        <span
          *ngIf="passwordControl?.touched && (properties.notUppercase || properties.notLowercase)"
          class="material-icons cancel-circle"
        >
          cancel
        </span>
        <span
          *ngIf="passwordControl?.touched && !properties.notUppercase && !properties.notLowercase"
          class="material-icons check-circle"
        >
          check_circle
        </span>
        <span *ngIf="!passwordControl?.touched && properties.bullet" class="material-icons bullet">
          noise_control_off
        </span>
        One upper & One lower Case
      </div>
    </div>
  </div>
</div>
