import {
  Component,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import {
  Resource,
  ResourceStatus,
  ResourceType,
  ResourceUtils,
  ResourceDialogState,
  ResourceUpload
} from '@neptune/models/resource';
import { Observable, of, from } from 'rxjs';
import { concatMap, mergeMap } from 'rxjs/operators';
import { ConfirmInput, ConfirmDialog } from '../../confirm-dialog/confirm-dialog';
import { ResourceService } from '@neptune/services/resource.service';
import { Asset } from '@neptune/models/asset';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';

interface ResourceUploadMap {
  fileName: string;
  resource: Resource;
  index: number;
  replace?: boolean;
}

@Component({
  selector: 'app-resource-dialog-content',
  templateUrl: './resource-dialog-content.component.html',
  styleUrls: ['./resource-dialog-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceDialogContentComponent {
  // Main source of resources
  @Input('resources') resources: Resource[];
  @Output('resourcesChange') resourcesChange: EventEmitter<Resource[]> = new EventEmitter();

  // Parent will show snackbar
  @Output('copiedToClipboard') copiedToClipboard: EventEmitter<any> = new EventEmitter();

  // State is set by parent depending on resources status
  @Input('state') set state(value: ResourceDialogState) {
    this._state = value;
    this.changeDetector.markForCheck();
  }

  private _state: ResourceDialogState;

  // Data needed to construct presigned_url
  @Input('projectId') projectId: string;
  @Input('folderId') folderId: string;
  @Input('bucketName') bucketName: string;
  @Input('resourcePath') resourcePath: string;

  // Used to compare existing assets and take action (replace or not)
  @Input('assets') assets: Asset[];

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  // Used to show/hide replace dialog
  public APPLY_TO_ALL: boolean = false;
  public APPLY_TO_ALL_REPLACE: boolean = false;
  public CONFIRM_DIALOG_DATA: ConfirmInput = {
    title: 'Replace existing file?',
    message: `File __FILENAME__ already exists. Do you want to replace it with the one you're uploading?`,
    okMessage: 'REPLACE',
    cancelMessage: `DON'T REPLACE`,
    checkbox: true,
    checkboxLabel: 'Apply to all'
  };

  // Pubic enums
  public ResourceType = ResourceType;
  public ResourceStatus = ResourceStatus;

  public ngxMessages = {
    // Message to show when array is presented
    // but contains no values
    emptyMessage: ''
  };

  public get showDrop() {
    return this._state === ResourceDialogState.UPLOAD;
  }

  public get acceptFiles() {
    return ResourceUtils.getAllAllowedTypes().join(',');
  }

  constructor(
    private dialog: MatDialog,
    private resourceService: ResourceService,
    private changeDetector: ChangeDetectorRef,
    private domSanitizer: DomSanitizer
  ) {}

  /**
   * Displays icon in row depending on status
   */
  public statusIcon(status: ResourceStatus): string {
    return ResourceUtils.getStatusIcon(status);
  }

  /**
   * Displays icon in row depending on status
   */
  public resourceIcon(type: ResourceType): string {
    return ResourceUtils.getResourceIcon(type);
  }

  /**
   * Display label in row depending on status
   */
  public statusLabel(status: ResourceStatus): string {
    return ResourceUtils.getStatusLabel(status);
  }

  public isImage(type: ResourceType): boolean {
    return ResourceUtils.isImage(type);
  }

  public showDeterminateProgressBar(status: ResourceStatus): boolean {
    return this.resourceService.showDeterminateProgressBar(status);
  }

  public showIndeterminateProgressBar(status: ResourceStatus): boolean {
    return this.resourceService.showIndeterminateProgressBar(status);
  }

  public showRemoveButton(status: ResourceStatus): boolean {
    return (
      status === ResourceStatus.CANCELLED ||
      status === ResourceStatus.ERROR ||
      status === ResourceStatus.INVALID ||
      status === ResourceStatus.READY ||
      status === ResourceStatus.DELETED
    );
  }

  public showTryAgainButton(status: ResourceStatus): boolean {
    return status === ResourceStatus.ERROR || status === ResourceStatus.CANCELLED;
  }

  /**
   * Called from parent when the user hits the UPLOAD button.
   */
  upload() {
    this.APPLY_TO_ALL = false;
    from(this.resources)
      .pipe(concatMap((resource, index) => this._checkExisting(resource, index) as Observable<ResourceUploadMap>))
      .subscribe((next: ResourceUploadMap) => {
        if (next) {
          this._doUpload(next);
        }
      });
  }

  /**
   * We switch the status to READY before moving on
   */
  onRetry(resource: Resource, index: number) {
    resource.status = ResourceStatus.READY;
    this.APPLY_TO_ALL = false;
    this._triggerChange();
    this._checkExisting(resource, index)?.subscribe((next: ResourceUploadMap) => {
      if (next) {
        this._doUpload(next);
      }
    });
  }

  /**
   * Called by parent.
   * Upload in progress will be cancelled, and complete uploads will be deleted
   * */
  cancel() {
    this.resources.forEach(resource => {
      if (resource.id) {
        this.resourceService.deleteResource(resource).subscribe(this._triggerChange.bind(this));
      }
      this.onCancel(resource);
    });
  }

  // PUBLIC HANDLERS
  /**
   * Called when hitting trash icon
   */
  onRemove(resource: Resource, index: number) {
    this.onCancel(resource);
    this.resources = [...this.resources.slice(0, index), ...this.resources.slice(index + 1)];
    this._triggerChange();
  }

  /**
   * Called when hitting cancel/cross icon
   */
  onCancel(resource: Resource) {
    const req = resource.upload;

    if (req && req.sub && !req.sub.closed) {
      req.sub.unsubscribe();
    }

    resource.status = ResourceStatus.CANCELLED;
    this._triggerChange();
  }

  /**
   * Called when hitting trash icon
   */
  onDeleteClick(resource: Resource) {
    resource.status = ResourceStatus.DELETE;
    this._triggerChange();
    this.resourceService.deleteResource(resource).subscribe(res => {
      resource.status = ResourceStatus.DELETED;
      this._triggerChange();
    });
  }

  onCopyToClipboard(copied: boolean) {
    if (copied) {
      this.copiedToClipboard.emit();
    }
  }

  sanitizeResourceUrl(base64: string) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(base64);
  }

  // NGX DRAG DROP
  /**
   * This function a describe success file.
   */
  onDropSuccessFile(files: NgxFileDropEntry[]) {
    const filesPromises = files
      .map(x => x.fileEntry as FileSystemFileEntry)
      .map(
        x =>
          new Promise<File>((resolve, reject) => {
            x.file((file: File) => {
              resolve(file);
            });
          })
      );

    Promise.all(filesPromises)
      .then(async _files => {
        this.resources = await this._setFiles(_files);
        this._triggerChange();
      })
      .catch(err => console.error(err));
  }

  /**
   * Handler for retrieving file via browse
   */
  async onFileBrowsed(event) {
    const files = [] as any[];

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < event.target.files.length; i++) {
      files.push(event.target.files[i] as never);
    }

    this.resources = await this._setFiles(files);

    // Clear fileInput in case we remove a file and decide to added in the same instance.
    // If we don't clear the input, it'll never trigger change event sice FileList still references the initial selection
    this.fileInput.nativeElement.value = '';

    this._triggerChange();
  }

  /**
   * Convert File[] to Resource[] and merge with existing ones.
   */
  private _setFiles(files: File[]): Promise<Resource[]> {
    return new Promise((resolve, reject) => {
      const resources = files
        .filter((file: File) => this.resources.findIndex(r => r.fileName === file.name) === -1)
        .map((file: File) => ResourceUtils.convertFileToResource(file, this.projectId, this.folderId));

      const previews: Promise<any>[] = [];
      resources.forEach(resource => {
        // Only handle upload subscriptions for valid files
        if (resource.status === ResourceStatus.READY) {
          const file = files.find(f => f.name === resource.fileName) as File;
          resource.upload = {
            config: {
              s3key: '',
              bucketName: this.bucketName,
              contentType: file?.type as string
            },
            file: file as File
          };

          if (ResourceUtils.isImage(resource.type)) {
            previews.push(
              new Promise<void>(_resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file as File);
                reader.onload = () => {
                  resource.thumb = reader.result as string | ArrayBuffer;
                  _resolve();
                };
              })
            );
          }
        }
      });

      Promise.all(previews).then(() => resolve([...this.resources, ...resources]));
    });
  }

  /**
   * First we discard resources that don't have a READY status.
   * Then we check the filename agains the resource assets list present in the opened folder.
   * If there are no collisions, we move to the upload stream process.
   *
   * If there are duplicates, the user is prompted with a "confirm replacement" dialog.
   * When the user discards the replacement, the flow stops to force the user to take action (change the filename or remove it from the queue).
   * If the user checks "APPLY TO ALL" checkbox on dialog, the UI will pass a replace: true flag to the upload stream process without prompting the user again.
   */
  private _checkExisting(resource: Resource, index: number): Observable<ResourceUploadMap> | null {
    // NOTE: we're performing filter inside the concatMap since we need to know the real index in the resources array
    if (resource.status === ResourceStatus.READY) {
      const fileName = (resource.name as string) + resource.extension;
      const dupe: Asset = this.assets.find(asset => asset.name === fileName) as Asset;

      if (dupe) {
        const resourceDupe: Resource = {
          ...resource,
          id: dupe.assetId
        };
        const dupeData: ResourceUploadMap = {
          fileName,
          resource: resourceDupe,
          index,
          replace: true
        };

        if (!this.APPLY_TO_ALL) {
          const dialog = ConfirmDialog.open(this.dialog, {
            ...this.CONFIRM_DIALOG_DATA,
            message: this.CONFIRM_DIALOG_DATA.message?.replace('__FILENAME__', fileName)
          });

          return dialog.afterClosed().pipe(
            // Called when closed
            mergeMap(replace => {
              if (dialog.componentInstance.checked) {
                this.APPLY_TO_ALL = true;
                this.APPLY_TO_ALL_REPLACE = replace;
              }
              return replace ? of(dupeData) : ((of(null) as unknown) as Observable<ResourceUploadMap>);
            })
          ) as Observable<ResourceUploadMap>;
        }
        return this.APPLY_TO_ALL_REPLACE
          ? (of(dupeData) as Observable<ResourceUploadMap>)
          : ((of(null) as unknown) as Observable<ResourceUploadMap>);
      }
      return of({ fileName, resource, index });
    } else {
      return (of(null) as unknown) as Observable<ResourceUploadMap>;
    }
  }

  /**
   * Setup of the upload stream process for a resource.
   * See _setUploadObserver/_setUploadSubscription methods for more details.
   */
  private _doUpload({ fileName, resource, index, replace }: ResourceUploadMap) {
    const req = resource.upload;
    resource.fileName = fileName;
    const path = `${this.resourcePath}/${this.projectId}/${this.folderId}/`;
    (req as ResourceUpload).config.s3key = path + resource.fileName;
    (req as ResourceUpload).obs = this.resourceService.setUploadObserver(resource, req as ResourceUpload, replace);
    (req as ResourceUpload).sub = this._setUploadSubscription(req?.obs as any, index);
    req?.sub?.add(this._handleUnsubscribe(index));
  }

  /**
   * This function will be executed for each resource in the upload stream process.
   * Takes care of subscribing to the stream and updating the UI accordingly.
   */
  private _setUploadSubscription(obs: Observable<any>, index: number) {
    this.resources[index].status = ResourceStatus.IN_PROGRESS;
    this.resources[index].progress = 0;

    this._triggerChange();

    return obs.subscribe({
      next: next => {
        this.resources[index] = { ...next };
        this._triggerChange();
      },
      error: error => {
        console.error(error);
        this.resources[index].status = ResourceStatus.ERROR;
        this._triggerChange();
      },
      complete: () => {
        this.resources[index].upload = null;
        this._triggerChange();
      }
    });
  }

  /**
   * Whenever the upload subscription completes, fails or the user cancel it,
   * this function will get called. We do some cleaning if necessary.
   */
  private _handleUnsubscribe(index: number) {
    return () => {
      const resource = this.resources[index];
      // Cancel upload
      if (resource.upload && resource.upload.uploadSub && !resource.upload.uploadSub.closed) {
        resource.upload.uploadSub.unsubscribe();
      }
      // NOTE: Completed observers calls unsubcribe method,
      // Delete posted records only if not finished successfully or are not being deleted
      if (resource.status !== ResourceStatus.DONE && resource.status !== ResourceStatus.DELETE && resource.id) {
        this.resourceService.deleteResource(resource).subscribe(this._triggerChange.bind(this));
      }
    };
  }

  /**
   * Update the resources array and triggers a UI update
   */
  private _triggerChange() {
    this.resources = [...this.resources];
    this.resourcesChange.emit(this.resources);
    this.changeDetector.detectChanges();
  }
}
