import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CanReceiveKeySelectorDataDirective } from './can-receive-key-selector-data/can-receive-key-selector-data.directive';
import { ShowAssetMultipleVersionIconDirective } from './show-asset-multiple-version-icon/show-asset-multiple-version-icon.directive';
import { StepperPositionDirective } from './stepper-position.directive';

@NgModule({
  declarations: [CanReceiveKeySelectorDataDirective, ShowAssetMultipleVersionIconDirective, StepperPositionDirective],
  imports: [CommonModule],
  exports: [CanReceiveKeySelectorDataDirective, ShowAssetMultipleVersionIconDirective, StepperPositionDirective]
})
export class NeptuneDirectivesModule {}
