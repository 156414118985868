import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toCapitalCase'
})
export class ToCapitalCasePipe implements PipeTransform {
  transform(value: string, ...args: string[]): string {
    return value
      ?.replace(/([A-Z]+)/g, ' $1') // Add space before a Capital letter -> "ThisIsTheUSA" to " This Is The USA"
      .toLowerCase()
      .replace(/[-_]/g, ' ') // Replace all _ and - for spaces
      .replace(/ +/g, ' ') // Reduce multiple spaces into one.
      .replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase())) // Upper case first letter of each word
      .trim();
  }
}
