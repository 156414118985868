import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TimezoneUtils } from '@neptune/models/timezone';

enum Geolocation {
  LATITUDE = 'LATITUDE',
  LONGITUDE = 'LONGITUDE'
}

/**
 * Validator for user password inside te system.
 */
export const passwordValidator: (control: AbstractControl) => ValidationErrors | null = (
  control: AbstractControl
): ValidationErrors | null =>
  !/\d/.test(control.value)
    ? { pattern: 'Must contain at least: One number' }
    : !/[A-Z]/.test(control.value)
    ? { pattern: 'Must contain at least: One upper case character' }
    : !/[a-z]/.test(control.value)
    ? { pattern: 'Must contain at least: One lower case character' }
    : !/[!@#$%^&]/.test(control.value)
    ? { pattern: 'Must contain at least: One special character' }
    : !(control.value.length >= 8)
    ? { pattern: 'Must contain at least: 12 characters' }
    : !(control.value.length <= 256)
    ? { pattern: 'Must contain at most: 256 characters' }
    : null;

/**
 * Validator to set HH:MM time in between to ranges.
 */
export const timeValidator: (min: string | null, max: string | null) => ValidatorFn = (
  min: string | null,
  max: string | null
): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
  const format: string = 'HH:mm';
  if (min && TimezoneUtils.createMoment(control.value, format).isBefore(TimezoneUtils.createMoment(min, format))) {
    return {
      time: 'Time is below minimum'
    };
  }
  if (max && TimezoneUtils.createMoment(control.value, format).isAfter(TimezoneUtils.createMoment(max, format))) {
    return {
      time: 'Time is above maximum'
    };
  }
  return null;
};

/**
 * Validator to set date in between a max and a min.
 */
export const dateValidator: (min: Date | null, max?: Date | null) => ValidatorFn = (
  min: Date | null,
  max?: Date | null
): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
  if (!TimezoneUtils.createMoment(control.value).isValid()) {
    return {
      time: 'invalid format'
    };
  }
  if (min && control.value.isBefore(TimezoneUtils.createMoment(min))) {
    return {
      time: 'below min'
    };
  }
  if (max && control.value.isAfter(TimezoneUtils.createMoment(max))) {
    return {
      time: 'above max'
    };
  }
  return null;
};

export const REQUIRED_VALIDATION_LABEL = 'You must enter a value.';
export const ALLOWED_CHARS_LABEL = 'Only alphanumeric characters and underscore are allowed.';
export const FIELD_STARTED_WITH_LETTER_LABEL = 'Field name must start with a letter.';
export const FIELD_INVALID_PREFIX = 'Invalid prefix.';
export const TABLE_STARTED_WITH_LETTER_LABEL = 'Table name must start with a letter.';
export const FIELD_INVALID_NAME = 'Invalid name. Longitude and Latitude are system names';

/**
 * Valid characters allowed to use as DynamoDB attributes.
 *
 * Referenec: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Character_Classes
 */
export const allowedChars: (control: AbstractControl) => ValidationErrors | null = (
  control: AbstractControl
): ValidationErrors | null => (!/^[\w]+$/.test(control.value) ? { allowed: ALLOWED_CHARS_LABEL } : null);

/**
 * Validates srting starts with a letter (case insensitive)
 */
export const startsWithLetterRegexTest = (value: string): boolean => /^[a-zA-Z]{1}.*$/.test(value);

/**
 * Validates table name input starts with a letter (case insensitive)
 */
export const tableStartsWithLetter: (control: AbstractControl) => ValidationErrors | null = (
  control: AbstractControl
): ValidationErrors | null =>
  startsWithLetterRegexTest(control.value) ? null : { startsWith: TABLE_STARTED_WITH_LETTER_LABEL };

/**
 * Max length input valid label
 */
export const maxLengthValidationLabel = (value: number): string => `Length can be no more than ${value} characters.`;

/**
 * Validates field name input starts with a letter (case insensitive)
 */
export const fieldStartsWithLetter: (control: AbstractControl) => ValidationErrors | null = (
  control: AbstractControl
): ValidationErrors | null =>
  startsWithLetterRegexTest(control.value) ? null : { startsWith: FIELD_STARTED_WITH_LETTER_LABEL };

/**
 * Regex to check if a field its a System field,
 * which are those that have QP as a prefix
 * Tests true if string DOES NOT contains "qp" (case insensitive) prefix
 */
export const QPSystemPrefixRegex = new RegExp(/^(?!qp).*$/i);

/**
 * Regex to check if a field its a System field,
 * which are those that have QP as a prefix
 * Tests true if string DOES NOT contains "qp" (case insensitive) prefix
 */
export const PURLPrefixRegex = new RegExp(/^(?!_purl).*$/i);

/**
 * Tests if string starts with QP prefix (case insensitive)
 */
export const noQPPrefixRegexTest = (value: string): boolean => QPSystemPrefixRegex.test(value);

/**
 * Tests if string starts with QP prefix (case insensitive)
 */
export const noPURLPrefixRegexTest = (value: string): boolean => PURLPrefixRegex.test(value);

/**
 * Tests if value doesn't starts with QPSystemPrefixRegex
 */
export const noQPrefix: (control: AbstractControl) => ValidationErrors | null = (
  control: AbstractControl
): ValidationErrors | null => (noQPPrefixRegexTest(control.value) ? null : { prefix: 'Invalid prefix.' });

/**
 * Tests if control value has a geolocation system field name
 */
export const hasControlSystemName: (control: AbstractControl) => ValidationErrors | null = (
  control: AbstractControl
): ValidationErrors | null =>
  control.value.toUpperCase() === Geolocation.LATITUDE || control.value.toUpperCase() === Geolocation.LONGITUDE
    ? { prefix: 'Invalid name.' }
    : null;

/**
 * Tests if value has a geolocation system field name
 */
export const hasSystemName = (value: string): boolean =>
  value.toUpperCase() === Geolocation.LATITUDE || value.toUpperCase() === Geolocation.LONGITUDE;

/**
 * Specific set of validators required for a table name field.
 */
export const validTableName = Validators.compose([tableStartsWithLetter, allowedChars]);

/**
 * Specific set of validators required for a field name.
 */
export const validFieldName = Validators.compose([noQPrefix, fieldStartsWithLetter, allowedChars]);

/**
 * Specific set of validators required for a field name.
 */
export const validSchemaFieldName = Validators.compose([
  noQPrefix,
  fieldStartsWithLetter,
  allowedChars,
  hasControlSystemName
]);

/**
 * Full Domain Email validator
 * https://github.com/angular/angular.js/issues/5899
 * http://w3c.github.io/html-reference/input.email.html
 */
const _emailWithFullDomain: (control: AbstractControl) => ValidationErrors | null = (
  control: AbstractControl
): ValidationErrors | null =>
  /^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(control.value)
    ? null
    : { username: 'Invalid Email' };

/**
 * Full Domain Email validator + Angular Email validator
 */
export const emailWithFullDomain = Validators.compose([Validators.email, _emailWithFullDomain]);

/**
 * Validator for username of a email
 * Refrence: https://blog.thoughtram.io/angular/2016/03/14/custom-validators-in-angular-2.html
 */
export const emailUsernameValidator: (control: AbstractControl) => ValidationErrors | null = (
  control: AbstractControl
): ValidationErrors | null =>
  // Note on regex:
  // ^ -> from the first char
  // [a-A-Z0-9] -> start with no symbol
  // Symbols -> [!#$%&'*+\/=?^_`{|}~.-] (I'll use a S to simplify)
  // (?!.*SS) -> Neg. Lookahead, do not repeat 2 consecutive symbol
  // [a-zA-Z0-9S] -> any valid character.
  // {1, 63} -> from 1 to 63 in length (total max is 64)
  // $ -> to the last char
  !/^[a-zA-Z0-9](?!.*[!#$%&'*+\/=?^_`{|}~.-][!#$%&'*+\/=?^_`{|}~.-])[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]{1,63}$/.test(
    control.value
  )
    ? { username: 'Invalid Username' }
    : null;

/**
 * Validator for a valid domain
 * Reference: https://www.regextester.com/97066
 * Added (\/[a-zA-Z0-9\-]{1,86}|\/)? at the end to allow one sub domain if needed
 */
export const domainValidator: (control: AbstractControl) => ValidationErrors | null = (
  control: AbstractControl
  // eslint-disable-next-line arrow-body-style
): ValidationErrors | null => {
  // eslint-disable-next-line max-len
  return !/^(?!(https:\/\/|http:\/\/|www\.|mailto:|smtp:|ftp:\/\/|ftps:\/\/))(((([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,86}[a-zA-Z0-9]))\.(([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,73}[a-zA-Z0-9]))\.(([a-zA-Z0-9]{2,12}\.[a-zA-Z0-9]{2,12})|([a-zA-Z0-9]{2,25})))|((([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,162}[a-zA-Z0-9]))\.(([a-zA-Z0-9]{2,12}\.[a-zA-Z0-9]{2,12})|([a-zA-Z0-9]{2,25}))))(\/[a-zA-Z0-9\-]{1,86}|\/)?$/.test(
    control.value
  )
    ? { domain: 'Invalid Domain' }
    : null;
};

/**
 * Validate if a string is part of the value.
 */
export const stringIncludedValidator: (stringGetter: () => string) => ValidatorFn = (
  stringGetter: () => string
): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
  if (control.value && stringGetter() && stringGetter().length > 0 && control.value.indexOf(stringGetter()) < 0) {
    return {
      string: `${stringGetter()} is not included`
    };
  }
  return null;
};

/**
 * Validate that value is not part of given array
 */
export const notEqualTo: (values: any[], getErrorMessage: (value: string) => string) => ValidatorFn = (
  values: any[],
  getErrorMessage: (value: string) => string
): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
  if (values.indexOf(control.value) >= 0) {
    return {
      notEqualTo: getErrorMessage(control.value)
    };
  }
  return null;
};
