import { Icon, IconType } from '@neptune/models/connector';

export class IconMap {
  private ICON_PATH: string = 'assets/images/data-connector/';
  private ICON_PREFIX: string = 'icon-';

  private _iconMap: Map<string, Icon> = new Map<string, Icon>([]);

  private addIcon(id: string, type: IconType) {
    if (this._iconMap.has(id)) {
      return;
    }
    this._iconMap.set(id, {
      type,
      value: this.ICON_PATH + this.ICON_PREFIX + id.toLowerCase()
    });
  }

  addSvgIcon(id: string) {
    this.addIcon(id, 'svg');
  }

  getOrCreateSvgIcon(id: string): Icon {
    if (!this._iconMap.has(id)) {
      this.addSvgIcon(id);
    }
    return this._iconMap.get(id) as Icon;
  }
}
