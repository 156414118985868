import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  IframeEvent,
  IframeEventName,
  IframeWrapperComponent
} from '@neptune/components/iframe-wrapper/iframe-wrapper.component';
import { LoadingModalComponent } from '@neptune/components/loading-modal/loading-modal.component';

@Component({
  selector: 'wysiwyg-editor',
  templateUrl: './wysiwyg-editor.component.html',
  styleUrls: ['./wysiwyg-editor.component.scss']
})
export class WysiwygEditorComponent implements OnInit {
  public SOURCE: string = 'contentbuilderjs/wysiwyg-editor.html';
  public SOURCE_ID: string = 'wysiwyg-editor';

  @ViewChild(LoadingModalComponent, { static: true })
  private loadingComponent: LoadingModalComponent;
  @ViewChild('iframeTemplate')
  iframeTemplate: IframeWrapperComponent;

  @Input('content') content: string;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output('onContentChange') onContentChange: EventEmitter<string> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output('onWysiwygClicked') onWysiwygClicked: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {}

  public customEvent(data: IframeEvent) {
    switch (data.custom) {
      case 'content-builder-loaded':
        this.loadTemplate(this.content);
        break;
      case 'html-content-changed':
        this.content = data.data as string;
        this.onContentChange.emit(this.content);
        break;
    }
  }

  public iframeEvent(event: IframeEvent, template?: string) {
    switch (event.type) {
      case IframeEventName.LOADED_WITH_ERRORS:
        this.loadingComponent.showError('There was an error loading Template builder');
        break;
      case IframeEventName.CLICKED:
        this.onWysiwygClicked.emit(event);
        break;
    }
  }

  public loadTemplate(template: string) {
    this.loadingComponent.stopLoading();
    this.iframeTemplate.postMessage({
      type: IframeEventName.CUSTOM,
      custom: 'load-template',
      data: template
    });
  }

  public pasteTextAtCaretInWysiwyg(text) {
    this.iframeTemplate.postMessage({
      type: IframeEventName.CUSTOM,
      custom: 'paste-text-at-caret',
      data: text
    });
  }
}
