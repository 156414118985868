import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusIconComponent } from './status-icon.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [StatusIconComponent],
  imports: [CommonModule, MatIconModule],
  exports: [StatusIconComponent]
})
export class StatusIconModule {}
