import { Component, Input, OnInit } from '@angular/core';
import { ButtonConfigType, ToolbarButtonConfiguration } from '@neptune/models/buttons';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss']
})
export class ButtonGroupComponent implements OnInit {
  @Input('buttonsConfig') buttonsConfig: ToolbarButtonConfiguration[];

  public ButtonConfigType = ButtonConfigType;

  ngOnInit() {}
}
