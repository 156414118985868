import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessageKeySelectorTargetService {
  private _focusedElement?: ElementRef;

  public static mockService() {
    return {
      setFocus: jest.fn(() => {}),
      removeFocus: jest.fn(() => {}),
      hasFocus: jest.fn(() => true),
      focusedElement: jest.fn(() => {}),
      someElementHasFocus: jest.fn(() => {}),
      insertTextOnFocusedElement: jest.fn(() => {})
    };
  }

  public setFocus(el: ElementRef) {
    this._focusedElement = el;
  }

  public removeFocus(isMessageKeySelectorPicker?: boolean) {
    if (isMessageKeySelectorPicker) {
      this._focusedElement?.nativeElement.focus();
    } else {
      this._focusedElement = undefined;
    }
  }

  public hasFocus(el: ElementRef): boolean {
    if (!el || !this._focusedElement) {
      return false;
    }
    return el.nativeElement.isEqualNode(this._focusedElement.nativeElement);
  }

  public get focusedElement(): ElementRef {
    return this._focusedElement as ElementRef;
  }

  public someElementHasFocus(): boolean {
    return this._focusedElement !== undefined;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public insertTextOnFocusedElement(textToInsert: string, customInsertFunction?: Function): string {
    if (customInsertFunction) {
      return customInsertFunction(textToInsert);
    } else {
      return this.insert(textToInsert);
    }
  }

  private insert(textToInsert: string) {
    // save selection start and end position
    const input = this._focusedElement?.nativeElement;
    const start = input.selectionStart;
    const end = input.selectionEnd;

    // update the value with our text inserted
    const editedValue = input.value.slice(0, start) + textToInsert + input.value.slice(end);
    input.value = editedValue;

    // update cursor to be at the end of insertion
    input.selectionStart = input.selectionEnd = start + textToInsert.length;

    // It's necessary to dispatch a CHANGE EVENT because the value was changed with js code
    input.dispatchEvent(new Event('change'));

    return editedValue;
  }
}
