import { Pipe, PipeTransform } from '@angular/core';
import { ProjectItemFlatNode } from 'app/main/content/pages/user/my-projects/shared-components/project-list-database';

@Pipe({
  name: 'getNodeName'
})
export class GetNodeNamePipe implements PipeTransform {
  transform(node: ProjectItemFlatNode) {
    const name = node.item.length > 30 ? node.item.slice(0, 30) + '...' : node.item;
    return node.item?.name || name;
  }
}
