import { Injectable } from '@angular/core';
import { Folder, ObjectFolder, Project } from '@neptune/models/projects';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService, Endpoint } from './base.service';

@Injectable()
export class ProjectsService extends BaseService {
  /**
   * For use with jasmine testing, returns a mock of service
   */
  public static mockService(jasmine: unknown, project: Project): unknown {
    return {
      getAllProjects: jest.fn(() => of([])),
      deleteProject: jest.fn(() => of(true)),
      deleteProjectFolder: jest.fn(() => of([])),
      getProject: jest.fn(() => of(project)),
      createProject: jest.fn(() => of(project)),
      createProjectFolder: jest.fn(() => of([])),
      updateProject: jest.fn(() => of(project)),
      updateProjectFolder: jest.fn(() => of([])),
      getAllFoldersByProject: jest.fn(() => of([])),
      getFolderObjByProjectFolderId: jest.fn(() => of([])),
      getProjectAndFolder: jest.fn(() => of([])),
      cloneProject: jest.fn(() => of(project))
    };
  }

  /**
   * This function a get list projects
   */
  getAllProjects(): Observable<Project[]> {
    return super.baseGet<Project[]>(Endpoint.PROJECT, `projects`);
  }

  /**
   * Delete a project
   */
  deleteProject(projectId: string): Observable<null> {
    const url = `projects/${projectId}`;
    return super.baseDelete(Endpoint.PROJECT, url);
  }

  /**
   * Delete a project folder
   */
  deleteProjectFolder(projectId: string, folderId: string): Observable<null> {
    const url = `projects/${projectId}/folders/${folderId}`;
    return super.baseDelete(Endpoint.PROJECT, url);
  }

  /**
   * Get a project
   */
  getProject(projectId: string): Observable<Project> {
    const url = `projects/${projectId}`;
    return super.baseGet(Endpoint.PROJECT, url);
  }

  /**
   * Create a project
   */
  createProject<U extends Project>(project: U): Observable<Project> {
    return super.basePost(Endpoint.PROJECT, `projects`, project);
  }

  /**
   * Create a project folder
   */
  createProjectFolder<U extends Folder>(projectId: string, folder: U): Observable<Folder> {
    return super.basePost(Endpoint.PROJECT, `projects/${projectId}/folders`, folder);
  }

  /**
   * Update a project
   */
  updateProject<U extends Project>(projectId: string, project: U): Observable<Project> {
    return super.basePut(Endpoint.PROJECT, `projects/${projectId}`, project);
  }

  /**
   * Update a project folder
   */
  updateProjectFolder<U extends Folder>(projectId: string, folderId: string, project: U): Observable<Folder> {
    return super.basePut(Endpoint.PROJECT, `projects/${projectId}/folders/${folderId}`, project);
  }

  /**
   * This function a get list projects
   */
  getAllFoldersByProject(projectId: string): Observable<Folder[]> {
    return super
      .baseGet<Folder[]>(Endpoint.PROJECT, `projects/${projectId}/folders`)
      .pipe(
        map((folders: Folder[]) =>
          folders.sort((a, b) =>
            (a.name as string).toLocaleLowerCase() < (b.name as string).toLocaleLowerCase() ? -1 : 1
          )
        )
      );
  }

  /**
   * Get ObjectFolder by project and folder ids
   */
  getFolderObjByProjectFolderId(projectId: string, folderId: string): Observable<ObjectFolder> {
    return super.baseGet<ObjectFolder>(Endpoint.PROJECT, `projects/${projectId}/folders/${folderId}`);
  }

  /**
   * Get Project and ObjectFolder, a convenience function for getting both at once
   */
  getProjectAndFolder(projectId: string, folderId: string): Observable<{ project: Project; folder: ObjectFolder }> {
    return forkJoin({
      project: this.getProject(projectId),
      folder: this.getFolderObjByProjectFolderId(projectId, folderId)
    });
  }

  /**
   * Clone a project
   */
  cloneProject(projectId: string): Observable<Project> {
    return super.basePost(Endpoint.PROJECT, `projects/${projectId}/clone`, {});
  }
}
