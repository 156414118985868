import { AssetType } from './asset';

// eslint-disable-next-line no-shadow
export enum PurlStatus {
  DRAFT = 'DRAFT',
  APPROVED = 'APPROVED'
}

export interface PurlCollisionDetection {
  numberOfCharacters?: number;
  randomCharacters?: boolean;
  autoIncrement: boolean;
}
export interface Purl {
  name: string; // Name of the Purl.
  projectId: string | null; // Project Id that this Purl belongs.
  assetId: string; // Unique Identifier for Asset.
  baseTableColumnName: string; // This is the name of the purl column which will be added to the ‘baseTableName’.
  baseTableName: string; // The table whose columns will be used for defining the purl column.
  domainId: string; // The domain used for this Purl.
  expression: string; // The string expression which will be used for creating purl url
  id: string; // Unique Identifier for Purl.
  insertedAt: string; // Date and time of when the records gets inserted.
  insertedBy: string; // user/process that inserted this entry.
  orgId: string; // Organization Id that this Purl belongs.
  targetLandingPage: string; // The page the ends up when the user clicks the purl url.
  updatedAt: string; // Dateand time of when the records gets modified.
  updatedBy: string; // user/process that modified this entry.
  collisionDetection: PurlCollisionDetection;
  status: PurlStatus;
  assetType: AssetType;
}

export interface PurlData {
  // The string expression which will be used for creating Purl url
  expression: string;
  // Unique identifier of an Asset
  assetId: string;
  // The name of the Purl.
  name: string;
  //   projectId: string;
  collisionDetection: PurlCollisionDetection;
  // Project Id that this Purl belongs to
  projectId: string;
  // The id of the domain used for this Purl
  domainId: string;
  // The table whose columns will be used for defining the Purl column
  baseTableName: string;
  // The page that ends up when the user clicks the Purl url
  targetLandingPage: string;
  // Status
  status: PurlStatus;
}
