<div class="resource-dialog">
  <loading-modal [loading]="loading"></loading-modal>
  <div mat-dialog-title class="dialog-header">
    <div class="dialog-title">
      <span class="h2">{{ title }}</span>
    </div>
  </div>

  <mat-dialog-content>
    <div class="resource-dialog__content">
      <p *ngIf="data?.description">{{ data?.description }}</p>
      <ng-container *ngIf="!loading">
        <div class="resource-dialog__content__container" fxLayout>
          <app-resource-dialog-content
            #resourceUpload
            fxFlex
            [state]="currentState"
            [projectId]="data?.projectId"
            [folderId]="data?.folderId"
            [bucketName]="bucketName"
            [resourcePath]="resourcePath"
            [assets]="data?.assets"
            [(resources)]="files"
            (resourcesChange)="onFilesChange($event)"
            (copiedToClipboard)="onCopiedToClipboard()"
          ></app-resource-dialog-content>
        </div>
      </ng-container>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayoutAlign="end start" class="dialog-footer resource-dialog__footer" fxLayout="row nowrap">
    <div fxFlex class="files-status">
      <ng-container *ngFor="let status of fileStatuses">
        <span *ngIf="status.count > 0" class="{{ status.class }}">{{ status.count }} {{ status.label }}</span>
      </ng-container>
    </div>
    <button mat-button color="primary" [disabled]="isCancelDisabled" (click)="onClose()" class="cancel-btn">
      Cancel
    </button>
    <button
      *ngIf="showButton(State.UPLOAD)"
      mat-flat-button
      color="primary"
      [disabled]="isDisabled(State.UPLOAD)"
      (click)="onUpload()"
    >
      Upload
    </button>
    <button *ngIf="showButton(State.UPLOADING)" mat-button color="primary" [disabled]="isDisabled(State.UPLOADING)">
      Uploading...
    </button>
    <button
      *ngIf="showButton(State.DONE)"
      mat-flat-button
      color="primary"
      [disabled]="isDisabled(State.DONE)"
      (click)="onSave()"
    >
      Done
    </button>
  </mat-dialog-actions>
</div>
