<div class class="response-dialog">
  <div mat-dialog-title class="dialog-header">
    <div class="dialog-title">
      <span class="h2">Response</span>
    </div>
  </div>

  <mat-dialog-content>
    <div class="description-container">
      <div class="mb-20">
        <span [innerHTML]="message"></span>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayoutAlign="end start" class="dialog-footer" fxLayout="row nowrap">
    <button mat-flat-button color="primary" (click)="onClose()">Close</button>
  </mat-dialog-actions>
</div>
