import { Component, OnInit } from '@angular/core';
import { SideDrawersBaseComponent } from '../side-drawers-base/side-drawers-base.component';

@Component({
  selector: 'side-drawers-content',
  templateUrl: '../side-drawers-base/side-drawers-base.component.html',
  styleUrls: ['../side-drawers-base/side-drawers-base.component.scss']
})
export class SideDrawersContentComponent extends SideDrawersBaseComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}
