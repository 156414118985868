import { TaskType } from '.';
import { StoreObject } from '../services/store.service';
import { ConnectorData } from './connector';
import { ExpressionField, ExpressionType, TaskCategory } from './expression';
import { DataType, DialogOutputStatus, FileFormat, FileUpload, FileUploadModel, UploadFileInfo } from './file';
import { UploadType } from './my-files';

export class Profile {
  id?: string;
  name: string;
  type: TaskCategory;
}

export interface ProfileDialogResult {
  status: DialogOutputStatus;
  profile?: Profile;
}

/**
 * Class to hold data used throughout the table export process
 */
export class TableExportStore extends StoreObject {
  // id for storage of retrieval
  public static ID: string = 'TableExportStore';
  // id of table being exported
  tableId: string;
  // name of table being exported
  tableName: string;
  // id of table query
  queryId: string;
  // name of query
  queryName: string;
  // object defining destination
  connector: ConnectorData;
  // secret name for destination access
  connectorInstanceId: string;
  // source fields
  sourceFields: ExportSourceField[];
  // destination fields
  destinationFields: DestinationField[];
  // name of table or file to map to
  targetName: string;
  // format for file export (delimiter, qualifier, header)
  fileFormat: FileFormat;
  // destination path
  path: string;
  // destination isNewTable
  isNewTable?: boolean;
}

/**
 * Class to hold data used throughout the table creation process
 */
export class TableCreationStore extends StoreObject {
  // id for storage of retrieval
  public static ID: string = 'TableCreationStore';
  // name of table being created
  targetName: string;
  // id of table selected for pipeline
  targetId?: string;
  // object defining source
  connector: ConnectorData;
  // Id of connector instance for source access
  connectorInstanceId: string;
  // in case of connect type, id selected table within source
  sourceTableId: string;
  // in case of connect type, name selected table within source
  sourceTableName?: string;
  // in case of file upload type, data defining file
  uploadFile: UploadFileInfo;
  // expressionFields
  expFields: ExpressionField[];
  // source table fields
  sourceFields: ImportSourceField[];

  // specific to file import
  fileModel: FileUploadModel;
  fileUpload: FileUpload;
}

// ////////////////// PIPELINE ////////////////////

export class PipelineProfile extends Profile {
  destination: {
    name: string;
    destinationId: string;
    fields?: ExpressionField[];
  };
  source: ProfileSource;
  id?: string;
  name: string;
  sourceTableName?: string;
}

export interface ProfileSourceParameters {
  connectorId?: UploadType;
  instanceId: string;
  fileId?: string;
  filePath?: string;
  childOrgId?: string;
}
export class ProfileSource {
  connectorId: string;
  parameters: ProfileSourceParameters;
  fields: ImportSourceField[];
  object: string;
  sourceDatabase?: string | undefined;
  sourceSchema?: string | undefined;
}

export interface ImportSourceField {
  name: string;
  included: boolean;
  dataType: DataType;
  filter: FieldFilter;
  isPrimaryKey?: boolean;
}

export interface FieldFilter {
  dataType: DataType;
  value: any;
  operator: string;
  dateValue?: DateValue;
}

export interface DateValue {
  dataType: string;
  quantity?: number | null;
  interval: string;
}

// ////////////////// DRAIN ////////////////////

export class DrainProfile extends Profile {
  source: {
    starTableId: string;
    query: {
      id: string;
      name: string;
    };
    fields: ExportSourceField[];
  };
  destination: {
    connectorId: string;
    parameters: {
      instanceId: string;
    };
    fields: DestinationField[];
    // table or file to map to
    object: string;
    path: string;
    isNewTable?: boolean;
  };
  fieldDelimiter: string;
  fieldQualifier: string;
}

export interface ExportSourceField {
  name: string;
  included: boolean;
  dataType: string;
}

export interface DestinationField {
  name: string;
  destinationValue: {
    type: ExpressionType.SOURCE;
    value: string;
  };
}

export interface ImportPaginationData {
  items: [ImportTableData[]];
  lastKeyAsGUID: string;
}

export interface ImportPaginationTokenData {
  lastToken: ImportPaginationLastTokenData;
  maxPage: string;
  pageSize: string;
  totalResults: number;
}

export interface ImportPaginationLastTokenData {
  id: string;
  orgId: string;
  type: string;
  updatedAt: string;
}

export interface EventPage {
  page: number;
}

export interface ImportTaskData {
  id: string;
  name: string;
  active: boolean;
  type: TaskType.IMPORT;
  scheduleId?: string;
  compressed: boolean;
  data: {
    sourceTable: {
      id: string;
      name: string;
      source: string;
      sourceParameters?: {
        instanceId: string;
        fileId: string;
      };
    };
    targetTable: {
      id: string;
      name: string;
      source: string;
    };
    pipeline: {
      id: string;
      name: string;
    };
    fileName?: string;
  };
}

export interface ScheduleTask {
  UpdatedAt: string;
  InsertedBy: string;
  NextRun: string;
  InsertedAt: string;
  Deleted: boolean;
  Expiration: string;
  UpdatedBy: string;
  Id: string;
  Active: boolean;
  OrgId: string;
  Schedule: {
    StartImmediately: boolean;
    StartDateTime: string;
  };
  LastRun: string;
  Name: string;
}

export interface ImportTableData {
  task: ImportTaskData;
  taskGroups?: ImportTaskData[];
  schedule?: ScheduleTask;
  compressed?: boolean;
}
