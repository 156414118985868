import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from '@neptune/material.module';
import { NeptunePipesModule } from '@neptune/pipes/pipes.module';
import { DatatypeIconModule } from '../datatype-icon/datatype-icon.module';
import { AggregateFunctionDialogModule } from './aggregate-function-dialog/aggregate-function-dialog.module';
import { FieldDialogComponent } from './field-dialog';

@NgModule({
  declarations: [FieldDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FuseSharedModule,
    DatatypeIconModule,
    AggregateFunctionDialogModule,
    NeptunePipesModule
  ],
  exports: [FieldDialogComponent],
  providers: []
})
export class FieldDialogComponentModule {}
