/* eslint-disable @typescript-eslint/ban-types */
export class org {
  OrgId: {
    type: String;
    hashKey: true;
  };
  ParentOrgId: String;
  SubOrgIds: [String];
  DomainsId: [String];
  ValidSelSignupDomains: [String];
  Name: String;
  Status: String;
  State: String;
  Owner: String;
  Resources: {
    type: 'map';
    map: {
      DataDB: {
        type: 'map';
        map: {
          ClusterName: String;
          DBName: String;
          SecretName: String;
          Status: String;
        };
      };
      PreviewDB: {
        type: 'map';
        map: {
          ClusterName: String;
          DBName: String;
          SecretName: String;
          Status: String;
        };
      };
      OrgBucket: {
        type: 'map';
        map: {
          DataExtractsPath: String;
          ExportPath: String;
          Name: String;
          RootPath: String;
          Status: String;
          UploadPath: String;
        };
      };
    };
  };
  TierInfo: {
    type: 'map';
    map: {
      Opened: String;
      Type: String;
    };
  };
}

export interface DetailResourcesOrg {
  DataDB: {
    type: string;
    map: {
      ClusterName: string;
      DBName: string;
      SecretName: string;
      Status: string;
    };
  };
  PreviewDB: {
    type: string;
    map: {
      ClusterName: string;
      DBName: string;
      SecretName: string;
      Status: string;
    };
  };
  OrgBucket: {
    type: string;
    map: {
      DataExtractsPath: string;
      ExportPath: string;
      Name: string;
      RootPath: string;
      Status: string;
      UploadPath: string;
    };
  };
}

export interface CleanupProcess {
  status: string;
  requestId: string;
}

export interface ResourcesOrg {
  type: string;
  map: DetailResourcesOrg;
}

export interface TierInfo {
  Opened?: string;
  Type: string;
}

// eslint-disable-next-line no-shadow
export enum OrgTypes {
  B2B = 'B2B',
  B2C = 'B2C'
}

export const NOPARENTORG = '--- No parent organization ---';

export interface OrgModel {
  OrgID: string;
  Name: string;
  OrgType?: string;
  ParentOrgId?: string;
  SubOrgIds?: string[];
  Status?: string;
  State?: string;
  Owner: string;
  Resources?: ResourcesOrg;
  Active?: boolean;
  DomainsId?: string[];
  ValidSelSignupDomains?: string[];
  Timezone: string;
  AllowSubOrg?: boolean;
  Children?: OrgModel[];
  UpdatedAt?: any;
  InsertedAt?: any;
}

// eslint-disable-next-line no-shadow
export enum CleanupStatus {
  SUCCEEDED = 'SUCCEEDED',
  RUNNING = 'RUNNING',
  WARNING = 'WARNING',
  FAILED = 'FAILED'
}

export class OrgUtils {
  static CleanupStatusMessagesMap = new Map<string, string>([
    [CleanupStatus.SUCCEEDED, 'Cleanup finished successfully'],
    [CleanupStatus.RUNNING, 'Cleanup in progress'],
    [CleanupStatus.WARNING, 'Cleanup finished but some steps failed'],
    [CleanupStatus.FAILED, 'Cleanup finished, all steps failed']
  ]);

  static getCleanupMessage(status: CleanupStatus): string {
    return OrgUtils.CleanupStatusMessagesMap.get(status) as string;
  }
}
