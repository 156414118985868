<div class="standard-list-dialog">
  <loading-modal #loadingComponent></loading-modal>

  <div mat-dialog-title class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="dialog-title" fxLayoutAlign="start center" fxLayout="row">
      <span class="h2">{{ entityName | titlecase }}</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <button *ngIf="showRefresh" mat-button matSuffix mat-icon-button class="refresh-btn" (click)="onRefresh()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>

  <mat-dialog-content>
    <div class="description-container">
      <div class="table-container" #containerBodytable></div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row nowrap" fxLayoutAlign="space-between start" class="dialog-footer">
    <!-- Use empty span to push other buttons to right when theres no buttonName -->
    <span *ngIf="!buttonName"></span>
    <button *ngIf="buttonName" mat-flat-button color="primary" (click)="onNewEntityFormDialog()">
      <span>+&nbsp;{{ buttonName }}</span>
    </button>
    <div fxLayout="row nowrap" fxLayoutAlign="end start">
      <button mat-button color="primary" (click)="onCancel()" class="cancel-btn">Cancel</button>
      <button mat-flat-button color="primary" (click)="onConfirm()">
        {{ confirmName }}
      </button>
    </div>
  </mat-dialog-actions>
</div>
