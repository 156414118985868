// import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Resource,
  ResourceDialogState,
  ResourcePresignedUrl,
  ResourcePresignedUrlGet,
  ResourceStatus,
  ResourceUpload,
  ResourceUtils
} from '@neptune/models/resource';
import { interval, Observable, of } from 'rxjs';
import { concatMap, concatWith, map, switchMap } from 'rxjs/operators';
import { BaseService, Endpoint } from './base.service';
import { CognitoService } from './cognito.service';
import { StoreService } from './store.service';
import { UploadService, UploadServiceReturnValue } from './upload.service';
import { Asset, OrgData } from '@neptune/models';
import { AccountService } from '@neptune/services/account.service';

@Injectable()
export class ResourceService extends BaseService {
  public RESOURCE: string = 'resources';
  public PRESIGNED: string = 'presigned_url';

  // Expiration in seconds for the presigned url to generate
  private EXPIRES: string = '300';

  constructor(
    protected http: HttpClient,
    protected cognitoService: CognitoService,
    protected storeService: StoreService,
    protected uploadService: UploadService,
    public accountService: AccountService
  ) {
    super(http, cognitoService, storeService);
  }

  /** ------------- CRUD METHODS ------------- */
  public getResources() {
    return this.baseGet(Endpoint.RESOURCE, this.RESOURCE, false);
  }

  public getResource(id: string): Observable<Resource> {
    return this.baseGet(Endpoint.RESOURCE, `${this.RESOURCE}/${id}`);
  }

  private _getPresignedUrl(data: ResourcePresignedUrlGet): Observable<ResourcePresignedUrl> {
    const url = `${this.PRESIGNED}/${data.bucketName}`;
    const params = {
      s3key: data.s3key,
      Expires: data.expires || this.EXPIRES,
      content_type: data.contentType
    };

    return this.baseGet(Endpoint.RESOURCE, url, false, params);
  }

  public putPresignedUrl(url: string, file): UploadServiceReturnValue {
    return this.uploadService.upload(url, file);
  }

  public deletePresignedUrl(url: string): Observable<any> {
    return this.http.delete(url);
  }

  public cloneResource(id: string, fileName: string, folderId: string): Observable<any> {
    return this.basePost(Endpoint.RESOURCE, `${this.RESOURCE}/${id}/clone`, {
      fileName,
      folderId
    });
  }

  private _postResource(resource: Resource): Observable<Resource> {
    return this.basePost(Endpoint.RESOURCE, this.RESOURCE, resource);
  }

  private _putResource(resource: Resource): Observable<Resource> {
    const url = `${this.RESOURCE}/${resource.id}`;
    return this.basePut(Endpoint.RESOURCE, url, resource);
  }

  private _deleteResource(resource: Resource): Observable<Resource> {
    const url = `${this.RESOURCE}/${resource.id}`;
    return this.baseDelete(Endpoint.RESOURCE, url);
  }

  public mockUpload(): Observable<any> {
    return new Observable(observer => {
      let count = 0;
      interval(25)
        .pipe(
          map(i => {
            if (i > 100) {
              const luck = Math.round(Math.random());
              if (luck) {
                observer.complete();
              } else {
                observer.error('Bad luck');
              }
            } else {
              observer.next(++count);
            }
          })
        )
        .subscribe();
    });
  }

  /** ------------- HELPER METHODS ------------- */
  public mapResourceToAsset(resources: Resource[]): Asset[] {
    return resources
      .filter(resource => resource.status === ResourceStatus.DONE)
      .map((resource: Resource) => ({
        assetId: resource.id,
        assetType: ResourceUtils.getAssetType(resource.type),
        folderId: resource.folderId,
        id: resource.assetVid,
        insertedAt: resource.insertedAt,
        insertedBy: resource.insertedBy,
        name: resource.fileName,
        orgId: resource.orgId,
        projectId: resource.projectId,
        status: resource.status,
        updatedAt: resource.updatedAt,
        updatedBy: resource.updatedBy
      }));
  }

  /**
   * Returns an Observable that internally triggers the flow:
   *
   * 1. GET presigned-url,
   * 2. POST to resource API (IN PROGRESS) if resource doesn't exists, PUT's otherwise
   * 3. UPLOAD via PUT to presigned-url,
   * 3. PUT to resource API (DONE)
   *
   * The flow can be cancelled only after we have saved the record to DynamoDB,
   * (after POST) to avoid saving horphan data/files.
   */
  public setUploadObserver(originalResource: Resource, req: ResourceUpload, replace?: boolean): Observable<any> {
    return this.getPresignedUrl(req, originalResource).pipe(
      switchMap((presignedResource: Resource) => {
        if (replace) {
          return this.putResource({
            ...originalResource,
            ...presignedResource,
            status: ResourceStatus.IN_PROGRESS
          }).pipe(
            concatMap(updatedResource =>
              of(updatedResource).pipe(
                concatWith(
                  this.uploadFile(req, presignedResource),
                  this.putResource({ ...updatedResource, status: ResourceStatus.DONE })
                )
              )
            )
          );
        } else {
          return this.postResource(presignedResource).pipe(
            concatMap((postedResource: Resource) =>
              of(postedResource).pipe(
                concatWith(
                  this.uploadFile(req, postedResource),
                  this.putResource({ ...postedResource, status: ResourceStatus.DONE })
                )
              )
            )
          );
        }
      })
    );
  }

  /**
   * Get's the presigned URL for the given resource
   */
  public getPresignedUrl(req: ResourceUpload, originalResource: Resource): Observable<Resource> {
    return this._getPresignedUrl(req.config).pipe(
      map((presignedUrl: ResourcePresignedUrl) => ({
        ...originalResource,
        ...presignedUrl
      }))
    );
  }

  /**
   * Posts the resource to DynamoDB.
   * Upon completion it sets the state to UPLOADING,
   * which will allow the user from this point forward to cancel the uplaod stream
   */
  public postResource(presignedResource): Observable<Resource> {
    return this._postResource({
      ...this._cleanModel(presignedResource),
      status: ResourceStatus.IN_PROGRESS
    }).pipe(
      map(resourceRecord => ({
        ...presignedResource,
        ...resourceRecord,
        status: ResourceStatus.UPLOADING
      }))
    );
  }

  /**
   * Put preseignedUrl uses UploadService internally to report progress.
   * This function will uploadte the resource state accordingly.
   */
  public uploadFile(req: ResourceUpload, presignedResource: Resource): Observable<Resource> {
    const request = this.putPresignedUrl(presignedResource.presigned_url as string, req.file);
    req.uploadSub = request.httpRequest;

    return request.progress.pipe(
      map(progress => {
        const uploaded = progress === 100;
        return {
          ...presignedResource,
          progress,
          status: uploaded ? ResourceStatus.FINISHED_UPLOAD : ResourceStatus.UPLOADING
        };
      })
    );
  }

  /**
   * // FIXME: once public path is ready replace base64 thumb
   * Calls the PUT resource API
   */
  public putResource(postedResource: Resource): Observable<Resource> {
    return this._putResource({
      ...postedResource,
      // Since we're using posted resource, we must manually match uploaded resource
      upload: null,
      progress: 100
      // FIXME: once public path is ready replace base64 thumb
      // thumb: uploaded ? presignedResource.publishedUrl : presignedResource.thumb
    }).pipe(
      map(resourceRecord => ({
        ...postedResource,
        ...resourceRecord
      }))
    );
  }

  /**
   * Helper to discard UI-only Resource properties
   */
  private _cleanModel(resource: Resource): Resource {
    return {
      status: resource.status,
      fileName: resource.fileName,
      type: resource.type,
      publishedUrl: resource.publishedUrl,
      presigned_url: resource.presigned_url,
      projectId: resource.projectId,
      folderId: resource.folderId,
      description: resource.description,
      active: true,
      deleted: false
    };
  }

  public deleteResource(resource: Resource) {
    resource.status = ResourceStatus.DELETE;
    return this._deleteResource(resource).pipe(
      map(r => ({
        ...r,
        status: ResourceStatus.DELETED
      }))
    );
  }

  public isCompleted(resources: Resource[], state: ResourceDialogState): boolean {
    switch (state) {
      case ResourceDialogState.UPLOAD:
        return resources.some(file => file.status === ResourceStatus.DONE);
      case ResourceDialogState.UPLOADING:
        return resources.some(file => file.status === ResourceStatus.UPLOADING);
      case ResourceDialogState.DONE:
        return true;
      default:
        return false;
    }
  }

  public isDisabled(resources: Resource[], state: ResourceDialogState): boolean {
    switch (state) {
      case ResourceDialogState.UPLOAD:
        return !resources.some(file => file.status === ResourceStatus.READY);
      case ResourceDialogState.UPLOADING:
        return true;
      default:
        return false;
    }
  }

  public canClose(resources: Resource[]): boolean {
    return !resources.some(
      file =>
        file.status === ResourceStatus.UPLOADING ||
        file.status === ResourceStatus.IN_PROGRESS ||
        file.status === ResourceStatus.FINISHED_UPLOAD ||
        file.status === ResourceStatus.DONE
    );
  }

  public setCurrentState(resources: Resource[]): ResourceDialogState | undefined {
    const loading = resources.some(
      f =>
        f.status === ResourceStatus.UPLOADING ||
        f.status === ResourceStatus.IN_PROGRESS ||
        f.status === ResourceStatus.FINISHED_UPLOAD
    );
    const success = resources.some(f => f.status === ResourceStatus.DONE);
    const ready = resources.some(f => f.status === ResourceStatus.READY);
    const failed = resources.some(f => f.status === ResourceStatus.ERROR || f.status === ResourceStatus.CANCELLED);
    const allCanceled = resources.every(f => f.status === ResourceStatus.CANCELLED);

    if (ready) {
      return ResourceDialogState.UPLOAD;
    } else if (loading) {
      return ResourceDialogState.UPLOADING;
    } else if (success && (!ready || !failed)) {
      return ResourceDialogState.DONE;
    } else if (allCanceled) {
      return ResourceDialogState.UPLOAD;
    }
  }

  public isCancelDisabled(resources: Resource[]): boolean {
    return resources
      .filter(
        (resource: Resource) => resource.status !== ResourceStatus.INVALID && resource.status !== ResourceStatus.ERROR
      )
      .some((resource: Resource) => resource.status === ResourceStatus.IN_PROGRESS && !resource.id);
  }

  public showDeterminateProgressBar(status: ResourceStatus): boolean {
    return status === ResourceStatus.UPLOADING || status === ResourceStatus.READY;
  }

  public showIndeterminateProgressBar(status: ResourceStatus): boolean {
    return (
      status === ResourceStatus.IN_PROGRESS ||
      status === ResourceStatus.FINISHED_UPLOAD ||
      status === ResourceStatus.DELETE
    );
  }

  public getOrgData(): OrgData {
    return this.accountService.getOrgData();
  }
}
