<div class="relationships">
  <div class="relationships__header">
    <h4 fxLayout>
      <mat-icon class="header-icon" svgIcon="join"></mat-icon>
      <span>Joins</span>
      <mat-menu #listMenu="matMenu">
        <dropdown-button label="Delete all" (onClick)="deleteAll()" qpIcon="trash"></dropdown-button>
      </mat-menu>
      <button mat-icon-button [matMenuTriggerFor]="listMenu" class="list-menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <button mat-icon-button aria-label="Close attributes" (click)="closeDrawer()" class="hasMenu go-back-btn">
        <mat-icon svgIcon="close_inspector"></mat-icon>
      </button>
    </h4>
  </div>
  <div fusePerfectScrollbar class="relationships__wrapper">
    <div class="relationships__selection" *ngIf="relationshipsSelection.length > 0">
      <div class="relationships__selection__option" *ngFor="let rel of relationshipsSelection; index as i">
        <button
          mat-button
          (click)="removeJoin(i)"
          [disabled]="relationshipsSelection.length === 1"
          class="mat-button remove-join"
        >
          Delete
        </button>

        <app-dropdown
          placeholder="{{ sourceTable?.name }} field"
          [options]="sourceFields"
          [(value)]="rel.source"
          (selectionChange)="onSelectionChange(true, rel, i, $event)"
        ></app-dropdown>
        <div class="join-line" [class.confirmed]="rel.confirmed"></div>
        <app-dropdown
          placeholder="{{ targetTable?.name }} field"
          [options]="targetFields"
          [(value)]="rel.target"
          [disabled]="!rel.source"
          (selectionChange)="onSelectionChange(false, rel, i, $event)"
          (openedChange)="onOpenedChange(i, $event)"
        ></app-dropdown>
      </div>
      <button
        mat-button
        (click)="addJoin()"
        class="relationships__selection__submitBtn mat-button"
        [disabled]="!canAdd()"
      >
        <mat-icon>add</mat-icon>
        Add Another Join
      </button>
    </div>
  </div>
</div>
