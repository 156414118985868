import { Entity } from '@neptune/components/svg-canvas';

// eslint-disable-next-line no-shadow
export enum ElementType {
  None = 'None',
  Peg = 'Peg',
  Port = 'Port',
  Edge = 'Edge'
}

// eslint-disable-next-line no-shadow
export enum CanvasActionType {
  /** Action to display message to user */
  Message = 'Message',
  /** Delete an element from the canvas */
  Delete = 'Delete',
  /** Element from canvas has been clicked */
  Click = 'Click',
  /** Force canvas element to be selected */
  Select = 'Select',
  /** Deselected all currently selected elements*/
  Deselect = 'Deselect',
  /** Element has been added to canvas */
  Add = 'Add',
  /** Export the full state of the canvas */
  Export = 'Export',
  /** Restore canvas from a previously exported state */
  Restore = 'Restore',
  /** Clear the canvas of all elements */
  Clear = 'Clear',
  /** Update a canvas element, likely with new state information  */
  Update = 'Update',
  /** Update board scale and position to fit current window */
  Fit = 'Fit'
}

/**
 * LockedsState of donut inspector
 */
// eslint-disable-next-line no-shadow
export enum InspectorState {
  UNLOCKED,
  LOCKED,
  LOCK_PARTIAL
}

export class CanvasAction {
  actionType: CanvasActionType;
  model?: any;
}

/**
 * Use to determine message type, when displaying user message via loading component
 */
// eslint-disable-next-line no-shadow
export enum MessageActionType {
  Error = 'Error',
  Warning = 'Warning',
  Success = 'Success'
}

export class MessageAction extends CanvasAction {
  actionType: CanvasActionType.Message = CanvasActionType.Message;
  messageType: MessageActionType;
  model?: string;
}

export type ElementAction = (action: CanvasActionType, content?: ElementModel | Entity, hitType?: HitType) => void;

export interface HitResponse {
  type: any;
  entityId: string;
  isClick?: boolean;
}

// eslint-disable-next-line no-shadow
export enum BoardActionType {
  None = 'None',
  Clear = 'Clear',
  Fit = 'Fit',
  Center = 'Center',
  Sort = 'Sort'
}

// eslint-disable-next-line no-shadow
export enum HitType {
  NONE = 'NONE',
  BOARD = 'BOARD',
  PORT = 'PORT',
  PEG = 'PEG',
  EDGE = 'EDGE',
  ARROW = 'ARROW',
  MAP = 'MAP'
}

// eslint-disable-next-line no-shadow
export enum LineType {
  LINE = 'LINE',
  CURVE = 'CURVE'
}

// eslint-disable-next-line no-shadow
export enum MarkerType {
  ARROW = 'ARROW',
  CIRCLE = 'CIRCLE'
}

export interface LineOptions {
  type?: LineType;
  color?: string;
  strokeData?: StrokeData;
  markerSize?: number;
  markerType?: MarkerType;
}

interface StrokeData {
  color?: string;
  width?: number;
  opacity?: number;
  linecap?: string;
  linejoin?: string;
  miterlimit?: number;
  dasharray?: string;
  dashoffset?: number;
}

export class ElementModel {
  elementType: ElementType;
  id: string;
}

export class PegModel extends ElementModel {
  elementType: ElementType.Peg = ElementType.Peg;
}

export class PortModel extends ElementModel {
  elementType: ElementType.Port = ElementType.Port;
  index: number;
}

export class EdgeModel<T> extends ElementModel {
  elementType: ElementType.Edge = ElementType.Edge;
  source: T;
  target?: T;
}

// eslint-disable-next-line no-shadow
export enum LayerLevel {
  Front,
  Back
}

export class XY {
  x: number;
  y: number;
}

export interface BoardDef extends XY {
  zoom: number;
}

export interface CanvasDef<N, E> extends BoardDef {
  nodes: N[];
  edges: E[];
}

/** Definition of connection between donuts */
export interface EdgeDef {
  startId: string;
  endId: string;
  startPort?: number;
  endPort?: number;
}

/** Definition of standard canvas 'node' */
export interface PegDef extends XY {
  id: string;
  data?: any;
}

export interface Rectangle extends XY {
  width: number;
  height: number;
}

export interface Bounds {
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
}
