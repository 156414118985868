import { Component } from '@angular/core';
import { VersionI } from '@neptune/models';
import { VERSION } from 'environments/version';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  // CUSTOM @NEPTUNE VARIABLES
  public version: VersionI = VERSION;
  public currentDate: Date = new Date();
}
