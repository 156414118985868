<div mat-dialog-title class="dialog-header">
  <div class="dialog-title">
    <div fxLayout="row" fxLayoutAlign="start center">
      <span id="import-table-name" class="h2">
        <span *ngIf="isEdit">Edit Field</span>
        <span *ngIf="!isEdit">New Field</span>
      </span>
    </div>
  </div>
</div>
<mat-dialog-content>
  <div class="description-container">
    <form fxLayout="column" fxLayoutAlign="space-between start" class="form" [formGroup]="saveForm" (submit)="onSave()">
      <mat-form-field class="input-field" appearance="fill">
        <input
          matInput
          type="text"
          placeholder="Enter field name"
          formControlName="fieldName"
          required
          cdkFocusInitial
        />
        <mat-error *ngIf="nameError">{{ nameError }}</mat-error>
        <mat-error *ngIf="saveForm.get('fieldName')!.invalid">{{ getErrorMessage() }}</mat-error>
      </mat-form-field>
      <mat-select formControlName="dataType" (selectionChange)="onTypeChange($event.value)">
        <mat-select-trigger>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <app-datatype-icon [type]="field.dataType"></app-datatype-icon>
            <div class="text-nowrap">{{ field.dataType | dataTypeToString }}</div>
          </div>
        </mat-select-trigger>
        <mat-option *ngFor="let type of typeMap | keyvalue" [value]="type.key">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <app-datatype-icon [type]="type.key"></app-datatype-icon>
            <span>{{ type.value }}</span>
          </div>
        </mat-option>
      </mat-select>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="end start" class="dialog-footer" fxLayout="row nowrap">
  <button
    *ngIf="!data?.isNewField"
    mat-button
    class="delete-btn"
    (click)="onDelete()"
    [disabled]="!enableDelete"
    color="warn"
  >
    Delete
  </button>
  <button mat-button color="primary" (click)="onClose()" class="cancel-btn">Cancel</button>
  <button mat-flat-button color="primary" (click)="onSave()" [disabled]="!enableSave">Save</button>
</mat-dialog-actions>
