import { Component, ChangeDetectorRef, ViewChild } from '@angular/core';
import { StandardListComponent } from '@neptune/components';
import { Purl } from '@neptune/models/purl';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable, Observer } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { PurlDialog } from '../purl-dialog/purl-dialog';
import { includes } from 'lodash';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PurlService } from '@neptune/services/purl.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Roles } from '@neptune/models/user';
import { SelectionType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-purl-list',
  templateUrl: './purl-list.component.html',
  styleUrls: ['./purl-list.component.scss']
})
export class PurlListComponent extends StandardListComponent<Purl> {
  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

  public selectionType = SelectionType;

  constructor(
    protected matDialogService: MatDialog,
    public changeDetector: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    public purlService: PurlService
  ) {
    super();
    this.rolList = [Roles.ADMIN, Roles.SYSTEM];
  }

  public populate(): Observable<null> {
    this.startLoading();
    return this.purlService
      .getPurls()
      .pipe(takeUntil(this.unsubscribeAll))
      .pipe(
        map((result: Purl[]) => {
          this.addEntity(result);
          this.stopLoading();
          return null;
        })
      );
  }

  /**
   * @inheritdoc
   */
  protected updateTableView() {
    this.rowsData = this.list.filter((m: Purl) => m);
    this.changeDetector.markForCheck();
  }

  /**
   * Handler to create new task schedule, opens import task editor
   */
  onNew(): void {
    this.openPurlEditor(null)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (result: Purl) => {
          this.addEntity(result);
          this.openSnackBar('Purl added');
          this.changeDetector.markForCheck();
        },
        error: err => {
          const message: string = `Issue saving purl`;
          console.warn(message, err);
          this.onShowError.emit(message);
        }
      });
  }

  onEdit(data: Purl) {
    this.openPurlEditor(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (result: Purl) => {
          this.replaceEntity(result, data);
          this.openSnackBar('Purl changed', '', ['custom-snackbar', 'custom-snackbar-deleted']);
          this.changeDetector.markForCheck();
        },
        error: err => {
          const message: string = `Issue editing purl`;
          console.warn(message, err);
          this.onShowError.emit(message);
        }
      });
  }

  /**
   * Handler to delete an existing user
   */
  onDelete(data: Purl) {
    this.onStartLoading.emit();
    this.purlService.deletePurl(data).subscribe({
      next: () => {
        this.removeEntity(data);
        this.showSuccess('Purl deleted');
        this.changeDetector.markForCheck();
        this.onStopLoading.emit();
      },
      error: (err: Error) => {
        const message: string = `Issue deleting purl`;
        console.warn(message, err);
        this.showError(message);
      }
    });
  }

  selectionChange(event: { selected: Purl[] }) {
    this.onSelectionChange.emit(event.selected[0]);
  }

  /**
   * @inheritdoc
   */
  searchFilter(data: Purl, searchValue: string): boolean {
    if (searchValue) {
      const search = searchValue.toLowerCase();
      return includes(data.name.toLowerCase(), search);
    } else {
      return true;
    }
  }

  private openPurlEditor(data?: Purl | null) {
    const dialogRef = this.matDialogService.open(PurlDialog, {
      disableClose: true,
      data: {
        ...data,
        edit: data ? true : false
      },
      panelClass: 'purl-dialog-container'
    });
    return new Observable((observer: Observer<any>) => {
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (result?: Purl) => {
            if (result) {
              observer.next(result);
              observer.complete();
            } else {
              // no result provided meaning dialog was cancelled
              observer.complete();
            }
          },
          error: err => {
            observer.error(err);
            observer.complete();
          }
        });
    });
  }

  private openSnackBar(message: string, action?: string, className?: string | string[]) {
    this.snackBar.open(message, action, {
      duration: 4000,
      panelClass: className ? className : ['custom-snackbar', 'custom-snackbar-send'],
      verticalPosition: 'bottom',
      horizontalPosition: 'center'
    });
  }
}
