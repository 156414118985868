import { Pipe, PipeTransform } from '@angular/core';
import { MailToNamePipe } from '../mailToName/mailToName.pipe';

@Pipe({ name: 'updatedMailOrUserToName' })
export class UpdatedMailOrUserToName implements PipeTransform {
  transform(value: any) {
    const mailToName = new MailToNamePipe();

    return value.updatedByUser
      ? value.updatedByUser.firstName + ' ' + value.updatedByUser.lastName
      : mailToName.transform(value.updatedBy);
  }
}
