import { Directive, ElementRef, Input } from '@angular/core';
import { AccountService } from '@neptune/services/account.service';
import { Roles } from '@neptune/models/user';

// Global user attributes
let userAttributes: any = null;

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[qp-roles]'
})
export class QPRolesDirective {
  private rolesList: string | string[] | null;
  private nativeElement: HTMLElement | null = null;

  @Input('rolList') set rolList(value: string | string[]) {
    this.rolesList = value;
  }

  constructor(private el: ElementRef, private accountService: AccountService) {
    this.nativeElement = this.el.nativeElement;

    if (this.nativeElement?.getAttribute('rolList')) {
      this.rolesList = this.nativeElement.getAttribute('rolList');
      this.ngOnChanges();
    }
  }

  // eslint-disable-next-line
  private ngOnChanges(): void {
    if (this.rolesList) {
      this.validateRoles();
    }
  }

  private get parseRoles(): string[] {
    return (this.rolesList as string[]).map((x: string) => x.toLowerCase());
  }

  private validateRoles(): void {
    const hideElement = (): void => {
        if (this.nativeElement) {
            this.nativeElement.innerHTML = '';
            this.nativeElement.style.display = 'none';
        }
    };

    const callback = () => {
      const { attributes } = userAttributes;
      const rol: any = attributes.find((role: any) => role.Name === 'custom:qp_role');
      if (!rol) {
        return;
      }

      const rolString: string = rol.Value.toLowerCase();

      if (this.parseRoles.includes(Roles.SYSTEM.toLowerCase())) {
        const systemRole: any = attributes.find((role: any) => role.Name === 'custom:systemManagement');
        const isSystemRole = systemRole && systemRole.Value === 'true';
        if (isSystemRole) {
          return;
        } else if (!isSystemRole && !this.parseRoles.includes(rolString)) {
          hideElement();
        }
      } else if (!this.parseRoles.includes(rolString)) {
        hideElement();
      }
    };

    if (!userAttributes) {
      this.accountService
        .getUserAttributes()
        .then((data: any) => (userAttributes = data))
        .then(() => callback())
        .catch((err: Error) => console.log(err.message));
    } else {
      callback();
    }
  }
}
