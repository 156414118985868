import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  MatLegacySnackBarRef as MatSnackBarRef,
  LegacySimpleSnackBar as SimpleSnackBar
} from '@angular/material/legacy-snack-bar';
import { LoadingModalService } from './loading-modal.service';

@Component({
  selector: 'loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss']
})
export class LoadingModalComponent implements OnInit {
  @Input()
  public loading: boolean;

  constructor(private changeDetector: ChangeDetectorRef, private loadingModalService: LoadingModalService) {}

  public ngOnInit(): void {}

  public startLoading(): void {
    this.loading = true;
    this.changeDetector.detectChanges();
  }

  public stopLoading(): void {
    this.loading = false;
    this.changeDetector.detectChanges();
  }

  public showSuccess(message: string, duration?: number): MatSnackBarRef<SimpleSnackBar> {
    this.stopLoading();
    return this.loadingModalService.openSnackBar(message, undefined, undefined, duration);
  }

  public showError(message: string, duration?: number): MatSnackBarRef<SimpleSnackBar> {
    this.stopLoading();
    return this.loadingModalService.openSnackBar(
      message,
      undefined,
      ['custom-snackbar', 'custom-snackbar-deleted'],
      duration
    );
  }

  public showWarning(msg: string): MatSnackBarRef<SimpleSnackBar> {
    this.stopLoading();
    return this.loadingModalService.openSnackBar(msg, 'Close', 'custom-snackbar-warning', 10000, 'top');
  }
}
