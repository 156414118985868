import { AllStatus, AssetBase } from '@neptune/models/asset';

export interface LandingPage extends AssetBase {
  htmlContent?: string;
  domainId?: string;
  approvedAt?: string;
  status?: AllStatus | LandingPageStatus;
}

// eslint-disable-next-line no-shadow
export enum LandingPageStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED'
}
