/* eslint-disable quote-props */
export const locale = {
  lang: 'tr',
  data: {
    NAV: {
      APPLICATIONS: 'Programlar',
      SAMPLE: {
        TITLE: 'Örnek',
        BADGE: '15'
      }
    }
  }
};
