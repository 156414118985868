import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SelectedField } from 'app/main/content/pages/message/message-key-selector-picker/message-key-selector-picker.component';
import { AggregateFunctionInput } from '../aggregate-function-dialog';

@Component({
  selector: 'nep-select-field-dialog',
  templateUrl: './select-field-dialog.component.html',
  styleUrls: ['./select-field-dialog.component.scss']
})
export class SelectFieldDialogComponent {
  public selectedField: SelectedField;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AggregateFunctionInput,
    private dialogRef: MatDialogRef<SelectFieldDialogComponent>
  ) {}

  public selectField(field: SelectedField): void {
    this.selectedField = field;
  }

  public onSelect(): void {
    this.dialogRef.close(this.selectedField);
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
