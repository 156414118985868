import { Component, Input } from '@angular/core';
import { RunState } from '@neptune/models/task';
import { TableActionStatus } from '@neptune/models';

@Component({
  selector: 'app-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss']
})
export class StatusIconComponent {
  private static iconMap: Map<RunState | TableActionStatus | string, string> = new Map<
    RunState | TableActionStatus | string,
    string
  >([
    [RunState.ALL, 'select_all'],
    [RunState.ACTIVE, 'timelapse'],
    [RunState.DONE, 'check_circle_outline'],
    [RunState.ERROR, 'error_outline'],
    [RunState.EXPIRED, 'access_time'],
    [TableActionStatus.PROGRESS, 'timelapse'],
    [TableActionStatus.SUCCESS, 'check_circle_outline'],
    [TableActionStatus.SUCCESS_WITH_ERRORS, 'report_problem'],
    [TableActionStatus.FAILURE, 'error_outline'],
    [TableActionStatus.TIMEOUT, 'error_outline'],
    [TableActionStatus.PENDING, 'schedule'],
    [TableActionStatus.SKIPPED, 'play_circle_outline']
  ]);

  public iconName: string;
  public iconColor: string;
  @Input('type') type: RunState | TableActionStatus;

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnChanges() {
    this.iconName = StatusIconComponent.iconMap.get(this.type) as string;
  }
}
