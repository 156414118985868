<mat-form-field
  class="email-chip-list-input w-100-p"
  fxFlex="60"
  [ngClass]="{ 'mat-form-field-invalid': emailWarning }"
>
  <mat-chip-list #chipList>
    <mat-chip *ngFor="let email of emails" [selectable]="false" [removable]="true" (removed)="removeEmail(email)">
      {{ email }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      matInput
      type="email"
      placeholder="Add Emails for proof"
      [disabled]="!!disabled"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      (matChipInputTokenEnd)="addEmail($event)"
    />
    <mat-error *ngIf="emailWarning">{{ emailWarning }}</mat-error>
  </mat-chip-list>
</mat-form-field>
