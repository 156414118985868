import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { interval, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ConfirmInput {
  title: string;
  message?: string;
  okMessage?: string;
  cancelMessage?: string;
  countDown?: number;
  checkbox?: boolean;
  checkboxLabel?: string;
}

@Component({
  selector: 'confirm-dialog',
  templateUrl: 'confirm-dialog.html',
  styleUrls: ['dialog-style.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ConfirmDialog implements OnInit, OnDestroy {
  constructor(public dialogRef: MatDialogRef<ConfirmDialog>, @Inject(MAT_DIALOG_DATA) public data: ConfirmInput) {}

  DEFAULT_OK = 'Ok';
  DEFAULT_CANCEL = 'Cancel';
  hasMessage: boolean = false;
  countDown: number;
  clock: string;
  checked: boolean = false;
  private intervalSubscription: Subscription;

  // eslint-disable-next-line @typescript-eslint/ban-types
  static open(dialog: MatDialog, data: ConfirmInput, confirmHandler?: Function, cancelHandler?: Function) {
    const dialogRef = dialog.open(ConfirmDialog, {
      width: 'auto',
      height: '285px',
      data
    });
    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        if (confirmHandler) {
          confirmHandler();
        }
      } else if (cancelHandler) {
        cancelHandler();
      }
    });
    return dialogRef;
  }

  /**
   * Handler for close buttons
   */
  onClose(confirm: boolean): void {
    this.dialogRef.close(confirm);
  }

  ngOnInit() {
    this.hasMessage = this.data.message && this.data.message.length > 0 ? true : false;
    this.data.okMessage = this.data.okMessage || this.DEFAULT_OK;
    this.data.cancelMessage = this.data.cancelMessage || this.DEFAULT_CANCEL;
    if (this.data.countDown) {
      this.setupCountdown();
    }
  }

  ngOnDestroy() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  private setupCountdown() {
    this.countDown = this.data.countDown || 0;
    this.intervalSubscription = interval(1000)
      .pipe(
        map(val => this.countDown--),
        map(val => {
          if (this.countDown === 0) {
            this.dialogRef.close();
          }
          const sec = this.countDown % 60;
          this.clock = `${Math.floor(this.countDown / 60)}:${sec > 9 ? sec : `0${sec}`}`;
        })
      )
      .subscribe();
  }
}
