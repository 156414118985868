/* eslint-disable no-shadow */
import { AssetBase } from '@neptune/models/asset';
import { ValidatorFn } from '@angular/forms';

export enum Button {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset'
}

export enum Input {
  Color = 'color',
  Date = 'date',
  Email = 'email',
  Month = 'month',
  Number = 'number',
  Search = 'search',
  Tel = 'tel',
  Text = 'text',
  Time = 'time',
  Url = 'url',
  Week = 'week'
}

export enum FontStyle {
  NORMAL = 'normal',
  ITALIC = ' italic',
  OBLIQUE = 'oblique'
}

export enum FontWeight {
  NORMAL = 'normal',
  BOLD = ' bold',
  BOLDER = 'bolder',
  LIGHTER = 'lighter'
}

export enum TextAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right'
}

export interface FormJson {
  elements: FormJsonElement[];
}

export interface FormJsonElement {
  id: string;
  selector: FormCanvasElementType;
  propertiesValues?: FormJsonPropertyValue[];
  selectorOptions?: IFormElementSelectOptions[];
}

export interface FormJsonPropertyValue {
  id: string;
  value: string;
}

export class FormUtils {
  private static buttonsLabelMap: Map<Button, string> = new Map([
    [Button.BUTTON, 'Button'],
    [Button.SUBMIT, 'Submit button'],
    [Button.RESET, 'Reset button']
  ]);

  private static inputLabelMap: Map<Input, string> = new Map([
    [Input.Color, 'Color'],
    [Input.Date, 'Date'],
    [Input.Email, 'Email Address'],
    [Input.Month, 'Month'],
    [Input.Number, 'Numeric'],
    [Input.Search, 'Search'],
    [Input.Tel, 'Phone Number'],
    [Input.Text, 'Text'],
    [Input.Time, 'Time'],
    [Input.Url, 'Url'],
    [Input.Week, 'Week']
  ]);

  private static fontStylesLabelMap: Map<FontStyle, string> = new Map([
    [FontStyle.NORMAL, 'Normal'],
    [FontStyle.ITALIC, 'Italic'],
    [FontStyle.OBLIQUE, 'Oblique']
  ]);

  private static fontWeightLabelMap: Map<FontWeight, string> = new Map([
    [FontWeight.LIGHTER, 'Lighter'],
    [FontWeight.NORMAL, 'Normal'],
    [FontWeight.BOLD, 'Bold'],
    [FontWeight.BOLDER, 'Bolder']
  ]);

  private static textAlignLabelMap: Map<TextAlign, string> = new Map([
    [TextAlign.LEFT, 'Left'],
    [TextAlign.CENTER, 'Center'],
    [TextAlign.RIGHT, 'Right']
  ]);

  public static getButtonLabel(type: Button): string {
    return FormUtils.buttonsLabelMap.get(type) as string;
  }

  public static getInputLabel(type: Input): string {
    return FormUtils.inputLabelMap.get(type) as string;
  }

  public static getFontStyleLabel(type: FontStyle): string {
    return FormUtils.fontStylesLabelMap.get(type) as string;
  }

  public static getFontWeightLabel(type: FontWeight): string {
    return FormUtils.fontWeightLabelMap.get(type) as string;
  }

  public static getTextAlignLabel(type: TextAlign): string {
    return FormUtils.textAlignLabelMap.get(type) as string;
  }
}

export interface IFormElementSelectOptions {
  label: string;
  value: string;
}

// Form canvas elements interfaces
export interface IFormRepositoryElement {
  selector: FormCanvasElementType;
  label?: string;
}

export enum FormStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED'
}

export enum FormCanvasElementType {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Input = 'input',
  CheckBox = 'checkbox',
  RadioButton = 'radio',
  Select = 'select',
  TextArea = 'textarea',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Button = 'button',
  Text = 'text',
  HorizontalLine = 'divider',
  Hidden = 'hidden',
  SubmitResetButtons = 'submitResetButtons'
}

export const enum FormCanvasElementPropertyType {
  Checked = 'checked',
  DefaultValue = 'value',
  SelectedOption = 'selectedOption',
  FieldName = 'fieldName',
  FontColor = 'fontColor',
  FontSize = 'fontSize',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  FontWeight = 'fontWeight',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  FontStyle = 'fontStyle',
  Hidden = 'hidden',
  Label = 'label',
  SubmitLabel = 'submitLabel',
  ResetLabel = 'resetLabel',
  LinkUrl = 'linkUrl',
  Options = 'options',
  Regex = 'regex',
  Required = 'required',
  Type = 'type',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  TextAlign = 'textAlign',
  TextOptions = 'TextOptions',
  Width = 'width'
}

export interface IFormCanvasElement extends IFormRepositoryElement {
  selector: FormCanvasElementType;
  id: string;
  properties: FormCanvasElementPropertiesType;
  deletable?: boolean;
  repeated?: boolean;
}

// Form canvas element properties interfaces
export type IFormCanvasElementPropertyValue = string | boolean | number | null;

export interface IFormCanvasElementProperty {
  selector: string;
  id: string;
  label?: string;
  type?: string;
  value?: IFormCanvasElementPropertyValue;
  required?: boolean;
  options?: IFormElementSelectOptions[];
  canReceiveTableRow?: boolean;
  validator?: ValidatorFn;
}

export type FormCanvasElementPropertiesType =
  | IFormCanvasElementProperties
  | IFormCanvasButtonProperties
  | IFormCanvasCheckboxProperties
  | IFormCanvasInputProperties
  | IFormCanvasRadioButtonProperties
  | IFormCanvasSelectProperties
  | IFormCanvasTextProperties
  | IFormCanvasTextareaProperties;

export interface IFormCanvasElementProperties {
  width?: IFormCanvasElementProperty;
  value?: IFormCanvasElementProperty;
}

export interface IFormCanvasButtonProperties extends IFormCanvasElementProperties {
  value?: IFormCanvasElementProperty;
  linkUrl?: IFormCanvasElementProperty;
}

export interface IFormCanvasTextProperties extends IFormCanvasElementProperties {
  fontSize?: IFormCanvasElementProperty;
  fontColor?: IFormCanvasElementProperty;
  fontWeight?: IFormCanvasElementProperty;
  fontStyle?: IFormCanvasElementProperty;
  textAlign?: IFormCanvasElementProperty;
  value?: IFormCanvasElementProperty;
}

export interface IFormCanvasCheckboxProperties extends IFormCanvasElementProperties {
  fieldName?: IFormCanvasElementProperty;
  label?: IFormCanvasElementProperty;
  checked?: IFormCanvasElementProperty;
}

export interface IFormCanvasTextareaProperties extends IFormCanvasElementProperties {
  fieldName?: IFormCanvasElementProperty;
  label?: IFormCanvasElementProperty;
  value?: IFormCanvasElementProperty;
  required?: IFormCanvasElementProperty;
}
export interface IFormCanvasInputProperties extends IFormCanvasElementProperties {
  fieldName?: IFormCanvasElementProperty;
  label?: IFormCanvasElementProperty;
  type?: IFormCanvasElementProperty;
  value?: IFormCanvasElementProperty;
  regex?: IFormCanvasElementProperty;
  required?: IFormCanvasElementProperty;
  hidden?: IFormCanvasElementProperty;
}

export interface IFormCanvasRadioButtonProperties extends IFormCanvasElementProperties {
  fieldName: IFormCanvasElementProperty;
  label: IFormCanvasElementProperty;
  options: IFormCanvasElementProperty;
  required: IFormCanvasElementProperty;
  selectedOption: IFormCanvasElementProperty;
}

export interface IFormCanvasSelectProperties extends IFormCanvasElementProperties {
  fieldName: IFormCanvasElementProperty;
  label: IFormCanvasElementProperty;
  options: IFormCanvasElementProperty;
  required: IFormCanvasElementProperty;
  selectedOption: IFormCanvasElementProperty;
}

export interface IFormCanvasWithOptionsProperties extends IFormCanvasElementProperties {
  fieldName?: IFormCanvasElementProperty;
  label?: IFormCanvasElementProperty;
  options?: IFormCanvasElementProperty;
  required?: IFormCanvasElementProperty;
  selectedOption?: IFormCanvasElementProperty;
}

export interface IFormCanvasSubmitResetButtonProperties extends IFormCanvasElementProperties {
  submitLabel?: IFormCanvasElementProperty;
  resetLabel?: IFormCanvasElementProperty;
}

// Form repository elements list
export const FORM_REPOSITORY_ELEMENTS: IFormRepositoryElement[] = [
  {
    selector: FormCanvasElementType.Input,
    label: 'Text Field'
  },
  {
    selector: FormCanvasElementType.CheckBox,
    label: 'Check Box'
  },
  {
    selector: FormCanvasElementType.RadioButton,
    label: 'Radio Buttons'
  },
  {
    selector: FormCanvasElementType.Select,
    label: 'Dropdown Field'
  },
  {
    selector: FormCanvasElementType.TextArea,
    label: 'Text Area'
  },
  {
    selector: FormCanvasElementType.Button,
    label: 'Button'
  },
  {
    selector: FormCanvasElementType.Text,
    label: 'Text'
  },
  {
    selector: FormCanvasElementType.HorizontalLine,
    label: 'Horizontal Line'
  }
];

export interface Form extends AssetBase {
  status?: FormStatus;
  domainId: string;
  formJson: FormJson;
  version?: number;
  idVersion?: string;
}
