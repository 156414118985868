<div
  #focusElement
  class="inline-editor normal-text"
  [ngClass]="{ placeholder: !value, disabled: disabled, 'icon-prefix': iconPrefix, 'icon-postfix': !iconPrefix }"
  *ngIf="!editMode && !useLongText"
  (click)="!disabled && enableEditMode()"
  (focus)="!disabled && enableEditModeFromFocus()"
  [tabindex]="tabIndex"
>
  <span class="inline-editor-value" [matTooltip]="value || placeholder">{{ value || placeholder }}</span>
  <span *ngIf="suffix">{{ suffix }}</span>
</div>

<div
  #focusElementLongText
  class="inline-editor long-text"
  [ngClass]="{ placeholder: !value, disabled: disabled, 'icon-prefix': iconPrefix, 'icon-postfix': !iconPrefix }"
  *ngIf="!editMode && useLongText"
  (click)="!disabled && enableEditMode()"
  (focus)="!disabled && enableEditModeFromFocus()"
  [tabindex]="tabIndex"
>
  <long-text class="w-100-p" [value]="value || placeholder"></long-text>
  <span *ngIf="suffix">{{ suffix }}</span>
</div>

<div *ngIf="editMode" class="inline-editor edit-mode">
  {{ editInput.focus() }}
  <mat-form-field class="w-100-p">
    <input
      #editInput
      matInput
      name="editValue"
      [(ngModel)]="editValue"
      (change)="onChange($event)"
      tabindex="-1"
      [pattern]="pattern"
    />
    <span *ngIf="suffix" matSuffix>{{ suffix }}</span>
  </mat-form-field>
</div>
