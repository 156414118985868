import { Injectable } from '@angular/core';
import { AssetDefinition, AssetType, Folder, Asset, Project, AllStatus } from '@neptune/models';
import { Observable } from 'rxjs';
import { AssetService } from '@neptune/services/asset.service';
import { ProjectsService } from '@neptune/services/projects.service';
import { map } from 'rxjs/operators';
import { SegmentationWaterFallService } from '@neptune/services/segmentation-waterfall.service';

@Injectable()
export class AssetSelectorService {
  constructor(
    private assetService: AssetService,
    private projectService: ProjectsService,
    private segwfService: SegmentationWaterFallService
  ) {}
  getProject(id: string): Observable<AssetDefinition> {
    return this.projectService.getProject(id).pipe(map(project => this.mapProjectToAsset([project])[0]));
  }

  getProjects(): Observable<AssetDefinition[]> {
    return this.projectService.getAllProjects().pipe(
      map(this.mapProjectToAsset.bind(this)),
      map((projects: AssetDefinition[]) =>
        projects.sort((a, b) => (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1))
      )
    );
  }
  /**
   * Maps the Folder[] returned by project.service#getAllFoldersByProject to AssetDefinition[]
   *
   * @param projectId
   */
  getFolders(projectId: string): Observable<AssetDefinition[]> {
    return this.projectService.getAllFoldersByProject(projectId).pipe(map(this.mapFolderToAsset.bind(this)));
  }
  /**
   * Calls the Asset API, passing an optional 'types' parameter
   *
   * @param folderId
   * @param assetTypes Array of AssetTypes
   */
  getAssets(folderId: string, assetTypes?: AssetType[], assetStatus?: AllStatus[]): Observable<Asset[]> {
    // FIXME: ideally we would want to have cache, but it's not working as expected
    return this.assetService.getAllAssetsByFolder(folderId, assetTypes, assetStatus);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  getlistWFModels(folderId: string): Observable<object> {
    return this.segwfService.getlistWFModels(folderId);
  }

  // NOTE: this conversion is to try to keep the component as general as possible.
  // Should the backend add su
  mapFolderToAsset(folders: Folder[]): AssetDefinition[] {
    return folders.map(folder => ({
      id: `${folder.id}`,
      name: folder.name,
      assetType: AssetType.FOLDER,
      children: this.mapFolderToAsset(folder.children as Folder[])
    })) as AssetDefinition[];
  }

  // NOTE: this conversion is to try to keep the component as general as possible.
  // Should the backend add su
  mapProjectToAsset(projects: Project[]): AssetDefinition[] {
    return projects.map(project => ({
      id: `${project.id}`,
      name: project.name,
      assetType: AssetType.PROJECT,
      children: [],
      loading: false
    }));
  }

  stitchProjectAndFolder(project, folders): AssetDefinition {
    return {
      ...project,
      children: this.mapFolderToAsset(folders),
      loading: false
    };
  }
}
