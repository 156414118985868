<div mat-dialog-title class="dialog-header">
  <div class="dialog-title">
    <span class="h2">Select Field</span>
  </div>
</div>
<mat-dialog-content>
  <div class="container">
    <message-key-selector-picker
      fxFlex="350px"
      [showDeepJoin]="true"
      [showInsertButton]="false"
      [hideInputTableFields]="true"
      [inputTable]="data.tableSchema.id"
      (onSelectedField)="selectField($event)"
    ></message-key-selector-picker>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end start" class="dialog-footer" fxLayout="row nowrap">
  <button mat-button color="primary" (click)="onClose()" class="cancel-btn">Cancel</button>
  <button type="submit" mat-flat-button color="primary" [disabled]="!selectedField" (click)="onSelect()">Select</button>
</mat-dialog-actions>
