import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetSelectorComponent } from '@neptune/components/asset-selector/asset-selector.component';
import { MaterialModule } from '@neptune/material.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { PurlListDialogModule } from '../purl-list-dialog/purl-list-dialog.module';
import { ImportAssetListDialogModule } from '../import-asset-list-dialog/import-asset-list-dialog.module';

@NgModule({
  declarations: [AssetSelectorComponent],
  imports: [CommonModule, MaterialModule, FuseSharedModule, PurlListDialogModule, ImportAssetListDialogModule],
  exports: [AssetSelectorComponent]
})
export class AssetSelectorModule {}
