import { EventEmitter, Injectable } from '@angular/core';
import { APIConfig } from 'environments/environment';
import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  ISignUpResult
} from 'amazon-cognito-identity-js';
import { LocalStorageService } from './local-storage.service';
import { Observable, of, Subject, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CognitoService {
  public onAuthSuccess: EventEmitter<CognitoUserSession> = new EventEmitter<CognitoUserSession>();
  public onAuthFailure: EventEmitter<any> = new EventEmitter<any>();
  public onNewPasswordRequired: EventEmitter<any> = new EventEmitter<any>();
  public onMfaRequired: EventEmitter<any> = new EventEmitter<any>();
  public onTotpRequired: EventEmitter<any> = new EventEmitter<any>();
  public onCustomChallenge: EventEmitter<any> = new EventEmitter<any>();
  public onMfaSetup: EventEmitter<any> = new EventEmitter<any>();
  public onSelectMFAType: EventEmitter<any> = new EventEmitter<any>();

  public onForgotPasswordSuccess: EventEmitter<any> = new EventEmitter<any>();
  public onForgotPasswordFailure: EventEmitter<Error> = new EventEmitter<Error>();
  public onInputVerificationCode: EventEmitter<any> = new EventEmitter<any>();

  public onRefreshSession: EventEmitter<CognitoUserSession> = new EventEmitter<CognitoUserSession>();
  public FAILED_LOGIN_MESSAGE = 'Login failed, try again';

  private _authenticationCognito: CognitoUser;
  private _currentUser: CognitoUser | null;
  public _registeredUser: CognitoUser | null;

  constructor(private localStorageService: LocalStorageService) {}

  private toUsername(email: string): string {
    return email.toLowerCase();
  }

  public get username(): string | null {
    return this.currentUser ? this.currentUser.getUsername() : null;
  }

  /**
   * For use with jasmine testing, returns a mock of service
   */
  public static mockService(): any {
    return {
      getUserAttributes: jest.fn(() =>
        of({
          attributes: [],
          session: <CognitoUserSession>{}
        })
      )
    };
  }

  public sendMFACode(username: string, code: string, rememberMe: string): Observable<any> {
    return new Observable<CognitoUserSession>((subs: Subscriber<CognitoUserSession>) => {
      if (this._currentUser) {
        this._currentUser.sendMFACode(code, {
          onSuccess: (session: CognitoUserSession) => {
            this.setRememberMe(rememberMe);
            subs.next(session);
            subs.complete();
          },
          onFailure: (err: any) => {
            console.log(err);
            subs.error(err);
          }
        });
      }
    });
  }

  /**
   * Authenticate user through Cognito
   */
  public authenticateUser(username: string, password: string | undefined, rememberMe: string) {
    username = this.toUsername(username);
    const authDetails: AuthenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password
    });

    const newCognitoUserPool: CognitoUserPool = this.newUserPool();

    this._authenticationCognito = new CognitoUser({
      Username: username,
      Pool: newCognitoUserPool
    });

    this._authenticationCognito.authenticateUser(authDetails, {
      onSuccess: (session: CognitoUserSession, userConfirmationNecessary?: boolean) => {
        this._currentUser = this._authenticationCognito;
        this.setRememberMe(rememberMe);
        this.onAuthSuccess.emit(session);
      },
      onFailure: (error: any) => this.onAuthFailureMessage(error),
      newPasswordRequired: (userAttributes: any, requiredAttributes: any) =>
        this.onNewPasswordRequired.emit({ userAttributes, requiredAttributes }),
      mfaRequired: (challengeName: any, challengeParameters: any) => {
        const session = (this._authenticationCognito as any).Session;
        this._currentUser = this._authenticationCognito;
        this.onMfaRequired.emit({ challengeName, challengeParameters, session });
      },
      totpRequired: (challengeName: any, challengeParameters: any) => this.onTotpRequired.emit(),
      customChallenge: (challengeParameters: any) => this.onCustomChallenge.emit(),
      mfaSetup: (challengeName: any, challengeParameters: any) => this.onMfaSetup.emit(),
      selectMFAType: (challengeName: any, challengeParameters: any) => this.onSelectMFAType.emit()
    });
  }

  /**
   * Set Device Status Remembered
   */
  setRememberMe(rememberMe: string) {
    if (this._currentUser) {
      if (rememberMe === 'true') {
        this._currentUser.setDeviceStatusRemembered({
          onSuccess: (success: string) => console.log('Remembered device'),
          onFailure: (err: any) => console.log('Remembered device error: ', err)
        });
      } else {
        this._currentUser.setDeviceStatusNotRemembered({
          onSuccess: (success: string) => console.log('Not Remembered device'),
          onFailure: (err: any) => console.log('Not Remembered device error: ', err)
        });
      }
    }
  }

  /**
   * Sign Out Cognito User
   */
  public signOut() {
    this.currentUser?.signOut();
    this._currentUser = null;
  }

  /**
   * Sign Up new User to Cognito system.
   * param username
   * param password
   * param firstName
   * param lastName
   * param phone_number
   * return {Observable<ISignUpResult>}
   */
  public signUp(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    phone_number: string
  ): Observable<ISignUpResult> {
    const subj = new Subject<ISignUpResult>();

    const attributes: CognitoUserAttribute[] = [
      new CognitoUserAttribute({ Name: 'given_name', Value: firstName }),
      new CognitoUserAttribute({ Name: 'family_name', Value: lastName }),
      new CognitoUserAttribute({ Name: 'email', Value: email }),
      new CognitoUserAttribute({ Name: 'phone_number', Value: phone_number })
    ];
    this.newUserPool().signUp(
      email,
      password,
      attributes,
      [],
      this.subjectToHandler(
        subj,
        res => res,
        err => err
      )
    );
    return subj.asObservable();
  }

  /**
   * Refresh current Cognito User session
   */
  public refreshSession(): Observable<CognitoUserSession> {
    const refreshToken = new CognitoRefreshToken({
      RefreshToken:
        this.currentUser && this.localStorageService.getSession(this.currentUser.getUsername())?.refreshTokenKey
    });

    const subj = new Subject<CognitoUserSession>();
    this.currentUser?.refreshSession(
      refreshToken,
      this.subjectToHandler(
        subj,
        res => res,
        err => err
      )
    );

    return subj.asObservable().pipe(
      map(session => {
        this.onRefreshSession.emit(session);
        return session;
      })
    );
  }

  /**
   * Get attributes for current Cognito User
   */
  public getUserAttributes(): Observable<{
    attributes: CognitoUserAttribute[];
    session: CognitoUserSession;
  }> {
    const subj = new Subject<{ attributes: CognitoUserAttribute[]; session: CognitoUserSession }>();
    this.getSession().subscribe({
      next: session => {
        this.currentUser?.getUserAttributes(
          this.subjectToHandler(
            subj,
            attributes => ({ attributes, session }),
            err => err
          )
        );
      },
      error: err => subj.error(err)
    });

    return subj.asObservable();
  }

  /**
   * Change password for current cognito user
   */
  public changePassword(oldPassword: string, newPassword: string): Observable<any> {
    const subj = new Subject<any>();
    this.getSession().subscribe({
      next: session => {
        this.currentUser?.changePassword(
          oldPassword,
          newPassword,
          this.subjectToHandler(
            subj,
            res => res,
            err => err
          )
        );
      },
      error: err => subj.error(err)
    });

    return subj.asObservable();
  }

  /**
   * Call cognito forget passowrd for username;
   */
  public forgetPassword(username: string) {
    username = this.toUsername(username);
    const newCognitoUserPool: CognitoUserPool = this.newUserPool();

    const newCognitoUser: CognitoUser = new CognitoUser({
      Username: username,
      Pool: newCognitoUserPool
    });

    newCognitoUser.forgotPassword({
      onSuccess: (data: any) => this.onForgotPasswordSuccess.emit(data),
      onFailure: (err: Error) => this.onForgotPasswordFailure.emit(err),
      inputVerificationCode: (data: any) => this.onInputVerificationCode.emit(data)
    });
  }

  /**
   * Confirm new password message to cognito
   */
  public confirmPassword(username: string, verificationCode: string, newPassword: string): Observable<any> {
    username = this.toUsername(username);
    const newCognitoUserPool: CognitoUserPool = this.newUserPool();

    const newCognitoUser: CognitoUser = new CognitoUser({
      Username: username,
      Pool: newCognitoUserPool
    });

    return new Observable<any>((subs: Subscriber<any>) => {
      newCognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: () => {
          subs.next();
          subs.complete();
        },
        onFailure: (err: Error) => subs.error(err)
      });
    });
  }

  /**
   * Complete new password Challenge
   */
  public completeNewPasswordChallenge(
    userAttributes: any,
    requiredAttributes: any,
    newPassword: string
  ): Observable<any> {
    delete userAttributes.email_verified; // it's returned but not valid to submit
    delete userAttributes.email; // it's returned but not valid to submit

    return new Observable<any>((subs: Subscriber<any>) => {
      this._authenticationCognito.completeNewPasswordChallenge(newPassword, userAttributes, {
        onSuccess: () => {
          subs.next();
          subs.complete();
        },
        onFailure: (err: Error) => subs.error(err)
      });
    });
  }

  /**
   * Get session for current Cognito user
   */
  public getSession(): Observable<CognitoUserSession> {
    return new Observable<CognitoUserSession>((subs: Subscriber<CognitoUserSession>) => {
      if (this.currentUser === undefined) {
        const userPool: CognitoUserPool = this.newUserPool();
        const currentUser = userPool.getCurrentUser();
        if (currentUser) {
          this._currentUser = currentUser;
        } else {
          subs.error('There is no user logged in');
          return;
        }
      }
      this.currentUser?.getSession((error: any, result: CognitoUserSession) => {
        if (error) {
          subs.error(error);
        } else {
          subs.next(result);
          subs.complete();
        }
      });
    });
  }

  /**
   * Validate verification code
   */
  validateVerificationCode(username: string, verificationCode: string): Observable<boolean> {
    username = this.toUsername(username);
    const subj = new Subject<boolean>();
    const newCognitoUser: CognitoUser = new CognitoUser({
      Username: username,
      Pool: this.newUserPool()
    });

    newCognitoUser.confirmRegistration(
      verificationCode,
      true,
      this.subjectToHandler(
        subj,
        res => res === 'SUCCESS',
        err => err
      )
    );

    return subj.asObservable();
  }

  /**
   * Validate mfa verification user code
   */
  verifyMfaCode(username: string, verificationCode: string): Observable<boolean> {
    username = this.toUsername(username);
    const subj = new Subject<boolean>();
    const newCognitoUser: CognitoUser = new CognitoUser({
      Username: username,
      Pool: this.newUserPool()
    });

    newCognitoUser.confirmRegistration(
      verificationCode,
      true,
      this.subjectToHandler(
        subj,
        res => res === 'SUCCESS',
        err => err
      )
    );

    return subj.asObservable();
  }

  /**
   * Changing Incorrect user credentials error message (NotAuthorizedException)
   */
  public onAuthFailureMessage(error) {
    if (error && error?.code === 'NotAuthorizedException') {
      error.message = this.FAILED_LOGIN_MESSAGE;
    }
    this.onAuthFailure.emit(error);
  }

  // #region Private helpers
  private newUserPool(): CognitoUserPool {
    return new CognitoUserPool({
      UserPoolId: APIConfig.Cognito.userPoolId,
      ClientId: APIConfig.Cognito.clientId
    });
  }

  private get currentUser(): CognitoUser | null {
    return this._currentUser;
  }
  // #endregion

  // #region Callback to Observable
  private subjectToHandler<T>(
    subject: Subject<T>,
    successFn: (m: any) => T,
    errorFn?: (a: any) => any
  ): CallbackFunction {
    return (error: any, result: any) => {
      if (!!error) {
        if (!!errorFn) {
          subject.error(errorFn(error));
        } else {
          subject.error(error);
        }
      } else {
        subject.next(successFn(result));
        subject.complete();
      }
    };
  }
  // #endregion
}

type CallbackFunction = (error: any, result: any) => void;
