import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from '@neptune/material.module';
import { ChangePasswordDialog } from './change-password-dialog';
import { UserService } from '@neptune/services/user.service';
import { ResponseDialogModule } from '@neptune/components/response-dialog/response-dialog.module';
import { LoadingModalModule } from '@neptune/components/loading-modal/loading-modal.module';
import { ResetPasswordModule } from 'app/main/content/pages/authentication/reset-password/reset-password.module';

@NgModule({
  declarations: [ChangePasswordDialog],
  imports: [FuseSharedModule, MaterialModule, ResponseDialogModule, LoadingModalModule, ResetPasswordModule],
  exports: [ChangePasswordDialog],
  providers: [UserService]
})
export class ChangePasswordDialogModule {}
