<ngx-datatable
  class="material purl-list-component"
  [rows]="rowsData"
  [count]="rowsData.length"
  [columnMode]="'flex'"
  [headerHeight]="48"
  [rowHeight]="50"
  [scrollbarH]="false"
  [scrollbarV]="true"
  [selectionType]="selectionType.single"
  (select)="selectionChange($event)"
  [footerHeight]="56"
  [sorts]="[{ prop: 'updatedAt', dir: 'desc' }]"
  [limit]="10"
>
  <!-- Row Column Template -->
  <ngx-datatable-column name="Name" [flexGrow]="3" [sortable]="true" prop="name">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <div class="purl-text" [ngClass]="row.class">{{ row.name }}</div>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Status" [flexGrow]="2" [sortable]="true" prop="status">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <div class="purl-text" [ngClass]="row.class">{{ row.status }}</div>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Expression" [flexGrow]="3" [sortable]="true" prop="expression">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <div class="purl-text" [ngClass]="row.class">{{ row.expression }}</div>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Updated At" [flexGrow]="2" [sortable]="true" prop="updatedAt">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <div class="purl-text" [ngClass]="row.class">
        <standard-date [date]="row.updatedAt"></standard-date>
      </div>
    </ng-template>
  </ngx-datatable-column>

  <!-- ACTIONS -->
  <ngx-datatable-column [flexGrow]="1" *ngIf="showActions">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <button mat-button matSuffix mat-icon-button [matMenuTriggerFor]="moreMenu" (click)="$event.stopPropagation()">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #moreMenu="matMenu" overlapTrigger="false" direction="down">
        <dropdown-button label="Edit" (onClick)="onEdit(row)" qpIcon="edit"></dropdown-button>
        <mat-divider></mat-divider>
        <dropdown-button
          *ngIf="row.atop == null"
          label="Delete"
          (onClick)="onDelete(row)"
          qpIcon="trash"
        ></dropdown-button>
      </mat-menu>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
