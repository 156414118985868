import { NgModule } from '@angular/core';
import { DropdownButtonComponent } from './dropdown-button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { CommonModule } from '@angular/common';
import { DatatypeIconModule } from '../datatype-icon/datatype-icon.module';

@NgModule({
  declarations: [DropdownButtonComponent],
  imports: [MatIconModule, MatMenuModule, CommonModule, DatatypeIconModule],
  exports: [DropdownButtonComponent]
})
export class DropdownButtonModule {}
