import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTreeModule } from '@angular/material/tree';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { FuseSharedModule } from '@fuse/shared.module';

import { MaterialModule } from '@neptune/material.module';
import { AssetService } from '@neptune/services/asset.service';
import { ProjectsService } from '@neptune/services/projects.service';
import { StandardUserDateModule } from '@neptune/components/standard-list-page/standard-user-date/standard-user-date.module';

import { AssetSelectorDialogComponent } from './asset-selector-dialog.component';
import { AssetSelectorService } from './asset-selector.service';
import { LoadingModalModule } from '../loading-modal/loading-modal.module';
import { NeptunePipesModule } from '@neptune/pipes/pipes.module';

@NgModule({
  declarations: [AssetSelectorDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FuseSharedModule,
    MatTreeModule,
    LoadingModalModule,
    NgxDatatableModule,
    StandardUserDateModule,
    NeptunePipesModule
  ],
  exports: [AssetSelectorDialogComponent],
  providers: [AssetService, ProjectsService, AssetSelectorService]
})
export class AssetSelectorDialogModule {}
