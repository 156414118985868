import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MaterialModule } from '@neptune/material.module';
import { ConfirmDialog } from './confirm-dialog';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
  declarations: [ConfirmDialog],
  imports: [FuseSharedModule, MaterialModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: []
})
export class ConfirmDialogModule {}
