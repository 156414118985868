import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { OrgService } from '@neptune/services/org.service';
import { UserService } from '@neptune/services/user.service';
import { NeptuneSharedModule } from '@neptune/shared.module';
import { UserProfileDialogModule } from '@neptune/components/user-profile-dialog/user-profile-dialog.module';
import { ChangePasswordDialogModule } from '@neptune/components/change-password-dialog/change-password-dialog.module';
import { MaterialModule } from '@neptune/material.module';
import { StandardSearchModule } from '@neptune/components/standard-search/standard-search.module';
import { SafePipe } from './safe.pipe';
@NgModule({
  declarations: [ToolbarComponent, SafePipe],
  imports: [
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MaterialModule,
    FuseSharedModule,
    FuseSearchBarModule,
    FuseShortcutsModule,
    NeptuneSharedModule,
    UserProfileDialogModule,
    ChangePasswordDialogModule,
    StandardSearchModule
  ],
  providers: [OrgService, UserService],
  exports: [ToolbarComponent]
})
export class ToolbarModule {}
