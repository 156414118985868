import { Pipe, PipeTransform } from '@angular/core';
import { DataType, DataTypeUtils } from '@neptune/models';

@Pipe({
  name: 'dataTypeToString'
})
export class DataTypeToStringPipe implements PipeTransform {
  public typeToString = DataTypeUtils.dataTypeToString;

  transform(value: DataType): string {
    return DataTypeUtils.dataTypeToString(value);
  }
}
