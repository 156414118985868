import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';

export interface DropdownOption {
  label?: string;
  value?: any;
  dataType?: string;
  disabled?: boolean;
  icon?: string;
  svgIcon?: boolean;
  divider?: boolean;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {
  /** Two way data binding for value */
  @Input()
  get value() {
    return this.selected;
  }
  @Output() valueChange = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set value(val) {
    this.selected = val;
    this.valueChange.emit(this.selected);
  }

  /** Options array provided to mat-select */
  @Input('options') options: DropdownOption[];

  /** Mat-select placeholder */
  @Input('placeholder') placeholder: string = 'Select';

  /** Mat-select appearance, defaults to legacy*/
  @Input('appearance') appearance: MatFormFieldAppearance = 'legacy';

  /** Custom space separated classnames */
  @Input('classes') classes: string;

  /** Boolean to set disabled attribute on mat-select */
  @Input('disabled') disabled: boolean = false;

  /** EventEmitter triggered when selection changes */
  @Output('selectionChange') selectionChange: EventEmitter<any> = new EventEmitter();

  /** Expose openedChange event, triggered when select opens */
  @Output('openedChange') openedChange: EventEmitter<any> = new EventEmitter();

  /** Store mat-select value */
  public selected: any;

  onSelectionChange() {
    this.selectionChange.emit(this.selected);
  }

  onOpenChange(event: boolean) {
    this.openedChange.emit(event);
  }
}
