/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { BaseService, Endpoint } from './base.service';
import { of as observableOf, Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CognitoService } from './cognito.service';
import { StoreService } from './store.service';
import {
  ExecuteModel,
  GetModel,
  MungProspects,
  SegmentReportModel,
  SegmentWF,
  SegmentWFModelState,
  SegmentWFMultiple,
  SelectedListsInput,
  VariablesGetListWF,
  VariablesGetModel
} from '@neptune/models/segmentation-waterfall';
import { HttpLink } from 'apollo-angular/http';
import { ApolloClient, OperationVariables } from '@apollo/client/core';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import gql from 'graphql-tag';

export const SegmentWaterfallGraphql = {
  queries: {
    getWFModelById: gql`
      query getWFModelById(
        $orgId: String!
        $segWFModelId: ID!
        $startingSeqnumber: Int
        $limit: Int
        $nextToken: String
      ) {
        getWFModelById(
          orgId: $orgId
          segWFModelId: $segWFModelId
          startingSeqnumber: $startingSeqnumber
          limit: $limit
          nextToken: $nextToken
        ) {
          PK
          SK
          assetSegWFModelVid
          modelName
          functionalityVersionId
          folderId
          projectId
          starObjectId
          description
          modelState
          totalListCount
          lastRunDate
          dedupeFields {
            dedupe_field
            limit
          }
          insertedAt
          insertedBy
          updatedAt
          updatedBy
          publishStatus
          publishedBy
          publishedAt
          publishedByName
          modelStatus
          RL_SWFEditorAccordion {
            listId
            name
            typeOfCount
            balance
          }
          RL_SWFEditorAccordionStatus {
            status
            message
          }
          universe {
            sourceId
            sourceType
            count
            sourceName
          }
          udfHeaders {
            udf_name
            column_header
          }
          detailRows {
            rowsGroupsDistribution {
              groupNumber
              color
              rowsSK
            }
            rowsList {
              PK
              SK
              seqNumber
              parentSeqNumber
              sourceId
              sourceType
              sourceName
              rowIsForOutput
              lastStartingCount
              modelCount
              universeCount
              beforeDedupeCount
              duplicateRecordsCount
              remainderCount
              rowMaximum
              remainderPassThru
              sampleMethod
              rowState
              insertedAt
              insertedBy
              updatedAt
              updatedBy
              splitted {
                percentage
              }
              udfValues {
                udf_name
                value
              }
              rowGroup
            }
            nextToken
          }
        }
      }
    `,
    getListWFModels: gql`
      query listWFModelsAssets($filter: TableWFModelFilterInput2!, $limit: Int, $nextToken: String) {
        listWFModelsAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
          segWFModelsAssetsList {
            projectId
            assetType
            folderId
            updatedByUser {
              firstName
              lastName
              email
              username
            }
            status
            updatedBy
            name
            insertedByUser {
              firstName
              lastName
              email
              username
            }
            insertedAt
            insertedBy
            assetId
            updatedAt
            orgId
            tableName
            lastExecutedCount
            id
            folderName
            projectName
            tableId
          }
          nextToken
        }
      }
    `,
    getWFModelByIdDouble: gql`
      query getWFModelById($orgId: String!, $segWFModelId1: ID!, $segWFModelId2: ID!) {
        query1: getWFModelById(orgId: $orgId, segWFModelId: $segWFModelId1) {
          PK
          SK
          assetSegWFModelVid
          modelName
        }
        query2: getWFModelById(orgId: $orgId, segWFModelId: $segWFModelId2) {
          PK
          SK
          assetSegWFModelVid
          modelName
        }
      }
    `
  },
  mutations: {
    createSegWFModel: gql`
      mutation createSegWFModel($segWFModelData: SegWFModelData) {
        createSegWFModel(segWFModelData: $segWFModelData) {
          wfmodelId
          segWFModelMetadata {
            PK
            SK
            assetSegWFModelVid
            modelName
            functionalityVersionId
            folderId
            projectId
            starObjectId
            description
            modelState
            totalListCount
            lastRunDate
            insertedAt
            insertedBy
            updatedAt
            updatedBy
            modelStatus
            udfHeaders {
              udf_name
              column_header
            }
            rowsGroups {
              groupNumber
              color
            }
          }
          processedSegWFRows {
            PK
            SK
          }
        }
      }
    `,
    updateSegWFModel: gql`
      mutation updateSegWFModel(
        $wfmodelId: ID!
        $segWFModelData: SegWFModelData
        $addedRows: [RowData]
        $modifiedRows: [UpdatedSegWFModelRow]
        $deletedRows: [DeletedSegWFModelRow]
        $startingSeqnumber: Int
        $limit: Int
      ) {
        updateSegWFModel(
          wfmodelId: $wfmodelId
          segWFModelData: $segWFModelData
          addedRows: $addedRows
          modifiedRows: $modifiedRows
          deletedRows: $deletedRows
          startingSeqnumber: $startingSeqnumber
          limit: $limit
        ) {
          segWFModelMetadata {
            SK
          }
        }
      }
    `,
    updateMultipleSegWFModel: gql`
      mutation updateSegWFModel(
        $wfmodelId: ID!
        $segWFModelData: SegWFModelData
        $addedRows1: [RowData]
        $addedRows2: [RowData]
        $addedRows3: [RowData]
        $addedRows4: [RowData]
        $addedRows5: [RowData]
        $addedRows6: [RowData]
        $addedRows7: [RowData]
        $addedRows8: [RowData]
        $addedRows9: [RowData]
        $addedRows10: [RowData]
        $modifiedRows1: [UpdatedSegWFModelRow]
        $modifiedRows2: [UpdatedSegWFModelRow]
        $modifiedRows3: [UpdatedSegWFModelRow]
        $modifiedRows4: [UpdatedSegWFModelRow]
        $modifiedRows5: [UpdatedSegWFModelRow]
        $modifiedRows6: [UpdatedSegWFModelRow]
        $modifiedRows7: [UpdatedSegWFModelRow]
        $modifiedRows8: [UpdatedSegWFModelRow]
        $modifiedRows9: [UpdatedSegWFModelRow]
        $modifiedRows10: [UpdatedSegWFModelRow]
        $deletedRows1: [DeletedSegWFModelRow]
        $deletedRows2: [DeletedSegWFModelRow]
        $deletedRows3: [DeletedSegWFModelRow]
        $deletedRows4: [DeletedSegWFModelRow]
        $deletedRows5: [DeletedSegWFModelRow]
        $deletedRows6: [DeletedSegWFModelRow]
        $deletedRows7: [DeletedSegWFModelRow]
        $deletedRows8: [DeletedSegWFModelRow]
        $deletedRows9: [DeletedSegWFModelRow]
        $deletedRows10: [DeletedSegWFModelRow]
        $startingSeqnumber: Int
        $limit: Int
      ) {
        update1: updateSegWFModel(
          wfmodelId: $wfmodelId
          segWFModelData: $segWFModelData
          addedRows: $addedRows1
          modifiedRows: $modifiedRows1
          deletedRows: $deletedRows1
          startingSeqnumber: $startingSeqnumber
          limit: $limit
        ) {
          segWFModelMetadata {
            SK
          }
        }
        update2: updateSegWFModel(
          wfmodelId: $wfmodelId
          segWFModelData: $segWFModelData
          addedRows: $addedRows2
          modifiedRows: $modifiedRows2
          deletedRows: $deletedRows2
          startingSeqnumber: $startingSeqnumber
          limit: $limit
        ) {
          segWFModelMetadata {
            SK
          }
        }
        update3: updateSegWFModel(
          wfmodelId: $wfmodelId
          segWFModelData: $segWFModelData
          addedRows: $addedRows3
          modifiedRows: $modifiedRows3
          deletedRows: $deletedRows3
          startingSeqnumber: $startingSeqnumber
          limit: $limit
        ) {
          segWFModelMetadata {
            SK
          }
        }
        update4: updateSegWFModel(
          wfmodelId: $wfmodelId
          segWFModelData: $segWFModelData
          addedRows: $addedRows4
          modifiedRows: $modifiedRows4
          deletedRows: $deletedRows4
          startingSeqnumber: $startingSeqnumber
          limit: $limit
        ) {
          segWFModelMetadata {
            SK
          }
        }
        update5: updateSegWFModel(
          wfmodelId: $wfmodelId
          segWFModelData: $segWFModelData
          addedRows: $addedRows5
          modifiedRows: $modifiedRows5
          deletedRows: $deletedRows5
          startingSeqnumber: $startingSeqnumber
          limit: $limit
        ) {
          segWFModelMetadata {
            SK
          }
        }
        update6: updateSegWFModel(
          wfmodelId: $wfmodelId
          segWFModelData: $segWFModelData
          addedRows: $addedRows6
          modifiedRows: $modifiedRows6
          deletedRows: $deletedRows6
          startingSeqnumber: $startingSeqnumber
          limit: $limit
        ) {
          segWFModelMetadata {
            SK
          }
        }
        update7: updateSegWFModel(
          wfmodelId: $wfmodelId
          segWFModelData: $segWFModelData
          addedRows: $addedRows7
          modifiedRows: $modifiedRows7
          deletedRows: $deletedRows7
          startingSeqnumber: $startingSeqnumber
          limit: $limit
        ) {
          segWFModelMetadata {
            SK
          }
        }
        update8: updateSegWFModel(
          wfmodelId: $wfmodelId
          segWFModelData: $segWFModelData
          addedRows: $addedRows8
          modifiedRows: $modifiedRows8
          deletedRows: $deletedRows8
          startingSeqnumber: $startingSeqnumber
          limit: $limit
        ) {
          segWFModelMetadata {
            SK
          }
        }
        update9: updateSegWFModel(
          wfmodelId: $wfmodelId
          segWFModelData: $segWFModelData
          addedRows: $addedRows9
          modifiedRows: $modifiedRows9
          deletedRows: $deletedRows9
          startingSeqnumber: $startingSeqnumber
          limit: $limit
        ) {
          segWFModelMetadata {
            SK
          }
        }
        update10: updateSegWFModel(
          wfmodelId: $wfmodelId
          segWFModelData: $segWFModelData
          addedRows: $addedRows10
          modifiedRows: $modifiedRows10
          deletedRows: $deletedRows10
          startingSeqnumber: $startingSeqnumber
          limit: $limit
        ) {
          segWFModelMetadata {
            SK
          }
        }
      }
    `,
    updateModelState: gql`
      mutation updateSegWFModel($wfmodelId1: ID!, $wfmodelId2: ID!, $segWFModelData: SegWFModelData) {
        update1: updateSegWFModel(wfmodelId: $wfmodelId1, segWFModelData: $segWFModelData) {
          segWFModelMetadata {
            PK
            SK
            modelState
          }
        }
        update2: updateSegWFModel(wfmodelId: $wfmodelId2, segWFModelData: $segWFModelData) {
          segWFModelMetadata {
            PK
            SK
            modelState
          }
        }
      }
    `,
    updateModelStateSingle: gql`
      mutation updateSegWFModel($wfmodelId: ID!, $segWFModelData: SegWFModelData) {
        updateSegWFModel(wfmodelId: $wfmodelId, segWFModelData: $segWFModelData) {
          wfmodelId
          segWFModelMetadata {
            SK
            modelState
          }
        }
      }
    `,
    deleteSegWFModel: gql`
      mutation deleteSegWFModel($wfmodelId: ID!) {
        deleteSegWFModel(wfmodelId: $wfmodelId) {
          segWFModelMetadata {
            PK
            SK
            assetSegWFModelVid
            modelName
            functionalityVersionId
            folderId
            projectId
            starObjectId
            description
            modelState
            totalListCount
            lastRunDate
            insertedAt
            insertedBy
            updatedAt
            updatedBy
            modelStatus
          }
        }
      }
    `,
    executeSegmentWaterfall: gql`
      mutation executeSegmentWaterfall($wfmodelId: ID!) {
        executeSegmentWaterfall(wfmodelId: $wfmodelId) {
          SQSMessageId
        }
      }
    `,
    getPreMungingAccordion: gql`
      mutation getPreMungingAccordion($wfmodelId: ID!) {
        getPreMungingAccordion(wfmodelId: $wfmodelId) {
          SQSMessageId
        }
      }
    `,
    mungProspects: gql`
      mutation mungProspects($wfmodelId: ID!, $selectedLists: [selectedListsInput]!) {
        mungProspects(wfmodelId: $wfmodelId, selectedLists: $selectedLists) {
          SQSMessageId
        }
      }
    `,
    unpublishSegWFModel: gql`
      mutation unpublishSegWFModel($wfmodelId: ID!) {
        unpublishSegWFModel(wfmodelId: $wfmodelId) {
          SQSMessageId
        }
      }
    `,
    setProspectsUnavailable: gql`
      mutation processSWFPublishingDependencies($wfmodelId: ID!) {
        processSWFPublishingDependencies(wfmodelId: $wfmodelId) {
          SQSMessageId
        }
      }
    `,
    cloneSegWFModel: gql`
      mutation cloneSegWFModel($CloningInput: CloningInput) {
        cloneSegWFModel(CloningInput: $CloningInput) {
          SQSMessageId
        }
      }
    `,
    getRentedLists: gql`
      mutation rlListingPage {
        rlListingPage {
          SQSMessageId
        }
      }
    `
  },
  subscriptions: {
    subscribeToUpdateModel: `
      subscription subscribeToUpdateModel {
        subscribeToUpdateModel(wfmodelId: $id) {
          segWFModelMetadata {
            PK
            SK
            totalListCount
            modelState
            modelStatus
          }
        }
      }
    `,
    subscribePremungingAccordion: `
    subscription subscribeToUpdateModel {
      subscribeToUpdateModel(wfmodelId: $id) {
        segWFModelMetadata {
          SK
          RL_AccordionPrePlanning {
          RentedLists {
          campaignCode
          balance
          created
          listId
          name
          totalCount
          typeOfCount
          used
          detail
          }
          previousInteractions {
          balance
          created
          listId
          name
          typeOfCount
          detail
          }
          }
          RL_AccordionPrePlanningStatus {
          status
          message
          }
        }
      }
    }
    `,
    subscribeMungProspects: `
    subscription subscribeToUpdateModel {
      subscribeToUpdateModel(wfmodelId: $id) {
      segWFModelMetadata {
      SK
      RL_SWFEditorAccordion {
      listId
      name
      typeOfCount
      balance
      }
      RL_SWFEditorAccordionStatus {
      status
      message
      }
     }
    }
  }
    `
  }
};

@Injectable()
export class SegmentationWaterFallService extends BaseService {
  public apolloClient: ApolloClient<object>;
  // @ts-ignore
  public appsyncClient: AWSAppSyncClient<NormalizedCacheObject>;
  public querySubscriptionUpdate: OperationVariables;
  public querySubscriptionAccordion: OperationVariables;
  public querySubscriptionProspect: OperationVariables;

  constructor(
    public httpClient: HttpClient,
    public cognitoService: CognitoService,
    public storeService: StoreService,
    public httpLink: HttpLink
  ) {
    super(httpClient, cognitoService, storeService);
  }

  /**
   * For use with jasmine testing, returns a mock of service
   */
  public static mockService(jasmine: unknown): unknown {
    return {
      getList: jest.fn(() => observableOf([])),
      getById: jest.fn(() => observableOf([])),
      create: jest.fn(() => observableOf([])),
      update: jest.fn(() => observableOf([])),
      delete: jest.fn(() => observableOf([]))
    };
  }

  public getList(folder_id: string): Observable<SegmentWF[]> {
    return super.baseGet<SegmentWF[]>(Endpoint.SEGMENTATION_WATERFALL, 'seg_wf_models', false, {
      folder_id
    });
  }

  public getById(
    wfmodel_id: string,
    start_row: number = 1,
    number_of_rows: number = 10000,
    orgId: string = 'MDL#quickpivot.com',
    nextToken: string | null = null
  ): Observable<GetModel> {
    const variables: VariablesGetModel = {
      orgId,
      segWFModelId: wfmodel_id,
      startingSeqnumber: start_row,
      limit: number_of_rows
    };

    if (nextToken) {
      variables.nextToken = nextToken;
    }

    return new Observable(observer => {
      this.configureApolloClient().subscribe(() => {
        this.apolloClient
          .watchQuery({
            query: SegmentWaterfallGraphql.queries.getWFModelById,
            variables
          })
          .subscribe({
            next: value => observer.next(value),
            complete: () => observer.complete(),
            error: err => observer.error(err)
          });
      });
    });
  }

  public getlistWFModels(folder_id: string, nextToken: string | null = null): Observable<object> {
    const variables: VariablesGetListWF = {
      filter: {
        folderId: {
          eq: folder_id
        },
        publishStatus: {
          eq: 'PUBLISHED'
        }
      },
      limit: 10000
    };

    if (nextToken) {
      variables.nextToken = nextToken;
    }

    return new Observable(observer => {
      this.configureApolloClient().subscribe(() => {
        this.apolloClient
          .watchQuery({
            query: SegmentWaterfallGraphql.queries.getListWFModels,
            variables
          })
          .subscribe({
            next: value => observer.next(value),
            complete: () => observer.complete(),
            error: err => observer.error(err)
          });
      });
    });
  }

  public create(data: SegmentWF): Observable<object> {
    return new Observable(observer => {
      this.configureApolloClient().subscribe(() => {
        this.apolloClient
          .mutate({
            mutation: SegmentWaterfallGraphql.mutations.createSegWFModel,
            variables: data
          })
          .then(result => {
            observer.next(result);
            observer.complete();
          })
          .catch(err => observer.error(err));
      });
    });
  }

  public update(model: SegmentWF): Promise<object> {
    return new Promise(resolve => {
      const result = this.apolloClient.mutate({
        mutation: SegmentWaterfallGraphql.mutations.updateSegWFModel,
        variables: model
      });

      resolve(result);
    });
  }

  public updateMultiple(model: SegmentWFMultiple): Promise<object> {
    return new Promise(resolve => {
      const result = this.apolloClient.mutate({
        mutation: SegmentWaterfallGraphql.mutations.updateMultipleSegWFModel,
        variables: model
      });

      resolve(result);
    });
  }

  public clone(
    id: string,
    folderId: string,
    modelName: string,
    orgId: string,
    description: string
  ): Observable<object> {
    return new Observable(observer => {
      this.configureApolloClient().subscribe(() => {
        this.apolloClient
          .mutate({
            mutation: SegmentWaterfallGraphql.mutations.cloneSegWFModel,
            variables: {
              CloningInput: {
                PK: `MDL#${orgId}`,
                SK: `${id}`,
                modelName,
                folderId,
                description
              }
            }
          })
          .then(result => {
            observer.next(result);
            observer.complete();
          })
          .catch(err => {
            observer.error(err);
          });
      });
    });
  }

  public updateModelState(model: SegmentWFModelState): Observable<object> {
    return new Observable(observer => {
      const result = this.apolloClient.mutate({
        mutation: SegmentWaterfallGraphql.mutations.updateModelState,
        variables: model
      });

      observer.next(result);
      observer.complete();
    });
  }

  public updateModelStateSingle(model: SegmentWFModelState): Observable<object> {
    return new Observable(observer => {
      const result = this.apolloClient.mutate({
        mutation: SegmentWaterfallGraphql.mutations.updateModelStateSingle,
        variables: model
      });

      observer.next(result);
      observer.complete();
    });
  }

  public delete(wfmodel_id: string): Observable<object> {
    return new Observable(observer => {
      this.configureApolloClient().subscribe({
        next: () => {
          const result = this.apolloClient.mutate({
            mutation: SegmentWaterfallGraphql.mutations.deleteSegWFModel,
            variables: {
              wfmodelId: wfmodel_id
            }
          });

          observer.next(result);
          observer.complete();
        },
        error: err => observer.error(err)
      });
    });
  }

  public getAuthToken(): Observable<HttpHeaders> {
    return this.getAuth(Endpoint.SEGMENTATION_WATERFALL);
  }

  public getEndpointURL(type: Endpoint, url: string): string {
    return this.getURL(type, url);
  }

  public subscribeToExecutedModel(wfmodel_id: string): Observable<ExecuteModel> {
    const id: string = `"${wfmodel_id}"`;
    return new Observable(observer => {
      this.configureApolloClient().subscribe({
        next: () => {
          this.querySubscriptionUpdate = this.appsyncClient
            .subscribe({
              query: gql`
                ${SegmentWaterfallGraphql.subscriptions.subscribeToUpdateModel.replace('$id', id)}
              `
            })
            .subscribe({
              next: ev => observer.next(ev),
              error: err => observer.error(err)
            });
        },
        error: err => observer.error(err)
      });
    });
  }

  public closeExecuteModel() {
    this.querySubscriptionUpdate.unsubscribe();
  }

  public executeSegmentWFModel(wfmodel_id: string): Observable<object> {
    return new Observable(observer => {
      this.configureApolloClient().subscribe({
        next: () => {
          const result = this.apolloClient.mutate({
            mutation: SegmentWaterfallGraphql.mutations.executeSegmentWaterfall,
            variables: {
              wfmodelId: wfmodel_id
            }
          });

          observer.next(result);
          observer.complete();
        },
        error: err => observer.error(err)
      });
    });
  }

  public getRentedListAccordion(nextToken: string | null = null): Observable<object> {
    return new Observable(observer => {
      this.configureApolloClient().subscribe(() => {
        this.apolloClient
          .mutate({
            mutation: SegmentWaterfallGraphql.mutations.getRentedLists,
            variables: {}
          })
          .then(result => {
            observer.next(result);
            observer.complete();
          })
          .catch(err => observer.error(err));
      });
    });
  }

  public subscribePremungingAccordion(wfmodel_id: string): Observable<MungProspects> {
    const id: string = `"${wfmodel_id}"`;
    return new Observable(observer => {
      this.configureApolloClient().subscribe({
        next: () => {
          this.querySubscriptionAccordion = this.appsyncClient
            .subscribe({
              query: gql`
                ${SegmentWaterfallGraphql.subscriptions.subscribePremungingAccordion.replace('$id', id)}
              `
            })
            .subscribe({
              next: ev => observer.next(ev),
              error: err => observer.error(err)
            });
        },
        error: err => observer.error(err)
      });
    });
  }

  public closePremungingAccordion() {
    this.querySubscriptionAccordion.unsubscribe();
  }

  public getPreMungingAccordion(wfmodel_id: string): Observable<any> {
    return new Observable(observer => {
      this.configureApolloClient().subscribe({
        next: () => {
          const result = this.apolloClient.mutate({
            mutation: SegmentWaterfallGraphql.mutations.getPreMungingAccordion,
            variables: {
              wfmodelId: wfmodel_id
            }
          });

          observer.next(result);
          observer.complete();
        },
        error: err => observer.error(err)
      });
    });
  }

  public subscribeMungProspects(wfmodel_id: string): Observable<MungProspects> {
    const id: string = `"${wfmodel_id}"`;
    return new Observable(observer => {
      this.configureApolloClient().subscribe({
        next: () => {
          this.querySubscriptionProspect = this.appsyncClient
            .subscribe({
              query: gql`
                ${SegmentWaterfallGraphql.subscriptions.subscribeMungProspects.replace('$id', id)}
              `
            })
            .subscribe({
              next: ev => observer.next(ev),
              error: err => observer.error(err)
            });
        },
        error: err => observer.error(err)
      });
    });
  }

  public closeSubscriptionProspect() {
    this.querySubscriptionProspect.unsubscribe();
  }

  public mungProspects(wfmodel_id: string, selectedLists: SelectedListsInput[]): Observable<any> {
    return new Observable(observer => {
      this.configureApolloClient().subscribe({
        next: () => {
          const result = this.apolloClient.mutate({
            mutation: SegmentWaterfallGraphql.mutations.mungProspects,
            variables: {
              wfmodelId: wfmodel_id,
              selectedLists
            }
          });

          observer.next(result);
          observer.complete();
        },
        error: err => observer.error(err)
      });
    });
  }

  public unpublishSegWFModel(wfmodel_id: string): Observable<Promise<object>> {
    return new Observable(observer => {
      this.configureApolloClient().subscribe({
        next: () => {
          const result = this.apolloClient.mutate({
            mutation: SegmentWaterfallGraphql.mutations.unpublishSegWFModel,
            variables: {
              wfmodelId: `${wfmodel_id}`
            }
          });

          observer.next(result);
          observer.complete();
        },
        error: err => observer.error(err)
      });
    });
  }

  public setProspectsUnavailable(wfmodel_id: string): Observable<Promise<object>> {
    return new Observable(observer => {
      this.configureApolloClient().subscribe({
        next: () => {
          const result = this.apolloClient.mutate({
            mutation: SegmentWaterfallGraphql.mutations.setProspectsUnavailable,
            variables: {
              wfmodelId: `${wfmodel_id}`
            }
          });

          observer.next(result);
          observer.complete();
        },
        error: err => observer.error(err)
      });
    });
  }

  public configureApolloClient(): Observable<object> {
    return new Observable(observer => {
      this.getAuthToken().subscribe({
        next: (headers: HttpHeaders) => {
          const link = this.httpLink.create({
            uri: this.getEndpointURL(Endpoint.SEGMENTATION_WATERFALL_GRAPHQL, 'graphql'),
            headers
          });

          this.appsyncClient = new AWSAppSyncClient({
            disableOffline: true,
            url: this.getEndpointURL(Endpoint.SEGMENTATION_WATERFALL_GRAPHQL, 'graphql'),
            region: 'us-east-1',
            auth: {
              type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
              jwtToken: headers.get('Authorization') as string
            }
          });

          // We're not storing in cache the same results for duplicated queries.
          const cache = new InMemoryCache({
            resultCaching: false,
            addTypename: false
          }) as any;

          this.apolloClient = new ApolloClient({
            link,
            cache
          });

          observer.next();
          observer.complete();
        },
        error: (err: Error) => observer.error(err)
      });
    });
  }

  public getReport(modelId: string): Observable<SegmentReportModel> {
    const url = `report/${modelId}`;
    return this.basePost<null, SegmentReportModel>(Endpoint.SEGMENTATION_WATERFALL_REPORT, url, null);
  }
}
