import { AssetBase } from '@neptune/models/asset';
import { TableJoinsData } from '.';

// eslint-disable-next-line no-shadow
export enum MessageStatus {
  DRAFT = 'DRAFT',
  APPROVED = 'APPROVED',
  DELETED = 'DELETED'
}

export interface Message extends AssetBase {
  approvedBy: string;
  type: MessageType;
  approvedAt: string;
  version: number;
  /** The last date in which the record was updated. */
  /** NOTE :: could get this from the Asset Reference */
  updatedAt?: string;
  idVersion?: string;
  contentType?: string;
  assetId?: string;
}

export interface PreviewMessageData {
  joinedTables: TableJoinsData;
  exportFields: (
    | MessageDestinationField
    | {
        name: string;
        value: string;
      }
  )[];
}
export interface PrintMessage extends Message {
  destination?: MessageDestination;
  tableName: string;
  type: MessageType.PRINT;
}

export interface ConnectorMessage {
  instanceId: string;
  connectorType: string;
  publicField?: [
    | {
        URI: string;
      }
    | {
        User: string;
      }
  ];
}

// eslint-disable-next-line no-shadow
export enum ConnectorUploadFileOptions {
  CHOOSE = 'CHOOSE',
  LOCAL = 'LOCAL',
  FTP = 'FTP',
  SFTP = 'SFTP',
  FTP_LOCAL = 'FTP_LOCAL',
  S3 = 'S3'
}

// eslint-disable-next-line no-shadow
export enum ConnectorMessageOptions {
  CHOOSE = 'CHOOSE',
  FTP = 'FTP',
  SFTP = 'SFTP',
  S3 = 'S3'
}

export interface DataOutput extends Message {
  destination?: MessageDestination;
  tableName: string;
  type: MessageType.DATA_OUTPUT;
}

export interface CloneMessageBody {
  description: string;
  folderId: string;
  name: string;
}

export interface EmailMessage extends Message {
  replyEmail: string;
  subject: string;
  approvedBy: string;
  appendCodes?: Record<string, unknown> | any;
  clickDomain: string;
  type: MessageType.EMAIL;
  htmlContent: string;
  fromEmail: string;
  senderId: string;
  toName: string;
  fromName: string;
  toEmail: string;
  senderDomain: string;
  textContent: string;
  replyName: string;
  bounceEmail: string;
  lastProofInsertedAt?: string;
  contentType: EmailMessageEditType;
  lastProofStatus: EmailProofStatus;
}

export interface Link {
  id?: string;
  name: string;
  messageIdVersion: string;
  url: string;
  isTracked: boolean;
  mustAppendCode: boolean;
  fullTextLink: string;
  content: string;
  alias: string;
  folderId: string;
  projectId: string;
  status: LinkStatus;
  active: boolean;
  deleted: boolean;
  insertedAt?: string;
  insertedBy?: string;
  updatedAt?: string;
  updatedBy?: string;
}

// eslint-disable-next-line no-shadow
export enum LinkStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED'
}

export interface PrintOnDemand extends Message {
  status?: PrintOnDemandStatus;
  dmmCampaign: DmmCampaign;
  type: MessageType.PRINT_ON_DEMAND;
  destination: { fields: MessageDestinationField[] };
}

// eslint-disable-next-line no-shadow
export enum EmailMessageEditType {
  HTML = 'HTML',
  WYSIWYG = 'WYSIWYG'
}

// eslint-disable-next-line no-shadow
export enum PrintOnDemandStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED'
}

export class MessageDestinationField {
  name: string;
  value: string;
  canEditName: boolean = true;
}

export class MessageDestination {
  instanceId?: string;
  connectorId?: string;
  fields?: MessageDestinationField[];
  parameters?: {
    hasHeader?: boolean;
    filename?: string;
    delimiter?: string;
    rowDelimiter?: string;
    qualifier?: string;
    qualifierType?: string;
    tableName?: string;
    isNewTable?: boolean;
  };
  path?: string;
}

export interface MessagePreviewData {
  [value: string]: string;
}

// eslint-disable-next-line no-shadow
export enum MessageType {
  EMAIL = 'EMAIL',
  PRINT = 'PRINT',
  PRINT_ON_DEMAND = 'PRINT_ON_DEMAND',
  DATA_OUTPUT = 'DATA_OUTPUT'
}

export class EmailProof {
  updatedBy: string;
  subject: string;
  messageId: string;
  textContent: string;
  orgId: string;
  htmlContent: string;
  emails?: string[];
  listDefinitionId?: string;
  listDefinitionName?: string;
  insertedBy: string;
  insertedAt: string;
  id: string;
  messageVersion: number;
  status: EmailProofStatus;
  updatedAt: string;
}

export class DmmCampaign {
  campaignName: string;
  orderNo: string;
  pdf: string;
  size: string;
  jpgBack: string;
  jpgFront: string;
}

export interface DmmCampaignBalance {
  balance: string;
}

// eslint-disable-next-line no-shadow
export enum EmailProofStatus {
  NO_PROOF = 'NO_PROOF',
  NEW = 'NEW',
  ERROR = 'ERROR',
  DONE = 'DONE'
}
