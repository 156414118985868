/* eslint-disable no-invalid-this */
import { Component, Inject, OnInit, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { environment } from 'environments/environment';
import { AssetDefinition, AssetSelectorDialogData, AssetType, Asset, AssetUtils, AllStatus } from '@neptune/models';
import { AssetSelectorService } from './asset-selector.service';
import { dialogContainerClassSafeCheck } from '@neptune/utilities/dialog-container-safecheck';
import { forkJoin } from 'rxjs';
import { LoadingModalComponent } from '@neptune/components/loading-modal/loading-modal.component';
import { SelectionType } from '@swimlane/ngx-datatable';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'asset-selector-dialog',
  templateUrl: './asset-selector-dialog.component.html',
  styleUrls: ['./asset-selector-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AssetSelectorDialogComponent implements OnInit, OnDestroy {
  @ViewChild(LoadingModalComponent, { static: true })
  private loadingComponent: LoadingModalComponent;

  /** Default title */
  public title: string = 'Select Asset';
  /** Expose AssetType */
  public AssetType = AssetType;
  /** Used for folder navigation */
  public selectedAssetNav: AssetDefinition;
  /** Passed to mat-tree to control the component */
  public treeControl = new NestedTreeControl<AssetDefinition>(node => node.children);
  /** Datasource of AssetsDefinitions passed to mat-tree to inject data*/
  public dataSource = new MatTreeNestedDataSource<AssetDefinition>();
  /** Datasource for ngx-datatable */
  public rowsData: Asset[] = [];
  /** This is what is going to be sent when closed */
  public selectedAssets: Asset[] = [];
  public SelectionType = SelectionType;
  /** Map to build filter function */
  private _filterMap = {
    assetTypes: this._filterByAssetType.bind(this),
    status: this._filterByStatus.bind(this),
    tableName: this._filterByTableName.bind(this)
  };
  /** Array containing the filter functions */
  private _filtersArray: ((asset: Asset) => boolean)[] = [];
  public isQuery: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AssetSelectorDialogComponent>,
    public assetService: AssetSelectorService,
    @Inject(MAT_DIALOG_DATA) public data: AssetSelectorDialogData
  ) {
    // Ensure the proper panelClass has been added to the configuration object
    dialogContainerClassSafeCheck('AssetSelectorDialog', 'asset-selector-dialog-container', dialogRef);
    this.checkSelectable = this.checkSelectable.bind(this);
    this.rowClass = this.rowClass.bind(this);
  }

  ngOnInit() {
    this.loadingComponent.startLoading();
    this.isQuery = (this.data.filterProps?.assetTypes && this.data.filterProps.assetTypes[0] === 'Query') || false;
    if (this.data.filterProps) {
      this._filtersArray = this._buildFilters() as ((asset: Asset) => boolean)[];
      if (this.data.filterProps.assetTypes && this.data.filterProps.assetTypes.length > 0) {
        const labels = this.data.filterProps.assetTypes.map(type => AssetUtils.getAssetLabel(type)).join(', ');
        this.title = `Select ${labels}`;
      }
    }
    // If we're using the selector in the context of a project, we have a projectId already
    if (this.data.projectId) {
      forkJoin({
        project: this.assetService.getProject(this.data.projectId),
        folders: this.assetService.getFolders(this.data.projectId)
      }).subscribe({
        next: response => {
          this.dataSource.data = [{ ...response.project, children: response.folders }];

          this.treeControl.expand(this.dataSource.data[0]);
          this.loadingComponent.stopLoading();
          if (response.folders.length) {
            const folder = this.data.folderId
              ? this._findFolderById(this.dataSource.data[0], this.data.folderId)
              : this.dataSource.data[0].children && this.dataSource.data[0].children[0];
            this.toggleSelect(folder as AssetDefinition);
          }
        },
        error: error => console.error(error)
      });
    } else {
      this.assetService.getProjects().subscribe((response: AssetDefinition[]) => {
        this.dataSource.data = response;
        this.loadingComponent.stopLoading();
        this.toggleSelect(this.dataSource.data[0]);
      });
    }
  }

  /** IconMap used to display on tree */
  public getAssetIcon(type: AssetType): string {
    return AssetUtils.getAssetIcon(type);
  }

  public checkSelectable(row: Asset): boolean {
    if (row.assetType === AssetType.PROJECT) {
      return this.data.projectId === undefined;
    } else if (this.data.filterProps && row.assetType !== AssetType.FOLDER) {
      return this._filtersArray.every(filter => filter(row));
    } else {
      return true;
    }
  }

  public hasChild(_: number, node: AssetDefinition): boolean {
    return node.assetType === AssetType.PROJECT || (!!node.children && node.children.length > 0);
  }

  public onClose(data?: Asset | Asset[]) {
    this.dialogRef.close(data);
  }

  public onSelect() {
    this.onClose(this.data.multiSelect ? this.selectedAssets : this.selectedAssets[0]);
  }

  public isAssetNavSelected(node: AssetDefinition): boolean {
    return this.selectedAssetNav && this.selectedAssetNav.id === node.id;
  }

  public toggleSelect(node: AssetDefinition) {
    const load = node !== this.selectedAssetNav;
    this.selectedAssetNav = node;
    if (load) {
      this._getAssetChildren(node);
    }
  }

  public rowClass(node: AssetDefinition) {
    return {
      disabled: !this.checkSelectable(node)
    };
  }

  private _getAssetChildren(node: AssetDefinition) {
    if (this.selectedAssetNav.assetType === AssetType.PROJECT) {
      if (node.loading || (node.children?.length as number) > 0) {
        return;
      }
      node.loading = true;
      this._getFolders(this.selectedAssetNav.id);
    } else {
      this._getAssets();
    }
  }

  private _getAssets() {
    this.loadingComponent.startLoading();
    this.assetService
      .getAssets(
        this.selectedAssetNav.id,
        this.data.filterProps && this.data.filterProps.assetTypes,
        this.data.filterProps && this.data.filterProps.status
      )
      .subscribe({
        next: assets => {
          // Filter out those assets that doesn't meet the filtering criteria, if any
          this.rowsData = this.data.filterProps && this.data.hideOthers ? this._filterAssets(assets) : assets;
          this.loadingComponent.stopLoading();
        },
        error: () => this.loadingComponent.showError('There was an error loading folder files')
      });
  }

  private _getFolders(projectId: string, stitch: boolean = true) {
    this.assetService.getFolders(projectId).subscribe((response: AssetDefinition[]) => {
      if (stitch) {
        const index = this.dataSource.data.findIndex(project => project.id === this.selectedAssetNav.id);
        this.dataSource.data[index] = this.assetService.stitchProjectAndFolder(this.selectedAssetNav, response);
        this.dataSource.data = [...this.dataSource.data];
        this.selectedAssetNav = this.dataSource.data[index];
        this.treeControl.expand(this.dataSource.data[index]);
        const folder =
          this.dataSource.data[index] &&
          this.dataSource.data[index].children &&
          this.dataSource.data[index].children?.length
            ? this.dataSource.data[index].children?.find(asset => asset.assetType === AssetType.FOLDER)
            : false;
        if (folder) {
          this.toggleSelect(folder);
        }
      } else {
        this.dataSource.data = response;
        this.loadingComponent.stopLoading();
        if (response.length) {
          this.toggleSelect(this.dataSource.data[0]);
        }
      }
    });
  }

  private _filterAssets(assets: Asset[]): Asset[] {
    return assets.filter(asset => this._filtersArray.every(filter => filter(asset)));
  }

  private _buildFilters(): ((asset: Asset) => boolean)[] | null {
    if (!this.data.filterProps) {
      return null;
    }
    return Object.keys(this.data.filterProps).reduce((acc: ((asset: Asset) => boolean)[], curr) => {
      if (this.data.filterProps && this.data.filterProps[curr] && this._filterMap.hasOwnProperty(curr)) {
        acc.push(this._filterMap[curr]);
      }
      return acc;
    }, []);
  }

  private _filterByAssetType(asset: Asset): boolean {
    if (this.data.filterProps && this.data.filterProps.assetTypes && this.data.filterProps.assetTypes.length) {
      return this.data.filterProps.assetTypes.indexOf(asset.assetType as AssetType) >= 0;
    } else {
      return true;
    }
  }

  private _filterByStatus(asset: Asset): boolean {
    if (this.data.filterProps && this.data.filterProps.status && this.data.filterProps.status.length) {
      return this.data.filterProps.status.indexOf(asset.status as AllStatus) >= 0;
    } else {
      return true;
    }
  }

  private _filterByTableName(asset: Asset): boolean {
    return (this.data.filterProps &&
      this.data.filterProps.tableName &&
      this.data.filterProps.tableName === asset.tableName) as boolean;
  }

  private _findFolderById(root: AssetDefinition, folderId: string): AssetDefinition | null | undefined {
    if (root.assetType === AssetType.FOLDER && root.id === folderId) {
      return root;
    }
    if (root.children) {
      let folder;
      for (const child of root.children) {
        folder = this._findFolderById(child, folderId);
        if (folder) {
          this.treeControl.expand(root);
          break;
        }
      }
      return folder;
    }
  }

  ngOnDestroy() {
    // little hack to overcome hmr issues.
    // see:
    // https://github.com/angular/angular-cli/issues/9600
    // https://stackoverflow.com/questions/47700026/angular-material-dialog-hot-module-reload
    if (environment.hmr) {
      // @ts-ignore
      this.dialogRef._overlayRef.hostElement.parentElement.innerHTML = '';
    }
  }
}
