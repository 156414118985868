import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { BaseService, Endpoint } from './base.service';
import { Domain } from '@neptune/models/domain';

@Injectable()
export class DomainService extends BaseService {
  /**
   * This function a get list domains
   */
  getAllDomains(orgId: string | null = null): Observable<Domain[]> {
    return super.baseGet<Domain[]>(Endpoint.DOMAIN, orgId ? `domains?orgid=${orgId}` : `domains`);
  }

  /**
   * Delete a domain
   */
  deleteDomain(domain_id: string): Observable<null> {
    const url = `domains/${domain_id}`;
    return super.baseDelete(Endpoint.DOMAIN, url);
  }

  /**
   * Create a domain
   */
  createDomain(domainModel: Domain): Observable<Domain> {
    return super.basePost(Endpoint.DOMAIN, `domains`, domainModel);
  }

  /**
   * Update a domain
   */
  updateDomain(domainModel: Domain): Observable<Domain> {
    return super.basePut(Endpoint.DOMAIN, `domains/${domainModel.id}`, domainModel);
  }
}
