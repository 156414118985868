<div class="w-100-p h-100-p wysiwyg-editor">
  <loading-modal></loading-modal>
  <nep-iframe-wrapper
    #iframeTemplate
    [source]="SOURCE"
    class="content-builder-iframe"
    (iframeEvent)="iframeEvent($event)"
    (customEvent)="customEvent($event)"
    [sourceId]="SOURCE_ID"
  ></nep-iframe-wrapper>
</div>
