import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[stepperPosition]'
})
export class StepperPositionDirective implements AfterViewInit {
  @Input('stepperPosition') position: 'top' | 'bottom';
  element: any;

  constructor(private elementRef: ElementRef) {
    this.element = this.elementRef.nativeElement;
  }

  ngAfterViewInit(): void {
    if (this.position === 'bottom') {
      const wrapper = this.element.querySelector('.mat-horizontal-stepper-wrapper');
      const header = wrapper.querySelector('.mat-horizontal-stepper-header-container');
      const content = wrapper.querySelector('.mat-horizontal-content-container');
      wrapper.insertBefore(content, header);
    }
  }
}
