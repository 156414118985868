import { Injectable, ChangeDetectorRef } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { StandardListComponent } from '../standard-list-page/standard-list.component';
import { StandardListContainer } from '../standard-list-page/standard-list-container';
@Injectable()
export abstract class StandardListDialog<T, L extends StandardListComponent<T>> extends StandardListContainer<T, L> {
  confirmName: string = 'OK';
  showRefresh: boolean = true;
  isSelectable: boolean = false;
  selected: any;

  constructor(
    protected dialogRef: MatDialogRef<StandardListDialog<T, L>>,
    protected dialog: MatDialog,
    protected changeDetector: ChangeDetectorRef
  ) {
    super(changeDetector);
  }

  /**
   * Set standard values.
   *
   * param  {string} entityName Entity Name Child Component
   * param  {string} buttonName Button Name Child Component
   */
  init(entityName: string, buttonName: string | null, confirmName: string, showRefresh: boolean = true) {
    this.entityName = entityName;
    this.buttonName = buttonName || '';
    this.confirmName = confirmName;
    this.showRefresh = showRefresh;
  }

  protected initTableInstance(list?: L) {
    super.initTableInstance(list);
    this.tableInstance.onSelectionChange.subscribe(data => (this.selected = data));
  }

  onConfirm() {
    if (!this.isSelectable) {
      this.close();
    } else {
      this.close(this.selected);
    }
  }

  onCancel() {
    this.close();
  }

  protected close(data?: any) {
    this.dialogRef.close(data);
  }
}
