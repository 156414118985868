import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AceEditorWrapperComponent } from '@neptune/components/ace-editor-wrapper/ace-editor-wrapper.component';
import { AceEditorModule } from '@derekbaker/ngx-ace-editor-wrapper';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from '@neptune/material.module';

@NgModule({
  declarations: [AceEditorWrapperComponent],
  imports: [CommonModule, FuseSharedModule, MaterialModule, AceEditorModule],
  exports: [AceEditorWrapperComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AceEditorWrapperModule {}
