import { Injectable } from '@angular/core';
import { BaseService, Endpoint } from '@neptune/services/base.service';
import { Observable, of } from 'rxjs';
import {
  CloneMessageBody,
  DmmCampaign,
  DmmCampaignBalance,
  EmailMessage,
  EmailMessageEditType,
  EmailProof,
  Link,
  Message,
  MessageDestinationField,
  MessagePreviewData,
  MessageType,
  PreviewMessageData
} from '@neptune/models/message';
import { TableJoinsData } from '@neptune/models';
import { map } from 'rxjs/operators';

@Injectable()
export class MessageService extends BaseService {
  /**
   * For use with jasmine testing, returns a mock of service
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  public static mockService(jasmine: unknown, message: Message, proofs: EmailProof): object {
    return {
      getMessage: jest.fn(() => of(message)),
      updateMessage: jest.fn(() => of(message)),
      createMessage: jest.fn(() => of(message)),
      getPreview: jest.fn(() => of([{}])),
      getProofs: jest.fn(() => of([proofs])),
      sendProof: jest.fn(() => of([proofs]))
    };
  }

  public getMessage(messageId: string, version?: string): Observable<Message> {
    return super.baseGet<Message>(Endpoint.MESSAGE, `messages/${this.idWithVersion(messageId, version)}`).pipe(
      map((m: Message) => {
        if (!(m as EmailMessage).contentType) {
          (m as EmailMessage).contentType = EmailMessageEditType.WYSIWYG;
        }
        return m;
      })
    );
  }

  public updateMessage(message: Message): Observable<Message> {
    return super.basePut<Message, Message>(
      Endpoint.MESSAGE,
      `messages/${this.idWithVersion(message.id as string, message.version?.toString())}`,
      message
    );
  }

  public createCloneMessage(message: Message): Observable<Message> {
    return super.basePost(Endpoint.MESSAGE, 'messages', message);
  }

  public cloneMessage(id: string, folderId: string, name: string, description: string) {
    return super.basePost<CloneMessageBody, CloneMessageBody>(Endpoint.MESSAGE, `messages/${id}/clone`, {
      description,
      folderId,
      name
    });
  }

  public createMessage(
    name: string,
    description: string,
    projectId: string,
    folderId: string,
    type: MessageType,
    tableName: string,
    contentType: string,
    senderId: string,
    dmmCampaign: DmmCampaign,
    idVersion?: string
  ): Observable<Message> {
    const data: any = {
      name,
      description,
      type,
      projectId,
      folderId,
      tableName
    };
    if (senderId) {
      data.senderId = senderId;
    }
    if (dmmCampaign) {
      data.dmmCampaign = dmmCampaign;
    }
    if (contentType) {
      data.contentType = contentType;
    }
    const messagePath = idVersion ? `messages/${idVersion}/new_version` : 'messages';

    return super.basePost(Endpoint.MESSAGE, messagePath, data);
  }

  public getPreview(
    fields: MessageDestinationField[],
    joinedTables: TableJoinsData,
    baseTableId: string
  ): Observable<MessagePreviewData[]> {
    const data = {
      joinedTables,
      baseTableName: baseTableId,
      exportFields: fields
        .filter((field: MessageDestinationField) => field && field.name)
        .map((field: MessageDestinationField) => (field && field.value ? field : { name: field.name, value: '' }))
    };
    return super.basePost<PreviewMessageData, MessagePreviewData[]>(Endpoint.MESSAGE, 'preview', data);
  }

  public getProofs(message: EmailMessage): Observable<EmailProof[]> {
    return super.baseGet<EmailProof[]>(
      Endpoint.MESSAGE,
      `messages/${this.idWithVersion(message.id as string, message.version?.toString())}/proof`
    );
  }

  public sendProofToEmails(emails: string[], message: EmailMessage): Observable<EmailProof[]> {
    return super.basePost(
      Endpoint.MESSAGE,
      `messages/${this.idWithVersion(message.id as string, message.version?.toString())}/proof`,
      {
        type: 'EMAIL',
        emails
      }
    );
  }

  public sendProofToListDefinition(listDefinitionId: string, message: EmailMessage): Observable<EmailMessage> {
    return super.basePost(
      Endpoint.MESSAGE,
      `messages/${this.idWithVersion(message.id as string, message.version?.toString())}/proof`,
      {
        type: 'LIST_DEFINITION',
        emails: [],
        listDefinitionId
      }
    );
  }

  public createLinks(message: EmailMessage): Observable<Link[]> {
    return this.basePost(
      Endpoint.LINK,
      `messages/${this.idWithVersion(message.id as string, message.version?.toString())}/create_links`,
      { name: message.name }
    );
  }

  public getLinks(message: EmailMessage): Observable<Link[]> {
    return this.baseGet<Link[]>(Endpoint.LINK, 'links', false, {
      messageIdVersion: this.idWithVersion(message.id as string, message.version?.toString())
    });
  }

  public updateLink(link: Link): Observable<Link> {
    return this.basePut(Endpoint.LINK, `links/${link.id}`, link);
  }

  public getDmmCampaigns(): Observable<DmmCampaign[]> {
    return super.baseGet(Endpoint.MESSAGE, 'dmm_campaigns');
  }

  public getDmmCampaignsBalance(): Observable<DmmCampaignBalance> {
    return super.baseGet(Endpoint.MESSAGE, 'dmm_campaigns/funds_balance');
  }

  private idWithVersion(id: string, version?: string): string {
    if (id.length <= 36) {
      return version ? `${id}_${version}` : `${id}_1`;
    }
    return id;
  }
}
