import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mailToName' })
export class MailToNamePipe implements PipeTransform {
  transform(value: string) {
    return value
      ? value.replace(/(\w+)\.(.*)@.*/, (...args: any[]) => {
          const name = args[1].substr(0, 1).toUpperCase() + args[1].substr(1, args[1].length - 1);
          const lastname = args[2].substr(0, 1).toUpperCase() + args[2].substr(1, args[2].length - 1);
          return `${name} ${lastname}`;
        })
      : '';
  }
}
