<div class="expression-dialog">
  <loading-modal #loadingComponent></loading-modal>
  <div mat-dialog-title class="dialog-header">
    <div class="dialog-title">
      <span class="h2">Expression for {{ fieldName }}</span>
    </div>
  </div>
  <mat-dialog-content>
    <div class="description-container-expression p-0">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" class="h-100-p">
        <div [fxFlex]="hidePreview ? '100' : '50'">
          <div id="editor-content" fxLayout="column" class="full-height">
            <mat-form-field appearance="fill">
              <mat-label>Expression</mat-label>
              <expression-editor-input
                [(ngModel)]="prompt"
                [fields]="sources"
                [tableName]="data?.tableName || ''"
                [required]="true"
                [editable]="data?.editable"
                (textChanged)="onExpressionChanged($event)"
                #expressionEditorInput
              ></expression-editor-input>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="!hidePreview" fxFlex="50" fxLayout="column" fxLayoutAlign="start stretch">
          <div fxLayout="row" fxLayoutAlign="space-between end">
            <div class="h2">Preview</div>
            <button
              mat-flat-button
              color="primary"
              [disabled]="!previewEnabled || expressionEditorInput.errorState"
              (click)="onApplyTransform()"
            >
              Apply
            </button>
          </div>
          <div *ngIf="isErrorApply" class="error-apply-container" fxLayout="row" fxLayoutAlign="space-between">
            <div fxLayoutAlign="start center">
              <span>
                <mat-icon color="warn" aria-label="icon error">error</mat-icon>
                {{ errorMessageApply }}
              </span>
            </div>
          </div>
          <div class="h-100-p overflow-auto">
            <table fxFill mat-table [dataSource]="previewItems" class="mt-10">
              <ng-container matColumnDef="{{ column }}" *ngFor="let column of displayedColumns">
                <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
                <td mat-cell *matCellDef="let element">
                  {{ element[column] }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutAlign="end start" class="dialog-footer" fxLayout="row nowrap">
    <button mat-button color="primary" (click)="onClose(false)" class="cancel-btn">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      (click)="onClose(true)"
      [disabled]="!hidePreview && (!expressionApplied || expressionEditorInput.errorState)"
    >
      Ok
    </button>
  </mat-dialog-actions>
</div>
