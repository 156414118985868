import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from '@neptune/material.module';
import { SideDrawersComponent } from './side-drawers-toggler.component';
import { SideDrawersBaseComponent } from './side-drawers-base/side-drawers-base.component';
import { SideDrawersDrawerComponent } from './side-drawers-drawer/side-drawers-drawer.component';
import { SideDrawersContentComponent } from './side-drawers-content/side-drawers-content.component';

@NgModule({
  declarations: [
    SideDrawersComponent,
    SideDrawersBaseComponent,
    SideDrawersDrawerComponent,
    SideDrawersContentComponent
  ],
  imports: [CommonModule, FuseSharedModule, MaterialModule],
  exports: [SideDrawersComponent, SideDrawersBaseComponent, SideDrawersDrawerComponent, SideDrawersContentComponent]
})
export class SideDrawersModule {}
