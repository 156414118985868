import { Observable, of as observableOf, from as observableFrom, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService, Endpoint } from './base.service';
import { Asset } from '@neptune/models/asset';
import { Query } from '@neptune/models/query';
import { QueryData } from '@neptune/models/segmentation';

@Injectable()
export class QueryService extends BaseService {
  private QUERY: string = 'queries';
  private QUERY_STORE = 'QUERY_STORE';

  /**
   * For use with jasmine testing, returns a mock of service
   */
  public static mockService(jasmine: unknown, query: Query): unknown {
    return {
      getAllQueriesFromStorage: jest.fn(() => observableOf([])),
      getAllQueries: jest.fn(() => observableOf([])),
      getAllQueriesByTableId: jest.fn(() => observableOf([])),
      getQueriesById: jest.fn(() => observableOf([])),
      createQuery: jest.fn(() => observableOf({ query, asset: {} })),
      getQueryById: jest.fn(() => observableOf(query)),
      updateQuery: jest.fn(() => observableOf(query)),
      deleteQuery: jest.fn(() => observableOf(true)),
      convertQuery: jest.fn(() => observableOf([]))
    };
  }

  /**
   * This function a list queries by store.
   */
  public getAllQueriesFromStorage(fromCache: boolean = true): Observable<Query[]> {
    if (fromCache) {
      const queries: Query[] = this.storeService.get(this.QUERY_STORE);
      if (queries) {
        return observableOf(queries);
      }
    }

    return observableFrom(this.getAllQueries()).pipe(
      map((result: Query[]) => {
        const queries: Query[] = result.map(query => this.convertQuery(query));
        this.storeService.set(this.QUERY_STORE, queries);
        return queries;
      })
    );
  }

  public getAllQueries(): Observable<Query[]> {
    return this.baseGet(Endpoint.QUERY, this.QUERY).pipe(
      map((result: Query[]) => result.map(query => this.convertQuery(query))) as OperatorFunction<unknown, Query[]>
    );
  }

  public getAllQueriesByTableId(tableId: string): Observable<Query[]> {
    return this.baseGet(Endpoint.QUERY, this.QUERY, null, { tableId }).pipe(
      map((result: Query[]) => result.map(query => this.convertQuery(query))) as OperatorFunction<unknown, Query[]>
    );
  }

  public getQueriesById(tableName: string, queryIds: string[]): Observable<Query[]> {
    if (queryIds.length) {
      const ids = queryIds.map(qid => `queryId=${qid}`).join('&');
      return this.baseGet<Query[]>(Endpoint.QUERY, `${this.QUERY}?${ids}`, null).pipe(
        map((result: Query[]) => result.map(query => this.convertQuery(query)))
      );
    } else {
      return this.baseGet<Query[]>(Endpoint.QUERY, this.QUERY, null, {
        tableId: tableName
      }).pipe(map((result: Query[]) => result.map(query => this.convertQuery(query))));
    }
  }

  public createQuery(query: QueryData): Observable<{ query: Query; asset: Asset }> {
    const converted = this.convertQuery(query, false);
    return super.basePost(Endpoint.QUERY, this.QUERY, converted);
  }

  public cloneQuery(
    queryId: string,
    folderID: string,
    name: string,
    description: string
  ): Observable<{ query: Query; asset: Asset }> {
    return super.basePost(Endpoint.QUERY, `${this.QUERY}/${queryId}`, {
      description,
      folderID,
      name
    });
  }

  public getQueryById(id: string): Observable<Query> {
    const url = `${this.QUERY}/${id}`;
    return super.baseGet<Query>(Endpoint.QUERY, url).pipe(map((query: Query) => this.convertQuery(query)));
  }

  public updateQuery(id: string, query: Query): Observable<{ query: Query; asset: Asset }> {
    const url = `${this.QUERY}/${id}`;
    const converted = this.convertQuery(query, false);

    return super.basePut(Endpoint.QUERY, url, converted).pipe(
      map((result: { query: Query; asset: Asset }) => ({
        query: this.convertQuery(result && result.query),
        asset: result && result.asset
      })) as OperatorFunction<unknown, { query: Query; asset: Asset }>
    );
  }

  /**
   * TEMP - Until API is fixed, changing <name>ID to <name>Id
   * Convert object between API an front-End interface signatures
   * Necessary for now since backend definition and Front-End interface do not match
   * check_any
   */
  private convertQuery(query: QueryData, fromAPI: boolean = true): Query {
    if (fromAPI) {
      if (query.hasOwnProperty('projectID')) {
        query['projectId'] = query.projectID;
        delete query['projectID'];
      }
      if (query.hasOwnProperty('folderID')) {
        query['folderId'] = query.folderID;
        delete query['folderID'];
      }
    } else {
      if (query.hasOwnProperty('projectId')) {
        query['projectID'] = query.projectId;
        delete query['projectId'];
      }
      if (query.hasOwnProperty('folderId')) {
        query['folderID'] = query.folderId;
        delete query['folderId'];
      }
    }
    return query;
  }
}
