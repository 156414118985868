<div class="standard-search">
  <mat-form-field appearance="outline" fxFlex="100" floatLabel="never">
    <input
      matInput
      type="text"
      [placeholder]="placeholder"
      [(ngModel)]="search"
      (keydown.enter)="eventSearch()"
      (keyup)="eventChange($event)"
      class="input-placeholder"
      [attr.autocomplete]="auto"
    />
    <button mat-icon-button (click)="eventSearch()" fxFlex>
      <mat-icon svgIcon="search" matTooltip="Search"></mat-icon>
    </button>
  </mat-form-field>
</div>
