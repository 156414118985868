import {
  APIConfig as defaultAPIConfig,
  QPConfig as defaultQPConfig,
  environment as defaultEnvironment
} from './environment.default';

export const environment = {
  ...defaultEnvironment,
  isPredev: true
};

export const QPConfig = {
  ...defaultQPConfig,
  // Redirect to Sisense
  SisenseAppUrl: 'https://bvuiwgzg7k.execute-api.us-east-1.amazonaws.com/Prod/jwt'
};

export const NeptuneSetup = {
  AutoIdleSignOutTime: 90,
  DefaultCacheExpirationTime: 5 * 60 * 1000,
  FileSizeLimit: 250000000
};

// NOTE :: to test a locally hosted API use: 'http://localhost:8010/proxy/.'
export const APIConfig = {
  ...defaultAPIConfig,
  EnvName: 'predev',

  // New User Pool
  Cognito: {
    userPoolId: 'us-east-1_Lw26GHv9s',
    clientId: '6p7h5ijfs7rtd6fj06j20kqb77',
    region: 'us-east-1'
  },

  // To avoid conflicts PLEASE TAKE A MINUTE TO ORDER ALPHABETICALLY this urls list.
  AssetsApiUrl: 'https://apigw.predev.nxtdrive.app/assets-api',
  CanvasApiURL: 'https://apigw.predev.nxtdrive.app/canvas-positions',
  CleansingRuleApiUrl: 'https://apigw.predev.nxtdrive.app/cleansing-rules-api',
  ConnectorApiUrl: 'https://apigw.predev.nxtdrive.app/data-extraction-api',
  ConnectorApiUrlNEW: 'https://apigw.predev.nxtdrive.app/connectors-api',
  ConnectorApiUrlInstances: 'https://api.predev.nxtdrive.app/api/v1',
  ContactApiUrl: 'https://apigw.predev.nxtdrive.app/contacts-api',
  ContactProfileUrl: 'https://apigw.predev.nxtdrive.app/contact-profile-data-api',
  DataDrainURL: 'https://api.predev.nxtdrive.app/api/v1',
  DataPipelineURL: 'https://apigw.predev.nxtdrive.app/data-pipeline',
  DeleteUserApiUrl: 'https://api.predev.nxtdrive.app/api/v1/mfa-user',
  DomainsApiUrl: 'https://apigw.predev.nxtdrive.app/domain-api',
  FileTransferApiUrl: 'https://apigw.predev.nxtdrive.app/file-transfer-api',
  JoinApiUrl: 'https://apigw.predev.nxtdrive.app/joins-api',
  JourneyApiUrl: 'https://apigw.predev.nxtdrive.app/journey-api',
  MessageApi: 'https://apigw.predev.nxtdrive.app/messaging-api',
  MyFilesApiURL: 'https://apigw.predev.nxtdrive.app/my-files-api',
  NxtdriveApiUrl: 'https://api.predev.nxtdrive.app/api/v1',
  OrgApiURL: 'https://apigw.predev.nxtdrive.app/organization-api',
  ProjectsApiUrl: 'https://apigw.predev.nxtdrive.app/projects-api',
  PublicApiHub: 'https://apigw.predev.nxtdrive.app/public-api/hub',
  PublicOrgApiUrl: 'https://apigw.predev.nxtdrive.app/public-api/lambda/organization-api',
  PublicUserApiUrl: 'https://apigw.predev.nxtdrive.app/public-api/lambda/useradmin-api',
  QueriesApiUrl: 'https://apigw.predev.nxtdrive.app/query-api',
  SchedulerApiUrl: 'https://apigw.predev.nxtdrive.app/scheduler-api',
  SegmentationApiUrl: 'https://apigw.predev.nxtdrive.app/orville-api',
  TableApiUrl: 'https://apigw.predev.nxtdrive.app/datastorage-tables-api',
  UDFApiUrl: 'https://apigw.predev.nxtdrive.app/udf-api',
  UserApiUrl: 'https://apigw.predev.nxtdrive.app/useradmin-api',
  WhereUsedApiURL: 'https://apigw.predev.nxtdrive.app/whereused-api',

  // DEPRECATED url list
  ApiKeyApiUrl: 'https://f4mxu0de11.execute-api.us-east-1.amazonaws.com/Prod', // Doesn't exist DEPRECATED SECTION
  ContactLookupApiUrl: 'https://ttlc8w99s2.execute-api.us-east-1.amazonaws.com/Prod', // Doesn't exist - REFACTOR REQUIRED
  DynamicContentApiUrl: 'https://6e6j3e20i6.execute-api.us-east-1.amazonaws.com/Prod', // Doesn't exist DEPRECATED SECTION
  FileApiUrl: 'https://efk1ern0o9.execute-api.us-east-1.amazonaws.com/Prod', // Doesn't exist - REFACTOR REQUIRED
  FormApiUrl: 'https://yr1j8kzn5d.execute-api.us-east-1.amazonaws.com/Prod', // Doesn't exist DEPRECATED SECTION
  LandingPageApiUrl: 'https://qtmwfy8ge3.execute-api.us-east-1.amazonaws.com/Prod', // Doesn't exist DEPRECATED SECTION
  LinkApiUrl: 'https://agu3t6rej4.execute-api.us-east-1.amazonaws.com/Prod', // Doesn't exist DEPRECATED SECTION
  ListDefinitionApiUrl: 'https://4cgrai30sk.execute-api.us-east-1.amazonaws.com/Prod', // Doesn't exist DEPRECATED SECTION
  MetadataApi: 'https://kz0dhfmstc.execute-api.us-east-1.amazonaws.com/Prod', // Doesn't exist DEPRECATED SERVICE - REFACTOR REQUIRED
  NewsApiUrl: 'https://7huxidlkjk.execute-api.us-east-1.amazonaws.com/Prod', // Doesn't exist DEPRECATED SECTION
  NotificationsGraphqlApiUrl: 'https://appsync-notifications.predev.nxtdrive.app', // Updated custom domain DEPRECATED SWF
  PurlApiUrl: 'https://j925t6x9ib.execute-api.us-east-1.amazonaws.com/Prod', // Doesn't exist DEPRECATED SECTION
  RepeatBlockApiUrl: 'https://da6py20fwd.execute-api.us-east-1.amazonaws.com/Prod', // Doesn't exis DEPRECATED SECTION
  ResourceAPI: 'https://0hppgjioag.execute-api.us-east-1.amazonaws.com/Prod', // Doesn't exis DEPRECATED SECTION
  SegmentationBuilderApiUrl: 'https://apigw.predev.nxtdrive.app/segment-builder-api', // Updated custom domain DEPRECATED SECTION
  SegmentationWaterFallApiUrl: 'https://02jbs3keal.execute-api.us-east-1.amazonaws.com/Prod', // Doesn't exis DEPRECATED SECTION
  SegmentationWaterFallGraphqlApiUrl: 'https://appsync-segwaterfall.predev.nxtdrive.app/graphql', // Updated custom domain DEPRECATED SWF
  SegmentationWaterFallReportApiUrl: 'https://apigw.predev.nxtdrive.app/segwfreport-api', // Updated custom domain DEPRECATED SWF
  SenderAPI: 'https://adf1mv77q3.execute-api.us-east-1.amazonaws.com/Prod' // Doesn't exis DEPRECATED SERVICE - REFACTOR REQUIRED
};

export const SisenseDashs = {
  SisenseUrl: 'https://vericast-dev1.sisense.com',
  IFrameUrl: 'https://vericast-dev1.sisense.com/app/main#/dashboards/',
  tagId: 'sisenseScript',
  sisenseLibrary: '/js/sisense.v1.js',
  dashHome: '65a810f12e6ac2004209b11c',
  dashInsights: '65a812852e6ac2004209b129',
  dashCampaigns: '65a812ae2e6ac2004209b12d',
  dashDatabase: '65a812ce2e6ac2004209b131'
};
