import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@neptune/material.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { HtmlTextEditPreviewComponent } from './html-text-edit-preview.component';
import { IframeWrapperModule } from '../iframe-wrapper/iframe-wrapper.module';
import { LoadingModalModule } from '../loading-modal/loading-modal.module';
import { MaxSizeValidatorDirective } from './html-text-edit-preview-validators';
import { WysiwygEditorModule } from '@neptune/components/wysiwyg-editor/wysiwyg-editor.module';
import { AceEditorWrapperModule } from '@neptune/components/ace-editor-wrapper/ace-editor-wrapper.module';
import { NeptuneDirectivesModule } from '@neptune/directives/directives.module';

@NgModule({
  declarations: [HtmlTextEditPreviewComponent, MaxSizeValidatorDirective],
  imports: [
    CommonModule,
    FuseSharedModule,
    MaterialModule,
    IframeWrapperModule,
    LoadingModalModule,
    AceEditorWrapperModule,
    WysiwygEditorModule,
    NeptuneDirectivesModule
  ],
  exports: [HtmlTextEditPreviewComponent]
})
export class HtmlTextEditPreviewModule {}
