<div class="iframe-wrapper-component">
  <div class="nep-loader" *ngIf="_loading | async">
    <ng-content></ng-content>
  </div>
  <iframe
    #iframe
    sandbox="allow-same-origin allow-scripts"
    [src]="_uniquePath | safe: 'resourceUrl'"
    scrolling="yes"
    class="nep-iframe"
    [ngStyle]="_styling | async"
    [height]="_bodyHeight | async"
  ></iframe>
</div>
