<ngx-datatable
  class="material import-asset-list-component"
  [rows]="rowsData"
  [count]="rowsData.length"
  [columnMode]="'flex'"
  [headerHeight]="48"
  [rowHeight]="50"
  [scrollbarH]="false"
  [scrollbarV]="true"
  [selectionType]="null!"
  [footerHeight]="56"
  [sorts]="[{ prop: 'name', dir: 'desc' }]"
  [limit]="10"
>
  <!-- Row Column Template -->
  <ngx-datatable-column name="Name" [flexGrow]="4" [sortable]="true" prop="name">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <mat-checkbox [checked]="row.selected" color="primary" (change)="onChecked(row, $event.checked)">
        <span class="import-text">{{ row.name }}</span>
      </mat-checkbox>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Source" [flexGrow]="3" [sortable]="true" prop="source">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <div class="import-text">{{ row.source }}</div>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Source Table" [flexGrow]="3" [sortable]="true" prop="sourceTable">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <div class="import-text">{{ row.sourceTable }}</div>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
