import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export class PendingRequest {
  request: Observable<any>;
  subscription: Subject<any>;

  constructor(request: Observable<any>, subscription: Subject<any>) {
    this.request = request;
    this.subscription = subscription;
  }
}

@Injectable()
export class RequestQueueUtil {
  private queue: PendingRequest[] = [];

  private execute(requestData: PendingRequest): void {
    requestData.request.subscribe({
      next: res => {
        requestData.subscription.next(res);
        requestData.subscription.complete();
        this.queue.shift();
        this.startNextRequest();
      },
      error: error => {
        // TODO: We may need to define what happends on error, continue with cue? stop? retry current?
        requestData.subscription.error(error);
        this.queue.shift();
      }
    });
  }

  public addRequestToQueue(request: Observable<any>, forceFlush: boolean = false): Subject<any> {
    const sub = new Subject<any>();
    const pendingReq: PendingRequest = new PendingRequest(request, sub);

    this.queue.push(pendingReq);

    if (forceFlush) {
      this.flush();
    }
    return sub;
  }

  public flush(): void {
    this.startNextRequest();
  }

  private startNextRequest(): void {
    // get next request, if any.
    if (this.queue.length > 0) {
      this.execute(this.queue[0]);
    }
  }
}
