import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService, Endpoint } from './base.service';
import { AllStatus, Asset, AssetStatus, AssetType, AssetUtils } from '@neptune/models/asset';
import { SegmentationWaterFallService } from '@neptune/services/segmentation-waterfall.service';
// import { SegmentBuilderService } from '@neptune/services/segment-builder.service';
import { HttpClient } from '@angular/common/http';
import { CognitoService } from '@neptune/services/cognito.service';
import { StoreService } from '@neptune/services/store.service';

@Injectable()
export class AssetService extends BaseService {
  private ASSET: string = 'assets';

  private ASSET_TYPES_VALUES = Object.values(AssetType);

  constructor(
    protected segmentationWaterfallService: SegmentationWaterFallService,
    // protected segmentBuilderService: SegmentBuilderService,
    protected httpClient: HttpClient,
    protected cognitoService: CognitoService,
    protected storeService: StoreService
  ) {
    super(httpClient, cognitoService, storeService);
  }

  public getAllAssetsByFolder(folderId: string, types?: AssetType[], status?: AllStatus[]): Observable<Asset[]> {
    interface Params {
      folderId: string;
      types?: AssetType[];
      status?: AllStatus[];
    }
    const params: Params = { folderId };
    if (types) {
      params.types = types;
    }
    if (status) {
      params.status = status;
    }
    return this.baseGet<Asset[]>(Endpoint.ASSET, this.ASSET, true, params).pipe(map(this._filterAssetTypes.bind(this)));
  }

  public getRecentAsset(limit: number, searchText?: string): Observable<Asset[]> {
    interface Params {
      query: boolean;
      limit: number;
      orderDir: string;
      orderField: string;
      orderFieldType: string;
      searching?: boolean;
      // eslint-disable-next-line @typescript-eslint/ban-types
      filter?: object[];
      searchText?: string;
    }
    const params = <Params>{
      query: true,
      limit,
      orderDir: 'desc',
      orderField: 'updatedAt',
      orderFieldType: 'date'
    };

    if (searchText) {
      params.searching = true;
      params.filter = [];
      params.searchText = searchText;
    }

    return this.baseGet(Endpoint.ASSET, this.ASSET, false, params);
  }

  public getAssetById(id: string): Observable<Asset> {
    const url = `${this.ASSET}/${id}`;
    return super.baseGet(Endpoint.ASSET, url);
  }

  public getAssetByAssetModelId(assetModelId: string): Observable<Asset> {
    const url = `${this.ASSET}/objects/${assetModelId}`;
    return super.baseGet(Endpoint.ASSET, url);
  }

  // TODO : There needs to be a better way to do this, maybe have a map to call each service or something like that.
  // eslint-disable-next-line @typescript-eslint/ban-types
  public deleteAsset(assetType: AssetType, assetId: string): Observable<object> {
    const path: [Endpoint, string] = AssetUtils.getAssetEndpoint(assetType);
    const versionedAsset: boolean = ['messages', 'landing_pages', 'forms'].indexOf(path[1]) >= 0;
    const params = versionedAsset
      ? ['messages', 'landing_pages'].indexOf(path[1]) >= 0
        ? { delete_all: true }
        : { deleteAll: true }
      : null;

    assetId =
      ['messages', 'landing_pages', 'forms'].indexOf(path[1]) < 0 || assetId.includes('_') ? assetId : `${assetId}_1`;

    if (assetType === AssetType.SEGMENTATION_WATERFALL_MODEL) {
      return this.segmentationWaterfallService.delete(assetId);
    }

    if (assetType === AssetType.SEGMENT_BUILDER) {
      return this.baseDelete(Endpoint.SEGMENTATION_BUILDER, `segmentbuilder/${assetId}`);
    } else {
      return super.baseDelete(path[0], `${path[1]}/${assetId}`, versionedAsset ? params : null);
    }
  }

  /**
   * Safecheck to ensure service is providing us with valid assetTypes
   */
  private _filterAssetTypes(assets: Asset[]): Asset[] {
    return assets.filter(asset => {
      if (this.ASSET_TYPES_VALUES.includes(asset.assetType as AssetType)) {
        return true;
      } else {
        console.warn(`${asset.name}.assetType = ${asset.assetType} doesn't exist on AssetType enum.`);
        return false;
      }
    });
  }

  public getVersions(folderId: string, assetId: string): Observable<Asset[]> {
    return this.getAllAssetsByFolder(folderId).pipe(
      map((assets: Asset[]) => {
        const assetsFilter: Asset[] = assets
          .filter(currentAsset => {
            const currentAssetId = currentAsset.assetId?.includes('_')
              ? currentAsset.assetId.substring(0, currentAsset.assetId.indexOf('_'))
              : currentAsset.assetId;
            return currentAssetId?.includes(assetId);
          })
          .sort((a, b) => (b.version as number) - (a.version as number));
        return assetsFilter;
      })
    );
  }

  /**
   * For use with testing, returns a mock of service
   */
  public static mockService(): unknown {
    return {
      getAllAssetsByFolder: jest.fn(() => observableOf([])),
      getAssetById: jest.fn(() => observableOf([])),
      deleteAsset: jest.fn(() => observableOf([])),
      _filterAssetTypes: jest.fn(() => observableOf([])),
      getRecentAsset: jest.fn(() => observableOf(this.mockAssets()))
    };
  }

  /**
   * For use with testing, returns a mock of assets
   */
  public static mockAssets(): Asset[] {
    return [
      {
        projectId: '0af697e0-2bfb-11ea-a6b3-23fda708fc4c',
        assetType: AssetType.JOURNEY,
        folderId: '059bffb0-75ed-11ea-8424-cb5fa3dae389',
        status: AssetStatus.NEW,
        updatedBy: '01f42b9d-1f03-44b1-82b7-4c1b964e0885',
        name: 'DeleteEmailJour',
        insertedAt: '2022-05-17T19:05:58.243Z',
        insertedBy: '01f42b9d-1f03-44b1-82b7-4c1b964e0885',
        assetId: '61ee7430-d614-11ec-a018-957cbabec1e7',
        updatedAt: '2022-05-17T19:07:01.433Z',
        orgId: 'quickpivot.com',
        tableName: 'KBAng12Tbl1',
        id: '62886a40-d614-11ec-bc97-9f0e6e37a86a',
        projectName: 'QAKeith2',
        folderName: 'SingleTableAssets',
        tableId: 'dest_table_2651f140a17611ec90251d280862ffd4'
      },
      {
        orgId: 'quickpivot.com',
        assetId: '3bb37770-d614-11ec-8e72-cbc6bdd41190',
        assetType: AssetType.QUERY,
        name: 'SpringHiking',
        folderId: '059bffb0-75ed-11ea-8424-cb5fa3dae389',
        projectId: '0af697e0-2bfb-11ea-a6b3-23fda708fc4c',
        updatedAt: '2022-05-17T19:04:54.994Z',
        insertedAt: '2022-05-17T19:04:54.994Z',
        status: AssetStatus.ACTIVE,
        insertedBy: '01f42b9d-1f03-44b1-82b7-4c1b964e0885',
        updatedBy: '01f42b9d-1f03-44b1-82b7-4c1b964e0885',
        tableName: 'KBAng12Tbl1',
        id: '3cd56230-d614-11ec-b7de-f9ffdd7f1a17',
        projectName: 'QAKeith2',
        folderName: 'SingleTableAssets',
        tableId: 'dest_table_2651f140a17611ec90251d280862ffd4'
      }
    ];
  }
}
