import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadingModalModule } from '../loading-modal/loading-modal.module';
import { LoadingModalGlobalComponent } from './loading-modal-global.component';

@NgModule({
  declarations: [LoadingModalGlobalComponent],
  imports: [CommonModule, LoadingModalModule],
  exports: [LoadingModalGlobalComponent]
})
export class LoadingModalGlobalModule {}
