// import { v1 as uuidv1 } from 'uuid';
import { Asset, AssetType } from './asset';
import { Observable, Subscription } from 'rxjs';

export interface ResourceDialogData {
  title: string;
  description: string;
  projectId: string;
  folderId: string;
  assets: Asset[];
}

export interface ResourceReplacementDialogData {
  projectId: string;
  folderId: string;
  currentAsset: Asset;
}

// eslint-disable-next-line no-shadow
export enum ResourceDialogState {
  UPLOAD = 'UPLOAD',
  UPLOADING = 'UPLOADING',
  DONE = 'DONE'
}

export interface ResourceUpload {
  obs?: Observable<any>;
  sub?: Subscription;
  uploadSub?: Subscription;
  config: ResourcePresignedUrlGet;
  file: File;
}

// eslint-disable-next-line no-shadow
export enum ResourceStatus {
  INVALID = 'INVALID',
  READY = 'READY',
  UPLOADING = 'UPLOADING',
  FINISHED_UPLOAD = 'FINISHED_UPLOAD',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  DONE = 'DONE',
  DELETE = 'DELETE',
  ERROR = 'ERROR',
  FAILED = 'FAILED',
  DELETED = 'DELETED',
  CANCELLED = 'CANCELLED'
}

// eslint-disable-next-line no-shadow
export enum ResourceType {
  JPEG = 'JPEG',
  JPG = 'JPG',
  JFIF = 'JFIF',
  PNG = 'PNG',
  GIF = 'GIF',
  SVG = 'SVG',
  JS = 'JS',
  CSS = 'CSS'
}
export interface ResourcePresignedUrl {
  presigned_url: string;
  publishedUrl: string;
}

export interface ResourcePresignedUrlGet {
  // Bucket name for the presigned URL to generate.
  bucketName: string;
  // s3 key for the presigned url to generate
  // Path to file of the resource, including extension
  s3key: string;
  // Expiration in seconds for the presigned url to generate
  expires?: string;
  // Content type for the file to upload with the presigned url to generate
  contentType: string;
}

export interface Resource extends Asset {
  // ui
  progress?: number;
  thumb?: string | ArrayBuffer;
  size?: number;
  extension?: string;
  presigned_url?: string;
  upload?: ResourceUpload | null;

  // Status of the resource
  status: ResourceStatus;
  // Type of the file
  type: ResourceType;
  // Name of the resource file.
  fileName: string;
  // The URL where the Resource is hosted
  publishedUrl: string;
  // A flag that indicates if the resource is active or not.
  active: boolean;
  // A flag that indicates if the resource is deleted or not.
  deleted: boolean;
  // Inverse of asset record <-> resouce record ids
  assetVid?: string;
}

export class ResourceUtils {
  private static ImageTypes: ResourceType[] = [
    ResourceType.JPEG,
    ResourceType.JPG,
    ResourceType.JFIF,
    ResourceType.PNG,
    ResourceType.GIF,
    ResourceType.SVG
  ];
  private static allowedTypesMap: Map<ResourceType, string[]> = new Map<ResourceType, string[]>([
    [ResourceType.JPEG, ['image/jpeg']],
    [ResourceType.JPG, ['image/jpeg']],
    [ResourceType.JFIF, ['image/pipeg']],
    [ResourceType.PNG, ['image/png']],
    [ResourceType.GIF, ['image/gif']],
    [ResourceType.JS, ['application/javascript']],
    [ResourceType.CSS, ['text/css']]
  ]);

  private static typeIconsMap: Map<ResourceType, string> = new Map<ResourceType, string>([
    [ResourceType.JS, 'resource_css_js'],
    [ResourceType.CSS, 'resource_css_js']
  ]);

  private static typeStatusIconMap: Map<ResourceStatus, string> = new Map<ResourceStatus, string>([
    [ResourceStatus.DONE, 'complete'],
    [ResourceStatus.ERROR, 'warning'],
    [ResourceStatus.CANCELLED, 'warning'],
    [ResourceStatus.INVALID, 'stopped'],
    [ResourceStatus.DELETED, 'stopped']
  ]);

  private static typeStatusLabelMap: Map<ResourceStatus, string> = new Map<ResourceStatus, string>([
    [ResourceStatus.ERROR, 'Upload failed'],
    [ResourceStatus.CANCELLED, 'Upload cancelled'],
    [ResourceStatus.INVALID, 'Invalid file type'],
    [ResourceStatus.DELETED, 'Deleted file'],
    [ResourceStatus.DONE, 'Upload complete']
  ]);

  private static resourceTypeAssetTypeMap: Map<ResourceType, AssetType> = new Map<ResourceType, AssetType>([
    [ResourceType.JPEG, AssetType.RESOURCE_JPEG],
    [ResourceType.JPG, AssetType.RESOURCE_JPG],
    [ResourceType.JFIF, AssetType.RESOURCE_JFIF],
    [ResourceType.PNG, AssetType.RESOURCE_PNG],
    [ResourceType.GIF, AssetType.RESOURCE_GIF],
    [ResourceType.JS, AssetType.RESOURCE_JS],
    [ResourceType.CSS, AssetType.RESOURCE_CSS]
  ]);

  private static assetTypeLabel: Map<AssetType, string> = new Map<AssetType, string>([
    [AssetType.RESOURCE_JPEG, 'Image'],
    [AssetType.RESOURCE_JPG, 'Image'],
    [AssetType.RESOURCE_JFIF, 'Image'],
    [AssetType.RESOURCE_PNG, 'Image'],
    [AssetType.RESOURCE_JS, 'JavaScript'],
    [AssetType.RESOURCE_GIF, 'Image'],
    [AssetType.RESOURCE_CSS, 'CSS']
  ]);

  public static setImageType(type) {
    if (type.indexOf('image/jp') !== -1) {
      return ResourceType.JPG;
    }
    if (type.indexOf('image/svg') !== -1) {
      return ResourceType.SVG;
    }
    if (type.indexOf('image/png') !== -1) {
      return ResourceType.PNG;
    }
    if (type.indexOf('image/pi') !== -1) {
      return ResourceType.JFIF;
    }
    if (type.indexOf('image/gif') !== -1) {
      return ResourceType.GIF;
    }
  }

  public static getAllowedTypes(type: ResourceType): string[] {
    const allowedTypes = ResourceUtils.allowedTypesMap.get(type)?.concat([]);
    // If both application and text are added to the map *.js extension will appear twice
    // If text/javascript is not added then *.js may appear as invalid.
    if ((allowedTypes as string[]).indexOf('application/javascript') >= 0) {
      allowedTypes?.push('text/javascript');
    }
    return allowedTypes as string[];
  }

  public static getAllAllowedTypes(): string[] {
    return [].concat.apply([], ([...ResourceUtils.allowedTypesMap.values()] as unknown) as ConcatArray<never>[]);
  }

  public static getResourceIcon(type: ResourceType): string {
    return ResourceUtils.typeIconsMap.get(type) as string;
  }

  public static getStatusIcon(status: ResourceStatus): string {
    return ResourceUtils.typeStatusIconMap.get(status) as string;
  }

  public static getStatusLabel(status: ResourceStatus): string {
    return ResourceUtils.typeStatusLabelMap.get(status) as string;
  }

  public static isImage(type: ResourceType): boolean {
    return ResourceUtils.ImageTypes.indexOf(type) !== -1;
  }

  public static setType(file: File): ResourceType | null {
    if (file.type.indexOf('image') !== -1) {
      return ResourceUtils.setImageType(file.type) as ResourceType;
    } else if (file.type.indexOf('javascript') !== -1 && file.name.indexOf('.mjs') < file.name.length - 4) {
      // Both .js and .mjs are included in application/javascript text/javascript but .mjs should be invalid.
      return ResourceType.JS;
    } else if (file.type.indexOf('css') !== -1) {
      return ResourceType.CSS;
    } else {
      return null;
    }
  }

  public static setStatus(file: File, type: ResourceType): ResourceStatus {
    return type && ResourceUtils.getAllowedTypes(type).indexOf(file.type) !== -1
      ? ResourceStatus.READY
      : ResourceStatus.INVALID;
  }

  public static convertFileToResource(file: File, projectId: string, folderId: string): Resource {
    const type = ResourceUtils.setType(file);
    const status = ResourceUtils.setStatus(file, type as ResourceType);

    const resource = <Resource>{
      // For UI logic, will be replaced by the resource id after post
      // id: uuidv1(),
      name: file.name.slice(0, file.name.lastIndexOf('.')),
      extension: file.name.slice(file.name.lastIndexOf('.'), file.name.length),
      fileName: file.name,
      size: file.size,
      status,
      type,
      progress: 0,
      publishedUrl: '',
      projectId,
      folderId
    };

    return resource;
  }

  public static getAssetType(type: ResourceType): AssetType {
    return ResourceUtils.resourceTypeAssetTypeMap.get(type) as AssetType;
  }

  public static getAssetTypeLabel(type: AssetType): string {
    return ResourceUtils.assetTypeLabel.get(type) as string;
  }
}
