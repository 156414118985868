import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';

import { LoadingModalComponent } from './loading-modal.component';
import { MaterialModule } from '@neptune/material.module';

@NgModule({
  declarations: [LoadingModalComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,

    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatRippleModule,
    MaterialModule,
    MatSnackBarModule
  ],
  exports: [LoadingModalComponent]
})
export class LoadingModalModule {}
