<div mat-dialog-title class="dialog-header">
  <div class="dialog-title">
    <div fxLayout="row" fxLayoutAlign="start center">
      <span id="import-table-name" class="h2">
        <span>{{ data.title }}</span>
      </span>
    </div>
  </div>
</div>

<span class="m-2"></span>

<mat-dialog-content>
  <div class="container-messages">
    <p class="dialog-message" *ngIf="hasMessage">{{ data.message }}</p>
  </div>
</mat-dialog-content>

<div mat-dialog-actions fxLayoutAlign="end start" class="dialog-footer">
  <mat-checkbox *ngIf="data?.checkbox && data?.checkboxLabel" [(ngModel)]="checked" fxFlexAlign="center">
    {{ data?.checkboxLabel }}
  </mat-checkbox>
  <button mat-button color="primary" class="cancel-btn" (click)="onClose(false)">
    {{ data.cancelMessage }}
  </button>
  <button mat-flat-button color="primary" (click)="onClose(true)" class="clock-container">
    {{ data.okMessage }}
    <div class="countdown" *ngIf="clock" class="clock-inline">[{{ clock }}]</div>
  </button>
</div>
