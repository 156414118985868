/* eslint-disable @typescript-eslint/ban-types */
import { DetailResourcesOrg } from './organization';
import { Roles } from './user';

export class RegistrationModel {
  password: string;
  email: string;
  given_name: string;
  family_name: string;
  phone_number?: string;
  token?: string;
  // referredBy: string;
}

export class RegistrationMobile {
  flag: string;
  mobilenumber: string;
}

export interface AccountServiceMock {
  getOrgId: object;
  getCurrentUser: object;
  getUserAttributes: object;
  getIsUserSystemManagement: object;
  getAccount: object;
  getAccountAuthToken: object;
  getCurrentUserDetails: object;
}

export interface DataResourcesOrgBucket {
  RootPath?: string;
  ResourcePath?: string;
  Name?: string;
}

export interface OrgData {
  orgId: string;
  orgName: string;
  resources?: ResourceData;
}

export interface ResourceData {
  DataDB?: ResourceItem;
  MessageDB?: ResourceItem;
  OrgBucket?: ResourceBucket;
  PreviewDB?: ResourceItem;
  SisenseGroups?: SisenseGroup;
  SnowflakeDB?: SnowflakeDB;
  SparkPost?: SparkPost;
  type?: string;
  map?: DetailResourcesOrg;
}

export interface ResourceItem {
  SecretName: string;
  Status: string;
  ClusterName: string;
  DBName: string;
}

export interface ResourceBucket {
  Status: string;
  RootPath: string;
  ResourcePath: string;
  UploadPath: string;
  DataExtractsPath: string;
  Name?: string;
}

export interface SisenseGroup {
  Groups: Record<string, unknown>;
  Status: string;
  datamodel_oid: string;
  dataset_oid: string;
  title: string;
}

export interface SnowflakeDB {
  AccountName: string;
  DBName: string;
  RotationUsers: string[];
  Schema: string;
  SecretName: string;
  Status: string;
  Warehouse: string;
}

export interface SparkPost {
  MaxNumberOfRecipients: number;
  MaxNumberOfStreams: number;
  TestMode: boolean;
}

export interface OrgParam {
  Active?: boolean;
  AllowSubOrg?: boolean;
  CompanySiteTableName?: string;
  ContactTableName?: string;
  Deleted?: boolean;
  DomainsId?: string[];
  Name?: string;
  OrgID?: string;
  OrgType?: string;
  Owner?: string;
  Resources?: ResourceData;
  State?: string;
  Status?: string;
  TierInfo?: {
    Type?: string;
  };
  UpdatedAt?: string;
  UpdatedBy?: string;
  ValidSelSignupDomains?: string[];
  defaultOrg?: string;
  ParentOrgId?: string;
  SubOrgIds?: string[];
  Timezone?: string;
  Children?: OrgParam[];
  role?: Roles | null;
  orgId?: string;
  checked?: boolean;
  id?: string;
  InsertedAt?: Date;
  InsertedBy?: string;
  parentOrganization?: string;
  hasChildren?: boolean;
  children?: any;
  c3selected?: boolean;
}

export interface SessionData {
  accessTokenKey?: string;
  clockDrift?: number;
  exp?: number;
  idTokenKey?: string;
  refreshTokenKey?: string;
}

export const NORTH_AMERICA_FORMAT = '000-000-0000';
export const SOURTH_AMERICA_FORMAT = '000000-0000';

export const SUCCESSFULLY_CODE = 'Code sent successfully.';

export const CHALLENGE_NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';

export const ACCOUNT_NOT_VERIFIED = 'Account could not be verified.';
export const ACCOUNT_VERIFIED = 'Account successfully verified.';
export const CODE_SENT_SUCCESS = 'Verification code was sent successfully.';
export const CODE_SENT_NOT_SUCCESS = 'Error sending verification code.';
