<div class="print-on-demand-preview-content h-100-p">
  <loading-modal></loading-modal>

  <mat-tab-group mat-align-tabs="center" class="h-100-p">
    <mat-tab label="Front Image">
      <div class="image-box" fxLayout="row" fxLayoutAlign="center start">
        <img [src]="_modifiedMessage?.dmmCampaign?.jpgFront" (load)="imageLoaded()" (error)="errorLoadingImage()" />
      </div>
    </mat-tab>
    <mat-tab label="Back Image">
      <div class="image-box" fxLayout="row" fxLayoutAlign="center start">
        <img [src]="_modifiedMessage?.dmmCampaign?.jpgBack" (load)="imageLoaded()" (error)="errorLoadingImage()" />
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
