import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTreeModule } from '@angular/material/tree';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from '@neptune/material.module';
import { FolderSelectorDialogComponent } from './folder-selector-dialog.component';
import { LoadingModalModule } from '../loading-modal/loading-modal.module';
import { NeptunePipesModule } from '@neptune/pipes/pipes.module';

@NgModule({
  declarations: [FolderSelectorDialogComponent],
  imports: [CommonModule, MaterialModule, FuseSharedModule, MatTreeModule, LoadingModalModule, NeptunePipesModule],
  exports: [FolderSelectorDialogComponent],
  providers: []
})
export class FolderSelectorDialogModule {}
