<div
  [formGroup]="formGroup"
  [ngSwitch]="element.selector"
  fxLayout="column"
  class="pl-4 pt-4"
  [style.width.px]="element.width"
>
  <ng-container *ngSwitchCase="FormCanvasElementType.Input">
    <input
      *ngIf="element.hidden; else isNotHiddenInput"
      [id]="element.id"
      type="hidden"
      [formControlName]="element.id"
    />
    <ng-template #isNotHiddenInput>
      <mat-form-field>
        <mat-label>{{ element.label }}</mat-label>
        <input
          matInput
          [id]="element.id"
          [type]="element.type"
          [formControlName]="element.id"
          [required]="element.required"
        />
      </mat-form-field>
    </ng-template>
  </ng-container>

  <mat-checkbox
    *ngSwitchCase="FormCanvasElementType.CheckBox"
    [id]="element.id"
    [formControlName]="element.id"
    class="py-8 display-block"
  >
    {{ element.label }}
  </mat-checkbox>

  <mat-radio-group
    *ngSwitchCase="FormCanvasElementType.RadioButton"
    [id]="element.id"
    [required]="element.required"
    [formControlName]="element.id"
    class="py-8 display-block"
  >
    <mat-label class="display-block mb-8">{{ element.label }}</mat-label>
    <mat-radio-button *ngFor="let option of element.options" [value]="option" class="mr-8">
      {{ option }}
    </mat-radio-button>
  </mat-radio-group>

  <mat-form-field *ngSwitchCase="FormCanvasElementType.Select">
    <mat-label>{{ element.label }}</mat-label>
    <mat-select [id]="element.id" [formControlName]="element.id" [required]="element.required">
      <mat-option *ngFor="let option of element.options" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <button *ngSwitchCase="FormCanvasElementType.Button" [id]="element.id" type="button" mat-raised-button>
    {{ element.value }}
  </button>

  <mat-form-field *ngSwitchCase="FormCanvasElementType.TextArea">
    <mat-label>{{ element.label }}</mat-label>
    <textarea matInput [id]="element.id" [formControlName]="element.id" [required]="element.required">{{
      element.value
    }}</textarea>
  </mat-form-field>

  <p
    class="my-0"
    *ngSwitchCase="FormCanvasElementType.Text"
    [id]="element.id"
    [style.color]="element.fontColor"
    [style.font-size.px]="element.fontSize"
    [style.font-style]="element.fontStyle"
    [style.font-weight]="element.fontWeight"
    [style.text-align]="element.textAlign"
  >
    {{ element.value }}
  </p>

  <mat-divider class="py-8" [id]="element.id" *ngSwitchCase="FormCanvasElementType.HorizontalLine"></mat-divider>

  <div *ngSwitchCase="FormCanvasElementType.SubmitResetButtons" [id]="element.id" fxFlex fxLayoutAlign="end">
    <button [id]="element.id + '-reset'" type="reset" mat-raised-button class="mr-32" (click)="onReset()">
      {{ element.resetLabel }}
    </button>
    <button [id]="element.id + '-submit'" type="submit" mat-raised-button color="primary" [disabled]="!formGroup.valid">
      {{ element.submitLabel }}
    </button>
  </div>

  <div *ngSwitchDefault [id]="element.id">
    Selector
    <strong>{{ element.selector }}</strong>
    not found
  </div>
</div>
