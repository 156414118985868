import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AceEditorComponent } from '@derekbaker/ngx-ace-editor-wrapper';
import 'brace/ext/searchbox';

// Notes from how to implement this :
// https://itnext.io/creating-a-custom-form-field-control-compatible-with-reactive-forms-and-angular-material-cf195905b451
@Component({
  selector: 'ace-editor-wrapper',
  templateUrl: './ace-editor-wrapper.component.html',
  styleUrls: ['./ace-editor-wrapper.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AceEditorWrapperComponent)
    },
    {
      provide: MatFormFieldControl,
      useExisting: AceEditorWrapperComponent
    }
  ],
  // eslint-disable-next-line
  host: {
    '[id]': 'id',
    '[attr.aria-describedby]': 'describedBy'
  }
})
export class AceEditorWrapperComponent
  implements DoCheck, OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<any> {
  static nextId = 0;
  @HostBinding() id = `ace-editor-wrapper-${AceEditorWrapperComponent.nextId++}`;

  @ViewChild('container', { read: ElementRef }) container: ElementRef;

  @ViewChild('aceEditor', { static: true }) aceEditor: AceEditorComponent;

  @Input() mode = '';
  @Input() theme = 'eclipse';
  @Input() options = {};
  @Input() readOnly = false;
  @Input() autoUpdateContent = true;
  @Input() durationBeforeCallback = 1000;
  @Input() classes: string = '';

  /**
   * Gets called when the editor's value is about to change
   */
  @Output() textChange = new EventEmitter<string>();

  /**
   * Gets called when the editor's value has changed
   */
  @Output() textChanged = new EventEmitter<string>();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onFocus: EventEmitter<AceEditorComponent> = new EventEmitter();

  _value: string;

  _changeFn: (_: any) => void;
  _touchedFn: any;

  // MatFormFieldControl Implementation

  stateChanges = new Subject<void>();
  ngControl;
  focused;
  controlType = 'richeditor';
  errorState = false;
  public _placeholder: string;
  public _required = false;
  public _disabled = false;

  @HostBinding('attr.aria-describedby') describedBy = '';
  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis);
    this.stateChanges.next();
  }

  constructor(public elRef: ElementRef, public injector: Injector, public fm: FocusMonitor) {
    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      if (this.focused) {
        this.onFocus.emit(this.aceEditor);
      }
      this.stateChanges.next();
    });
  }

  public resize() {
    this.aceEditor.getEditor().resize();
  }

  ngOnInit() {
    this.ngControl = this.injector.get(NgControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'div') {
      this.container.nativeElement.querySelector('div').focus();
    }
  }

  get empty() {
    const commentText = this._value ? this._value.trim() : '';
    return commentText ? false : true;
  }

  // ControlValueAccessor implementation

  registerOnChange(fn: (_: any) => void): void {
    this._changeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this._touchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.readOnly = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  // Event handling

  onTextChange(text: string) {
    this._value = text;

    if (this._changeFn) {
      this._changeFn(text);
    }

    this.textChange.emit(text);
  }

  onTextChanged(text: string) {
    this._value = text;

    if (this._changeFn) {
      this._changeFn(text);
    }

    this.textChanged.emit(text);
  }

  // Property accessors

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this.onTextChange(value);
    this._value = value;
    this.onTextChanged(value);
  }
}
