import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssetId } from '@neptune/models/journey';
import { AssetSelectorDialogData, Asset, AssetType, AssetUtils, AssetSelectorDialogFilter } from '@neptune/models';
import { AssetSelectorDialogComponent } from '@neptune/components/asset-selector-dialog/asset-selector-dialog.component';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AbstractControl } from '@angular/forms';
import { ComponentType } from '@angular/cdk/portal';
import { PurlListDialogComponent } from '../purl-list-dialog/purl-list-dialog.component';
import { ImportAssetListDialogComponent } from '../import-asset-list-dialog/import-asset-list-dialog.component';

interface AssetDialogData {
  panelClass: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  dialogComponent: ComponentType<object>;
}

@Component({
  selector: 'asset-selector',
  templateUrl: './asset-selector.component.html',
  styleUrls: ['./asset-selector.component.scss']
})
export class AssetSelectorComponent implements OnInit {
  /** Used in cases where a new asset button wanted instead of an input field */
  @Input() asButton: boolean;
  @Input() buttonLabel: string;
  @Input() svgIcon: string;
  /** Associated project */
  @Input() projectId: string | null;
  // filters to be applied to selector
  @Input() filters: AssetSelectorDialogFilter;
  // associated table id, not used for filter
  @Input() tableId: string;
  @Input() errorMatcher: ErrorStateMatcher;
  @Input() assetFormControl: AbstractControl | null;
  @Input() disabled: boolean;
  // list of currently selected assets ids
  @Input() selected: AssetId[];

  @Input('initialAsset') set initialAsset(assetId: AssetId) {
    this.assetId = assetId;
  }

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onAssetSelected: EventEmitter<Asset | null> = new EventEmitter();

  public assetId: AssetId;
  public asset: Asset | null;

  private readonly DEFAULT_ASSET_DIALOG: AssetDialogData = <AssetDialogData>{
    panelClass: 'asset-selector-dialog-container',
    dialogComponent: AssetSelectorDialogComponent
  };

  /**
   * “Global Assets” need a concrete implementation of GlobalAssetListDialog (e.g.: PurlListDialog).
   * For simplicity and backwards compatibility,
   * JUST ADD ASSETS THAT DON'T USE THE AssetSelectorDialogComponent to this Map.
   */
  private assetDialogData: Map<AssetType, AssetDialogData> = new Map<AssetType, AssetDialogData>([
    [
      AssetType.PURL,
      <AssetDialogData>{
        panelClass: 'purl-list-dialog-container',
        dialogComponent: PurlListDialogComponent
      }
    ],
    [
      AssetType.IMPORT,
      <AssetDialogData>{
        panelClass: 'import-asset-list-dialog-container',
        dialogComponent: ImportAssetListDialogComponent
      }
    ]
  ]);

  constructor(private matDialog: MatDialog) {}

  ngOnInit() {
    if (!this.assetId) {
      this.clearAsset();
    }
    if (this.assetFormControl) {
      this.assetFormControl.valueChanges.subscribe(value => {
        if (!value) {
          this.clearAsset();
        }
      });
    }
  }

  private getAssetDialog(type: AssetType | null): AssetDialogData {
    let dialogData = type && this.assetDialogData.get(type);
    if (!dialogData) {
      dialogData = this.DEFAULT_ASSET_DIALOG;
    }
    return dialogData;
  }

  public isGlobalAsset(type: AssetType): boolean {
    return AssetUtils.isGlobalAsset(type);
  }

  public selectAsset() {
    if (!this.disabled) {
      const data: AssetSelectorDialogData = {
        multiSelect: false,
        filterProps: this.filters,
        hideOthers: this.filters?.assetTypes && this.filters.assetTypes.length > 0,
        tableName: this.filters.tableName,
        tableId: this.tableId,
        selected: this.selected
      };

      if (this.projectId) {
        data.projectId = `${this.projectId}`;
      }

      // NOTE: The assetTypeDialog map idea is to customize the dialog component for certain "assets".
      // If no DialogComp is associated to that assetType, existing AssetSelectorDialog will be used.
      const assetType = this.getAssetType();
      const dialogData = this.getAssetDialog(assetType);
      const dialogRef = this.matDialog.open(dialogData.dialogComponent, {
        data,
        panelClass: dialogData.panelClass
      });

      dialogRef.afterClosed().subscribe((selectedAsset: Asset) => {
        if (selectedAsset) {
          this.asset = selectedAsset;
          // NOTE: We define assetId as selectedAsset.id since there's no assetId on "Global Assets"... yet?
          if (AssetUtils.isGlobalAsset(assetType)) {
            this.asset.assetId = selectedAsset.id;
          }
          this.assetId = {
            // NOTE: Fallback to id, since there are "Global Assets" (e.g. PURL) which don't have an assetId
            id: selectedAsset.assetId || selectedAsset.id,
            name: selectedAsset.name,
            assetType: selectedAsset.assetType || assetType
          };
        }
        this.onAssetSelected.emit(this.asset);
      });
    }
  }

  private clearAsset() {
    let message: string = 'Select ';
    const assetType = this.getAssetType();
    if (this.filters.assetTypes?.length === 1) {
      message += `a ${AssetUtils.getAssetLabel(assetType)}`;
    } else if (this.filters.assetTypes && this.filters.assetTypes.length > 1) {
      message += `from ${this.filters.assetTypes?.map(a => AssetUtils.getAssetLabel(a)).join(', ')}`;
    } else {
      message += 'any asset';
    }
    this.assetId = {
      id: '',
      name: message,
      assetType
    };
    this.asset = null;
  }

  private getAssetType(): AssetType | null {
    return this.filters.assetTypes && this.filters.assetTypes.length > 0 ? this.filters.assetTypes[0] : null;
  }
}
