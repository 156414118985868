import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTreeModule } from '@angular/material/tree';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { FuseSharedModule } from '@fuse/shared.module';

import { MaterialModule } from '@neptune/material.module';
import { StandardUserDateModule } from '@neptune/components/standard-list-page/standard-user-date/standard-user-date.module';
import { StandardSearchModule } from '@neptune/components/standard-search/standard-search.module';
import { ImportAssetListDialogComponent } from './import-asset-list-dialog.component';
import { LoadingModalModule } from '../loading-modal/loading-modal.module';
import { NeptunePipesModule } from '@neptune/pipes/pipes.module';
import { ImportAssetListModule } from '../import-asset-list/import-asset-list.module';

@NgModule({
  declarations: [ImportAssetListDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FuseSharedModule,
    MatTreeModule,
    LoadingModalModule,
    NgxDatatableModule,
    StandardUserDateModule,
    NeptunePipesModule,
    ImportAssetListModule,
    StandardSearchModule
  ],
  exports: [ImportAssetListDialogComponent],
  providers: []
})
export class ImportAssetListDialogModule {}
