import {
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Component,
  OnDestroy,
  Output,
  Input,
  EventEmitter
} from '@angular/core';
import { SvgEngine } from './entity-engine/SvgEngine';
import { CanvasAction } from '@neptune/models/svg-canvas';
import { MatIconRegistry } from '@angular/material/icon';
import { Observable } from 'rxjs';

export interface SvgCanvasOptions {
  useMap: boolean;
}

/**
 * The SvgCanvasComponent is designed to support interactive node flows in a canvas setting using svg for rendering.
 * There are two libraries that the component is built upon:
 * https://www.richardlord.net/ash/ - an entity-component-system framework used to manage state and updating
 * https://svgjs.com/docs/3.0/ - a lightweight library for manipulating and animating SVG
 * To understand the structure and theory of the entity engine it's recommended to read through the ash framework intros
 * For specific questions on how the elements are rendered or styled refer to svg.js documentation.
 *
 * The svg-canvas component is designed to provide the base support for a canvas of relational nodes,
 * while allowing for flexibility in terms of specific execution.
 * Currently this component is used by the data tables canvas and palette,
 * each extend svg-canvas in ways necessary for the specific needs of those pages,
 * datatable is a simpler extension while palette is more complex.
 * It's worth looking at both to see how svg-canvas can be used.
 *
 * To interface with SvgCanvasComponent you can send and receive data in the form of CanvasAction,
 * actionIn and actionOut are used for this purpose.
 */
@Component({
  selector: 'svg-canvas',
  templateUrl: './svg-canvas.component.html',
  styleUrls: ['./svg-canvas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SvgCanvasComponent implements OnDestroy {
  @Output() actionOut: EventEmitter<CanvasAction> = new EventEmitter<CanvasAction>();
  @Input() set actionIn(action: CanvasAction) {
    this.engine?.processAction(action);
  }
  private engine: SvgEngine | null;

  constructor(private matIconRegistry: MatIconRegistry) {}

  init(engine: SvgEngine, options?: SvgCanvasOptions): Observable<any> {
    this.engine = engine;
    const svgHolder: HTMLOrSVGElement = <HTMLOrSVGElement>document.getElementById('svg-board');
    this.engine.onAction.add((action: CanvasAction) => {
      this.actionOut.emit(action);
    });
    const svgMap: HTMLOrSVGElement | null =
      options && options.useMap ? <HTMLOrSVGElement>document.getElementById('svg-map') : null;
    return this.engine.init(<SVGSVGElement>svgHolder, this.matIconRegistry, <SVGSVGElement>svgMap);
  }

  ngOnDestroy() {
    if (this.engine) {
      this.engine.destroy();
      this.engine = null;
    }
  }
}
