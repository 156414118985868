import { Component, OnChanges, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { TableDetails, TableColumnDetails, ColumnPair } from '@neptune/models';
import { DropdownOption } from '../dropdown/dropdown.component';

export interface RelationshipSelected {
  source?: DropdownOption;
  target?: DropdownOption;
  confirmed?: boolean;
}

export enum JoinActionType {
  Delete = 'Delete',
  Add = 'Add',
  Update = 'Update'
}

export interface JoinChangedEvent {
  actionType: JoinActionType;
  model: RelationshipSelected | null;
  index: number | null;
}

@Component({
  selector: 'app-relationships',
  templateUrl: './relationships.component.html',
  styleUrls: ['./relationships.component.scss']
})
export class RelationshipsComponent implements OnChanges {
  @Input('sourceTable') sourceTable: TableDetails;
  @Input('targetTable') targetTable: TableDetails;
  @Output('joinChanged') joinChanged: EventEmitter<JoinChangedEvent> = new EventEmitter();
  @Input('closeDrawer') closeDrawer: () => any;
  public helperMessage: string = 'Choose two columns from the tables'; // TODO: maybe try to use some api or i18n lib?
  public relationshipsSelection: RelationshipSelected[] = [{}];
  public sourceFields: DropdownOption[] | null = [];
  public targetFields: DropdownOption[] | null = [];

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.sourceTable &&
        changes.sourceTable.previousValue !== changes.sourceTable.currentValue &&
        changes.sourceTable.currentValue) ||
      (changes.targetTable &&
        changes.targetTable.previousValue !== changes.targetTable.currentValue &&
        changes.targetTable.currentValue)
    ) {
      this.setupSelections();
    } else if (
      changes.sourceTable &&
      !changes.sourceTable.currentValue &&
      changes.targetTable &&
      !changes.targetTable.currentValue
    ) {
      this.resetRelationshipsDrawer();
    }
  }
  resetRelationshipsDrawer() {
    this.sourceFields = null;
    this.targetFields = null;
    this.relationshipsSelection = [{}];
  }

  setupSelections() {
    this.relationshipsSelection = [];
    this.sourceFields = this.setDropdownOptions(this.sourceTable.columns);
    this.targetFields = this.setDropdownOptions(this.targetTable.columns);

    if (
      this.sourceTable.relationships &&
      this.sourceTable.relationships[this.targetTable.id] &&
      this.sourceTable.relationships[this.targetTable.id].columns.length
    ) {
      this.sourceTable.relationships[this.targetTable.id].columns.forEach((column: ColumnPair) => {
        this.relationshipsSelection.push({
          source: this.sourceFields?.find(field => field.label === column.from),
          target: this.targetFields?.find(field => field.label === column.to),
          confirmed: true
        });
      });
    } else {
      this.relationshipsSelection = [{}];
    }
  }

  setDropdownOptions(columns: TableColumnDetails[]): DropdownOption[] {
    // eslint-disable-next-line arrow-body-style
    return columns.map(column => {
      return {
        label: column.name,
        value: column,
        dataType: column.dataType,
        disabled: false
      };
    });
  }

  removeJoin(index: number) {
    this.joinChanged.emit({
      actionType: JoinActionType.Delete,
      model: this.relationshipsSelection.splice(index, 1)[0],
      index
    });
  }

  addJoin() {
    this.relationshipsSelection.push({});
  }

  deleteAll() {
    this.joinChanged.emit({
      actionType: JoinActionType.Delete,
      model: null,
      index: null
    });
    this.relationshipsSelection = [{}];
  }

  onSelectionChange(source: boolean, rel: RelationshipSelected, i: number, e: DropdownOption) {
    const origin = source ? 'source' : 'target';
    this.relationshipsSelection[i][origin] = e;

    // Disable dataTypes that do not match source.
    if (origin === 'source') {
      this.targetFields?.forEach(field => {
        field.disabled = field.dataType !== e.dataType;
      });
      if (this.relationshipsSelection[i].target && this.relationshipsSelection[i].target?.dataType !== e.dataType) {
        this.relationshipsSelection[i].target = undefined;
      }
    }

    if (this.relationshipsSelection[i].source && this.relationshipsSelection[i].target) {
      // When both fields have been selected, reset disabled state
      this.targetFields?.forEach(field => (field.disabled = false));
    }

    this.joinChanged.emit({
      actionType: JoinActionType.Update,
      model: this.relationshipsSelection[i],
      index: i
    });
  }

  onOpenedChange(i: number, opened: boolean) {
    if (opened) {
      this.targetFields?.forEach(field => {
        field.disabled = field.dataType !== this.relationshipsSelection[i].source?.dataType;
      });
    } else {
      this.targetFields?.forEach(field => (field.disabled = false));
    }
  }

  canAdd(): boolean {
    const last = this.relationshipsSelection[this.relationshipsSelection.length - 1];
    return last.source !== undefined && last.target !== undefined;
  }
}
