<div class="purl-dialog">
  <loading-modal></loading-modal>
  <div mat-dialog-title class="dialog-header">
    <div class="dialog-title">
      <span class="h2">{{ title }}</span>
    </div>
  </div>
  <form class="form" (ngSubmit)="onSubmit()" [formGroup]="crudForm">
    <mat-dialog-content>
      <div class="description-container" fxLayout="column" fxLayoutAlign="space-between start">
        <!-- NAME -->
        <mat-form-field class="input-field" appearance="fill">
          <mat-label>Name</mat-label>
          <input matInput type="text" formControlName="name" />
          <mat-error
            *ngIf="
              crudForm.get('name')!.touched && !crudForm.get('name')!.valid && crudForm.get('name')!.errors!.required
            "
          >
            Name is required
          </mat-error>
          <mat-error
            *ngIf="
              crudForm.get('name')!.touched && !crudForm.get('name')!.valid && crudForm.get('name')!.errors!.pattern
            "
          >
            Only letters, numbers, underscores and hyphens are allowed
          </mat-error>
        </mat-form-field>

        <!-- DOMAIN -->
        <mat-form-field appearance="fill">
          <mat-label *ngIf="crudForm.value.domain">Domain</mat-label>
          <mat-select [placeholder]="domainPlaceholder" formControlName="domain">
            <mat-option *ngFor="let d of domains" [value]="d">{{ d.name }}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              crudForm.get('domain')!.touched &&
              !crudForm.get('domain')!.valid &&
              crudForm.get('domain')!.errors!.required
            "
          >
            Domain is required
          </mat-error>
        </mat-form-field>

        <!-- TABLE -->
        <mat-form-field appearance="fill" [ngClass]="{ 'hide-arrow': tableDisabled }">
          <mat-label>Table</mat-label>
          <mat-icon matPrefix svgIcon="table" class="form-icon-front"></mat-icon>
          <mat-select formControlName="tableName">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let t of tables" [value]="t.Id">{{ t.name }}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- LANDING -->
        <asset-selector
          class="landing-selector"
          svgIcon="webpage"
          [filters]="assetFilter"
          [projectId]="data?.projectId || null"
          (onAssetSelected)="onAssetSelected(purl, $event!)"
          [initialAsset]="landingPage"
          [assetFormControl]="crudForm.get('landing')"
          [disabled]="!assetFilter.tableName"
          fxFlex="1 1 100%"
        ></asset-selector>
        <mat-error *ngIf="crudForm.get('landing')!.touched && crudForm.get('landing')!.errors!.required">
          Landing Page is required
        </mat-error>

        <!-- SYNTAX -->
        <mat-form-field appearance="fill" class="w-100-p">
          <mat-label>Syntax</mat-label>
          <autocomplete-syntax-input
            syntaxStartKey="{{"
            syntaxEndKey="}}"
            [options]="filteredColumnsOptions"
            formControlName="expression"
            class="w-100-p"
          ></autocomplete-syntax-input>
          <mat-error
            *ngIf="
              crudForm.get('expression')!.touched &&
              !crudForm.get('expression')!.valid &&
              crudForm.get('expression')!.errors?.required
            "
          >
            Syntax is required
          </mat-error>
          <mat-error
            *ngIf="
              crudForm.get('expression')!.touched &&
              !crudForm.get('expression')!.valid &&
              crudForm.get('expression')!.errors!.pattern
            "
          >
            Only numbers, letters, underscore and curly braces are allowed
          </mat-error>
          <mat-hint>Type &#0123;&#0123; to enter a field value token</mat-hint>
        </mat-form-field>

        <!-- PREVIEW -->
        <mat-form-field class="preview-field" appearance="fill">
          <mat-label>Preview</mat-label>
          <button
            type="button"
            mat-icon-button
            matSuffix
            [disabled]="!crudForm.get('expression')?.value"
            [cdkCopyToClipboard]="syntaxPreview"
            (cdkCopyToClipboardCopied)="showSnackbar()"
          >
            <mat-icon svgIcon="copy"></mat-icon>
          </button>
          <input matInput readonly disabled [value]="syntaxPreview" />
        </mat-form-field>

        <!-- APPROVE -->
        <mat-checkbox
          *ngIf="isEditing"
          formControlName="approved"
          class="checkbox-approved"
          [disabled]="!canBeApproved"
          labelPosition="before"
          [matTooltip]="_tooltip"
          fxFlexAlign="end"
        >
          Approve
        </mat-checkbox>

        <!-- COLLISION AVOIDANCE -->
        <label id="collision-avoidance">Collision Avoidance</label>
        <mat-radio-group
          class="collision-avoidance-radio"
          aria-labelledby="collision-avoidance"
          formControlName="collision"
          fxLayout="column"
        >
          <mat-radio-button [value]="CollisionRadioOptions.AUTOINCREMENT">Add autoincremented number</mat-radio-button>
          <!-- NOTE: disabled until backend counterpart gets implemented -->
          <mat-radio-button value="" disabled matTooltip="Coming Soon">
            Add
            <input placeholder="X" type="number" min="1" max="10" disabled />
            random characters
          </mat-radio-button>
        </mat-radio-group>
        <!-- NOTE: hidden until product definitions -->
        <!-- <p value="" disabled class="collision-position" matTooltip="Coming Soon">
          Prefix
          <mat-slide-toggle disabled [checked]="true"></mat-slide-toggle>
          Suffix
        </p> -->
      </div>
    </mat-dialog-content>
    <mat-dialog-actions fxLayoutAlign="end start" class="dialog-footer" fxLayout="row nowrap">
      <button type="button" mat-button color="primary" (click)="onCancel()" class="cancel-btn">Cancel</button>
      <button type="submit" mat-flat-button color="primary" [disabled]="crudForm.invalid">Save</button>
    </mat-dialog-actions>
  </form>
</div>
