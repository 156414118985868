import { from as observableFrom, Observable, of as observableOf } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CleanupProcess, org, OrgModel } from '@neptune/models/organization';
import { BaseService, Endpoint } from './base.service';
import { HttpClient } from '@angular/common/http';
import { CognitoService } from './cognito.service';
import { StoreService } from './store.service';
import { AccountService } from './account.service';
import { Roles, UserDetails } from '@neptune/models/user';

@Injectable()
export class OrgService extends BaseService {
  private ORGANIZATION_STORE = 'ORGANIZATION_STORE';

  constructor(
    protected http: HttpClient,
    protected cognitoService: CognitoService,
    protected storeService: StoreService,
    protected accountService: AccountService
  ) {
    super(http, cognitoService, storeService);
  }

  /**
   * This function a list admin organization by store.
   */
  getAllAdminStoreOrganizations(fromCache: boolean = true): Observable<OrgModel[]> {
    if (fromCache) {
      const organizations: OrgModel[] = this.storeService.get(this.ORGANIZATION_STORE);

      if (organizations) {
        return observableOf(organizations);
      }
    }

    return observableFrom(this.getAllAdminOrganizations()).pipe(
      map((result: OrgModel[]) => {
        const organizations: OrgModel[] = result.map(data => data);

        this.storeService.set(this.ORGANIZATION_STORE, organizations);

        return organizations;
      })
    );
  }

  getOrganizationWithChildren(organizationId: string): Observable<OrgModel> {
    return super.baseGet<OrgModel>(Endpoint.ORG, `admin/organizations/${organizationId}/children`);
  }

  getAllUsersAndAdminFromOrganization(organizationId: string): Observable<any[]> {
    return super.baseGet<OrgModel[]>(Endpoint.ORG, `admin/organizations/${organizationId}`);
  }

  getPartners(organization_id: string): Observable<OrgModel[]> {
    return super.baseGet<OrgModel[]>(Endpoint.ORG, `admin/organizations/${organization_id}`, false, { partners: true });
  }

  /**
   * Get a organization
   */
  getOrganization(organizationId: string, fromCache = false): Observable<OrgModel> {
    const url = `admin/organizations/${organizationId}`;
    return this.accountService
      .getCurrentUserDetails()
      .pipe(
        mergeMap(
          (userDetails: UserDetails) =>
            super.baseGetWithCache(
              'ORGANIZATION_STORE',
              fromCache,
              userDetails.attributes.qp_role === Roles.C3USER ? Endpoint.PUBLIC_ORG : Endpoint.ORG,
              url,
              false,
              null,
              3600000
            ) as Observable<OrgModel>
        )
      );
  }

  /**
   * Update a organization
   */
  updateOrg(organizationModel: OrgModel): Observable<org> {
    return super.basePut(Endpoint.ORG, `admin/organizations/${organizationModel.OrgID}`, organizationModel);
  }

  // --------------> NEW ENDPOINT -----------------------------
  /**
   * Get All Organizations
   */
  getAllOrganizations(): Observable<any> {
    const url = 'organizations';
    return super.baseGet(Endpoint.NXTDRIVE, url);
  }

  /**
   * Activity organization data
   */
  getOrgActivity(organization_id: string): Observable<any> {
    const url = `organizations/${organization_id}/activity`;
    return super.baseGet(Endpoint.NXTDRIVE, url);
  }

  /**
   * Delete organization
   */
  deleteOrg(organization_id: string): Observable<any> {
    const url = `organizations/${organization_id}`;
    return super.baseDelete(Endpoint.NXTDRIVE, url);
  }

  /**
   * Create New organization
   */
  createOrg(organizationModel: OrgModel): Observable<org> {
    const url = 'organizations';
    return super.basePost(Endpoint.NXTDRIVE, url, organizationModel);
  }

  /**
   * Clone organization
   */
  cloneOrg(organizationModel: OrgModel, oldOrganization_id: string): Observable<org> {
    const url = `organizations/${oldOrganization_id}/clone`;
    return super.basePost(Endpoint.NXTDRIVE, url, organizationModel);
  }

  /**
   * CleanUp data organization
   */
  cleanupOrg(organization_id: string): Observable<CleanupProcess> {
    const url = `organizations/${organization_id}/cleanup`;
    return super.basePost(Endpoint.NXTDRIVE, url, null);
  }

  /**
   * This function a get list organization
   */
  getAllAdminOrganizations(
    isImpersonationBox: boolean = false,
    includeChildOrganizations: boolean = false
  ): Observable<OrgModel[]> {
    const url: string = includeChildOrganizations ? `organizations?children_tree=true` : `organizations`;
    return this.accountService.getCurrentUserDetails().pipe(
      mergeMap(
        (userDetails: UserDetails) =>
          super.baseGet<OrgModel[]>(
            userDetails.attributes.qp_role === Roles.C3USER ? Endpoint.PUBLIC_HUB : Endpoint.NXTDRIVE,
            url,
            false,
            {
              is_impersonation_box: isImpersonationBox
            }
          ) as Observable<OrgModel[]>
      )
    );
  }
}
