import { Injectable } from '@angular/core';
import { APIConfig } from 'environments/environment';
import { BoardDef } from '@neptune/models/svg-canvas';

// eslint-disable-next-line no-shadow
enum LocalStorageKey {
  EXPRESS_PROMPT_FOR_FIELD = 'qp-express-prompt-field',
  COGNITO_INFO_PREFIX = 'qp-cognito-info',
  COGNITO_SESSION_SUFIX = 'session',
  COGNITO_ACCOUNT_SUFIX = 'account',
  SVG_CANVAS_PREFIX = 'svg-canvas'
}

@Injectable()
export class LocalStorageService {
  /**
   * Saves Key, Value to Local Storage
   */
  private set(key: string, value: any): void {
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Retrieves Value from Local Storage with a Key.
   */
  private get(key: string): any | null {
    const res: string | null = window.localStorage.getItem(key);
    return res ? JSON.parse(res) : res;
  }

  /**
   * Removes one Key from Local Storage
   */
  private remove(key: string): void {
    return window.localStorage.removeItem(key);
  }

  /** *************************************************************************/
  /*                           EXPRESS DIALOG                                */
  /** *************************************************************************/

  public getPromptFor(fieldName: string): string {
    return this.get(`${LocalStorageKey.EXPRESS_PROMPT_FOR_FIELD}-${fieldName}`) as string;
  }

  public setPromptFor(fieldName: string, prompt: string) {
    this.set(`${LocalStorageKey.EXPRESS_PROMPT_FOR_FIELD}-${fieldName}`, prompt);
  }

  /** *************************************************************************/
  /*                           ACCOUNT SERVICE                               */
  /** *************************************************************************/

  public getSession(username: string): any {
    return this.get(this.sessionKeyFor(username));
  }

  public setSession(username: string, sessionInfo: any) {
    this.set(this.sessionKeyFor(username), sessionInfo);
  }

  public getAccount(username: string): any {
    return this.get(this.accountKeyFor(username));
  }

  public setAccount(username: string, accountInfo: any) {
    this.set(this.accountKeyFor(username), accountInfo);
  }

  public clearAccount(username: string) {
    this.remove(this.accountKeyFor(username));
  }

  public clearSession(username: string) {
    this.remove(this.sessionKeyFor(username));
  }

  private sessionKeyFor(username: string): string {
    return `${LocalStorageKey.COGNITO_INFO_PREFIX}-${APIConfig.EnvName}-${username}-${LocalStorageKey.COGNITO_SESSION_SUFIX}`;
  }

  private accountKeyFor(username: string): string {
    return `${LocalStorageKey.COGNITO_INFO_PREFIX}-${APIConfig.EnvName}-${username}-${LocalStorageKey.COGNITO_ACCOUNT_SUFIX}`;
  }

  /** *************************************************************************/
  /*                        SVG CANVAS SERVICE                               */
  /** *************************************************************************/
  setViewport(id, data) {
    this.set(this.canvasKeyFor(id), data);
  }

  getViewport(id): BoardDef {
    return (
      this.get(this.canvasKeyFor(id)) ||
      <BoardDef>{
        x: 0,
        y: 0,
        zoom: 1
      }
    );
  }
  canvasKeyFor(id) {
    return `${LocalStorageKey.SVG_CANVAS_PREFIX}-${id}`;
  }
}
