import { Component, EventEmitter, Input, AfterViewInit, Output, ViewChild } from '@angular/core';
import { IframeEvent, IframeEventName, IframeWrapperComponent } from '../iframe-wrapper/iframe-wrapper.component';
import { ConfirmDialog } from '@neptune/components';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LoadingModalComponent } from '../loading-modal/loading-modal.component';
import { WysiwygEditorComponent } from '@neptune/components/wysiwyg-editor/wysiwyg-editor.component';
import { AceEditorWrapperComponent } from '@neptune/components/ace-editor-wrapper/ace-editor-wrapper.component';
import { DynamicContentTypes } from '@neptune/models';

export enum ToggleSelection {
  HTML = 'HTML',
  TEXT = 'TEXT',
  WYSIWYG = 'WYSIWIG'
}

export enum ViewMode {
  EDIT = 'EDIT',
  PREVIEW = 'PREVIEW'
}

export enum EditorTypes {
  TEXT = 'text',
  HTML = 'html',
  WYSIWYG = 'wysiwig'
}

export interface EditorConfig {
  content: string;
  placeholder: string;
  type: EditorTypes;
  label?: string;
  classes?: string;
  /** Max size in bytes for html/text content */
  maxSize?: number;
}

export interface Devise {
  // eslint-disable-next-line @typescript-eslint/ban-types
  size: Number;
  name: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'html-text-edit-preview',
  templateUrl: './html-text-edit-preview.component.html',
  styleUrls: ['./html-text-edit-preview.component.scss']
})
export class HtmlTextEditPreviewComponent implements AfterViewInit {
  /** Expose interface for swich case  */
  public ToggleSelection = ToggleSelection;

  /** On Focus get's set to the textarea DOM element */
  public currentTextArea: any;
  /** Set to true only when on WYSIWYG mode */
  public currentlyOnWysiwyg: boolean = false;
  /** Expose ViewMode enum */
  public ViewMode = ViewMode;
  /** Devises dimentions*/
  public screenSizes: Array<Devise> = [
    {
      size: 1440,
      name: 'Laptop'
    },
    {
      size: 1024,
      name: 'iPad Pro'
    },
    {
      size: 768,
      name: 'iPad'
    },
    {
      size: 414,
      name: 'iPhone 6/7/8 Plus'
    },
    {
      size: 375,
      name: 'iPhone 5/SE'
    }
  ];
  /** Preview viewport empty default size */
  public emptySize: Devise = {
    size: 0,
    name: 'None'
  };
  /** Preview viewport width hover size */
  // eslint-disable-next-line no-invalid-this
  public hoveredSize: Devise = this.emptySize;
  /** Preview viewport width default */
  // eslint-disable-next-line no-invalid-this
  public selectedSize: Devise = this.screenSizes[0];
  /** Text to show on the snackbar */
  public MAX_SIZE_MESSAGE: string = 'The <editor_type> content exceeds the <max_size> limit.';
  /** Flag to email subject content type */
  public isEmailSubjectType: boolean;

  /** Current edit view mode */
  @Input('currentToggleSelection') currentToggleSelection: ToggleSelection;

  @Input('smallUploadButton') smallUploadButton: boolean = false;

  /** HTML Editor config */
  @Input('html') html: EditorConfig;
  /** Flag to use to show/hide HTML | TEXT toggle */
  @Input('disableHtml') disableHtml: boolean;
  @Output('html') htmlChange: EventEmitter<EditorConfig> = new EventEmitter<EditorConfig>();

  /** TEXT Editor config */
  @Input('text') text: EditorConfig;
  /** Flag to use to show/hide HTML | TEXT toggle */
  @Input('disableText') disableText: boolean;
  @Output('text') textChange: EventEmitter<EditorConfig> = new EventEmitter<EditorConfig>();

  /** WYSIWYG Editor config */
  @Input('wysiwyg') wysiwyg: EditorConfig;
  /** Flag to use to show/hide HTML | TEXT toggle */
  @Input('disableWysiwyg') disableWysiwyg: boolean;
  @Output('wysiwyg') wysiwygChange: EventEmitter<EditorConfig> = new EventEmitter<EditorConfig>();

  /** Set's the view mode of the component */
  @Input('mode') mode: ViewMode = ViewMode.EDIT;
  /** Must provide a Unique ID if there's more than one instance per page */
  @Input('SOURCE_ID') SOURCE_ID: string = 'email-preview';
  /** URL to the HTML file to load and display the content */
  @Input('SOURCE_PREVIEW') SOURCE_PREVIEW: string = 'contentbuilderjs/email-template-viewer.html';

  /** Type of dynamic content type */
  @Input('dynamicContentType') dynamicContentType?: string;

  /** Emits a boolean when either HTML or TEXT models changed */
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onWysiwygClickedEmmiter: EventEmitter<any> = new EventEmitter<any>();

  /** Reference to the iframe preview component */
  @ViewChild('iframePreview') iframePreview: IframeWrapperComponent;
  /** Reference to the html textarea */
  @ViewChild('htmlAceWrapper') htmlAceWrapper: AceEditorWrapperComponent;
  /** Reference to the text textarea */
  @ViewChild('textAceWrapper') textAceWrapper: AceEditorWrapperComponent;
  /** Reference to the Wysiwyg component*/
  @ViewChild(WysiwygEditorComponent, { static: false })
  private wysiwygEditorComponent: WysiwygEditorComponent;
  /** Disable Edit */
  @Input('disabled') disabled: boolean;
  @ViewChild(LoadingModalComponent)
  private loadingComponent: LoadingModalComponent;

  public aceOptions = {
    highlightActiveLine: true,
    highlightSelectedWord: true,
    behavioursEnabled: true,
    wrapBehavioursEnabled: true,
    showLineNumbers: true,
    displayIndentGuides: true,
    enableBasicAutocompletion: true,
    enableLiveAutocompletion: true
  };

  constructor(private matDialog: MatDialog) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.currentToggleSelection = ToggleSelection.TEXT;
      if (!this.disableWysiwyg) {
        this.currentToggleSelection = ToggleSelection.WYSIWYG;
      }
      if (!this.disableHtml) {
        this.currentToggleSelection = ToggleSelection.HTML;
      }

      this.currentlyOnWysiwyg = false;
      if (this.currentToggleSelection === ToggleSelection.TEXT) {
        this.currentTextArea = this.textAceWrapper;
      } else if (this.currentToggleSelection === ToggleSelection.HTML) {
        this.currentTextArea = this.htmlAceWrapper;
      } else {
        this.currentlyOnWysiwyg = true;
      }
      this.isEmailSubjectType = this.dynamicContentType === DynamicContentTypes.EMAIL_SUBJECT;
    });
  }

  public onWysiwygChange(htmlCode: any) {
    this.wysiwygChange.emit(htmlCode);
    this.onChanged.emit(true);
  }

  public pasteTextAtCaretInWysiwyg(text: any) {
    this.wysiwygEditorComponent.pasteTextAtCaretInWysiwyg(text);
  }

  /**
   * Called when the subject changes
   */
  public onSubjectChange($event: any) {
    this.text.content = $event.target.value;
    this.onChange();
  }

  /**
   * Called when the model of a textarea changes
   */
  public onChange() {
    this[`${this.currentEditorMode}Change`].emit(this[this.currentEditorMode]);
    this.onChanged.emit(true);
  }

  /**
   * Called when focusing a textearea
   */
  public setTextArea(textArea: any) {
    this.currentTextArea = textArea;
  }

  public resize() {
    if (this.htmlAceWrapper) {
      this.htmlAceWrapper.resize();
    }
    if (this.textAceWrapper) {
      this.textAceWrapper.resize();
    }
  }

  public iframeEvent(event: IframeEvent, template?: string) {
    switch (event.type) {
      case IframeEventName.LOADED_WITH_ERRORS:
        console.warn('There was an error loading Template builder');
        break;
    }
  }

  public onWysiwygClicked(event: any) {
    this.onWysiwygClickedEmmiter.emit(event);
  }

  public customEventPreview(data: IframeEvent, template: string, idx: number) {
    switch (data.custom) {
      case 'content-builder-loaded':
        this.iframePreview.postMessage({
          type: IframeEventName.CUSTOM,
          custom: 'load-template-preview',
          data: this.html.content
        });
        break;
    }
  }

  public onToggleChange(e) {
    // Differ so DOM get's updated
    setTimeout(() => {
      this.currentTextArea = this[`${this.currentEditorMode}TextArea`];

      if (this.mode === ViewMode.EDIT && this.currentTextArea) {
        if (this.currentTextArea && this.currentTextArea.nativeElement) {
          this.currentTextArea.nativeElement.focus();
        } else {
          this.currentTextArea.getEditor().container.focus();
        }
      }

      this.currentlyOnWysiwyg = this.currentToggleSelection === ToggleSelection.WYSIWYG;
    }, 50);
  }

  public uploadFile($event: any) {
    const file: File = $event.target.files[0];
    if (file) {
      const editor = this[this.currentEditorMode];
      if (editor.maxSize && file.size > editor.maxSize) {
        this._showSizeValidationError(editor.maxSize);
      } else {
        const fileReader = new FileReader();
        fileReader.onload = e => {
          ConfirmDialog.open(
            this.matDialog,
            {
              title: `Load ${this.currentToggleSelection} content`,
              message: `You will override current ${this.currentToggleSelection} content with the content of "${file.name}. Are you sure?"`
            },
            () => {
              editor.content = fileReader.result as string;
              this.onChange();
            }
          );
        };
        fileReader.readAsText(file);
      }
    }
  }

  public validateSize(editor: EditorConfig): boolean {
    if (!editor.maxSize) {
      return true;
    }
    if (new Blob([editor.content]).size > editor.maxSize) {
      this._showSizeValidationError(editor);
      return false;
    }
    return true;
  }

  public isValid() {
    return this.validateSize(this.html) && this.validateSize(this.text);
  }

  public getMaxSizeMessage(maxSize: number, type: EditorTypes): string {
    return this.MAX_SIZE_MESSAGE.replace('<max_size>', `${Math.round(maxSize / 1024)}kb`).replace(
      '<editor_type>',
      type
    );
  }

  private _showSizeValidationError(editor: EditorConfig) {
    this.loadingComponent.showWarning(this.getMaxSizeMessage(editor.maxSize as number, editor.type));
  }

  private get currentEditorMode(): string {
    return this.currentToggleSelection === ToggleSelection.HTML ? 'html' : 'text';
  }
}
