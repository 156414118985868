import { Component, OnInit } from '@angular/core';
import { SideDrawersBaseComponent } from '../side-drawers-base/side-drawers-base.component';

@Component({
  selector: 'side-drawers-drawer',
  templateUrl: '../side-drawers-base/side-drawers-base.component.html',
  styleUrls: ['./side-drawers-drawer.component.scss']
})
export class SideDrawersDrawerComponent extends SideDrawersBaseComponent implements OnInit {
  constructor() {
    super();
  }

  /**
   * Whether the drawer is currently active.
   */
  isActive = false;

  ngOnInit() {}
}
