import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ButtonGroupComponent } from '@neptune/components/button-group/button-group.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { DropdownButtonModule } from '../dropdown-button/dropdown-button.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { MatDividerModule } from '@angular/material/divider';
import { MaterialModule } from '@neptune/material.module';

@NgModule({
  declarations: [ButtonGroupComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatButtonModule,
    MatMenuModule,
    DropdownButtonModule,
    DropdownModule,
    FormsModule,
    RouterModule,
    FuseDirectivesModule,
    MatDividerModule,
    FlexLayoutModule,
    MaterialModule
  ],
  exports: [ButtonGroupComponent]
})
export class ButtonGroupModule {}
