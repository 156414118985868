import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourceDialogComponent } from './resource-dialog.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from '@neptune/material.module';
import { LoadingModalModule } from '../loading-modal/loading-modal.module';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ResourceDialogContentComponent } from './resource-dialog-content/resource-dialog-content.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NeptunePipesModule } from '@neptune/pipes/pipes.module';
import { ResourceService } from '@neptune/services/resource.service';
import { InlineEditModule } from '../inline-edit/inline-edit.module';
import { ConfirmDialogModule } from '../confirm-dialog/confirm-dialog.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { UploadService } from '@neptune/services/upload.service';

@NgModule({
  declarations: [ResourceDialogComponent, ResourceDialogContentComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    MaterialModule,
    LoadingModalModule,
    NgxFileDropModule,
    NgxDatatableModule,
    NeptunePipesModule,
    InlineEditModule,
    ConfirmDialogModule,
    ClipboardModule
  ],
  exports: [ResourceDialogComponent, ResourceDialogContentComponent],
  providers: [UploadService, ResourceService]
})
export class ResourceDialogModule {}
