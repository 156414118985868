import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoadingModalComponent } from '@neptune/components/loading-modal/loading-modal.component';
import { Purl, PurlStatus } from '@neptune/models/purl';
import { Asset, AssetSelectorDialogFilter, AssetType } from '@neptune/models/asset';
import { Domain, DomainType } from '@neptune/models/domain';
import { DomainService } from '@neptune/services/domain.service';
import { forkJoin } from 'rxjs';
import { TableService, TablesObject } from '@neptune/services/table.service';
import { TableData, TableDataSortOrderType, UserData } from '@neptune/models';
import { LandingPageStatus } from '@neptune/models/landing-page';
import { map } from 'rxjs/operators';
import { PurlService } from '@neptune/services/purl.service';
import { AssetId } from '@neptune/models/journey';
import { AssetService } from '@neptune/services/asset.service';
import { AccountService } from '@neptune/services/account.service';

// NOTE: disabled until backend counterpart gets implemented
// eslint-disable-next-line no-shadow
export enum CollisionRadioOptions {
  AUTOINCREMENT = 'AUTOINCREMENT'
  // RANDOM = 'RANDOM',
  // PREFIX = 'PREFIX',
  // SUFFIX = 'SUFFIX',
}

export interface PurlEditInput extends Purl {
  edit: boolean;
}

@Component({
  selector: 'app-purl-dialog',
  templateUrl: './purl-dialog.html',
  styleUrls: ['./purl-dialog.scss'],
  encapsulation: ViewEncapsulation.None
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PurlDialog implements OnInit {
  @ViewChild(LoadingModalComponent, { static: true })
  protected loadingComponent: LoadingModalComponent;

  public crudForm: UntypedFormGroup;
  public CollisionRadioOptions = CollisionRadioOptions;

  public domainDisabled: boolean = true;
  public domainPlaceholder: string = 'Loading domains...';

  public domains: Domain[] = [];
  public AssetType = AssetType;
  public assetFilter: AssetSelectorDialogFilter = {
    /** AssetTypes to be used by filter */
    assetTypes: [AssetType.LANDING_PAGE],
    /** Status to be used by filter */
    status: [LandingPageStatus.PUBLISHED]
  };
  public landingPage: AssetId;
  purl: Purl;
  title: string = '';

  private _isEditing: boolean = false;
  public get isEditing(): boolean {
    return this._isEditing;
  }

  tableDisabled: boolean = false;
  tables: TableData[];
  autoCompleteDisabled: boolean = false;
  canBeApproved: boolean = false;
  userData: UserData;
  _tooltip: string = '';

  // Chips
  filteredColumnsOptions: string[];
  columns: string[] = [];
  allColumns: string[] = [];
  FILTER_STRING: string = 'PURL_';

  syntaxPreview: string = '';
  tablePreview: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private domainService: DomainService,
    private tableService: TableService,
    private purlService: PurlService,
    private assetService: AssetService,
    protected dialogRef: MatDialogRef<PurlDialog>,
    @Inject(MAT_DIALOG_DATA) public data: PurlEditInput
  ) {}

  ngOnInit() {
    if (this.data.edit) {
      this._isEditing = true;
      if (this.data.targetLandingPage) {
        this.assetService.getAssetById(this.data.targetLandingPage).subscribe({
          next: res => {
            this.landingPage = {
              id: this.data.targetLandingPage,
              name: res.name,
              assetType: AssetType.LANDING_PAGE
            };
            if (this.data.status === PurlStatus.DRAFT && res.tableName) {
              this._setAutocomplete(res.tableName);
            }
          },
          error: error => console.error(error)
        });
      }
      if (this.data.status === PurlStatus.DRAFT && this.data.baseTableName) {
        this._setAutocomplete(this.data.baseTableName);
      }
    }

    this.accountService
      .getCurrentUser()
      .then(userData => {
        this.userData = userData;
        this.accountService.getIsUserSystemManagement().then(systemManagement => {
          this.userData.systemManagement = systemManagement;

          const diffUser = this.userData.username !== this.data.insertedBy;
          const isSysAdmin = this.userData.systemManagement;

          this.canBeApproved = diffUser || isSysAdmin;
          this._tooltip = this.canBeApproved
            ? ''
            : 'PURL cannot be approved by their own creator, another admin must approve this PURL for launch';
        });
      })
      .catch(err => {});

    this.title = `${this._isEditing ? 'Edit' : 'New'} Purl`;

    this.crudForm = this.formBuilder.group({
      name: [
        {
          value: this.data.name || '',
          disabled: this.data.status === PurlStatus.APPROVED
        },
        [Validators.required, Validators.pattern(/^[\w-]+$/)]
      ],
      landing: [
        { value: this.data.targetLandingPage || '', disabled: !this.assetFilter.tableName },
        Validators.required
      ],
      domain: [
        {
          value: this.data.domainId || '',
          disabled: this.data.status === PurlStatus.APPROVED
        },
        Validators.required
      ],
      tableName: [
        {
          value: this.data.baseTableName || '',
          disabled: this.data.status === PurlStatus.APPROVED
        }
      ],
      expression: [
        {
          value: this.data.expression || '',
          disabled: true
        },
        [Validators.required, Validators.pattern(/^[\w-\{\}]+$/)]
      ],
      approved: [
        {
          value: (this.data.status && this.data.status === PurlStatus.APPROVED) || false,
          disabled: this.data.status === PurlStatus.APPROVED
        }
      ],
      collision: [
        {
          value:
            this.data.collisionDetection && this.data.collisionDetection.autoIncrement
              ? CollisionRadioOptions.AUTOINCREMENT
              : undefined, // RIGHT NOW THERE IS NO OTHER OPTION
          disabled: this.data.status === PurlStatus.APPROVED
        },
        Validators.required
      ]
    });

    this.crudForm
      .get('expression')
      ?.valueChanges.pipe(
        map((expression: string | null) => (expression ? this._filter(expression) : this.allColumns.slice()))
      )
      .subscribe(options => {
        this.filteredColumnsOptions = options;
      });

    this.crudForm.get('tableName')?.valueChanges.subscribe((tableId: string) => {
      if (tableId) {
        const tableName = this.tables ? this.tables?.find(t => t.Id === tableId)?.name : '';
        this.assetFilter = {
          ...this.assetFilter,
          tableName
        };
        this.toggleAssetSelector();
        const landing = this.crudForm.get('landing');
        if (landing?.value && landing.value.tableName !== tableName) {
          landing.setValue(null);
          this.data.projectId = null;
        }
        const expression = this.crudForm.get('expression');
        if (expression?.value) {
          expression.setValue(null);
        }
        this._setAutocomplete(tableId);
      } else {
        this.assetFilter.tableName = undefined;
        this.toggleAssetSelector();
      }
    });

    this.crudForm.valueChanges.subscribe(this._setSyntaxPreview.bind(this));

    this._getDomains();
    this._getTables();
  }

  onSubmit() {
    const form = this.crudForm.getRawValue();
    const purl = <Purl>{
      name: form.name,
      projectId: !this._isEditing ? form.landing.projectId : this.data.projectId,
      expression: form.expression,
      baseTableName: form.tableName,
      collisionDetection: {
        autoIncrement: true
      },
      domainId: form.domain.id,
      targetLandingPage: typeof form.landing === 'object' ? form.landing.id : this.data.targetLandingPage,
      status: form.approved ? PurlStatus.APPROVED : PurlStatus.DRAFT
    };
    this.loadingComponent.startLoading();
    const obs = this._isEditing
      ? this.purlService.editPurl({
          ...purl,
          id: encodeURIComponent(this.data.id)
        })
      : this.purlService.addPurl(purl);
    obs.subscribe({
      next: response => {
        this.dialogRef.close(response);
      },
      error: err => {
        let mssg: string = 'There was an error saving the PURL';
        if (err && err.error && err.error.message && err.error.message.length > 3) {
          mssg = err.error.message;
        }
        this.loadingComponent.showError(mssg);
        console.error(err);
      }
    });
  }

  onAssetSelected(purl: Purl, landing: Asset | null) {
    if (landing) {
      this.crudForm?.get('landing')?.setValue(landing);
      this.data.projectId = landing.projectId || '';
      const tableId = this.tables.find(t => t.name === landing.tableName)?.Id || '';
      this._setAutocomplete(tableId);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  showSnackbar() {
    this.loadingComponent.showSuccess('Copied to clipboard!');
  }

  getErrorMessage(field) {
    const control = this.crudForm.get(field);
    if (!control) {
      return;
    }
    if (field === 'name' && control.hasError('pattern')) {
      return 'Only letters, numbers, underscores and hyphens are allowed';
    }
    if (field === 'expression' && control.hasError('pattern')) {
      return 'Only numbers, letters, underscore and curly braces are allowed.';
    }
  }

  private _filter(expression: string): string[] {
    if (this.autoCompleteDisabled) {
      return [];
    }
    const filterBySelected = column => expression.indexOf(`{{${column}}}`) === -1;
    return this.allColumns.filter(filterBySelected);
  }

  private _setSyntaxPreview() {
    const form = this.crudForm.getRawValue();

    if (!form) {
      return;
    }

    const host = form.domain ? form.domain.url : '';
    const path = form.name || '';
    const custom =
      form.expression && this.tablePreview
        ? form.expression.replace(/{{([^}]*)}}/g, token => {
            const key = token.substr(2, token.length - 4);
            return this.tablePreview[key] || '';
          })
        : '';

    // NOTE: Figure out wether to encode or not
    // this.syntaxPreview = `${host}/${path}/${encodeURIComponent(custom)}`;
    this.syntaxPreview = `${host}/${path}/${custom}`;
  }

  private _setAutocomplete(tableId: string) {
    if (tableId) {
      forkJoin({
        tables: this.tableService.getTable(tableId),
        preview: this.tableService.getTablePreview(tableId)
      }).subscribe(res => {
        this.allColumns = res.tables.fields.map(f => f.name).filter(f => f !== this.FILTER_STRING);
        this.tablePreview = res.preview[0].reduce((acc, key, i) => {
          // If the preview has actual records in it, preview[1] should be defined
          if (res.preview[1]) {
            acc[key] = res.preview[1][i];
          }
          return acc;
        }, {});
        this.crudForm.get('expression')?.enable();
      });
    } else {
      this.allColumns = [];
      this.tablePreview = {};
    }
  }

  private _getDomains() {
    return this.domainService.getAllDomains().subscribe((result: Domain[]) => {
      this.domains = result
        .sort((a, b) => (a.name && b.name && a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1))
        .filter((d: Domain) => d.type === DomainType.PURL);
      this.domainPlaceholder = 'Choose a Domain';
      this.domainDisabled = false;
      if (this.data && this.data.domainId) {
        this.crudForm.controls.domain.setValue(this.domains.find(d => d.id === this.data.domainId));
      }
    });
  }

  private _getTables() {
    this.tableService
      .getTableList(null, false, null, true, null, TableDataSortOrderType.DESC)
      .subscribe((tablesObject: TablesObject) => {
        this.tables = tablesObject.Tables;
        if (this.data.baseTableName) {
          this.assetFilter.tableName = this.tables.find(t => t.Id === this.data.baseTableName)?.name;
          this.toggleAssetSelector();
        }
      });
  }

  private toggleAssetSelector() {
    if (!this.assetFilter.tableName) {
      this.crudForm.controls.landing.disable();
    } else {
      this.crudForm.controls.landing.enable();
    }
  }
}
