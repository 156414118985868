import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoadingModalService } from '../loading-modal/loading-modal.service';

@Component({
  selector: 'nep-loading-modal-global',
  templateUrl: './loading-modal-global.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingModalGlobalComponent {
  public loadingObservable = this.loadingModalService.loadingSubject;

  constructor(private loadingModalService: LoadingModalService) {}
}
