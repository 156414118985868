<div class="change-password-dialog">
  <loading-modal [loading]="loading"></loading-modal>

  <div mat-dialog-title class="dialog-header">
    <div class="dialog-title">
      <div fxLayout="row" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="header-title h2">{{ title }}</div>
        </div>
      </div>
    </div>
  </div>

  <span class="m-2"></span>

  <mat-dialog-content [formGroup]="resetPasswordForm" name="resetPasswordForm">
    <div class="description-container">
      <div fxLayout="column" fxLayoutAlign="space-between stretch">
        <mat-form-field class="current-pass">
          <input
            matInput
            placeholder="Current Password"
            [type]="hidecurrentpass ? 'password' : 'text'"
            formControlName="currentPassword"
          />
          <button
            mat-icon-button
            matSuffix
            (click)="hidecurrentpass = !hidecurrentpass"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hidecurrentpass"
          >
            <mat-icon class="password-eye">{{ hidecurrentpass ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error *ngIf="currentPassControl?.touched && currentPassControl?.errors">
            Current Password is required
          </mat-error>
        </mat-form-field>

        <app-reset-password
          [resetPasswordFormErrors]="resetPasswordFormErrors"
          [resetPasswordForm]="resetPasswordForm"
        ></app-reset-password>

        <div *ngIf="errorCode" class="message-error">
          <span class="material-icons error-icon mr-8">error_outline</span>
          <span>There was an error resseting your password.</span>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <div mat-dialog-actions fxLayoutAlign="end start" class="dialog-footer">
    <button mat-button color="primary" (click)="onClose()" class="cancel-btn">Cancel</button>
    <button mat-flat-button color="primary" (click)="onSave()" [disabled]="!resetPasswordForm.valid">Save</button>
  </div>
</div>
