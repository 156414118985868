<div>
  <loading-modal></loading-modal>
  <div mat-dialog-title class="dialog-header">
    <div class="dialog-title">
      <span class="h2">Aggregate Calculation for Field {{ data.tableSchema.name }}.{{ data.currentField }}</span>
    </div>
  </div>
  <mat-dialog-content>
    <div class="description-container">
      <div fxLayout="row">
        <div fxLayout="column" class="w-25-p">
          <mat-form-field appearance="fill" class="pr-20">
            <mat-label>Aggregate Function</mat-label>
            <mat-select [(ngModel)]="function" placeholder="Select Aggregate Function" [disabled]="!data.editable">
              <mat-option *ngFor="let currentFunction of possibleFunctions" [value]="currentFunction">
                {{ currentFunction }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="column" class="w-50-p">
          <mat-form-field appearance="fill">
            <mat-label>Field to Aggregate</mat-label>
            <input
              [value]="selectedField ? selectedField?.tableName + '.' + selectedField?.field?.name : ''"
              matInput
              disabled
            />
            <button
              mat-icon-button
              matSuffix
              class="btn-icon"
              aria-label="Add field"
              (click)="openFieldToAggregateDialog()"
              [disabled]="!data.editable"
            >
              <mat-icon class="color-gray">more_horiz</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div fxLayout="column" class="w-25-p"></div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <span h1>Filters:</span>
        <button mat-button color="primary" (click)="addNewFilter()" [disabled]="!data.editable">+ FILTER</button>
      </div>
      <mat-table
        *ngIf="filtersDataSource.length > 0"
        [class.border-bottom-none]="filtersDataSource.length >= 7"
        [dataSource]="filtersDataSource"
        fxLayout="column wrap"
        fxLayoutAlign="start stretch"
        #filtersTable
      >
        <!-- FieldId Column -->
        <ng-container matColumnDef="fieldId">
          <mat-cell *matCellDef="let element; let i = index" class="pl-10" [attr.disabled]="!data.editable">
            {{ element.tableName ? element.tableName + '.' + element.fieldId : '' }}
            <button
              mat-icon-button
              class="btn-icon ml-auto"
              aria-label="Add field"
              (click)="openSelectFieldDialog(element)"
              [disabled]="!data.editable"
            >
              <mat-icon class="color-gray">more_horiz</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <!-- Operator Column -->
        <ng-container matColumnDef="operator">
          <mat-cell *matCellDef="let element" class="border-left pl-10">
            <mat-select
              [(ngModel)]="element.operator"
              placeholder="Comparison Operator"
              [disabled]="!data.editable || !element.fieldId"
              (selectionChange)="onComparisonOperatorChange($event.value, element)"
            >
              <mat-option value="eq">
                <span>Equals</span>
              </mat-option>
              <mat-option value="not_eq">
                <span>Does not equal</span>
              </mat-option>
              <ng-container
                *ngIf="
                  getFieldDataType(element.fieldType?.toUpperCase()) === 'date' ||
                  getFieldDataType(element.fieldType?.toUpperCase()) === 'number' ||
                  element.fieldType === 'bigint'
                "
              >
                <mat-divider></mat-divider>
                <mat-option value="gt">
                  <span>Greater than</span>
                </mat-option>
                <mat-option value="ge">
                  <span>Greater than or equal to</span>
                </mat-option>
                <mat-option value="lt">
                  <span>Less than</span>
                </mat-option>
                <mat-option value="le">
                  <span>Less than or equal to</span>
                </mat-option>
                <mat-option value="bt">
                  <span>Between</span>
                </mat-option>
              </ng-container>
              <ng-container
                *ngIf="
                  getFieldDataType(element.fieldType) === 'string' ||
                  element.fieldType === 'varchar' ||
                  element.fieldType === 'tinytext'
                "
              >
                <mat-divider></mat-divider>
                <mat-option value="bw">
                  <span>Begins with</span>
                </mat-option>
                <mat-option value="not_bw">
                  <span>Does not begin with</span>
                </mat-option>
                <mat-option value="ew">
                  <span>Ends with</span>
                </mat-option>
                <mat-option value="not_ew">
                  <span>Does not end with</span>
                </mat-option>
                <mat-divider></mat-divider>
                <mat-option value="ct">
                  <span>Contains</span>
                </mat-option>
                <mat-option value="not_ct">
                  <span>Does not contain</span>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-cell>
        </ng-container>

        <!-- ValueDef Column -->
        <ng-container matColumnDef="valueDef">
          <mat-cell *matCellDef="let element; let i = index" class="border-left pr-0" [attr.disabled]="!data.editable">
            <span class="d-flex expression-inputs">
              <span class="v-center" [ngClass]="element.operator === 'bt' ? 'w-50-p' : 'w-100-p'">
                <input
                  *ngIf="getFieldDataType(element.fieldType) !== 'date' && element.valueDef[0]?.valueType === 'literal'"
                  class="w-100-p pl-10"
                  type="text"
                  [disabled]="!element.operator || !data.editable"
                  [(ngModel)]="element.valueDef[0].filterValue"
                />
                <long-text
                  *ngIf="element.valueDef[0].valueType === 'expression'"
                  class="w-100-p pl-10"
                  [value]="element.valueDef[0].text"
                ></long-text>
                <span
                  *ngIf="getFieldDataType(element.fieldType) === 'date' && element.valueDef[0]?.valueType === 'literal'"
                  class="d-flex w-100-p pl-10"
                >
                  <input
                    matInput
                    [matDatepicker]="fromDate"
                    (focus)="fromDate.open()"
                    placeholder="MM/DD/YYYY"
                    [disabled]="!element.operator || !data.editable"
                    [(ngModel)]="element.valueDef[0].filterValue"
                  />
                  <mat-datepicker #fromDate></mat-datepicker>
                </span>
                <button
                  mat-icon-button
                  class="btn-icon ml-auto"
                  [disabled]="!element.operator || !data.editable"
                  aria-label="Add expression"
                  (click)="openExpressionDialog(element, 0)"
                >
                  <mat-icon [svgIcon]="'formula'"></mat-icon>
                </button>
              </span>
              <span *ngIf="element.operator === 'bt'" class="v-center w-50-p border-left">
                <input
                  *ngIf="getFieldDataType(element.fieldType) !== 'date' && element.valueDef[1]?.valueType === 'literal'"
                  class="w-100-p pl-10"
                  type="text"
                  [disabled]="!element.operator || !data.editable"
                  [(ngModel)]="element.valueDef[1].filterValue"
                />
                <long-text
                  *ngIf="element.valueDef[1]?.valueType === 'expression'"
                  class="w-100-p pl-10"
                  [value]="element.valueDef[1]?.text"
                ></long-text>
                <span
                  *ngIf="getFieldDataType(element.fieldType) === 'date' && element.valueDef[1]?.valueType === 'literal'"
                  class="d-flex w-100-p pl-10"
                >
                  <input
                    matInput
                    [matDatepicker]="toDate"
                    (focus)="toDate.open()"
                    placeholder="MM/DD/YYYY"
                    [disabled]="!element.operator || !data.editable"
                    [(ngModel)]="element.valueDef[1].filterValue"
                  />
                  <mat-datepicker #toDate></mat-datepicker>
                </span>
                <button
                  mat-icon-button
                  class="btn-icon ml-auto"
                  [disabled]="!element.operator || !data.editable"
                  aria-label="Add expression"
                  (click)="openExpressionDialog(element, 1)"
                >
                  <mat-icon [svgIcon]="'formula'"></mat-icon>
                </button>
              </span>
            </span>
            <div class="border-left">
              <button
                mat-icon-button
                class="btn-icon"
                (click)="deleteFilter(i)"
                aria-label="Delete"
                [disabled]="!data.editable"
              >
                <mat-icon [svgIcon]="'reject_outline'"></mat-icon>
              </button>
            </div>
          </mat-cell>
        </ng-container>

        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <mat-table
        *ngIf="filtersDataSource.length === 0"
        [dataSource]="[{ message: 'No filters' }]"
        fxLayout="column wrap"
        fxLayoutAlign="start stretch"
      >
        <ng-container matColumnDef="message">
          <mat-cell *matCellDef="let element" [attr.disabled]="!data.editable">
            <p class="text-italic">{{ element.message }}</p>
          </mat-cell>
        </ng-container>

        <mat-row *matRowDef="let row; columns: ['message']" class="border-bottom-none"></mat-row>
      </mat-table>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayoutAlign="end start" class="dialog-footer" fxLayout="row nowrap">
    <button mat-button color="primary" (click)="onCancel()" class="cancel-btn">Cancel</button>
    <button mat-flat-button color="primary" (click)="onSave()" [disabled]="!data.editable || !formIsValid()">
      {{ data.transformation ? 'Save' : 'Create' }}
    </button>
  </mat-dialog-actions>
</div>
