import { Injectable } from '@angular/core';
import { NavigationExtras, NavigationStart, Router, Event } from '@angular/router';
import { of, Subject } from 'rxjs';
import { merge } from 'lodash';
import { filter } from 'rxjs/operators';

export interface NavigatableComponent {
  setNavigateState: (state: any) => void;
  getNavigateState: () => any;
  componentType: string;
}

@Injectable({
  providedIn: 'root'
})
export class NavigationQueueService {
  // eslint-disable-next-line @typescript-eslint/ban-types
  private pendingComponentInitalization: Function | null;
  private navigationQueue: Queue<NavigationQueueElement> = new Queue<NavigationQueueElement>();
  private currentComponent: NavigatableComponent | undefined;

  /**
   * Subject to notify when the navigation queue is enabled.
   */
  public _navigationStatusChanged: Subject<boolean> = new Subject();

  /**
   * For use with jasmine testing, returns a mock of service
   */
  public static mockService(): any {
    return {
      navigationStatusChanged: jest.fn(() => of(false)),
      navigateTo: jest.fn(() => of(true))
    };
  }

  constructor(private router: Router) {
    this.onError = this.onError.bind(this);

    // Listen to events from router to identify back forward from browser
    // TODO: This needs a more complex logic to check what happends when user
    // clicks forward or he manually changes url ( where we are only clearing queue)
    // We should consider a more complex queue where we have history of the queue and move with a
    // index inside that list.
    router.events
      .pipe(filter((event: Event) => event instanceof NavigationStart))
      .subscribe((event: Event) => {
        const navigationStart = event as NavigationStart;
        // Only if it's from the browser
        if (navigationStart.navigationTrigger === 'popstate') {
          // clear first / of the url and remove whats after ?...
          const gotoUrl: string = navigationStart.url.slice(1).split('?')[0];

          if (
            navigationStart.restoredState && // If its from back / forward (not manual url change)
            navigationStart.url && // if there is a url (should never be empty, just in case
            !this.emptyQueue() && // If there is an actual queue
            this.navigationQueue.peek()?.currentCommands[0].indexOf(gotoUrl) >= 0 // if it matches top element of queue
          ) {
            if (
              this.currentComponent &&
              this.navigationQueue.peek() &&
              this.currentComponent?.componentType === this.navigationQueue.peek()?.component.componentType
            ) {
              // Make sure the jump already happened and the current component is the new to navigate and not the old one.
              this.afterBackDone();
            } else {
              // This needs to be done after setActiveComponent is called.
              this.pendingComponentInitalization = this.afterBackDone;
            }
          } else {
            this.clearQueue();
          }
        }
      });
  }

  public navigationStatusChanged(): Subject<boolean> {
    return this._navigationStatusChanged;
  }
  /**
   * Navigates to a new route, saves state and enables return option
   *
   * @param commands is a delta to be applied to the current URL
   * or the one provided in the `relativeTo` property of the last parameter (the
   * `NavigationExtras`)
   * @param component The current main page component who is doing the call (probably 'this')
   * @param navigationExtra object with several options: relativeTo, skipLocationChange, queryParams, etc..
   */
  public navigateTo<T>(commands: any[], component: NavigatableComponent, navigationExtra?: NavigationExtras) {
    // if its the first navigation use queryParams from url, otherwise use the navExtra that user send before.
    const currentNavExtra: NavigationExtras = !this.navigationQueue.empty()
      ? (this.navigationQueue.peek()?.nextNavigationExtra as NavigationExtras)
      : {
          queryParams: this.router.parseUrl(this.router.url).queryParams
        };

    // if it's the first navigation use route from url, otherwise use the commands that the user send before.
    const currentCommands: any[] = !this.navigationQueue.empty()
      ? (this.navigationQueue.peek()?.nextCommands as any[])
      : [this.router.url.split('?')[0]];

    // Navigate to new route and wait to finish
    this.router.navigate(commands, navigationExtra).then((result: boolean) => {
      if (result) {
        // Queue new NavigationQueueElement (only if it was successful)
        this.navigationQueue.push({
          component,
          currentState: component?.getNavigateState(),
          currentCommands,
          currentNavigationExtra: currentNavExtra,
          nextCommands: commands,
          nextNavigationExtra: navigationExtra
        });

        // If queue is not empty, notify navigation Status Change (to show <-- )
        if (!this.navigationQueue.empty()) {
          this.navigationStatusChanged().next(true);
        }
      }
    }, this.onError);
  }

  public navigateWithoutQueue(commands: any[], navigationExtra?: NavigationExtras): Promise<boolean> {
    return this.router.navigate(commands, navigationExtra);
  }

  /**
   * Goes back to the previous navigated page.
   */
  public back() {
    // Peek last NavigationQueueElement
    const navig: NavigationQueueElement = this.navigationQueue.peek() as NavigationQueueElement;

    // Navigate to previous route, with config from Navigation Queue Element
    this.router.navigate(navig.currentCommands, navig.currentNavigationExtra).then(
      (result: boolean) => {
        this.afterBackDone();
      },
      err => {
        console.warn('There was an error on navigate', err);
      }
    );
  }

  private afterBackDone() {
    const navig: NavigationQueueElement = this.navigationQueue.peek() as NavigationQueueElement;

    // Only if navigation was successful remove from queue
    this.navigationQueue.pop();
    // If queue is empty notify so <-- can be hidden
    if (this.navigationQueue.empty()) {
      this.navigationStatusChanged().next(false);
    }

    // If there is a stored state and components type matches, set state.
    if (this.currentComponent && navig && this.currentComponent?.componentType === navig.component?.componentType) {
      this.currentComponent.setNavigateState(navig.currentState);
      this.currentComponent = undefined;
    }
  }

  /**
   * If queue is not empty go back.
   * Else navigate to specific command.
   */
  public backOrNavigate(commands: any[], extras?: NavigationExtras) {
    if (this.emptyQueue()) {
      this.router.navigate(commands, extras);
    } else {
      this.back();
    }
  }

  /**
   * Fully Empty Queue.
   */
  public emptyQueue(): boolean {
    return this.navigationQueue.empty();
  }

  /**
   *  Clear all queue, this will remove the <-- option.
   */
  public clearQueue() {
    this.navigationQueue.clearAll();
    this.navigationStatusChanged().next(false);
  }

  /**
   * From Fuse Content Component this is notifying when new Component Page arrives.
   */
  public onActivateComponent(component) {
    if ('componentType' in component) {
      this.currentComponent = component as NavigatableComponent;
      if (this.pendingComponentInitalization) {
        this.pendingComponentInitalization();
        this.pendingComponentInitalization = null;
      }
    }
  }

  /**
   * From Fuse Content Component this is notifying when current Component Page is removed.
   */
  public onDeactivateComponent(component) {
    this.currentComponent = undefined;
  }

  /**
   * Update extra params for top of the queue
   * If route params are updated by any code manually , this needs to be updated
   */
  public updateNavigationExtras(params: NavigationExtras) {
    (this.navigationQueue.peek() as NavigationQueueElement).nextNavigationExtra = merge(
      this.navigationQueue.peek()?.nextNavigationExtra,
      params
    );
  }

  private onError(error) {
    console.warn('There was an error on navigate');
  }
}

/**
 * Standard push / pop one direction queue, with clearAll, peek and empty
 */
class Queue<T> {
  _store: T[] = [];

  push(val: T) {
    this._store.push(val);
  }

  pop(): T | undefined {
    return this._store.pop();
  }

  peek(): T | undefined {
    return this._store[this._store.length - 1];
  }

  clearAll(): void {
    this._store = [];
  }

  empty(): boolean {
    return this._store.length === 0;
  }
}

/**
 * Interface to be used in as element for the navigation Queue
 *
 * component: component who makes the jump to restore it.
 *
 * currentCommands: the current path definition when jump was requested
 * currentState: Saved state to be restored latter
 * currentNavigationExtra?: some extra navigation info if needed (query params or something like that)
 *
 * nextCommands: the actual path definition to jump to
 * nextNavigationExtra?: the extra params used to call next page.
 */
interface NavigationQueueElement {
  component: NavigatableComponent;

  currentCommands: any[];
  currentState: any;
  currentNavigationExtra?: NavigationExtras;

  nextCommands: any[];
  nextNavigationExtra?: NavigationExtras;
}
