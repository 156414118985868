import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormCanvasElementType } from '@neptune/models/form';
import {
  FormCanvasElement,
  FormCanvasElementWithOptions,
  FormCanvasSubmitResetButtons
} from '../../../factories/form-canvas-elements';

@Component({
  selector: 'nel-form-preview-element',
  templateUrl: './form-preview-element.component.html'
})
export class FormPreviewElementComponent {
  @Input() element: FormCanvasElement | FormCanvasElementWithOptions | FormCanvasSubmitResetButtons;
  @Input() formGroup: UntypedFormGroup;
  @Output() resetPreview = new EventEmitter();

  public FormCanvasElementType = FormCanvasElementType;

  onReset() {
    this.resetPreview.emit();
  }
}
