import * as moment from 'moment-timezone';
import { DateTime, DateTimeForForm } from '@neptune/models/schedule';
import { Duration } from 'moment';
import { environment } from 'environments/environment';

const _timezoneConstants = {
  timezones: [
    'Pacific/Midway',
    'Pacific/Honolulu',
    'America/Juneau',
    'America/Dawson',
    'America/Boise',
    'America/Chihuahua',
    'America/Phoenix',
    'America/Chicago',
    'America/Regina',
    'America/Mexico_City',
    'America/Belize',
    'America/New_York',
    'America/Indiana/Indianapolis',
    'America/Bogota',
    'America/Glace_Bay',
    'America/Caracas',
    'America/Santiago',
    'America/St_Johns',
    'America/Sao_Paulo',
    'America/Argentina/Buenos_Aires',
    'America/Godthab',
    'Atlantic/Azores',
    'Atlantic/Cape_Verde',
    'Africa/Casablanca',
    'Atlantic/Canary',
    'Europe/Belgrade',
    'Europe/Sarajevo',
    'Europe/Brussels',
    'Europe/Amsterdam',
    'Africa/Algiers',
    'Europe/Bucharest',
    'Africa/Cairo',
    'Europe/Helsinki',
    'Europe/Athens',
    'Asia/Jerusalem',
    'Africa/Harare',
    'Europe/Moscow',
    'Asia/Kuwait',
    'Africa/Nairobi',
    'Asia/Baghdad',
    'Asia/Tehran',
    'Asia/Dubai',
    'Asia/Baku',
    'Asia/Kabul',
    'Asia/Yekaterinburg',
    'Asia/Karachi',
    'Asia/Kolkata',
    'Asia/Kathmandu',
    'Asia/Dhaka',
    'Asia/Colombo',
    'Asia/Almaty',
    'Asia/Rangoon',
    'Asia/Bangkok',
    'Asia/Krasnoyarsk',
    'Asia/Shanghai',
    'Asia/Kuala_Lumpur',
    'Asia/Taipei',
    'Australia/Perth',
    'Asia/Irkutsk',
    'Asia/Seoul',
    'Asia/Tokyo',
    'Asia/Yakutsk',
    'Australia/Darwin',
    'Australia/Adelaide',
    'Australia/Sydney',
    'Australia/Brisbane',
    'Australia/Hobart',
    'Asia/Vladivostok',
    'Pacific/Guam',
    'Asia/Magadan',
    'Pacific/Fiji',
    'Pacific/Auckland',
    'Pacific/Tongatapu'
  ],
  i18n: {
    'Pacific/Midway': 'Midway Island, Samoa',
    'Pacific/Honolulu': 'Hawaii',
    'America/Juneau': 'Alaska',
    'America/Dawson': 'Pacific Time (US and Canada); Tijuana',
    'America/Boise': 'Mountain Time (US and Canada)',
    'America/Chihuahua': 'Chihuahua, La Paz, Mazatlan',
    'America/Phoenix': 'Arizona',
    'America/Chicago': 'Central Time (US and Canada)',
    'America/Regina': 'Saskatchewan',
    'America/Mexico_City': 'Guadalajara, Mexico City, Monterrey',
    'America/Belize': 'Central America',
    'America/New_York': 'Eastern Time (US and Canada)',
    'America/Indiana/Indianapolis': 'Indiana (East)',
    'America/Bogota': 'Bogota, Lima, Quito',
    'America/Glace_Bay': 'Atlantic Time (Canada)',
    'America/Caracas': 'Caracas, La Paz',
    'America/Santiago': 'Santiago',
    'America/St_Johns': 'Newfoundland and Labrador',
    'America/Sao_Paulo': 'Brasilia',
    'America/Argentina/Buenos_Aires': 'Buenos Aires, Georgetown',
    'America/Godthab': 'Greenland',
    'Atlantic/Azores': 'Azores',
    'Atlantic/Cape_Verde': 'Cape Verde Islands',
    'Africa/Casablanca': 'Casablanca, Monrovia',
    'Atlantic/Canary': 'Canary Islands',
    'Europe/Belgrade': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    'Europe/Sarajevo': 'Sarajevo, Skopje, Warsaw, Zagreb',
    'Europe/Brussels': 'Brussels, Copenhagen, Madrid, Paris',
    'Europe/Amsterdam': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    'Africa/Algiers': 'West Central Africa',
    'Europe/Bucharest': 'Bucharest',
    'Africa/Cairo': 'Cairo',
    'Europe/Helsinki': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
    'Europe/Athens': 'Athens, Istanbul, Minsk',
    'Asia/Jerusalem': 'Jerusalem',
    'Africa/Harare': 'Harare, Pretoria',
    'Europe/Moscow': 'Moscow, St. Petersburg, Volgograd',
    'Asia/Kuwait': 'Kuwait, Riyadh',
    'Africa/Nairobi': 'Nairobi',
    'Asia/Baghdad': 'Baghdad',
    'Asia/Tehran': 'Tehran',
    'Asia/Dubai': 'Abu Dhabi, Muscat',
    'Asia/Baku': 'Baku, Tbilisi, Yerevan',
    'Asia/Kabul': 'Kabul',
    'Asia/Yekaterinburg': 'Ekaterinburg',
    'Asia/Karachi': 'Islamabad, Karachi, Tashkent',
    'Asia/Kolkata': 'Chennai, Kolkata, Mumbai, New Delhi',
    'Asia/Kathmandu': 'Kathmandu',
    'Asia/Dhaka': 'Astana, Dhaka',
    'Asia/Colombo': 'Sri Jayawardenepura',
    'Asia/Almaty': 'Almaty, Novosibirsk',
    'Asia/Rangoon': 'Yangon Rangoon',
    'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
    'Asia/Krasnoyarsk': 'Krasnoyarsk',
    'Asia/Shanghai': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
    'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore',
    'Asia/Taipei': 'Taipei',
    'Australia/Perth': 'Perth',
    'Asia/Irkutsk': 'Irkutsk, Ulaanbaatar',
    'Asia/Seoul': 'Seoul',
    'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
    'Asia/Yakutsk': 'Yakutsk',
    'Australia/Darwin': 'Darwin',
    'Australia/Adelaide': 'Adelaide',
    'Australia/Sydney': 'Canberra, Melbourne, Sydney',
    'Australia/Brisbane': 'Brisbane',
    'Australia/Hobart': 'Hobart',
    'Asia/Vladivostok': 'Vladivostok',
    'Pacific/Guam': 'Guam, Port Moresby',
    'Asia/Magadan': 'Magadan, Solomon Islands, New Caledonia',
    'Pacific/Fiji': 'Fiji Islands, Kamchatka, Marshall Islands',
    'Pacific/Auckland': 'Auckland, Wellington',
    'Pacific/Tongatapu': `Nuku'alofa`
  }
};

export interface Timezone {
  name: string;
  formattedName: string;
  offset: number;
  formattedOffset: string;
}

export class TimezoneUtils {
  public static defaultTimezone: string = 'America/New_York';

  public static set currentTimezoneName(tz: string) {
    TimezoneUtils._currentTimezone = TimezoneUtils.timezoneNameToTimezone(tz ? tz : TimezoneUtils.defaultTimezone);
  }

  public static get currentTimezone(): Timezone {
    return this._currentTimezone;
  }
  public static timezones(): Timezone[] {
    return moment.tz
      .names()
      .filter(tz => _timezoneConstants.timezones.includes(tz))
      .map(tz => TimezoneUtils.timezoneNameToTimezone(tz))
      .sort((a, b) => a.offset - b.offset);
  }

  public static timezoneNameToTimezone(tz: string): Timezone {
    return {
      name: tz,
      formattedName: _timezoneConstants.i18n[tz],
      offset: moment.tz(tz).utcOffset(),
      formattedOffset: moment.tz(tz).format('Z')
    };
  }

  // Backwards compatibility, originally users do not have timezone defined,
  // so we set this to default one so UI does not crash when asking current TZ
  private static _currentTimezone: Timezone = TimezoneUtils.timezoneNameToTimezone(TimezoneUtils.defaultTimezone);

  /**
   * Convert UTC format string into DateTime
   */
  public static utcToDateTime(utc: string): DateTime | undefined {
    if (!utc || utc === '') {
      console.warn('Converting empty UTC to DateTime');
      return;
    }
    const datetime: moment.Moment = moment.tz(new Date(utc), TimezoneUtils._currentTimezone.name);
    return <DateTime>{
      date: datetime.format('MM/DD/YYYY'),
      time: datetime.format('hh:mm a')
    };
  }

  /**
   * Convert UTC formatted string into moment, with timezone adjustment
   */
  public static utcToMoment(utc: string): moment.Moment | undefined {
    if (!utc || utc === '') {
      console.warn('Converting empty UTC to moment');
      return;
    }
    return moment.tz(new Date(utc), TimezoneUtils._currentTimezone.name);
  }

  /**
   * Convert moment to UTC string
   */
  public static momentToUTC(m: moment.Moment): string {
    return moment.utc(m).format();
  }

  /**
   * Convert DateTime object to moment
   */
  public static dateTimeToMoment(dateTime: DateTime): moment.Moment {
    const m: moment.Moment = moment.tz(dateTime.date, 'MM/DD/YYYY', TimezoneUtils.currentTimezone.name);
    if (dateTime.time) {
      const t: moment.Moment = moment.tz(dateTime.time, 'hh:mm a', TimezoneUtils.currentTimezone.name);
      m.hour(t.hour());
      m.minute(t.minute());
    }
    return m;
  }

  /**
   * Convert DateTime to formats required by forms.
   * Date as MM/DD/YYYY string returns as moment.
   * Time from am/pm to military.
   */
  public static convertDateTimeForForm(dateTime: DateTime): DateTimeForForm {
    return {
      date: moment.tz(dateTime.date, 'MM/DD/YYYY', TimezoneUtils.currentTimezone.name),
      time: moment.tz(dateTime.time as string, 'hh:mm a', TimezoneUtils.currentTimezone.name).format('HH:mm')
    };
  }

  /**
   * Convert DateTime to UTC format string
   */
  public static dateTimeToUTCString(dateTime: DateTime): string {
    const dt: moment.Moment = moment.tz(
      dateTime.date + ' ' + dateTime.time,
      'MM/DD/YYYY hh:mm a',
      TimezoneUtils.currentTimezone.name
    );
    // convert to UTC
    return moment.utc(dt).format();
  }

  /**
   * Convert to DateTime object, takes UTC string, Date
   */
  public static convertToDateTime(date: string | DateTime | Date): DateTime {
    if (typeof date === 'string') {
      return TimezoneUtils.utcToDateTime(date as string) as DateTime;
    } else if ((<DateTime>date).date) {
      return <DateTime>date;
    } else {
      return TimezoneUtils.utcToDateTime((<Date>date).toLocaleString()) as DateTime;
    }
  }

  /**
   * Calculates min startTime as NOW + default offset.
   */
  public static getTimeWithOffset(offset: number = environment.defaultScheduleTimeOffset): string {
    return moment().tz(TimezoneUtils.currentTimezone.name).add(offset, 'm').format('HH:mm');
  }

  /**
   * Is giving Date the same day as today
   */
  public static isDateToday(d: Date | moment.Moment): boolean {
    return this.stringifyDate(d) === this.stringifyDate(new Date());
  }

  /**
   * From a Date, returns a MM/DD/YYYY string.
   */
  public static stringifyDate(d: Date | moment.Moment): string {
    return moment.isMoment(d)
      ? d.format('MM/DD/YYYY')
      : moment.tz(d, TimezoneUtils.currentTimezone.name).format('MM/DD/YYYY');
  }

  /**
   * From a Date, returns a MM/DD/YYYY string.
   */
  public static formatDate(d: Date): string {
    return moment.tz(d, TimezoneUtils.currentTimezone.name).format('MM/DD/YYYY');
  }

  public static formatDateWithoutTimeZone(d: Date): string {
    return moment(d).format('MM/DD/YYYY');
  }

  public static formatTime(time: string): string {
    return moment.tz(time, 'HH:mm', true, TimezoneUtils.currentTimezone.name).format('hh:mm a');
  }

  public static createMoment(date?, format?, strict?): moment.Moment {
    const timezone = TimezoneUtils.currentTimezone.name;
    if (date !== undefined && format !== undefined && strict !== undefined) {
      return moment.tz(date, format, strict, timezone);
    } else if (date !== undefined && format !== undefined) {
      return moment.tz(date, format, timezone);
    } else if (date !== undefined) {
      return moment.tz(date, timezone);
    } else {
      return moment.tz(timezone);
    }
  }

  public static durationBetweenTimes(startTime: string, endTime: string, format: string = 'hh:mm a'): Duration {
    const start = TimezoneUtils.createMoment(startTime, format);
    const end = TimezoneUtils.createMoment(endTime, format);
    return moment.duration(end.diff(start));
  }
}
