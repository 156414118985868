import {
  Component,
  AfterContentInit,
  ContentChildren,
  QueryList,
  ContentChild,
  Input,
  Output,
  EventEmitter,
  AfterContentChecked,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { Subscription } from 'rxjs';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { SideDrawersDrawerComponent } from './side-drawers-drawer/side-drawers-drawer.component';
import { SideDrawersContentComponent } from './side-drawers-content/side-drawers-content.component';

/** A simple change event emitted on focus or selection changes. */
export class DrawerChangeEvent {
  /** Index of the currently-selected tab. */
  index: number;
  /** Reference to the currently-selected tab. */
  drawer: SideDrawersDrawerComponent;
}

// eslint-disable-next-line no-shadow
export enum DrawerPosition {
  start = 'start',
  end = 'end'
}

// eslint-disable-next-line no-shadow
export enum DrawerMode {
  side = 'side',
  over = 'over',
  push = 'push'
}

@Component({
  selector: 'side-drawers-toggler',
  templateUrl: './side-drawers-toggler.component.html',
  styleUrls: ['./side-drawers-toggler.component.scss']
})
export class SideDrawersComponent implements AfterContentInit, AfterContentChecked, OnDestroy {
  /** Wether the drawer should be opened or not */
  @Input('opened') opened: boolean = true;

  /** Where should the drawers align, start or end of screen. Default start */
  @Input('position') position: DrawerPosition = DrawerPosition.start;

  /** Which mode the drawer should be: over, push or side. Default over */
  @Input('mode') mode: DrawerMode = DrawerMode.over;

  /** Should the drawer dispplay a backdrop. Default true */
  @Input('hasBackdrop') hasBackdrop: boolean = true;

  /** Subscription to tabs being added/removed. */
  private _tabsSubscription = Subscription.EMPTY;

  /** A QueryList populated with SideDrawersDrawerComponent */
  @ContentChildren(SideDrawersDrawerComponent) drawers: QueryList<SideDrawersDrawerComponent>;

  /** An instance of SideDrawersContentComponent */
  @ContentChild(SideDrawersContentComponent) content: SideDrawersContentComponent;

  /** The drawer index that should be selected after the content has been checked. */
  private _indexToSelect: number | null = 0;

  /** The index of the active drawer. */
  @Input()
  get selectedIndex(): number | null {
    return this._selectedIndex;
  }
  set selectedIndex(value: number | null) {
    this._indexToSelect = coerceNumberProperty(value, null);
  }
  private _selectedIndex: number | null = null;

  get activeDrawer(): SideDrawersDrawerComponent {
    return this.drawers.find((drawer: SideDrawersDrawerComponent) => drawer.isActive) as SideDrawersDrawerComponent;
  }

  /** Output to enable support for two-way binding on `[(selectedIndex)]` */
  @Output() readonly selectedIndexChange: EventEmitter<number> = new EventEmitter<number>();

  /** Event emitted when the body animation has completed */
  @Output() readonly animationDone: EventEmitter<void> = new EventEmitter<void>();

  /** Event emitted when the drawer selection has changed. */
  @Output() readonly selectedTabChange: EventEmitter<DrawerChangeEvent> = new EventEmitter<DrawerChangeEvent>(true);

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  /**
   * After the content is checked, this component knows what tabs have been defined
   * and what the selected index should be. This is where we can know exactly what position
   * each tab should be in according to the new selected index, and additionally we know how
   * a new selected tab should transition in (from the left or right).
   */
  ngAfterContentChecked() {
    // Don't clamp the `indexToSelect` immediately in the setter because it can happen that
    // the amount of tabs changes before the actual change detection runs.
    const indexToSelect = (this._indexToSelect = this._clampTabIndex(this._indexToSelect));

    // If there is a change in selected index, emit a change event. Should not trigger if
    // the selected index has not yet been initialized.
    if (this._selectedIndex !== indexToSelect) {
      const isFirstRun = this._selectedIndex == null;

      if (!isFirstRun) {
        this.selectedTabChange.emit(this._createChangeEvent(indexToSelect));
      }

      // Changing these values after change detection has run
      // since the checked content may contain references to them.
      Promise.resolve().then(() => {
        this.drawers.forEach((tab, index) => (tab.isActive = index === indexToSelect));

        if (!isFirstRun) {
          this.selectedIndexChange.emit(indexToSelect);
        }
      });
    }

    if (this._selectedIndex !== indexToSelect) {
      this._selectedIndex = indexToSelect;
      this._changeDetectorRef.markForCheck();
    }
  }

  ngAfterContentInit() {
    // this._subscribeToTabLabels();

    // Subscribe to changes in the amount of tabs, in order to be
    // able to re-render the content as new tabs are added or removed.
    this._tabsSubscription = this.drawers.changes.subscribe(() => {
      const indexToSelect = this._clampTabIndex(this._indexToSelect);

      // Maintain the previously-selected tab if a new tab is added or removed and there is no
      // explicit change that selects a different tab.
      if (indexToSelect === this._selectedIndex) {
        const tabs = this.drawers.toArray();

        for (let i = 0; i < tabs.length; i++) {
          if (tabs[i].isActive) {
            // Assign both to the `_indexToSelect` and `_selectedIndex` so we don't fire a changed
            // event, otherwise the consumer may end up in an infinite loop in some edge cases like
            // adding a tab within the `selectedIndexChange` event.
            this._indexToSelect = this._selectedIndex = i;
            break;
          }
        }
      }

      // this._subscribeToTabLabels();
      this._changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy() {
    this._tabsSubscription.unsubscribe();
    // this._tabLabelSubscription.unsubscribe();
  }

  /** Clamps the given index to the bounds of 0 and the tabs length. */
  private _clampTabIndex(index: number | null): number {
    // Note the `|| 0`, which ensures that values like NaN can't get through
    // and which would otherwise throw the component into an infinite loop
    // (since Math.max(NaN, 0) === NaN).
    return Math.min(this.drawers.length - 1, Math.max(index || 0, 0));
  }

  private _createChangeEvent(index: number): DrawerChangeEvent {
    const event = new DrawerChangeEvent();
    event.index = index;
    if (this.drawers && this.drawers.length) {
      event.drawer = this.drawers.toArray()[index];
    }
    return event;
  }
}
