<div class="expression-editor-input" #container fxLayout="column" fxLayoutAlign="start stretch">
  <loading-modal></loading-modal>
  <ace-editor [readOnly]="!editable" [(text)]="value" #editor></ace-editor>
  <mat-error *ngIf="ngControl?.errors?.required">Expression can't be empty</mat-error>
  <mat-error *ngIf="!ngControl?.errors?.required && ngControl?.errors?.expression">
    {{ ngControl.errors.expression }}
  </mat-error>
  <div fxFlex class="mt-8" fxLayout="row" fxLayoutAlign="space-between stretch">
    <div fxLayout="column" fxLayoutAlign="start stretch">
      <mat-tab-group
        fxFlex
        (selectedIndexChange)="clickTab($event)"
        [selectedIndex]="indexGroupTab"
        animationDuration="0ms"
      >
        <mat-tab label="FUNCTIONS">
          <div fxLayout="row" class="h-100-p">
            <div fxFlex="37">
              <div class="can-scroll functions-type">
                <mat-list>
                  <mat-list-item
                    *ngFor="let group of functionGroup; let i = index"
                    [ngClass]="{ 'function-selected': selectedIdx === i }"
                  >
                    <h3 matLine (click)="onSelectedFuncGroup(i, group)" title="{{ group.name }} ">
                      {{ group.name }}
                    </h3>
                  </mat-list-item>
                </mat-list>
              </div>
            </div>
            <div fxFlex="63">
              <div class="can-scroll functions-type">
                <mat-list *ngIf="functionGroupSelected">
                  <mat-list-item
                    *ngFor="let func of functionGroupSelected.functions; let i = index"
                    [ngClass]="{ 'function-selected': selectedFunc === i }"
                  >
                    <h3 matLine (click)="onSelectedFunction(i, func)" title="{{ func.name }} ">
                      {{ func.name }}
                    </h3>
                  </mat-list-item>
                </mat-list>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="FIELDS">
          <div fxLayout="row" class="h-100-p">
            <div fxFlex="37">
              <div class="can-scroll functions-type">
                <mat-list>
                  <mat-list-item class="function-selected">
                    <h3 matLine title="{{ tableName }}">
                      {{ tableName }}
                    </h3>
                  </mat-list-item>
                </mat-list>
              </div>
            </div>
            <div fxFlex="63">
              <div class="can-scroll functions-type">
                <mat-list>
                  <mat-list-item
                    *ngFor="let field of fields; let i = index"
                    [ngClass]="{ 'function-selected': tableFieldIndexSelected === i }"
                  >
                    <h3 matLine (click)="onSelectedFieldTable(field, i)" title="{{ field.name }}">
                      {{ field.name }}
                    </h3>
                  </mat-list-item>
                </mat-list>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
      <div class="insert-function-container mt-8" fxLayoutAlign="start start">
        <button
          mat-flat-button
          color="primary"
          class="insert-btn w-100-p"
          (click)="insertExpressionEditor()"
          [disabled]="editable ? (indexGroupTab === 0 ? !isSelectedFunction : !isSelectedField) : true"
        >
          INSERT
          {{ indexGroupTab === 0 ? functionSelected?.name + '( )' : tableFieldSelected?.name }}
        </button>
      </div>
    </div>
    <mat-card class="description-function-container">
      <div class="text-container">
        <span *ngIf="functionSelected && indexGroupTab === 0">
          <p class="title-function">
            {{ functionSelected.description.method }}
          </p>
          <p>{{ functionSelected.description.summary }}</p>
          <p *ngFor="let attrs of functionSelected.description.params; let i = index">
            <span>{{ attrs }}</span>
          </p>
        </span>
        <span *ngIf="tableFieldSelected && indexGroupTab === 1">
          <p>
            <span class="title-function">
              {{ tableFieldSelected.name }}
            </span>
          </p>
          <p>
            <span>Data type:&nbsp;</span>
            <span>{{ tableFieldSelected.dataType | lowercase }}</span>
          </p>
        </span>
      </div>
    </mat-card>
  </div>
</div>
