import { Component, Input } from '@angular/core';

export class TestUtils {
  static get mockDialogRef(): any {
    return {
      updateSize: (w: number, h: number) => {}
    };
  }
}

@Component({
  selector: 'loading-modal',
  template: ''
})
export class MockLoadingModalComponent {
  @Input() public loading: boolean;

  public startLoading() {}

  public stopLoading() {}

  public showSuccess(message: string) {}

  public showError(message: string) {}

  public showWarning(msg: string) {}
}
