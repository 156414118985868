import { Injectable } from '@angular/core';
import { ProjectsService } from '@neptune/services/projects.service';
import { QueryService } from '@neptune/services/query.service';
import { HttpClient } from '@angular/common/http';
import { StoreService } from '@neptune/services/store.service';
import { CognitoService } from '@neptune/services/cognito.service';
import { Breadcrumb } from '../breadcrumbs.model';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class QueryResolverService extends QueryService {
  constructor(
    private projectService: ProjectsService,
    protected http: HttpClient,
    protected cognitoService: CognitoService,
    protected storeService: StoreService
  ) {
    super(http, cognitoService, storeService);
  }

  public queryManager(queryParams: Params, breadcrumbs: Breadcrumb[]) {
    const breadcrumb1 = {
      order: 1,
      label: 'Projects',
      url: '/pages/user/projects'
    };
    breadcrumbs.push(breadcrumb1);

    this.projectService.getProject(queryParams['pr-id']).subscribe(p => {
      const breadcrumb2 = {
        order: 2,
        label: p.name,
        url: '/pages/user/projects/' + queryParams['pr-id']
      };
      breadcrumbs.push(breadcrumb2);
      if (queryParams['id']) {
        this.getQueryById(queryParams['id']).subscribe(q => {
          const order = 3;
          this.getFolder(queryParams['pr-id'], q.folderId as string, breadcrumbs, order);

          const breadcrumb4 = {
            order: 1000,
            label: 'Query: ' + q.name,
            url: ''
          };
          breadcrumbs.push(breadcrumb4);
          breadcrumbs.sort((x: Breadcrumb, y: Breadcrumb) => (x.order as number) - (y.order as number));
        });
      } else {
        const breadcrumb4 = {
          order: 1000,
          label: 'New Query',
          url: ''
        };
        breadcrumbs.push(breadcrumb4);
        breadcrumbs.sort((x: Breadcrumb, y: Breadcrumb) => (x.order as number) - (y.order as number));
      }
    });
  }

  public getFolder(projectId: string, folderId: string, breadcrumbs: Breadcrumb[], order: number) {
    let new_order = order++;
    this.projectService.getFolderObjByProjectFolderId(projectId, folderId).subscribe(folder => {
      if (folder.parentFolderId) {
        this.getFolder(projectId, folder.parentFolderId, breadcrumbs, new_order);
        new_order++;
      }
      const breadcrumb = {
        order: new_order++,
        label: folder.name,
        url: '/pages/user/projects/' + projectId + '/' + folder.id
      };
      breadcrumbs.push(breadcrumb);
      breadcrumbs.sort((x: Breadcrumb, y: Breadcrumb) => (x.order as number) - (y.order as number));
    });
  }
}
