<div class="folder-selector-dialog">
  <div mat-dialog-title class="dialog-header">
    <div class="dialog-title">
      <span class="h2">{{ title }}</span>
    </div>
  </div>

  <mat-dialog-content fxLayout="column" fxLayoutAlign="center center">
    <div class="folder-selector-content pt-10 pb-20">
      <ng-container *ngIf="!loading">
        <div fxLayoutAlign="start center" fxLayout="column" class="h-100-p px-30">
          <!-- Folder navigation -->
          <div class="w-100-p py-16 h-100-p">
            <mat-form-field fxLayout="row">
              <input matInput placeholder="Search" (input)="filterChanged($event.target.value)" class="w-100-p" />
            </mat-form-field>
            <div class="folder-selector-navigation mb-20" fusePerfectScrollbar>
              <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="folder-selector-tree">
                <!-- This is the tree node template for leaf nodes -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                  <div
                    class="mat-tree-node parent-node pl-34"
                    (click)="toggleSelect(node)"
                    [class.selected]="isFolderSelected(node)"
                  >
                    <mat-icon svgIcon="folder" class="mr-10"></mat-icon>
                    {{ node.name }}
                  </div>
                </mat-tree-node>
                <!-- This is the tree node template for expandable nodes -->
                <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                  <div
                    class="mat-tree-node parent-node pl-10"
                    (click)="toggleSelect(node)"
                    [class.selected]="isFolderSelected(node)"
                  >
                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name" class="mr-10">
                      <mat-icon
                        class="mat-icon-rtl-mirror mr-5"
                        [svgIcon]="treeControl.isExpanded(node) ? 'triangle_down' : 'triangle_right'"
                      >
                        {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                      </mat-icon>
                      <mat-icon svgIcon="folder" class="mr-8"></mat-icon>
                      {{ node.name }}
                    </button>
                  </div>
                </mat-tree-node>
              </mat-tree>
              <div *ngIf="disabledSelect">
                <span>No data to display</span>
              </div>
            </div>
            <!-- Folder listing -->
          </div>
        </div>
      </ng-container>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutAlign="end start" class="dialog-footer" fxLayout="row nowrap">
    <button mat-button color="primary" (click)="onClose()" class="cancel-btn">Cancel</button>
    <button mat-flat-button color="primary" (click)="onSelect()" [disabled]="disabledSelect">Select</button>
  </mat-dialog-actions>
</div>
