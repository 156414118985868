import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, forkJoin, fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import elementResizeDetectorMaker from 'element-resize-detector';

export interface IframeEvent {
  type: IframeEventName;
  custom?: string;
  source?: string;
  data?: any;
}

export enum IframeEventName {
  CLICKED = 'iframe-click',
  LOADED = 'iframe-loaded',
  LOADED_WITH_ERRORS = 'iframe-loaded-with-errors',
  UNLOADED = 'iframe-unloaded',
  STYLES_ADDED = 'iframe-styles-added',
  STYLESHEET_LOAD = 'iframe-stylesheet-load',
  STYLESHEET_LOADED = 'iframe-stylesheet-loaded',
  ALL_STYLESHEETS_LOADED = 'iframe-all-stylesheets-loaded',
  KEYUP = 'iframe-keyup',
  RESIZED = 'iframe-resized',
  CUSTOM = 'iframe-custom'
}

/**
 * This IframeWrapperComponent was based on one open source lib ng-magic-iframe
 * This lib had almost all we needed but some key difference was needed to be changed.
 * This is why I decided to create custom component based on the one from github.
 * Lib had no scrolling inside iframe and there was no option to change this setting.
 * For more info see https://github.com/sebgroup/ng-magic-iframe
 */
@Component({
  selector: 'nep-iframe-wrapper',
  templateUrl: './iframe-wrapper.component.html',
  styleUrls: ['./iframe-wrapper.component.scss']
})
export class IframeWrapperComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('iframe', { static: true }) iframe: ElementRef;

  @Input() set source(value: string) {
    this._source = value;
  }

  @Input() set styles(value: string) {
    this._styles = value;
  }

  @Input() set styleUrls(value: Array<string>) {
    this._styleUrls = value;
  }

  @Input() set autoResize(value: boolean) {
    this._autoResize = value;
  }

  @Input() set resizeDebounceMillis(value: number) {
    this._resizeDebounceMillis = value;
  }

  @Input() set sourceId(value: string) {
    this._sourceId = value;
  }

  @Output() iframeEvent: EventEmitter<IframeEvent> = new EventEmitter();
  @Output() customEvent: EventEmitter<IframeEvent> = new EventEmitter();

  /** Url that points to the html template to be used as the iframe basis */
  public _source: string;
  /** Unique Id for each iframe to be used for communication via PostMessage API */
  private _sourceId: string;
  /** A query parameter 'sid' is added to the template path, will be used be iframe-communication.js */
  public get _uniquePath(): string {
    return `${this._source}?sid=${this._sourceId}`;
  }

  public _styles: string;
  public _styleUrls: Array<string>;
  public _autoResize: boolean = true;
  public _resizeDebounceMillis: number = 50;

  public _styling: Observable<any>;
  public _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public _bodyHeight: Subject<number> = new Subject<number>();

  private _iframeBody: HTMLElement;
  private _iframeDocument: Document;
  private _unsubscribe = new Subject<void>();
  private _eventListeners: Array<any> = [];

  private _elementResizeDetector: elementResizeDetectorMaker.Erd;

  public get angularSourceId() {
    return `NEP-ANGULAR-${this._sourceId}`;
  }

  public get iframeSourceId() {
    return `NEP-IFRAME-${this._sourceId}`;
  }

  constructor(private renderer: Renderer2, private cdr: ChangeDetectorRef) {}

  private updateStyles() {
    this._styling = this._bodyHeight.pipe(
      distinctUntilChanged(),
      debounceTime(this._resizeDebounceMillis),
      tap(val => this.emitEvent(IframeEventName.RESIZED)),
      map(height => ({ 'height.px': height }))
    );

    this.emitCustomEvent = this.emitCustomEvent.bind(this);
  }

  ngOnInit() {
    this._loading
      .pipe(
        filter(value => value === false || value === null),
        takeUntil(this._unsubscribe)
      )
      .subscribe(res => {
        this.emitEvent(res === null ? IframeEventName.LOADED_WITH_ERRORS : IframeEventName.LOADED);
      });
    this.updateStyles();
  }

  ngAfterViewInit() {
    const iframeElement = this.iframe.nativeElement;

    fromEvent(iframeElement, 'load')
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(res => {
        try {
          // declare iframe document and body
          this._iframeDocument = iframeElement.contentDocument;
          this._iframeBody = this._iframeDocument.body;

          // add inline css
          if (this._styles) {
            this.addCss(this._styles);
          }

          // add external stylesheets
          if (this._styleUrls && this._styleUrls.length > 0) {
            this.addStyleSheets(this._styleUrls);
          } else {
            this._loading.next(false);
          }

          // add element resize detector
          if (this._autoResize) {
            this.addElementResizeDetector(
              this._iframeBody,
              iframeElement.contentWindow.getComputedStyle(this._iframeBody)
            );
          }

          // add click listener
          const clickListener = this.renderer.listen(iframeElement.contentWindow, 'click', ($event: MouseEvent) =>
            this.emitEvent(IframeEventName.CLICKED)
          );

          // add key up listener
          const keyUpListener = this.renderer.listen(iframeElement.contentWindow, 'keyup', ($event: KeyboardEvent) =>
            this.emitEvent(IframeEventName.KEYUP)
          );

          // add unload listener
          const unloadListener = this.renderer.listen(
            iframeElement.contentWindow,
            'beforeunload',
            ($event: BeforeUnloadEvent) => {
              this._loading.next(true);
              this.emitEvent(IframeEventName.UNLOADED);
              if (this._iframeBody) {
                this._iframeBody.style.overflow = 'hidden';
                this.cdr.detectChanges();
              }
            }
          );

          // add message listener
          (this.iframe.nativeElement as any).contentWindow.addEventListener('message', this.emitCustomEvent, false);

          this._eventListeners.push(clickListener, keyUpListener, unloadListener);

          this.postMessage({
            type: IframeEventName.CUSTOM,
            custom: 'iframe-wrapper-ready',
            data: {}
          });
        } catch (error) {
          console.log(
            'Event listeners and/or styles and resize listener could not be added due to a cross-origin frame error.'
          );
          console.warn(error);
          this._loading.next(false);
        }
      });
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();

    // detach event listeners
    this._eventListeners.map(listener => listener());

    this.iframe.nativeElement.contentWindow.removeEventListener('message', this.emitCustomEvent, false);

    // if auto resize...
    if (this._autoResize && this._iframeBody && this._elementResizeDetector) {
      this._elementResizeDetector.uninstall(this._iframeBody);
    }
  }

  iframeClickHandler(event) {
    this.iframeEvent.emit(event);
  }

  public reload() {
    if (this._iframeDocument && this._iframeDocument.location) {
      this._iframeDocument.location.reload();
    }
  }

  public postMessage(data: IframeEvent) {
    data.type = IframeEventName.CUSTOM;
    data.source = this.angularSourceId;
    this.iframe.nativeElement.contentWindow.postMessage(data, '*');
  }

  private addCss(styles: string) {
    const styleElement = this._iframeDocument.createElement('style');
    styleElement.appendChild(this._iframeDocument.createTextNode(styles));
    this._iframeDocument.getElementsByTagName('head')[0].appendChild(styleElement);
    this.emitEvent(IframeEventName.STYLES_ADDED);
  }

  private addElementResizeDetector(body: HTMLElement, style: any) {
    this._elementResizeDetector = elementResizeDetectorMaker({ strategy: 'scroll' });
    this._elementResizeDetector.listenTo(body, () => {
      const offsetHeight = body.scrollHeight;
      const marginTop = parseInt(style.getPropertyValue('margin-top'), 10);
      const marginBottom = parseInt(style.getPropertyValue('margin-bottom'), 10);
      const height = offsetHeight + marginTop + marginBottom;
      this._bodyHeight.next(height);
    });
  }

  private addStyleSheets(styleUrls) {
    if (styleUrls.length > 0) {
      // create placeholder for subjects
      const loadSubjects: Array<Subject<string>> = [];

      // loop through all style sheets...
      styleUrls.map((styleUrl: string) => {
        // create link element
        const linkElement: HTMLElement = this._iframeDocument.createElement('link');
        linkElement['rel'] = 'stylesheet';
        linkElement['type'] = 'text/css';
        linkElement['href'] = styleUrl;

        // create load subject that will emit once the stylesheet has loaded
        const loadSubject: Subject<string> = new Subject<string>();
        loadSubjects.push(loadSubject);

        // listen to load event on link
        const stylesheetLoadListener = this.renderer.listen(linkElement, 'load', (test: Event) => {
          this._iframeBody.style.overflow = 'inherit';
          this.emitEvent(IframeEventName.STYLESHEET_LOAD, styleUrl);
          loadSubject.next(styleUrl);
          loadSubject.complete();
          return true;
        });

        // push listener to array so that we can remove them later
        this._eventListeners.push(stylesheetLoadListener);

        // add link to iframe head
        this._iframeDocument.head.appendChild(linkElement);

        // emit load event
        this.emitEvent(IframeEventName.STYLESHEET_LOADED, styleUrl);
      });

      forkJoin(loadSubjects)
        .pipe(takeUntil(this._unsubscribe))
        .subscribe(res => {
          if (styleUrls.length > 1) {
            this.emitEvent(IframeEventName.ALL_STYLESHEETS_LOADED, styleUrls);
          }
          this._loading.next(false);
        });
    }
  }

  private emitCustomEvent(event: MessageEvent) {
    const data = event.data as IframeEvent;

    if (data && data.source === this.iframeSourceId) {
      this.customEvent.emit(data);
    }
  }

  private emitEvent(eventName: IframeEventName, resource?: string) {
    const iframeEvent: IframeEvent = { type: eventName, source: this.iframeSourceId };
    if (resource) {
      iframeEvent.data = resource;
    }
    this.iframeEvent.emit(iframeEvent);
  }
}
