import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/main/content/material.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { PrintOnDemandPreviewDialogComponent } from './print-on-demand-preview-dialog.component';
import { PrintOnDemandPreviewModule } from '../print-on-demand-preview-content/print-on-demand-preview-content.module';
import { LoadingModalModule } from '../loading-modal/loading-modal.module';

@NgModule({
  declarations: [PrintOnDemandPreviewDialogComponent],
  imports: [CommonModule, MaterialModule, FuseSharedModule, PrintOnDemandPreviewModule, LoadingModalModule],
  exports: [PrintOnDemandPreviewDialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: []
})
export class PrintOnDemandPreviewDialogModule {}
