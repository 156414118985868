import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'filterable-action-list-info',
  templateUrl: './filterable-action-list-info.component.html'
})
export class FilterableActionListInfoComponent implements OnInit {
  /** Template inside the MatTab view that contains an `<ng-content>`. */
  @ViewChild(TemplateRef, { static: true }) template: TemplateRef<any>;

  ngOnInit() {}
}
