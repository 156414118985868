export const environment = {
  isPredev: false,
  isDev: false,
  isQa: false,
  isLocal: false,
  isProd: false,
  showDevComponent: false,
  hmr: false,
  defaultScheduleTimeOffset: 5
};

export const QPConfig = {
  appName: 'Neptune',
  appUploadPreviewLimit: 500,
  notificationTypes: {
    error: 'dialog'
  }
};

export const NeptuneSetup = {
  AutoIdleSignOutTime: 20,
  DefaultCacheExpirationTime: 5 * 60 * 1000,
  FileSizeLimit: 250000000
};

export const APIConfig = {
  key: '',
  secret: '',
  LastAccessTimeSuffix: '-lat'
};
