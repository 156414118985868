export class Project {
  updatedAt?: string;
  orgId?: string;
  name: string;
  updatedBy?: string;
  createdAt?: string;
  owners: string[];
  id?: string;
  createdBy?: string;
  status?: string;
  isGlobal?: boolean;
  purgetAt?: string;
  foldersCount?: number;
  defaultSenderId?: string;
  hasChildren?: boolean;
  isProject?: boolean;
  project?: Project;
}

export class Folder {
  updatedAt?: string;
  projectId?: string;
  parentFolderId?: string | null;
  orgId?: string;
  name?: string;
  updatedBy?: string;
  createdAt?: string;
  id?: string;
  createdBy?: string;
  status?: string;
  children?: Folder[] | null;
  isQuery?: boolean;
  isSeedList?: boolean;
  isFolder?: boolean;
  hasChildren?: boolean;
  canGetQueries?: boolean;
  canGetSeedLists?: boolean;
  level?: number;
  hasFolders?: boolean;
}

export class ObjectFolder {
  createdAt: string;
  createdBy: string;
  id: string;
  name: string;
  orgId: string;
  parentFolderId: string;
  projectId: string;
  purgedAt: number;
  status: string;
  updatedAt: string;
  updatedBy: string;
}

export interface ErrorModel {
  message?: string;
  error?: {
    message?: string;
    error?: string;
  };
}

export interface ItemNode {
  hasFolders?: boolean;
  name?: string;
  projectId?: string;
  folderId?: string;
  parentFolderId?: string;
  level?: number;
  parentId?: string;
  id?: string;
}

export interface ProjectFolder extends Folder {
  [key: string]: ProjectFolder | Folder[keyof Folder] | Project;
  folders?: Folder[];
}

export interface UpdatedItem {
  folder: Folder;
  folders: boolean;
  item?: {
    item?: string;
  };
}
