import {
  Component,
  OnInit,
  OnChanges,
  ViewChild,
  AfterViewInit,
  ViewChildren,
  ElementRef,
  QueryList,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ContentChild,
  SimpleChanges
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UntypedFormControl } from '@angular/forms';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { interval } from 'rxjs';
import { throttle } from 'rxjs/operators';
import {
  FilterableActionList,
  FilterableActionListItem,
  FilterableActionItemElement,
  FilterableActionItem
} from './filterable-action-list.interfaces';
import { FilterableActionListInfoComponent } from './filterable-action-list-info.component';

@Component({
  selector: 'app-filterable-action-list',
  templateUrl: './filterable-action-list.component.html',
  styleUrls: ['./filterable-action-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterableActionListComponent implements OnInit, AfterViewInit, OnChanges {
  @Input('data') data: FilterableActionList;
  @Input('onListClose') onListClose: () => any;
  @Input('onListGoBack') onListGoBack: () => any;
  @Input('searchPlaceholder') searchPlaceholder: string = 'Filter';

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output('onItemMouseOver') onItemMouseOver: EventEmitter<FilterableActionItemElement> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output('onItemMouseOut') onItemMouseOut: EventEmitter<void> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output('onItemMouseClick') onItemMouseClick: EventEmitter<FilterableActionItem> = new EventEmitter();

  @ViewChild(CdkVirtualScrollViewport) virtualScrollbar: CdkVirtualScrollViewport;
  @ViewChild('container', { static: true }) container: ElementRef;
  @ViewChildren('tableInformationTip') tableInformationTips: QueryList<any>;
  @ContentChild(FilterableActionListInfoComponent) set filterableActionListInfo(
    info: FilterableActionListInfoComponent
  ) {
    this.info = info;
    this.changeDetectorRef?.detectChanges();
  }
  public info: FilterableActionListInfoComponent;

  public listFilter: UntypedFormControl;
  public filteredList: FilterableActionListItem[];
  public selectedItem: FilterableActionListItem;
  public minBuffer: number;
  public maxBuffer: number;
  public itemSize: number = 36;
  constructor(private domSanitizer: DomSanitizer, private changeDetectorRef: ChangeDetectorRef) {
    this.listFilter = new UntypedFormControl('');
    this.changeDetectorRef.detach();
  }

  ngOnInit() {
    this.filteredList = this.data ? [...this.data.list] : [];
  }

  ngAfterViewInit() {
    this.listFilter.valueChanges.pipe(throttle(ev => interval(100))).subscribe(e => {
      this.filteredList = <FilterableActionListItem[]>(
        this.data.list.filter(table => table.name && table.name.toLowerCase().indexOf(e.toLowerCase()) !== -1)
      );
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue !== changes.data.previousValue) {
      this.filteredList = [...this.data.list];
      this.listFilter.reset('');
      this.checkViewportSize();
      this.changeDetectorRef.reattach();
      this.changeDetectorRef.detectChanges();
    }
  }

  onMouseOver(item: FilterableActionListItem, elementRef: ElementRef) {
    this.onItemMouseOver.emit({ item, elementRef });
  }
  onMouseOut() {
    this.onItemMouseOut.emit();
  }
  onMouseClick(item: FilterableActionListItem) {
    this.onItemMouseClick.emit({ item });
  }
  openMenu(item: FilterableActionListItem, e: MouseEvent) {
    e.preventDefault();
    e.stopImmediatePropagation();
  }
  checkViewportSize() {
    // Differ calculation since the DOM hasn't been updated yet
    setTimeout(() => {
      const height = this.container.nativeElement.offsetHeight;
      const visible = Math.ceil(height / this.itemSize);
      // Tweaked to find a sweet spot were Rendered elements keep as close as visible elements
      // Reference: https://material.angular.io/cdk/scrolling/overview#scrolling-over-fixed-size-items
      const range = this.itemSize * 5;
      this.minBuffer = visible * this.itemSize - range;
      this.maxBuffer = this.minBuffer + range;
      if (this.virtualScrollbar) {
        this.virtualScrollbar.checkViewportSize();
      }
    });
  }
  /**
   * Returns true if current selected item matches parameter id
   *
   * @param id
   */
  isRowSelected(id: string): boolean {
    return this.selectedItem && id === this.selectedItem.id;
  }
  sanitize(value: string) {
    return this.domSanitizer.bypassSecurityTrustHtml(value);
  }
}
