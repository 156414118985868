import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineEditComponent } from '@neptune/components/inline-edit/inline-edit.component';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@neptune/material.module';
import { LongTextModule } from '@neptune/components/long-text/long-text.module';

@NgModule({
  declarations: [InlineEditComponent],
  imports: [CommonModule, MaterialModule, LongTextModule, FormsModule],
  exports: [InlineEditComponent]
})
export class InlineEditModule {}
