import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { DataType } from '@neptune/models/file';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownButtonComponent implements OnInit {
  @Input() label: string;
  @Input() isDisabled: boolean = false;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  @Input() set qpIcon(value: string) {
    this.resetAllValues();
    this.qpIconModel = value;
    this.changeDetector.markForCheck();
  }
  @Input() set materialIcon(value: string) {
    this.resetAllValues();
    this.materialIconModel = value;
    this.changeDetector.markForCheck();
  }

  @Input() set dataTypeIcon(value: DataType) {
    this.resetAllValues();
    this.dataTypeIconModel = value;
    this.changeDetector.markForCheck();
  }

  qpIconModel: string | null = null;
  materialIconModel: string | null = null;
  dataTypeIconModel: DataType | null = null;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {}

  public onClickHandler(): void {
    this.onClick.emit();
  }

  private resetAllValues() {
    this.qpIconModel = null;
    this.materialIconModel = null;
    this.dataTypeIconModel = null;
  }
}
