import { AssetBase } from './asset';

// eslint-disable-next-line no-shadow
export enum DynamicContentTypes {
  EMAIL_SUBJECT = 'EMAIL_SUBJECT',
  EMAIL_BODY = 'EMAIL_BODY',
  PRINT = 'PRINT',
  DATA_OUTPUT = 'DATA_OUTPUT',
  SMS = 'SMS',
  HOSTED_PAGE = 'HOSTED_PAGE'
}

// eslint-disable-next-line no-shadow
export enum DynamicContentStatus {
  NEW = 'NEW',
  DONE = 'DONE'
}

export class DynamicContentUtils {
  static LabelMap: Map<DynamicContentTypes, string> = new Map<DynamicContentTypes, string>([
    [DynamicContentTypes.EMAIL_SUBJECT, 'Email Subject'],
    [DynamicContentTypes.EMAIL_BODY, 'Email Body'],
    [DynamicContentTypes.PRINT, 'Print'],
    [DynamicContentTypes.DATA_OUTPUT, 'Data Output'],
    [DynamicContentTypes.SMS, 'SMS'],
    [DynamicContentTypes.HOSTED_PAGE, 'Hosted Page']
  ]);

  static IconMap: Map<DynamicContentTypes, string> = new Map<DynamicContentTypes, string>([
    [DynamicContentTypes.EMAIL_SUBJECT, 'message_email'],
    [DynamicContentTypes.EMAIL_BODY, 'message_email'],
    [DynamicContentTypes.PRINT, 'message_print'],
    [DynamicContentTypes.DATA_OUTPUT, 'data_output'],
    [DynamicContentTypes.SMS, 'donut_sms'],
    [DynamicContentTypes.HOSTED_PAGE, 'webpage']
  ]);

  /**
   * Currently we're supporting EMAIL BODY for HTML,
   * could be enabled for more types, in that case add the desired types here*/
  static disableHtml(type: DynamicContentTypes): boolean {
    return (
      [DynamicContentTypes.EMAIL_SUBJECT, DynamicContentTypes.PRINT, DynamicContentTypes.DATA_OUTPUT].indexOf(type) > -1
    );
  }

  static disableText(type: DynamicContentTypes): boolean {
    return [DynamicContentTypes.HOSTED_PAGE].indexOf(type) > -1;
  }
}

export interface DynamicContent extends AssetBase {
  /** The default block UUID of the dynamic content. */
  defaultDynamicContentBlockId: string;
  /** A flag that indicates if the dynamic content is active or not. */
  active?: boolean;
  /** The type of the dynamic content. */
  type: DynamicContentTypes;
  /** A flag that indicates if the dynamic content is deleted or not. */
  deleted?: boolean;
  tableName?: string;
}

export interface DynamicContentBlock {
  id: string;

  /** The unique UUID identifier of the dynamic content who this block belongs. */
  dynamicContentId: string;

  /** The unique UUID identifier of the query who is going to be used by this block. */
  queryId: string;

  // FIXME: won't refactor DC API,
  // use the Id to call query api and then stitch name
  /** Query Asset min object */
  query?: {
    id: string;
    name: string;
  };

  /** The name of the dynamic content block. */
  name: string;

  /** The html content of the dynamic content block. */
  htmlContent: string;

  /** The text content of the dynamic content block. */
  textContent: string;

  /** The order place of this dynamic block. */
  evaluationOrder: number;

  /** Flag to identify if the block is the default block */
  default?: boolean;

  /** used for input's fake id */
  uuid?: string;
}

export const DEFAULT_BLOCK: DynamicContentBlock = {
  id: '',
  dynamicContentId: '',
  queryId: '',
  name: '',
  htmlContent: '',
  textContent: '',
  evaluationOrder: 0,
  default: true
};

export const DEFAULT_BLOCKS: DynamicContentBlock[] = [
  { ...DEFAULT_BLOCK, evaluationOrder: 1, default: false },
  DEFAULT_BLOCK
];
