import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from '@neptune/material.module';
import { DatatypeIconModule } from '@neptune/components/datatype-icon/datatype-icon.module';
import { FilterableActionListComponent } from './filterable-action-list.component';
import { LoadingModalModule } from '../loading-modal/loading-modal.module';
import { DropdownButtonModule } from '../dropdown-button/dropdown-button.module';
import { FilterableActionListInfoComponent } from './filterable-action-list-info.component';
import { LongTextModule } from '@neptune/components/long-text/long-text.module';

@NgModule({
  declarations: [FilterableActionListComponent, FilterableActionListInfoComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    MaterialModule,
    ScrollingModule,
    DatatypeIconModule,
    LoadingModalModule,
    DropdownButtonModule,
    LongTextModule
  ],
  exports: [FilterableActionListComponent, FilterableActionListInfoComponent]
})
export class FilterableActionListModule {}
